<template>
    <div class="location-summary">
        <p v-if="location === 'remote'">
            Die Durchführung erfolgt remote von den Büros der usd über das Internet (ggf. mittels VPN-Verbindung).
        </p>

        <p v-else-if="location === 'onsite'">
            Die Durchführung erfolgt vollständig vor Ort beim Auftraggeber.
        </p>

        <p v-else-if="location === 'custom' && projectType === 'contingent'">
            Die Durchführung erfolgt sowohl remote als auch vor Ort beim Auftraggeber.
        </p>

        <div v-else-if="location === 'custom' && projectType === 'asset'">
            <p>
                Die Durchführung erfolgt sowohl remote als auch vor Ort beim Auftraggeber.
            </p>

            <table class="location-table">
                <tr class="table-header-row">
                    <th class="table-header-name">Asset-Name</th>
                    <th class="table-header-timing">Arbeitsort</th>
                </tr>

                <tr v-for="asset in assetList" :key="asset.id" class="table-row">
                    <td>{{ asset.name }}</td>
                    <td v-if="asset.location === 'remote'">Remote</td>
                    <td v-else-if="asset.location === 'onsite'">Vor Ort</td>
                </tr>
            </table>
        </div>

        <div v-else-if="location === 'custom' && projectType === 'scenario'">
            <p>
                Die Durchführung erfolgt sowohl remote als auch vor Ort beim Auftraggeber.
            </p>

            <table class="location-table">
                <tr class="table-header-row">
                    <th class="table-header-name">Asset-Name</th>
                    <th class="table-header-timing">Arbeitsort</th>
                </tr>

                <tr v-for="scenario in scenarioList" :key="scenario.name" class="table-row">
                    <td v-if="scenario.name === 'compromisedWebsite'">Website Defacement</td>
                    <td v-else-if="scenario.name === 'compromisedShop'">Cyber-Angriff auf Online-Shop</td>
                    <td v-else-if="scenario.name === 'localPrivilegeEscalation'">Lokale Rechteausweitung</td>
                    <td v-else-if="scenario.name === 'homeofficeAttack'">Homeoffice Angriff</td>
                    <td v-else-if="scenario.name === 'compromisedNetwork'">Kompromittiertes Netzwerk</td>
                    <td v-else-if="scenario.name === 'externalAttack'">Externer Angriff</td>
                    <td v-else-if="scenario.name === 'externalEnumeration'">Externe Enumeration</td>
                    <td v-else-if="scenario.name === 'insiderAttack'">Insiderangriff</td>
                    <td v-else-if="scenario.name === 'stolenLaptop'">Gestohlener Laptop</td>
                    <td v-else-if="scenario.name === 'ransomware'">Ransomware Angriff</td>
                    <td v-else-if="scenario.name === 'phishing'">Phishing Angriff</td>
                    <td v-else-if="scenario.name === 'cloudSecurity'">Cloud Angriff</td>

                    <td v-if="scenario.location === 'remote'">Remote</td>
                    <td v-else-if="scenario.location === 'onsite'">Vor Ort</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useScopeStore } from '@/store/scopeStore'
import { useProjectStore } from '@/store/projectStore'
import { useAssetStore } from '@/store/assetStore'
import { useScenarioStore } from '@/store/scenarioStore'

export default defineComponent({
    name: 'LocationSummary',

    setup() {
        const projectStore = useProjectStore()
        const projectId = projectStore.uuid.value
        const projectType = projectStore.type

        const assetStore = useAssetStore()
        const assetList = assetStore.getAll.value

        const scenarioStore = useScenarioStore()
        scenarioStore.updateSelectedScenarioList(projectId)
        const scenarioList = scenarioStore.getSelectedScenarios.value

        const scopeStore = useScopeStore()
        const location = scopeStore.location

        return {
            assetList,
            scenarioList,
            location,
            projectType
        }
    }
})
</script>

<style lang="scss" scoped>
.location-summary {
    .location-table {
        margin: 2em;
        width: 80%;

        th {
            color: var(--color-brand-secondary);
            text-align: center;
        }
        td {
            padding: 10px 20px 10px 0;
        }
        .table-header-name {
            width: 45%;
        }
        .table-row {
            text-align: center;
        }
    }
}
</style>
