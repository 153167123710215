import { ref } from 'vue'
import Repository from '@/repository/Repository'

// Store
// Variables are used as two-way bindings
const finalPresentation = ref<boolean>(false)
const auditReport = ref<boolean>(false)
const remediationSupport = ref<boolean>(false)
const liveDemo = ref<boolean>(false)
const retest = ref<boolean>(false)

const actions = {
    initializeOptionals: async(projectId: string) => {
        const params = { projectUuid: projectId }
        try {
            await Repository.get('/optionals', { params })
        } catch (error) {
            await Repository.post('/optionals/init', params)
            // console.log('Save error: ' + error)
        }
    },
    updateOptionals: async(projectId: string) => {
        try {
            const data = {
                projectUuid: projectId,
                finalPresentation: finalPresentation.value,
                auditReport: auditReport.value,
                remediationSupport: remediationSupport.value,
                liveDemo: liveDemo.value,
                retest: retest.value
            }
            await Repository.post('/optionals', data)
        } catch (error) {
            // console.log('Save error: ' + error)
        }
    },
    loadOptionals: async(projectId: string) => {
        try {
            const params = { projectUuid: projectId }
            const response = await Repository.get('/optionals',  { params })
            finalPresentation.value = response.data.finalPresentation as boolean
            auditReport.value = response.data.auditReport as boolean
            remediationSupport.value = response.data.remediationSupport as boolean
            liveDemo.value = response.data.liveDemo as boolean
            retest.value = response.data.retest as boolean
        } catch (error) {
            // console.log(error)
        }
    },
    deleteOptionals: async(projectId: string) => {
        try {
            const params = { projectUuid: projectId }
            await Repository.delete('/optionals', { params })
        } catch (error) {
            // console.log('Delete error: ' + error)
        }
    }
}

export const useOptionalStore = () => {
    return {
        finalPresentation,
        auditReport,
        remediationSupport,
        liveDemo,
        retest,
        ...actions
    } as const
}
