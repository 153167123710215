<template>
    <div class="slider">
        <div class="range-slider-numbers">
            <p v-for="element in rangeElements" :key="element.index">
                {{ element }}
            </p>
        </div>

        <input type="range" :min="min" :max="max" :step="step" v-model.number="selectedValue" class="range-slider" id="rangeSlider" @change="inputFinished()">

        <div v-if="unit !== ''" class="range-slider-unit">
            <h3 v-if="unitSingular !== '' && selectedValue === 1">
                {{ selectedValue }} {{ unitSingular }}
            </h3>
            <h3 v-else>
                {{ selectedValue }} {{ unit }}
            </h3>
        </div>

        <div v-if="error !== ''" class="invalid-feedback">
            {{ error }}
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, computed } from 'vue'

export default defineComponent({
    name: 'Slider',

    props: {
        value: {
            type: Number,
            required: true
        },
        min: {
            default: 1,
            type: Number
        },
        max: {
            type: Number,
            required: true
        },
        step: {
            default: 1,
            type: Number
        },
        unit: {
            default: '',
            type: String
        },
        unitSingular: {
            default: '',
            type: String
        },
        scale: {
            type: Number
        },
        error: {
            type: String,
            default: ''
        }
    },

    setup(props, { emit }) {
        const selectedValue = ref(props.value)

        onMounted(() => {
            if (props.value < props.min) {
                selectedValue.value = props.min
                emit('input', selectedValue.value)
            }
        })


        // Create Array containing appropriate elements for the scale displayed above the slider
        const rangeElements = computed(() => {
            const scaleArray = new Array(Math.floor((props.max - props.min) / props.step) + 1).fill(1).map((_, index) => props.min + index * props.step).map(String)
            if (!props.scale) {
                return scaleArray
            } else {
                for (let i = 1; i < scaleArray.length - 1; i++) {
                    if (parseInt(scaleArray[i]) % props.scale !== 0) {
                        scaleArray.splice(i, 1, '')
                    }
                }
            }
            return scaleArray
        })

        const inputFinished = () => {
            emit('input', selectedValue.value)
        }

        return {
            selectedValue,
            rangeElements,
            inputFinished
        }
    }
})
</script>

<style lang="scss" scoped>
.slider {
    background-color: transparent;
    position: inherit;
}

.slider::before {
    display: none;
}
.slider::after {
    display: none;
}

.range-slider-numbers {
    display: flex;
    justify-content: space-between;
    margin: 0 5px;
}

.range-slider-numbers p {
    width: 22px;
    text-align: center;
}

.range-slider-unit {
    padding-top: .5%;
    text-align: center;
    h3 {
        color: var(--color-brand-secondary);
    }
}

.range-slider {
    width: 100%;
    height: 22px;
    border-radius: 10px;
    background-color: var(--color-white);
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;
    overflow: hidden;
}

input[type=range] {
    -webkit-appearance: none;
}

input[type=range][data-v-2dedc691]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 1px solid var(--color-brand-secondary);
}

.range-slider::-webkit-slider-thumb {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: var(--color-white);
    box-shadow: -1000px 0 0 990px var(--color-brand-secondary);
    cursor: pointer;
}

.range-slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: var(--color-white);
    box-shadow: -1000px 0 0 990px var(--color-brand-secondary);
    cursor: pointer;
}
</style>
