<template>
    <div class='asset-create'>
        <div v-if="$route.name === 'add asset'" class="create-page">
            <div class="asset-page">
                <AssetType @change-asset-type="setAssetType($event)" :error="typeError"/>
            </div>

            <div class="buttons">
                <button class="ghost configurator-button" @click="gotoAssetList()">Asset-Erstellung abbrechen</button>
                <button class="primary configurator-button" @click="gotoAssetConfigurator()">Weiter</button>
            </div>
        </div>

        <div v-else>
            <router-view />
        </div>

        <modal class="info-modal" v-model="assetNameModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Assetname</h2>

            <p>
                Ihre Antwort hilft uns, die Anfrage intern besser zu verarbeiten. Bitte geben Sie einen für Sie eindeutigen
                Asset-Namen ein.
            </p>

            <form-actions slot="footer">
                <button @click="closeAssetNameModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>
    </div>
</template>

<script lang="ts">
import { defineComponent, nextTick, onMounted, ref, watch } from 'vue'
import { useRouter } from '@/composables/use-router'
import { useModal } from '@/composables/use-modal'
import { useProjectStore } from '@/store/projectStore'
import { useAssetStore } from '@/store/assetStore'
import AssetType from '@/components/asset/AssetType.vue'
import { useScopeStore } from '@/store/scopeStore'
import { useOptionalStore } from '@/store/optionalStore'
import { usePriceStore } from '@/store/priceStore'

export default defineComponent({
    name: 'AssetCreate',

    components: {
        AssetType
    },

    setup() {
        const { router, route } = useRouter()

        const { modal: assetNameModal, openModal: openAssetNameModal, closeModal: closeAssetNameModal } = useModal()

        const projectStore = useProjectStore()
        const projectId = projectStore.uuid

        const assetStore = useAssetStore()
        const currentAsset = assetStore.getCurrentAsset.value

        const priceStore = usePriceStore()

        onMounted(async() => {
            if (projectId.value === '') {
                const scopeStore = useScopeStore()
                const optionalStore = useOptionalStore()
                await projectStore.loadProject(route.value.params.id)
                await assetStore.updateAssetList(route.value.params.id)
                await calculateAssetPrice()
                await scopeStore.loadScope(route.value.params.id)
                await priceStore.fetchScopePrice(route.value.params.id)
                await optionalStore.loadOptionals(route.value.params.id)
                await priceStore.fetchOptionalsPrices(route.value.params.id)
            }
        })

        const calculateAssetPrice = async() => {
            await priceStore.calculateAssetPrice(projectId.value)
            await priceStore.fetchProjectPrice(projectId.value)
        }

        const setAssetType = (type: string) => {
            assetStore.setType(type)
        }

        const gotoAssetList = () => {
            window.scrollTo(0, 0)
            assetStore.clearAsset()
            router.replace({ name: 'asset-based project', params: { id: projectId.value } })
        }

        const typeError = ref<string>('')

        watch(() => currentAsset.type, (value) => {
            typeError.value = value === '' ? 'Dies ist ein Pflichtfeld.' : ''
        })

        const validate = async() => {
            typeError.value = currentAsset.type === '' ? 'Dies ist ein Pflichtfeld.' : ''

            if (typeError.value === '') {
                return true
            } else {
                await nextTick()
                const element = document.querySelector('.invalid-feedback')?.parentNode?.parentNode as HTMLElement
                element.scrollIntoView({behavior: 'smooth'})
            }
        }

        const gotoAssetConfigurator = async() => {
            if (await validate()) {
                window.scrollTo(0, 0)
                await router.push({ name: 'configure asset', params: { id: projectId.value } })
            }
        }

        return {
            assetNameModal,
            openAssetNameModal,
            closeAssetNameModal,
            currentAsset,
            setAssetType,
            gotoAssetList,
            typeError,
            gotoAssetConfigurator
        }
    }
})

</script>

<style lang="scss" scoped>
.create-page {
    h2 {
        color: var(--color-brand-primary);
    }
    .asset-page {
        background-color: var(--color-lightest-grey);
    }
}
</style>
