<template>
    <div class="progressbar">
        <div class="progressbar-frame">
            <div class="progressbar-start" :class="{ active: active === 1 }" @click="gotoProject()"/>
            <div v-if="active > 1" class="progressbar-block" :class="{ active: active === 2 }" @click="gotoType()"/>
            <div v-if="active > 2" class="progressbar-block" :class="{ active: active === 3 }" @click="gotoScope()"/>
            <div v-if="active > 3" class="progressbar-block" :class="{ active: active === 4 }" @click="gotoOptionals()"/>
            <div v-if="active > 4" class="progressbar-end" :class="{ active: active === 5 }" @click="gotoSummary()"/>
        </div>

        <div class="progressbar-subtitles">
            <div class="progressbar-text" :class="{active: active === 1}">
                <h2>
                    Projekt
                </h2>
            </div>

            <div class="progressbar-text" :class="{active: active === 2}">
                <h2>
                    Scope
                </h2>
            </div>

            <div class="progressbar-text" :class="{active: active === 3}">
                <h2>
                    Organisation
                </h2>
            </div>

            <div class="progressbar-text" :class="{active: active === 4}">
                <h2>
                    Zusatzleistungen
                </h2>
            </div>

            <div class="progressbar-text" :class="{active: active === 5}">
                <h2>
                    Zusammenfassung
                </h2>
            </div>
        </div>

        <modal class="info-modal" v-model="confirmationModal">
            <img slot="title" src="./../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Möchten Sie Ihren Projektansatz wirklich ändern?</h2>

            <div class="buttons-modal">
                <div class="buttons-left">
                    <button class="ghost-primary button-modal-back-dialog" @click="confirmDeleteProject()">Ja, zurück zur Projektansatzauswahl.</button>
                </div>
                <div class="buttons-right">
                    <button class="primary button-modal-back-dialog" @click="closeConfirmationModal()">Nein, der Projektansatz wird weiterverfolgt.</button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useRouter } from '@/composables/use-router'
import { useModal } from '@/composables/use-modal'
import { useProjectStore } from '@/store/projectStore'

export default defineComponent({
    name: 'Progressbar',

    props: {
        active: {
            type: Number,
            required: true
        }
    },

    setup(props) {
        const { router } = useRouter()

        const { modal: confirmationModal, openModal: openConfirmationModal, closeModal: closeConfirmationModal } = useModal()

        const projectStore = useProjectStore()
        const projectId = projectStore.uuid
        const projectType = projectStore.type

        const gotoProject = () => {
            if (props.active !== 1) {
                openConfirmationModal()
            }
        }

        const confirmDeleteProject = async() => {
            window.scrollTo(0, 0)
            closeConfirmationModal()
            // Reload page
            await router.push({ name: 'new project' })
        }

        const gotoType = async() => {
            if (props.active > 1 && props.active !== 2) {
                window.scrollTo(0, 0)
                if (projectType.value === 'asset') {
                    await router.push({ name: 'asset-based project', params: { id: projectId.value } })
                } else if (projectType.value === 'contingent') {
                    await router.push({ name: 'contingent-based project', params: { id: projectId.value } })
                } else if (projectType.value === 'scenario') {
                    await router.push({ name: 'scenario-based project', params: { id: projectId.value } })
                }
            }
        }

        const gotoScope = async() => {
            if (props.active > 2 && props.active !== 3) {
                window.scrollTo(0, 0)
                await router.push({ name: 'project scope', params: { id: projectId.value } })
            }
        }

        const gotoOptionals = async() => {
            if (props.active > 3 && props.active !== 4) {
                window.scrollTo(0, 0)
                await router.push({ name: 'project optional', params: { id: projectId.value } })
            }
        }

        const gotoSummary = async() => {
            if (props.active > 4) {
                window.scrollTo(0, 0)
                await router.push({ name: 'project summary', params: { id: projectId.value } })
            }
        }

        return {
            gotoProject,
            gotoType,
            gotoScope,
            gotoOptionals,
            gotoSummary,
            confirmDeleteProject,
            confirmationModal,
            openConfirmationModal,
            closeConfirmationModal
        }
    }
})
</script>

<style lang="scss" scoped>
@import "../styles/breakpoints";

.progressbar {
    @include default-padding();
    background-color: var(--color-white);
}

.progressbar-frame {
    height: 50px;
    border: 1px solid var(--color-black);
    border-radius: 25px;
    display: flex;
    align-items: flex-start
}

.progressbar-start, .progressbar-block, .progressbar-end {
    height: 32px;
    width: calc((100% / 5) - 8px);
    border: 1px solid transparent;
    cursor: pointer;
    background-color: var(--color-brand-secondary);
    &.active {
        background-color: var(--color-brand-primary);
    }
}

.progressbar-start {
    margin: 8px 3px 0 10px;
    border-radius: 20px 0 0 20px;
}

.progressbar-block {
    margin: 8px 3px 0 0;
}

.progressbar-end {
    margin-top: 8px;
    border-radius: 0 20px 20px 0;
}

.progressbar-subtitles {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5em 0 2em;
}

.progressbar-text {
    width: 20%;
    text-align: center;
}

.active {
    color: var(--color-brand-primary);
}

@media screen and (min-width: $bp-large) and (max-width: 1670px) {
    h2 {
        font-size: 1.2vw;
    }
}

@media screen and (max-width: $bp-large) {
    .progressbar-subtitles {
        display: block;
        justify-content: flex-start;
        margin-left: 1em;
    }

    .progressbar-subtitles > div {
        counter-increment: progress;
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 5px;
    }

    .progressbar-subtitles > div h2 {
        font-size: 1rem;
        margin: 0;
    }

    .progressbar-subtitles > div:before {
        content: counter(progress);
        font-size: 1rem;
        margin-right: 0.5em;
        font-weight: 300;
        width: 25px;
        height: 25px;
        background: #eee;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .progressbar-subtitles > div.active:before {
        background-color: var(--color-brand-primary);
        color: white;
        font-weight: normal;
    }

    .progressbar {
        padding: 1em 1em 0 1em !important;
    }
}
</style>
