<template>
    <div class='webservice-asset'>
        <div v-if="currentStep === 0">
            <div class="asset-question">
                <h2>
                    Assetname *
                </h2>
                <p>
                    Bitte geben Sie einen Namen für das Asset ein.
                </p>

                <text-field id="asset-name" label="" v-model="currentAsset.name" :error="nameError"></text-field>

                <div class="buttons-info">
                    <button class="button-info" @click="openAssetNameModal">Weitere Infos</button>
                </div>
            </div>

            <div class="asset-question asset-question-white">
                <h2>
                    Kurzbeschreibung *
                </h2>
                <p>
                    Bitte beschreiben Sie in wenigen Sätzen, wofür der von Ihnen angelegte Webservice eingesetzt wird.
                </p>

                <text-area id="webservice-description" label="" v-model="currentAsset.description" :error="descriptionError"/>

                <div class="buttons-info">
                    <button class="button-info" @click="openDescriptionModal">Weitere Infos</button>
                </div>
            </div>
        </div>

        <div v-if="currentStep === 1">
            <div class="asset-question">
                <h2>
                    Funktionsumfang *
                </h2>
                <p>
                    Wie groß ist Ihr Webservice, gemessen an der Anzahl bereitgestellter API Endpunkte?
                </p>

                <radio-field label="" id="webservice-size" v-model="webservice.size" :options="sizeOptions" :error="sizeError" continuous/>

                <div class="buttons-info">
                    <button class="button-info" @click="openSizeModal">Weitere Infos</button>
                </div>
            </div>
        </div>

        <div v-if="currentStep === 2">
            <div class="asset-question">
                <h2>
                    Geschützter Benutzerbereich *
                </h2>
                <p>
                    Existieren API Endpunkte auf die man nur mit einem gültigen Benutzer und nach erfolgreicher Authentisierung
                    (Anmeldung) zugreifen kann?
                </p>

                <tristate-switch-field id="webservice-authentication" v-model="webservice.authentication" @input="checkClearValueRoles" :error="authenticationError" label="" label-pre="Ja" label-post="Nein"/>

                <div class="buttons-info">
                    <button class="button-info" @click="openAuthenticationModal">Weitere Infos</button>
                </div>

                <div v-if="webservice.authentication">
                    <hr>

                    <h2>
                        Benutzerrollen *
                    </h2>
                    <p>
                        Wie viele Rollen mit jeweils unterschiedlichen Zugriffsberechtigungen existieren in dem Webservice?
                    </p>

                    <radio-field id="webservice-roles" label="" v-model="webservice.roles" :options="rolesOptions" :error="rolesError" continuous/>

                    <div class="buttons-info">
                        <button class="button-info" @click="openRolesModal">Weitere Infos</button>
                    </div>
                </div>
            </div>

            <div class="asset-question asset-question-white">
                <h2>
                    Technische Dokumentation *
                </h2>
                <p>
                    Können Sie eine Beschreibung der API Endpunkte in einem gültigen, technischen Format bereitstellen?
                </p>

                <tristate-switch-field id="webservice-technical-description" v-model="webservice.technicalDescription" @input="checkClearValuesTechnicalDescriptionFormatTechnicalDocumentation" :error="technicalDescriptionError" label="" label-pre="Ja" label-post="Nein"/>

                <div class="buttons-info">
                    <button class="button-info" @click="openTechnicalDescriptionModal">Weitere Infos</button>
                </div>

                <div v-if="webservice.technicalDescription">
                    <hr>

                    <h2>
                        Format der API-Dokumentation *
                    </h2>
                    <p>
                        Welches Format hat die technische API Beschreibung?
                    </p>

                    <BoxSelectRadio id="webservice-technical-description-format" v-model="webservice.technicalDescriptionFormat" :options="technicalDescriptionFormatOptions" @input="checkClearValueTechnicalDescriptionFormatIndividual" :error="technicalDescriptionFormatError"/>

                    <div v-if="webservice.technicalDescriptionFormat === 'individual'">
                        <hr>

                        <h2>
                            Konkretisierung des Formats <sup>(optional)</sup>
                        </h2>
                        <p>
                            Bitte geben sie das Format ihrer technischen API Beschreibung an.
                        </p>

                        <text-field id="webservice-technical-description-format-individual" label="" v-model="webservice.technicalDescriptionFormatIndividual" />
                    </div>
                </div>

                <div v-else-if="webservice.technicalDescription === false">
                    <hr>

                    <h2>
                        Entwicklerdokumentation *
                    </h2>
                    <p>
                        Können Sie eine Entwicklerdokumentation in einem beliebigen Format (z.B. Kommentare im Source Code,
                        ein Lasten-/Pflichtenheft) bereitstellen, welche die Endpunkte Ihres Webservice (Funktionen, URLs,
                        Parameter usw.) beschreibt?
                    </p>

                    <tristate-switch-field id="webservice-technical-documentation" v-model="webservice.technicalDocumentation" :error="technicalDocumentationError" label="" label-pre="Ja" label-post="Nein"/>
                </div>
            </div>

            <div class="asset-question">
                <h2>
                    Webtechnologie <sup>(optional)</sup>
                </h2>
                <p>
                    Auf welcher Technologie basiert Ihr Webservice?
                </p>

                <BoxSelectRadio id="webservice-technology" v-model="webservice.technology" :options="technologyOptions" />
            </div>
        </div>

        <div v-if="currentStep === 3">
            <div class="asset-question">
                <h2>
                    Erreichbarkeit im Internet *
                </h2>
                <p>
                    Ist der Webservice direkt aus dem Internet erreichbar?
                </p>

                <tristate-switch-field id="webservice-reachability" v-model="webservice.reachability" :error="reachabilityError" label="" label-pre="Ja" label-post="Nein"/>

                <div class="buttons-info">
                    <button class="button-info" @click="openReachabilityModal">Weitere Infos</button>
                </div>
            </div>

            <div class="asset-question asset-question-white">
                <h2>
                    Applikationsbetrieb <sup>(optional)</sup>
                </h2>
                <p>
                    Wie erfolgt der Betrieb des Webservices?
                </p>

                <BoxSelectRadio id="webservice-operation" v-model="webservice.operation" :options="operationOptions" @input="checkClearValueOperator"/>

                <div v-if="webservice.operation === 'external' || webservice.operation === 'mixed'">
                    <hr>

                    <h2>
                        Liste der Dienstleister <sup>(optional)</sup>
                    </h2>
                    <p>
                        Gerne dürfen Sie uns den oder die Dienstleister für den Betrieb des Webservices nennen.
                    </p>

                    <text-field id="webservice-operator" label="" v-model="webservice.operatorList[0]" />

                    <div v-if="numberAdditionalOperators === 0" class="add-button">
                        <button class="ghost-primary" @click="addOperator()">Weiteren Dienstleister hinzufügen</button>
                    </div>

                    <div v-for="currentIndex in numberAdditionalOperators" :key="currentIndex">
                        <div class="additional-field">
                            <text-field :id="'webservice-operator' + currentIndex" label="" v-model="webservice.operatorList[currentIndex]" />
                            <div class="icon-remove">
                                <button class="icon-button" @click="removeOperatorFromList(currentIndex)">
                                    <i class="material-icon mdi mdi-close" />
                                </button>
                            </div>
                        </div>

                        <div v-if="numberAdditionalOperators === currentIndex" class="add-button">
                            <button class="ghost-primary" @click="addOperator()">Weiteren Dienstleister hinzufügen</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="asset-question">
                <h2>
                    Cloud <sup>(optional)</sup>
                </h2>
                <p>
                    Erfolgt der Betrieb des Webservices ganz oder teilweise in der Cloud?
                </p>

                <tristate-switch-field id="webservice-cloud" v-model="webservice.cloud" @input="checkClearValueCloudProviderCloudProviderIndividual" label="" label-pre="Ja" label-post="Nein"/>

                <div v-if="webservice.cloud">
                    <hr>

                    <h2>
                        Cloudanbieter <sup>(optional)</sup>
                    </h2>
                    <p>
                        Welcher Cloudanbieter wird von Ihnen eingesetzt?
                    </p>

                    <BoxSelectCheckbox id="webservice-cloud-provider" v-model="webservice.cloudProviderList" :options="cloudProviderOptions" @input="checkClearValueCloudProviderIndividual"/>

                    <div class="buttons-info">
                        <button class="button-info" @click="openCloudProviderModal">Weitere Infos</button>
                    </div>

                    <div v-if="webservice.cloudProviderList.includes('individual')">
                        <hr>

                        <h2>
                            Liste der Cloudanbieter <sup>(optional)</sup>
                        </h2>
                        <p>
                            Bitte nennen Sie uns nachfolgend den eingesetzten Cloudanbieter.
                        </p>

                        <text-field id="webservice-cloud-provider-individual" label="" v-model="webservice.cloudProviderIndividualList[0]"/>

                        <div v-if="numberAdditionalCloudProviders === 0" class="add-button">
                            <button class="ghost-primary" @click="addCloudProvider()">Weiteren Cloudanbieter hinzufügen</button>
                        </div>

                        <div v-for="currentIndex in numberAdditionalCloudProviders" :key="currentIndex">
                            <div class="additional-field">
                                <text-field :id="'webservice-cloud-provider-individual' + currentIndex" label="" v-model="webservice.cloudProviderIndividualList[currentIndex]" />
                                <div class="icon-remove">
                                    <button class="icon-button" @click="removeCloudProviderFromList(currentIndex)">
                                        <i class="material-icon mdi mdi-close" />
                                    </button>
                                </div>
                            </div>

                            <div v-if="numberAdditionalCloudProviders === currentIndex" class="add-button">
                                <button class="ghost-primary" @click="addCloudProvider()">Weiteren Cloudanbieter hinzufügen</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="currentStep === 4">
            <div class="asset-question">
                <h2>
                    Schutzbedarf <sup>(optional)</sup>
                </h2>
                <p>
                    Haben Sie den Schutzbedarf des Webservices ermittelt?
                </p>

                <tristate-switch-field id="webservice-security-assessment" v-model="webservice.securityAssessment" @input="checkClearValueSecurityAssessmentLevel" label="" label-pre="Ja" label-post="Nein"/>

                <div class="buttons-info">
                    <button class="button-info" @click="openSecurityAssessmentModal">Weitere Infos</button>
                </div>

                <div v-if="webservice.securityAssessment">
                    <hr>

                    <h2>
                        Höhe des Schutzbedarfs <sup>(optional)</sup>
                    </h2>
                    <p>
                        Wie hoch ist der Schutzbedarf?
                    </p>

                    <radio-field label="" id="webservice-security-assessment-level" v-model="webservice.securityAssessmentLevel" :options="securityAssessmentLevelOptions" continuous/>

                    <div class="buttons-info">
                        <button class="button-info" @click="openSecurityAssessmentLevelModal">Weitere Infos</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="buttons">
            <div class="buttons-left">
                <button class="ghost-primary space-right" @click="back">Zurück</button>
            </div>
            <div class="buttons-center">
                <button v-if="route.path.includes('/edit')" class="ghost configurator-button" @click="openConfirmationEditModal()">Asset-Bearbeitung abbrechen</button>
                <button v-else class="ghost configurator-button" @click="openConfirmationCreateModal()">Asset-Erstellung abbrechen</button>
            </div>
            <div class="buttons-right">
                <button v-if="currentStep === 4 && !route.path.includes('/edit')" class="primary" @click="completeAsset">Asset-Konfiguration abschließen</button>
                <button v-else-if="currentStep === 4 && route.path.includes('/edit')" class="primary" @click="completeAsset">Asset-Bearbeitung abschließen</button>
                <button v-else class="primary" @click="advance">Weiter</button>
            </div>
        </div>

        <modal class="info-modal" v-model="confirmationCreateModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Möchten Sie die Asset-Erstellung wirklich abbrechen?</h2>

            <p>
                Dabei geht Ihre bisherige Konfiguration verloren.
            </p>

            <div class="buttons-modal">
                <div class="buttons-left">
                    <button class="ghost-primary button-modal-back-dialog" @click="gotoAssetList()">Ja, zurück zur Assetübersicht.</button>
                </div>
                <div class="buttons-right">
                    <button class="primary button-modal-back-dialog" @click="closeConfirmationCreateModal()">Nein, Asset-Konfiguration behalten.</button>
                </div>
            </div>
        </modal>

        <modal class="info-modal" v-model="confirmationEditModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Möchten Sie die Asset-Bearbeitung wirklich abbrechen?</h2>

            <p>
                Dabei gehen Ihre bisherigen Änderungen verloren.
            </p>

            <div class="buttons-modal">
                <div class="buttons-left">
                    <button class="ghost-primary button-modal-back-dialog" @click="gotoAssetList()">Ja, zurück zur Assetübersicht.</button>
                </div>
                <div class="buttons-right">
                    <button class="primary button-modal-back-dialog" @click="closeConfirmationEditModal()">Nein, Asset-Konfiguration behalten.</button>
                </div>
            </div>
        </modal>

        <modal class="info-modal" v-model="assetNameModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Assetname</h2>

            <p>
                Ihre Antwort hilft uns, die Anfrage intern besser zu verarbeiten. Bitte geben Sie einen für Sie eindeutigen
                Asset-Namen ein.
            </p>

            <form-actions slot="footer">
                <button @click="closeAssetNameModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="assetNameExistsModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Asset mit identischem Namen</h2>

            <p>
                Es existiert bereits ein Asset mit dem gleichen Namen. Möchten Sie den Namen des Assets ändern?
            </p>

            <div class="buttons-modal">
                <div class="buttons-left">
                    <button class="ghost-primary button-modal-back-dialog" @click="closeAssetNameExistsModal()">Ja, Namen ändern.</button>
                </div>
                <div class="buttons-right">
                    <button class="primary button-modal-back-dialog" @click="acceptAssetNameExists()">Nein, so fortfahren.</button>
                </div>
            </div>
        </modal>

        <modal class="info-modal" v-model="descriptionModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Kurzbeschreibung</h2>

            <p>
                Bitte beschreiben Sie in wenigen Sätzen, wofür der von Ihnen angelegte Webservice eingesetzt wird.
                Zentrale Fragen sind:
            </p>

            <ul>
                <li>
                    Von wem und wofür wird der Webservice genutzt?
                </li>
                <li>
                    Welche Daten werden von dem Webservice verarbeitet und/oder  gespeichert?
                </li>
            </ul>

            <p>
                Ihre Beschreibung hilft uns, Ihren Prüfumfang besser zu verstehen. Je detaillierter Sie auf das Asset
                eingehen, umso besser können wir Ihren Prüfumfang im Vorfeld analysieren und kritisch hinterfragen.
            </p>

            <form-actions slot="footer">
                <button @click="closeDescriptionModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="sizeModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Funktionsumfang</h2>

            <p>
                Funktionen werden bei Webservices über API Endpunkte bereitgestellt. Jeder API Endpunkt hat eine eindeutige
                URL und kann über keinen, einen oder mehrere Parameter verfügen.
            </p>
            <p>
                Im Rahmen des Pentests werden die von dem Webservice bereitgestellten Endpunkte auf Schwachstellen überprüft.
                Je mehr Endpunkte bereitgestellt werden, umso aufwändiger wird die Sicherheitsanalyse.
            </p>
            <p>
                Damit wir einen Eindruck von der Größe Ihres zu prüfenden Webservices gewinnen, bitten wir um eine grobe
                Abschätzung der Anzahl bereitgestellter Endpunkte. Es ist nicht notwendig eine exakte Aussage zu treffen.
                Die Anzahl der Parameter je Endpunkt ist ebenfalls nicht relevant.
            </p>
            <p>
                Bitte berücksichtigen Sie, dass wir den Pentest bei sehr großen Webservices auf Basis eines risikobasierten
                Ansatzes durchführen. Dabei werden nicht alle Endpunkte im Detail von uns geprüft, sondern eine umfangreiche
                Auswahl der wichtigsten bzw. kritischsten Endpunkte untersucht. Gerne stimmen wir die Auswahl im Rahmen
                des Kick-offs mit Ihnen ab
            </p>

            <form-actions slot="footer">
                <button @click="closeSizeModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="authenticationModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Geschützter Benutzerbereich</h2>

            <p>
                Sensible, vertrauliche Personen- und/oder Unternehmensdaten sowie Funktionen, die diese Daten verarbeiten,
                sollten nicht öffentlich über einen Webservice zur Verfügung gestellt werden. Der Zugriff auf diese Daten
                sowie die Funktionen sollte stattdessen authentisiert erfolgen, also mit einem gültigen Benutzernamen sowie
                nach erfolgreicher Authentisierung (z.B. mit einem Passwort).
            </p>
            <p>
                Webservices, die über einen geschützten Bereich verfügen, werden von uns immer authentisiert überprüft.
                Hierdurch steigt der Prüfaufwand und damit die Kosten, dafür erhalten Sie jedoch ein wesentlich besseres
                Lagebild von der Sicherheit des Webservices und ggf. vorhandenen Risiken.
            </p>

            <form-actions slot="footer">
                <button @click="closeAuthenticationModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="rolesModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Benutzerrollen</h2>

            <p>
                Häufig ist es in der Praxis notwendig, dass ausgewählte Funktionen oder Daten eines Webservice ausschließlich
                bestimmten Nutzern zur Verfügung gestellt werden sollen. Das heißt, nicht jeder Nutzer soll auf alle
                Funktionen und alle Daten zugreifen können.
            </p>
            <p>
                Der Zugriff auf die jeweiligen Funktionen und Daten wird dabei über Rollen mit entsprechenden Zugriffsrechten
                realisiert. Es können beliebig viele Rollen mit unterschiedlichen Rechten existieren.
            </p>
            <p>
                Typisch sind folgende Rollen:
            </p>
            <ul>
                <li>Normale Nutzer</li>
                <li>Privilegierte Nutzer mit zusätzlichen Rechten</li>
                <li>Administrative Nutzer mit vollen Rechten</li>
            </ul>
            <p>
                Verfügt ein Webservice über mehr als 2 Rollen, empfehlen wir die Prüfung von maximal 2 Rollen, um den
                notwendigen Prüfaufwand und verbundene Kosten zu beschränken. Dabei werden die Rolle mit den meisten und
                die Rolle mit den wenigsten Rechten überprüft.
            </p>

            <form-actions slot="footer">
                <button @click="closeRolesModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="technicalDescriptionModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Technische Dokumentation</h2>

            <p>
                Gültige, technische Formate sind z.B. OpenAPI, Swagger, Proxy Capture Files oder Postman Scripte. Sollten
                Sie ausschließlich über eine Entwicklerdokumentation oder über keine Dokumentation verfügen, beantworten
                Sie die Frage bitte mit "Nein".
            </p>

            <form-actions slot="footer">
                <button @click="closeTechnicalDescriptionModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="reachabilityModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Erreichbarkeit im Internet</h2>

            <p>
                Webservices, die Unternehmens-intern, also im internen Firmen-Netzwerk, betrieben werden, sind nicht direkt
                über das Internet erreichbar.
            </p>
            <p>
                Webservices, die öffentlich im Internet oder alternativ in einer an das Internet angebundenen demilitarisierten
                Zone mit oder ohne Schutz durch eine Firewall betrieben werden, sind direkt über das Internet erreichbar.
            </p>
            <p>
                Häufig kommt es dabei vor, dass der Internet-Zugriff mittels Black- bzw. White Listing auf bestimmte
                IP-Adressen beschränkt wird. Für die Beantwortung der Frage ist dies unerheblich; der Webservices ist
                direkt aus dem Internet erreichbar.
            </p>

            <form-actions slot="footer">
                <button @click="closeReachabilityModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="cloudProviderModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Cloudanbieter</h2>

            <p>
                Im Rahmen des Pentests müssen ggf. besondere Vorgaben des Cloudanbieters eingehalten werden. Dies kann
                beispielsweise eine Vorankündigung der Sicherheitsanalyse bedeuten.
            </p>

            <form-actions slot="footer">
                <button @click="closeCloudProviderModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="securityAssessmentModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Schutzbedarf</h2>

            <p>
                Der Schutzbedarf misst sich an den potenziellen Schäden, die mit der Beeinträchtigung des betroffenen
                Webservices einhergehen. Dabei werden üblicherweise die drei Schutzziele Integrität, Vertraulichkeit und
                Verfügbarkeit betrachtet. Bei der Bewertung der Schäden sollten neben finanziellen Aspekte weitere
                Schadensszenarien wie zum Beispiel: 
            </p>
            <ul>
                <li>Verstöße gegen Gesetze, Vorschriften oder Verträge</li>
                <li>Beeinträchtigungen des informationellen Selbstbestimmungsrechts</li>
                <li>Beeinträchtigungen der persönlichen Unversehrtheit</li>
                <li>Beeinträchtigungen der Aufgabenerfüllung</li>
            </ul>
            <p>
                berücksichtigt werden.
            </p>

            <form-actions slot="footer">
                <button @click="closeSecurityAssessmentModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="securityAssessmentLevelModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Höhe des Schutzbedarfs</h2>

            <p>
                Die konkrete Ausprägung der vorgenannten Schutzbedarfskategorien wird von jedem Unternehmen individuell
                vorgenommen und kann sich unterscheiden. Das Bundesamt für Informationssicherheit (BSI) liefert im
                BSI-Standard 100-4 folgendes Beispiel:
            </p>
            <ul>
                <li>Niedrig: kaum spürbare Auswirkung</li>
                <li>Normal: spürbare Auswirkungen</li>
                <li>Hoch: erhebliche Auswirkungen</li>
                <li>Sehr hoch: existentielle Auswirkungen</li>
            </ul>

            <form-actions slot="footer">
                <button @click="closeSecurityAssessmentLevelModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, onMounted, computed, watch, nextTick } from 'vue'
import { useModal } from '@/composables/use-modal'
import { useRouter } from '@/composables/use-router'
import { useProjectStore } from '@/store/projectStore'
import { useAssetStore } from '@/store/assetStore'
import Slider from '@/components/Slider.vue'
import BoxSelectRadio from '@/components/BoxSelectRadio.vue'
import BoxSelectCheckbox from '@/components/BoxSelectCheckbox.vue'
import { RequiredValidator, RequiredValidatorBoolean } from '@/validator/validator'

export default defineComponent({
    name: 'WebserviceAsset',

    props: {
        passedAsset: {
            type: Object,
            default: null
        }
    },

    components: {
        Slider,
        BoxSelectRadio,
        BoxSelectCheckbox
    },

    setup(props, { emit }) {
        const { router, route } = useRouter()

        const assetStore = useAssetStore()
        const currentAsset = computed(() => {
            if (route.value.path.includes('/edit')) {
                const assetList = assetStore.getAll.value
                return assetList.filter(asset => asset.id === route.value.params.assetId)[0]
            } else {
                return assetStore.getCurrentAsset.value
            }
        })

        const projectStore = useProjectStore()
        const projectId = projectStore.uuid

        const currentStep = ref<number>(0)

        const webservice = reactive<any>({
            size: typeof props.passedAsset.config.size === 'undefined' ? '' : props.passedAsset.config.size,
            authentication: typeof props.passedAsset.config.authentication === 'undefined' ? null : props.passedAsset.config.authentication,
            roles: typeof props.passedAsset.config.roles === 'undefined' ? '' : props.passedAsset.config.roles,
            technicalDescription: typeof props.passedAsset.config.technicalDescription === 'undefined' ? null : props.passedAsset.config.technicalDescription,
            technicalDescriptionFormat: typeof props.passedAsset.config.technicalDescriptionFormat === 'undefined' ? '' : props.passedAsset.config.technicalDescriptionFormat,
            technicalDescriptionFormatIndividual: typeof props.passedAsset.config.technicalDescriptionFormatIndividual === 'undefined' ? '' : props.passedAsset.config.technicalDescriptionFormatIndividual,
            technicalDocumentation: typeof props.passedAsset.config.technicalDocumentation === 'undefined' ? null : props.passedAsset.config.technicalDocumentation,
            technology: typeof props.passedAsset.config.technology === 'undefined' ? '' : props.passedAsset.config.technology,
            reachability: typeof props.passedAsset.config.reachability === 'undefined' ? null : props.passedAsset.config.reachability,
            operation: typeof props.passedAsset.config.operation === 'undefined' ? '' : props.passedAsset.config.operation,
            operatorList: typeof props.passedAsset.config.operatorList === 'undefined' ? [] : props.passedAsset.config.operatorList,
            cloud: typeof props.passedAsset.config.cloud === 'undefined' ? null : props.passedAsset.config.cloud,
            cloudProviderList: typeof props.passedAsset.config.cloudProviderList === 'undefined' ? [] : props.passedAsset.config.cloudProviderList,
            cloudProviderIndividualList: typeof props.passedAsset.config.cloudProviderIndividualList === 'undefined' ? [] : props.passedAsset.config.cloudProviderIndividualList,
            securityAssessment: typeof props.passedAsset.config.securityAssessment === 'undefined' ? null : props.passedAsset.config.securityAssessment,
            securityAssessmentLevel: typeof props.passedAsset.config.securityAssessmentLevel === 'undefined' ? '' : props.passedAsset.config.securityAssessmentLevel
        })

        const { modal: assetNameModal, openModal: openAssetNameModal, closeModal: closeAssetNameModal } = useModal()
        const { modal: assetNameExistsModal, openModal: openAssetNameExistsModal, closeModal: closeAssetNameExistsModal } = useModal()
        const { modal: descriptionModal, openModal: openDescriptionModal, closeModal: closeDescriptionModal } = useModal()
        const { modal: sizeModal, openModal: openSizeModal, closeModal: closeSizeModal } = useModal()
        const { modal: authenticationModal, openModal: openAuthenticationModal, closeModal: closeAuthenticationModal } = useModal()
        const { modal: rolesModal, openModal: openRolesModal, closeModal: closeRolesModal } = useModal()
        const { modal: technicalDescriptionModal, openModal: openTechnicalDescriptionModal, closeModal: closeTechnicalDescriptionModal } = useModal()
        const { modal: reachabilityModal, openModal: openReachabilityModal, closeModal: closeReachabilityModal } = useModal()
        const { modal: cloudProviderModal, openModal: openCloudProviderModal, closeModal: closeCloudProviderModal } = useModal()
        const { modal: securityAssessmentModal, openModal: openSecurityAssessmentModal, closeModal: closeSecurityAssessmentModal } = useModal()
        const { modal: securityAssessmentLevelModal, openModal: openSecurityAssessmentLevelModal, closeModal: closeSecurityAssessmentLevelModal } = useModal()
        const { modal: confirmationCreateModal, openModal: openConfirmationCreateModal, closeModal: closeConfirmationCreateModal } = useModal()
        const { modal: confirmationEditModal, openModal: openConfirmationEditModal, closeModal: closeConfirmationEditModal } = useModal()

        const sizeOptions = [
            {
                id: 'very small',
                label: 'Sehr klein<br>(< 5 Endpunkte)'
            },
            {
                id: 'small',
                label: 'Klein<br>(5-10 Endpunkte)'
            },
            {
                id: 'medium',
                label: 'Mittel<br>(11-30 Endpunkte)'
            },
            {
                id: 'large',
                label: 'Groß<br>(31-50 Endpunkte)'
            },
            {
                id: 'very large',
                label: 'Sehr groß<br>(> 50 Endpunkte)'
            }
        ]

        const rolesOptions = [
            {
                id: '1',
                label: '1 Rolle'
            },
            {
                id: '2',
                label: '2 Rollen'
            },
            {
                id: '2+',
                label: 'mehr als 2 Rollen'
            }
        ]

        const technicalDescriptionFormatOptions = [
            {
                id: 'openapi',
                label: ' OpenAPI'
            },
            {
                id: 'swagger',
                label: 'Swagger'
            },
            {
                id: 'capture file',
                label: 'Proxy Capture File'
            },
            {
                id: 'postman',
                label: 'Postman Script'
            },
            {
                id: 'individual',
                label: 'Sonstige'
            }
        ]

        const technologyOptions = [
            {
                id: 'rest',
                label: 'REST'
            },
            {
                id: 'soap',
                label: 'SOAP'
            },
            {
                id: 'xml rpc',
                label: 'XML RPC'
            },
            {
                id: 'proprietary',
                label: 'Proprietäre Schnittstelle'
            },
            {
                id: 'unknown',
                label: 'Unbekannt'
            }
        ]

        const operationOptions = [
            {
                id: 'customer',
                label: 'Durch uns selbst'
            },
            {
                id: 'external',
                label: 'Durch einen Dienstleister'
            },
            {
                id: 'mixed',
                label: 'Gemeinsam mit einem Dienstleister'
            }
        ]

        const cloudProviderOptions = [
            {
                id: 'gcp',
                label: 'Google Cloud Platform (GCP)'
            },
            {
                id: 'aws',
                label: 'Amazon Web Services (AWS)'
            },
            {
                id: 'azure',
                label: 'Microsoft Azure'
            },
            {
                id: 'individual',
                label: 'Andere'
            }
        ]

        const securityAssessmentLevelOptions = [
            {
                id: 'low',
                label: 'Niedrig'
            },
            {
                id: 'normal',
                label: 'Normal'
            },
            {
                id: 'high',
                label: 'Hoch'
            },
            {
                id: 'very high',
                label: 'Sehr hoch'
            }
        ]

        const numberAdditionalOperators = ref<number>(0)
        const numberAdditionalCloudProviders = ref<number>(0)

        const checkOperatorListLength = () => {
            if (webservice.operatorList.length !== 0) {
                numberAdditionalOperators.value = webservice.operatorList.length - 1
            }
        }

        const checkCloudProviderIndividualListLength = () => {
            if (webservice.cloudProviderIndividualList.length !== 0) {
                numberAdditionalCloudProviders.value = webservice.cloudProviderIndividualList.length - 1
            }
        }

        const removeOperatorFromList = (index: number) => {
            webservice.operatorList.splice(index, 1)
            numberAdditionalOperators.value--
        }

        const removeCloudProviderFromList = (index: number) => {
            webservice.cloudProviderIndividualList.splice(index, 1)
            numberAdditionalCloudProviders.value--
        }

        const addOperator = () => {
            numberAdditionalOperators.value++
        }

        const addCloudProvider = () => {
            numberAdditionalCloudProviders.value++
        }

        onMounted(() => {
            checkOperatorListLength()
            checkCloudProviderIndividualListLength()
        })

        const markAssetDone = () => {
            props.passedAsset.config = webservice
            emit('webserviceAssetDone', props.passedAsset)
        }

        const completeAsset = async() => {
            if (await checkAdvance()) {
                markAssetDone()
            }
        }

        const checkClearValueRoles = () => {
            if (webservice.authentication !== true) {
                webservice.roles = ''
            }
        }

        const checkClearValueTechnicalDescriptionFormatIndividual = () => {
            if (webservice.technicalDescriptionFormat !== 'individual') {
                webservice.technicalDescriptionFormatIndividual = ''
            }
        }

        const checkClearValuesTechnicalDescriptionFormatTechnicalDocumentation = () => {
            if (webservice.technicalDescription) {
                webservice.technicalDocumentation = null
            } else if (webservice.technicalDescription === false) {
                webservice.technicalDescriptionFormat = ''
                webservice.technicalDescriptionFormatIndividual = ''
            } else {
                webservice.technicalDocumentation = null
                webservice.technicalDescriptionFormat = ''
                webservice.technicalDescriptionFormatIndividual = ''
            }
        }

        const checkClearValueOperator = () => {
            if (webservice.operation === 'customer') {
                webservice.operatorList = []
            }
        }

        const checkClearValueCloudProviderCloudProviderIndividual = () => {
            if (webservice.cloud !== true) {
                webservice.cloudProviderList = []
                webservice.cloudProviderIndividualList = []
            }
        }

        const checkClearValueCloudProviderIndividual = () => {
            if (!webservice.cloudProviderList.includes('individual')) {
                webservice.cloudProviderIndividualList = []
            }
        }

        const checkClearValueSecurityAssessmentLevel = () => {
            if (webservice.securityAssessment !== true) {
                webservice.securityAssessmentLevel = ''
            }
        }

        const nameUnique = ref<boolean>(true)
        const checkAssetNameUnique = () => {
            nameUnique.value = true
            const assetList = assetStore.getAll.value
            assetList.forEach((asset: any) => {
                if (currentAsset.value.name === asset.name && !route.value.path.includes('/edit')) {
                    nameUnique.value = false
                    openAssetNameExistsModal()
                }
            })
        }

        const acceptAssetNameExists = async() => {
            nameUnique.value = true
            closeAssetNameExistsModal()
            if (await validateFirstStepWithoutAssetNameUnique()) {
                window.scrollTo(0, 0)
                currentStep.value++
            }
        }

        const checkAdvance = async() => {
            if (currentStep.value === 0) {
                if (await validateFirstStep()) {
                    return true
                }
            } else if (currentStep.value === 1) {
                if (await validateSecondStep()) {
                    return true
                }
            } else if (currentStep.value === 2) {
                if (await validateThirdStep()) {
                    return true
                }
            } else if (currentStep.value === 3) {
                if (await validateFourthStep()) {
                    return true
                }
            } else if (currentStep.value === 4) {
                return true
            }
            return false
        }

        const advance = async() => {
            if (await checkAdvance()) {
                if (currentStep.value < 5) {
                    window.scrollTo(0, 0)
                    currentStep.value++
                }
            }
        }

        const back = () => {
            if (currentStep.value > 0) {
                currentStep.value--
            } else {
                assetStore.clearAsset()
                router.go(-1)
            }
        }

        const reset = () => {
            currentStep.value = 0
        }

        const nameError = ref<string>('')
        const descriptionError = ref<string>('')
        const typeError = ref<string>('')
        const sizeError = ref<string>('')
        const authenticationError = ref<string>('')
        const rolesError = ref<string>('')
        const technicalDescriptionError = ref<string>('')
        const technicalDescriptionFormatError = ref<string>('')
        const technicalDocumentationError = ref<string>('')
        const reachabilityError = ref<string>('')

        watch(() => currentAsset.value.name, (value) => {
            nameError.value = RequiredValidator(value)
        })

        watch(() => currentAsset.value.description, (value) => {
            descriptionError.value = RequiredValidator(value)
        })

        watch(() => webservice.type, (value) => {
            typeError.value = RequiredValidator(value)
        })

        watch(() => webservice.size, (value) => {
            sizeError.value = RequiredValidator(value)
        })

        watch(() => webservice.authentication, (value) => {
            authenticationError.value = RequiredValidatorBoolean(value)
        })

        watch(() => webservice.roles, (value) => {
            rolesError.value = RequiredValidator(value)
        })

        watch(() => webservice.technicalDescription, (value) => {
            technicalDescriptionError.value = RequiredValidatorBoolean(value)
        })

        watch(() => webservice.technicalDescriptionFormat, (value) => {
            technicalDescriptionFormatError.value = RequiredValidator(value)
        })

        watch(() => webservice.technicalDocumentation, (value) => {
            technicalDocumentationError.value = RequiredValidatorBoolean(value)
        })

        watch(() => webservice.reachability, (value) => {
            reachabilityError.value = RequiredValidatorBoolean(value)
        })

        const validateFirstStep = async() => {
            nameError.value = RequiredValidator(currentAsset.value.name)
            descriptionError.value = RequiredValidator(currentAsset.value.description)
            if (nameError.value === '' &&
                descriptionError.value === ''
            ) {
                checkAssetNameUnique()
                if (nameUnique.value) {
                    return true
                }
            } else {
                await nextTick()
                const element = document.querySelector('.invalid-feedback')?.parentNode?.parentNode as HTMLElement
                element.scrollIntoView({behavior: 'smooth'})
            }
        }

        const validateFirstStepWithoutAssetNameUnique = async() => {
            nameError.value = RequiredValidator(currentAsset.value.name)
            descriptionError.value = RequiredValidator(currentAsset.value.description)
            if (nameError.value === '' &&
                descriptionError.value === ''
            ) {
                return true
            } else {
                await nextTick()
                const element = document.querySelector('.invalid-feedback')?.parentNode?.parentNode as HTMLElement
                element.scrollIntoView({behavior: 'smooth'})
            }
        }

        const validateSecondStep = async() => {
            sizeError.value = RequiredValidator(webservice.size)
            if (sizeError.value === '') {
                return true
            } else {
                await nextTick()
                const element = document.querySelector('.invalid-feedback')?.parentNode?.parentNode as HTMLElement
                element.scrollIntoView({behavior: 'smooth'})
            }
        }

        const validateThirdStep = async() => {
            authenticationError.value = RequiredValidatorBoolean(webservice.authentication)
            if (webservice.authentication) {
                rolesError.value = RequiredValidator(webservice.roles)
            }
            technicalDescriptionError.value = RequiredValidatorBoolean(webservice.technicalDescription)
            if (webservice.technicalDescription) {
                technicalDescriptionFormatError.value = RequiredValidator(webservice.technicalDescriptionFormat)
            } else {
                technicalDocumentationError.value = RequiredValidatorBoolean(webservice.technicalDocumentation)
            }
            if (authenticationError.value === '' &&
                (rolesError.value === '' || webservice.authentication !== true) &&
                technicalDescriptionError.value === '' &&
                (technicalDescriptionFormatError.value === '' || webservice.technicalDescription === false) &&
                (technicalDocumentationError.value === '' || webservice.technicalDescription)
            ) {
                return true
            } else {
                await nextTick()
                const element = document.querySelector('.invalid-feedback')?.parentNode?.parentNode as HTMLElement
                element.scrollIntoView({behavior: 'smooth'})
            }
        }

        const validateFourthStep = async() => {
            reachabilityError.value = RequiredValidatorBoolean(webservice.reachability)
            if (reachabilityError.value === '') {
                return true
            } else {
                await nextTick()
                const element = document.querySelector('.invalid-feedback')?.parentNode?.parentNode as HTMLElement
                element.scrollIntoView({behavior: 'smooth'})
            }
        }

        const gotoAssetList = () => {
            if (!route.value.path.includes('/edit')) {
                const assetStore = useAssetStore()
                assetStore.clearAsset()
            }
            window.scrollTo(0, 0)
            router.replace({ name: 'asset-based project', params: { id: projectId.value } })
        }

        return {
            route,
            currentAsset,
            currentStep,
            webservice,
            acceptAssetNameExists,
            numberAdditionalOperators,
            removeOperatorFromList,
            addOperator,
            numberAdditionalCloudProviders,
            removeCloudProviderFromList,
            addCloudProvider,
            assetNameModal,
            openAssetNameModal,
            closeAssetNameModal,
            assetNameExistsModal,
            openAssetNameExistsModal,
            closeAssetNameExistsModal,
            descriptionModal,
            openDescriptionModal,
            closeDescriptionModal,
            sizeModal,
            openSizeModal,
            closeSizeModal,
            authenticationModal,
            openAuthenticationModal,
            closeAuthenticationModal,
            rolesModal,
            openRolesModal,
            closeRolesModal,
            technicalDescriptionModal,
            openTechnicalDescriptionModal,
            closeTechnicalDescriptionModal,
            reachabilityModal,
            openReachabilityModal,
            closeReachabilityModal,
            cloudProviderModal,
            openCloudProviderModal,
            closeCloudProviderModal,
            securityAssessmentModal,
            openSecurityAssessmentModal,
            closeSecurityAssessmentModal,
            securityAssessmentLevelModal,
            openSecurityAssessmentLevelModal,
            closeSecurityAssessmentLevelModal,
            confirmationCreateModal,
            openConfirmationCreateModal,
            closeConfirmationCreateModal,
            confirmationEditModal,
            openConfirmationEditModal,
            closeConfirmationEditModal,
            sizeOptions,
            rolesOptions,
            technicalDescriptionFormatOptions,
            technologyOptions,
            operationOptions,
            cloudProviderOptions,
            securityAssessmentLevelOptions,
            checkClearValueRoles,
            checkClearValueTechnicalDescriptionFormatIndividual,
            checkClearValuesTechnicalDescriptionFormatTechnicalDocumentation,
            checkClearValueOperator,
            checkClearValueCloudProviderCloudProviderIndividual,
            checkClearValueCloudProviderIndividual,
            checkClearValueSecurityAssessmentLevel,
            nameError,
            descriptionError,
            sizeError,
            authenticationError,
            rolesError,
            technicalDescriptionError,
            technicalDescriptionFormatError,
            technicalDocumentationError,
            reachabilityError,
            completeAsset,
            advance,
            back,
            reset,
            gotoAssetList
        }
    }
})
</script>

<style lang="scss" scoped>
@import "../../styles/breakpoints";

.webservice-asset {
    h2 {
        color: var(--color-brand-primary);
    }

    .asset-question {
        @include default-padding();
    }

    .asset-question-white {
        background-color: var(--color-white);
    }

    .additional-field {
        display: flex;
    }

    .add-button {
        margin: 2em 0 0 -0.5em;
    }
}
</style>
