import Repository from '@/repository/Repository'

export const checkAccessLimit = async() => {
    if (!process.env.ACCESS_LIMIT_ACTIVE) {
        return true
    }
    try {
        await Repository.get('/access')
        return true
    } catch (error) {
        return false
    }
}
