<template>
    <div class="standards">
        <h1>
            Standards & Methoden
        </h1>

        <div class="standards-container">
            <div class="standards-slider">
                <div class="slide-track">
                    <!-- Contains every icon twice. This is neccesary to create the illusion of endless looping -->
                    <div class="slide">
                        <img src="../../../public/icons-standards/9001.png">
                    </div>
                    <div class="slide">
                        <img src="../../../public/icons-standards/pci-qsa.png">
                    </div>
                    <div class="slide">
                        <img src="../../../public/icons-standards/bsi.png">
                    </div>
                    <div class="slide">
                        <img src="../../../public/icons-standards/ptes.png">
                    </div>
                    <div class="slide">
                        <img src="../../../public/icons-standards/cvss.png">
                    </div>
                    <div class="slide">
                        <img src="../../../public/icons-standards/27001.png">
                    </div>
                    <div class="slide">
                        <img src="../../../public/icons-standards/pci-asv.png">
                    </div>
                    <div class="slide">
                        <img src="../../../public/icons-standards/acs.png">
                    </div>
                    <div class="slide">
                        <img src="../../../public/icons-standards/9001.png">
                    </div>
                    <div class="slide">
                        <img src="../../../public/icons-standards/pci-qsa.png">
                    </div>
                    <div class="slide">
                        <img src="../../../public/icons-standards/bsi.png">
                    </div>
                    <div class="slide">
                        <img src="../../../public/icons-standards/ptes.png">
                    </div>
                    <div class="slide">
                        <img src="../../../public/icons-standards/cvss.png">
                    </div>
                    <div class="slide">
                        <img src="../../../public/icons-standards/27001.png">
                    </div>
                    <div class="slide">
                        <img src="../../../public/icons-standards/pci-asv.png">
                    </div>
                    <div class="slide">
                        <img src="../../../public/icons-standards/acs.png">
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Standards'
})
</script>

<style lang="scss" scoped>
@import "../../styles/breakpoints";

.standards {
    background-color: var(--color-lightest-grey);
    padding: 2.5% 10%;
    h1 {
        font-size: 50px;
        padding-bottom: 3%;
    }
}

.standards-container {
    display: flex;
    min-height: 10vh;
    align-items: center;
    justify-content: flex-start;
}

@mixin gradient {
    // Color used is from variable 'color-lightest-grey' with value #f6f6f6
    background: linear-gradient(to right, rgba(246,246,246,1) 0%, rgba(246,246,246,0) 100%);
}

$animationSpeed: 50s;
$slideHeight: 100px;
$slideWidth: 400px;

@keyframes scroll {
    // scroll transformation is calculated with the number of individual icons
    100% { transform: translateX(calc(-1 * #{$slideWidth} * 8)); }
}

.standards-slider {
    background: var(--color-lightest-grey);
    position: relative;
    height: $slideHeight;
    width: 100%;
    overflow: hidden;
    &::before, &::after {
        @include gradient;
        content: "";
        position: absolute;
        height: $slideHeight;
        width: calc(#{$slideWidth} / 2);
        z-index: 2;
    }
    &::after {
        top: 0;
        right: 0;
        transform: rotateZ(180deg);
    }
    &::before {
        top: 0;
    left: 0;
    }
    .slide-track {
        display: flex;
        // total Width is calculated from number of overall icons
        width: calc(#{$slideWidth} * 16);
        animation: scroll $animationSpeed linear infinite;
    }
    .slide {
        width: $slideWidth;
        text-align: center;
        img {
            height: $slideHeight;
        }
    }
}

@media screen and (max-width: $bp-medium) {
    .standards {
        padding: 2em 1em;
    }

    .standards h1 {
        font-size: 30px;
        text-align: center;
    }

    .standards-slider {
        &::before, &::after {
            width: 2em;
        }
    }
}
</style>
