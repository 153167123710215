<template>
    <div class='project-create'>
        <div v-if="$route.name === 'new project'">
            <ConfiguratorHeader />

            <Progressbar :active="1" />

            <div class="project-name">
                <h2>Projektname *</h2>

                <p>
                    Der Projektname hilft uns, Ihre Anfrage intern zu verarbeiten. Bitte geben Sie einen für Sie eindeutigen Projektnamen ein.
                </p>

                <text-field id="name" label="" v-model="projectName" :error="projectNameError"></text-field>
            </div>

            <ProjectType :error="projectTypeError"/>

            <div class="buttons">
                <button class="ghost-primary configurator-button" @click="$router.go(-1)">Zurück</button>
                <button class="primary configurator-button" @click="commitProjectData">Weiter</button>
            </div>
        </div>

        <div v-else>
            <router-view />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, nextTick, ref, watch } from 'vue'
import { useProjectStore } from '@/store/projectStore'
import { useAssetStore } from '@/store/assetStore'
import { useContingentStore } from '@/store/contingentStore'
import { useRouter } from '@/composables/use-router'
import { RequiredValidator } from '@/validator/validator'
import { checkAccessLimit } from '@/accessLimiter/accessLimiter'
import ConfiguratorHeader from '@/components/ConfiguratorHeader.vue'
import Progressbar from '@/components/Progressbar.vue'
import ProjectType from '@/components/project/ProjectType.vue'

export default defineComponent({
    name: 'ProjectCreate',

    components: {
        ConfiguratorHeader,
        Progressbar,
        ProjectType
    },

    setup() {
        const { router } = useRouter()

        const projectStore = useProjectStore()
        const projectId = projectStore.uuid
        const projectName = projectStore.name
        const projectType = projectStore.type

        const projectNameError = ref<string>('')
        const projectTypeError = ref<string>('')

        watch(() => projectName.value, (value) => {
            projectNameError.value = RequiredValidator(value)
        })

        watch(() => projectType.value, (value) => {
            projectTypeError.value = RequiredValidator(value)
        })

        const validate = async() => {
            projectNameError.value = RequiredValidator(projectName.value)
            projectTypeError.value = RequiredValidator(projectType.value)

            if (projectNameError.value === '' &&
                projectTypeError.value === ''
            ) {
                return true
            } else {
                await nextTick()
                const element = document.querySelector('.invalid-feedback')?.parentNode?.parentNode as HTMLElement
                element.scrollIntoView({behavior: 'smooth'})
            }
        }

        const commitProjectData = async() => {
            if (await validate()) {
                if (await checkAccessLimit()) {
                    await projectStore.initializeProject()
                    window.scrollTo(0, 0)
                    if (projectType.value === 'asset') {
                        const assetStore = useAssetStore()
                        const assetList = assetStore.getAll.value
                        if (assetList.length === 0) {
                            await router.push({ name: 'asset-based project', params: { id: projectId.value } })
                        } else {
                            await router.push({ name: 'asset-based project', params: { id: projectId.value } })
                        }
                    } else if (projectType.value === 'contingent') {
                        const contingentStore = useContingentStore()
                        await contingentStore.initializeContingent(projectId.value)
                        await router.push({ name: 'contingent-based project', params: { id: projectId.value } })
                    } else if (projectType.value === 'scenario') {
                        await router.push({ name: 'scenario-based project', params: { id: projectId.value } })
                    }
                } else {
                    await router.replace({ name: 'too many requests' })
                }
            }
        }

        return {
            commitProjectData,
            projectName,
            projectType,
            projectNameError,
            projectTypeError
        }
    }
})
</script>

<style lang="scss" scoped>
@import "../../styles/breakpoints";

.project-name {
    @include default-padding();
    background-color: var(--color-lightest-grey);
    h2 {
        color: var(--color-brand-primary);
    }
}

.form-element-textfield {
    max-width: 650px;
    width: 100%;
}

.form-element-textfield ::v-deep input {
    width: 100%;
}

@media screen and (max-width: 560px) {
    .buttons {
        display: flex;
        flex-direction: column;
    }
}
</style>
