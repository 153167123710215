<template>
    <div class="standard-scope">
        <div class="scope-header">
            <h2>
                Organisation des Pentests *
            </h2>

            <p>
                Nachfolgende organisatorische Standardbedingungen gelten für Pentests der usd.
            </p>
        </div>

        <div class="standards-list">
            <ul>
                <li>
                    Der Leistungszeitraum wird nach Beauftragung flexibel mit dem Kunden abgestimmt.
                </li>
                <li>
                    Der Pentest erfolgt zu üblichen Büroarbeitszeiten montags bis freitags zwischen 8.00 und 18.00 Uhr, außer an bundesweiten Feiertagen.
                </li>
                <li>
                    Die Durchführung erfolgt remote von den Büros der usd über das Internet (ggf. mittels VPN-Verbindung).
                </li>
                <li>
                    Es nicht vorgesehen, dass usd Spezialhardware verwendet oder eine Testumgebung aufsetzt.
                </li>
                <li>
                    Eingesetzte Pentester haben mind. 1-3 Jahre Berufserfahrung, sind als usd Certified Pentester (UCP) zertifiziert und haben ein aktuelles polizeiliches Führungszeugnis.
                </li>
                <li>
                    Kritische Schwachstellen werden unmittelbar nach der Verifikation an Sie gemeldet.
                </li>
                <li>
                    Zusätzlich wird ein ausführlicher Bericht im usd Standardformat (PDF) in deutscher (oder englischer) Sprache verfasst.
                </li>
                <li>
                    Schwachstellen werden nach dem Risikoschema der usd bewertet und Maßnahmenempfehlungen ausgesprochen.
                </li>
                <li>
                    Eine davon abweichende Risikobewertung des Kunden wird nicht im Bericht aufgegriffen.
                </li>
                <li>
                    Abschließend werden im Rahmen eines Online-Meetings offene Fragen beantwortet.
                </li>
            </ul>
        </div>

        <div class="standards-radio-container">
            <p>
                Stimmen Sie den Standardbedingungen der usd zu oder wünschen Sie individuelle Regelungen?
            </p>

            <BoxSelectRadio id="standard-radio" v-model="standardScope" @input="setSettingsDefault()" :options="standardOptions" />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useScopeStore } from '@/store/scopeStore'
import BoxSelectRadio from '@/components/BoxSelectRadio.vue'

export default defineComponent({
    name: 'StandardScope',

    components: {
        BoxSelectRadio
    },

    setup() {
        const standardOptions = [
            {
                id: 'default',
                label: 'Standardbedingungen'
            },
            {
                id: 'custom',
                label: 'Individuelle Regelungen'
            }
        ]

        const scopeStore = useScopeStore()
        const standardScope = scopeStore.standardSelection
        const location = scopeStore.location
        const timing = scopeStore.timing
        const separateEnvironment = scopeStore.separateEnvironment
        const hardware = scopeStore.hardware
        const report = scopeStore.report
        const guidelines = scopeStore.guidelines
        const dateList = scopeStore.dateList
        const motivation = scopeStore.motivation

        const setSettingsDefault = () => {
            if (standardScope.value === 'default') {
                location.value = 'default'
                timing.value = false
                separateEnvironment.value = false
                hardware.value = false
                report.layout = 'default'
                report.riskAssessment = ['usd']
                report.customerRiskAssessment = false
                report.language = ['de']
                guidelines.certPentester = ['ucp']
                guidelines.securityClearance = ['criminal record']
                dateList.value = []
                motivation.value = []
            }
        }

        return {
            standardOptions,
            standardScope,
            setSettingsDefault
        }
    }
})
</script>

<style lang="scss" scoped>
@import "../../styles/breakpoints";

.standard-scope {
    @include default-padding();
}

.scope-header {
    padding-bottom: 1.5%;
    text-align: left;
    h2 {
        color: var(--color-brand-primary);
    }
}

.standards-radio-options {
    display: flex;
    margin-top: 1.5%;
    border: 1px solid var(--color-black);
    background-color: var(--color-white);
}
</style>
