<template>
    <div class='asset-configurator'>
        <SystemAsset v-if="currentAsset.type === 'system'" :passedAsset="currentAsset" @systemAssetDone="markAssetDone" />
        <WebappAsset v-if="currentAsset.type === 'webapp'" :passedAsset="currentAsset" @webappAssetDone="markAssetDone" />
        <WebserviceAsset v-if="currentAsset.type === 'webservice'" :passedAsset="currentAsset" @webserviceAssetDone="markAssetDone" />
        <MobileAsset v-if="currentAsset.type === 'mobile'" :passedAsset="currentAsset" @mobileAssetDone="markAssetDone" />
        <FatclientAsset v-if="currentAsset.type === 'fatclient'" :passedAsset="currentAsset" @fatclientAssetDone="markAssetDone" />
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted } from 'vue'
import { useRouter } from '@/composables/use-router'
import { useProjectStore } from '@/store/projectStore'
import { useAssetStore } from '@/store/assetStore'
import SystemAsset from '@/components/asset/SystemAsset.vue'
import WebappAsset from '@/components/asset/WebappAsset.vue'
import WebserviceAsset from '@/components/asset/WebserviceAsset.vue'
import MobileAsset from '@/components/asset/MobileAsset.vue'
import FatclientAsset from '@/components/asset/FatclientAsset.vue'
import { usePriceStore } from '@/store/priceStore'
import { useScopeStore } from '@/store/scopeStore'
import { useOptionalStore } from '@/store/optionalStore'

export default defineComponent({
    name: 'AssetConfigurator',

    components: {
        SystemAsset,
        WebappAsset,
        WebserviceAsset,
        MobileAsset,
        FatclientAsset
    },

    setup() {
        const { router, route } = useRouter()

        const projectStore = useProjectStore()
        const projectId = projectStore.uuid

        const assetStore = useAssetStore()
        const assetList = assetStore.getAll

        onMounted(async() => {
            if (projectId.value === '') {
                const scopeStore = useScopeStore()
                const optionalStore = useOptionalStore()
                await projectStore.loadProject(route.value.params.id)
                await assetStore.updateAssetList(route.value.params.id)
                await calculateAssetPrice()
                await scopeStore.loadScope(route.value.params.id)
                await priceStore.fetchScopePrice(route.value.params.id)
                await optionalStore.loadOptionals(route.value.params.id)
                await priceStore.fetchOptionalsPrices(route.value.params.id)
            }
        })

        const currentAsset = computed(() => {
            if (route.value.path.includes('/edit')) {
                return assetList.value.filter(asset => asset.id === route.value.params.assetId)[0]
            } else {
                return assetStore.getCurrentAsset.value
            }
        })
        const priceStore = usePriceStore()
        const calculateAssetPrice = async() => {
            await priceStore.calculateAssetPrice(projectId.value)
            await priceStore.fetchProjectPrice(projectId.value)
        }

        const markAssetDone = async(value: any) => {
            await assetStore.setConfig(value.config)
            const exists = await assetStore.checkAssetExists(value.id, projectId.value)
            if (!exists) {
                await assetStore.saveAsset(value, projectId.value)
            } else {
                await assetStore.updateAsset(value, projectId.value)
            }
            await assetStore.updateAssetList(projectId.value)
            await calculateAssetPrice()
            assetStore.clearAsset()
            window.scrollTo(0, 0)
            await router.push({ name: 'asset-based project', params: { id: route.value.params.id } })
        }

        return {
            currentAsset,
            markAssetDone
        }
    }

})
</script>

<style>
.asset-configurator {
    background-color: var(--color-lightest-grey);
}
</style>
