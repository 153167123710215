<template>
    <div class="numbered-steps">
        <h1>
            In nur 5 Schritten zu Ihrer Pentest-Anfrage
        </h1>

        <div class="steps">
            <div class="step-container">
                <div class="number-container">
                    <span class="large-number">1</span>
                    <h3 class="number-container-text">Projekt</h3>
                </div>
                <div class="step-container-text">
                    <p>
                        Bestimmen Sie Ihren Projektansatz.
                    </p>
                </div>
            </div>

            <div class="step-container">
                <div class="number-container">
                    <span class="large-number">2</span>
                    <h3 class="number-container-text">Scope</h3>
                </div>
                <div class="step-container-text">
                    <p>
                        Legen Sie Ihren individuellen Prüfumfang fest.
                    </p>
                </div>
            </div>

            <div class="step-container">
                <div class="number-container">
                    <span class="large-number">3</span>
                    <h3 class="number-container-text">Organisation</h3>
                </div>
                <div class="step-container-text">
                    <p>
                        Definieren Sie die Rahmenbedingungen Ihres Projektes.
                    </p>
                </div>
            </div>

            <div class="step-container">
                <div class="number-container">
                    <span class="large-number">4</span>
                    <h3 class="number-container-text">Zusatzleistungen</h3>
                </div>
                <div class="step-container-text">
                    <p>
                        Komplettieren Sie Ihr Projekt durch optionale Beratungsleistungen der usd.
                    </p>
                </div>
            </div>

            <div class="step-container">
                <div class="number-container">
                    <span class="large-number">5</span>
                    <h3 class="number-container-text">Zusammenfassung</h3>
                </div>
                <div class="step-container-text">
                    <p>
                        Übermitteln Sie uns Ihre Anfrage und Sie erhalten Ihr individuelles Angebot.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'NumberedSteps'
})
</script>

<style lang="scss">
@import "../../styles/breakpoints";

.numbered-steps {
    padding: 2.5% 10%;

    h1 {
        font-size: 3rem;
    }
    .steps {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }
    .step-container {
        display: flex;
        flex-direction: column;
        text-align: center;
        flex: 0 1 14%;
        //max-width: 270px;

        .number-container {
            display: flex;
            align-items: center;

            .large-number {
                font-size: 8rem;
                margin-bottom: -2rem;
                color: var(--color-light-grey)
            }
            .number-container-text {
                padding: 0 0 20px 10px;
                align-self: flex-end;
                font-weight: bold;
            }
        }
        .step-container-text {
            text-align: left;
        }
    }
}

@media screen and (max-width: 1470px) {
    .numbered-steps .steps {
        flex-flow: row wrap;
        justify-content: flex-start;
    }

    .numbered-steps .steps .step-container {
        flex: 0 1 33%;
        max-width: initial;
    }
}

@media screen and (max-width: $bp-large) {
    .numbered-steps {
        padding: 2em 1em;
    }

    .numbered-steps h1 {
        font-size: 30px;
    }

    .numbered-steps .steps {
        flex-flow: row wrap;
        justify-content: flex-start;
    }

    .numbered-steps .steps .step-container {
        flex: 0 1 33%;
        max-width: initial;
    }
}

@media screen and (max-width: $bp-medium) {
    .numbered-steps .steps .step-container {
        flex: 0 1 40%;
        max-width: initial;
        margin: 0 auto;
        align-items: center;
    }
}

@media screen and (max-width: 530px) {
    .numbered-steps .steps .step-container {
        flex: 0 0 60%;
    }
}

@media screen and (max-width: $bp-small) {
    .numbered-steps .steps .step-container {
        flex: 0 0 60%;
    }
}
</style>
