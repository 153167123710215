<template>
    <div class="contingent-scope-summary">
        <expansion-panel>
            <expansion-panel-content>
                <template v-slot:header>
                    <h3>
                        Scope
                    </h3>
                </template>
                <template>
                    <div class="summary-project-specific">
                        <div>
                            <div class="scope-item">
                                <p class="scope-item-name">
                                    <strong>Volumen: </strong>
                                    {{ days }} Beratertage
                                </p>
                            </div>

                            <div>
                                <p>
                                    <strong>
                                        Nachfolgende Bedingungen gelten für das vereinbarte Kontingent:
                                    </strong>
                                </p>

                                <ul>
                                    <li>
                                        <p>
                                            Die Anzahl der vereinbarten Beratertage stellt eine Obergrenze dar.
                                        </p>
                                    </li>

                                    <li v-if="!obligation">
                                        <p>
                                            Es gibt keine Abnahmeverpflichtung. Nicht genutzte Kontingente verfallen zum Vertragsende.
                                        </p>
                                    </li>
                                    <li v-else>
                                        <p>
                                            Der Auftraggeber verpflichtet sich die vereinbarte Anzahl an Beratertagen abzunehmen.
                                            Er erhält dafür die Leistung der usd zu Vorzugskonditionen.
                                        </p>
                                    </li>

                                    <li>
                                        <p>
                                            Das Kontingent hat eine Laufzeit von {{ duration }} Monaten.
                                        </p>
                                    </li>

                                    <li>
                                        <p>
                                            Ein Beratertag entspricht {{ dailyHours }} Stunden.
                                        </p>
                                    </li>

                                    <li>
                                        <p>
                                            Es werden konkrete Arbeitspakete mit dem Auftraggeber abgestimmt.
                                        </p>
                                    </li>

                                    <li>
                                        <p>
                                            Terminvereinbarungen erfolgen in gemeinsamer Absprache.
                                        </p>
                                    </li>

                                    <li v-if="billingUnit === 'hours'">
                                        <p>
                                            Die Abrechnung erfolgt auf Stundenbasis nach Aufwand zum Monatsende.
                                        </p>
                                    </li>
                                    <li v-else-if="billingUnit === 'days'">
                                        <p>
                                            Die Abrechung erfolgt auf Basis von Tagen nach Aufwand zum Monatsende.
                                        </p>
                                    </li>

                                    <li>
                                        <p>
                                            Abgerechnet wird jede angefangene halbe Stunde.
                                        </p>
                                    </li>

                                    <li v-if="performanceProof">
                                        <p>
                                            Der Auftraggeber erhält einen detaillierten Leistungsnachweis mit kundenspezifischen Angaben.
                                        </p>
                                    </li>
                                    <li v-else-if="!performanceProof">
                                        <p>
                                            Der Auftraggeber erhält jeweils einen detaillierten Leistungsnachweis.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </template>
            </expansion-panel-content>
        </expansion-panel>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useContingentStore } from '@/store/contingentStore'
import ExpansionPanel from '@/components/ExpansionPanel.vue'
import ExpansionPanelContent from '@/components/ExpansionPanelContent.vue'

export default defineComponent({
    name: 'ContingentSummary',

    components: {
        ExpansionPanel,
        ExpansionPanelContent
    },

    setup() {
        const contingentStore = useContingentStore()
        const days = contingentStore.days
        const dailyHours = contingentStore.dailyHours
        const billingUnit = contingentStore.billingUnit
        const performanceProof = contingentStore.performanceProof
        const duration = contingentStore.duration
        const obligation = contingentStore.obligation

        return {
            days,
            dailyHours,
            billingUnit,
            performanceProof,
            duration,
            obligation
        }
    }
})
</script>

<style lang="scss" scoped>
.scope-item {
    display: flex;
}
item-name {
    width: 30%;
}
h3 {
    color: var(--color-brand-secondary);
    font-size: 22px;
}
hr {
    border: none;
    height: 1px;
    background-color: var(--color-light-grey);
    margin: 2em 0;
}
ul {
    margin-left: -1.5em;
}
</style>
