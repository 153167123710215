<template>
    <div class="project-summary">
        <ConfiguratorHeader />
        <Progressbar :active="5" />

        <div class="project-summary-outer">
            <div class="project-summary-container">
                <div class="summary-container">
                    <div class="summary-container-header">
                        <h2>
                            Projektname "{{ projectName }}"
                        </h2>
                    </div>

                    <div class="summary-container-description">
                        <p>
                            Vielen Dank für Ihr Interesse! Nachfolgend finden Sie eine Zusammenfassung Ihres Pentest-Projekts.
                        </p>
                    </div>

                    <TypeSummary />

                    <AssetSummary v-if="projectType === 'asset'" />
                    <ContingentSummary v-if="projectType === 'contingent'" />
                    <ScenarioSummary v-if="projectType === 'scenario'" />

                    <OptionalSummary />

                    <ScopeSummary />
                </div>

<!--                <div class="summary-sidebar">-->
<!--                    <AssetPrice v-if="projectType === 'asset'" />-->
<!--                    <ContingentPrice v-else-if="projectType === 'contingent'" />-->
<!--                    <ScenarioPrice v-else-if="projectType === 'scenario'" />-->

<!--                    <div class="help-container">-->
<!--                        <h3>-->
<!--                            Ihr Kontakt bei Fragen-->
<!--                        </h3>-->

<!--                        <img src="../../../public/contact-heyne.png" alt="contact image">-->

<!--                        <p>-->
<!--                            <strong>-->
<!--                                Daniel Heyne-->
<!--                            </strong>-->
<!--                        </p>-->

<!--                        <p>-->
<!--                            Security Consultant Pentest, OSCP, OSCE-->
<!--                        </p>-->

<!--                        <p>-->
<!--                            vertrieb@usd.de | +49 6102 8631-190-->
<!--                        </p>-->

<!--                    </div>-->
<!--                </div>-->
            </div>

            <div class="button-back-wrapper">
                <button class="ghost-primary configurator-button" @click="$router.go(-1)">Zurück</button>
                <button class="primary configurator-button" @click="finalizeOrder()">Angebot anfordern</button>
            </div>

            <div class="buttons-new-project">
                <button class="primary configurator-button" @click="newProject()">Neues Projekt anlegen</button>
            </div>
        </div>

        <modal class="order-modal" v-model="orderModal">
            <h2 slot="title">Angebot zu Ihrem Pentest anfordern</h2>

            <div class="order-support-header">
                <img src="../../../public/contact-heyne.png" alt="kontakt bild">

                <p>
                    Gerne unterbreiten wir Ihnen umgehend ein unverbindliches Angebot zu dem konfigurierten
                    Pentest und lassen Ihnen dieses per E-Mail zukommen.
                </p>
            </div>

            <h3 class="pentest-name">
                Pentest "{{ projectName }}"
            </h3>

            <OrderForm @finished="sendOrder"/>
        </modal>

        <modal class="info-modal" v-model="successModal" dialog>
            <img slot="title" src="../../../public/icon-info.svg" alt="icon-info">
            <h2 slot="title">Angebotsanfrage abgeschickt</h2>

            <p>
                Vielen Dank für Ihre Anfrage, über die wir uns sehr gefreut haben. Ihre Anfrage ist bei uns eingegangen
                und wird jetzt schnellstmöglich von unseren Mitarbeitern bearbeitet. Wir melden uns zeitnah mit unserem
                Angebot bei Ihnen zurück.
            </p>
            <p>
                Nutzen Sie doch die Zwischenzeit und geben uns Feedback, wie zufrieden Sie mit unserem Pentest-Konfigurator
                waren. Ihr Feedback wird anonym erfasst und ermöglicht uns, den Konfigurator kontinuierlich zu verbessern.
                Vorab vielen Dank!
            </p>

            <FeedbackForm />

            <form-actions slot="footer">
                <button @click="sendFeedback" class="primary">Zur Startseite</button>
            </form-actions>
        </modal>

        <modal class="info-modal error-modal" v-model="errorModal" dialog>
            <img slot="title" src="../../../public/icon-info.svg" alt="icon-info">
            <h2 slot="title">Da ist etwas schiefgelaufen</h2>

            <p>
                Bitte nutzen Sie eine der anderen Kontaktmöglichkeiten.
            </p>

            <form-actions slot="footer">
                <button @click="newProject" class="primary">Zur Startseite</button>
            </form-actions>
        </modal>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue'
import { useRouter } from '@/composables/use-router'
import { useModal } from '@/composables/use-modal'
import { useProjectStore } from '@/store/projectStore'
import { useCustomerStore } from '@/store/customerStore'
import { useFeedbackStore } from '@/store/feedbackStore'
import { useAssetStore } from '@/store/assetStore'
import { useScenarioStore } from '@/store/scenarioStore'
import { useContingentStore } from '@/store/contingentStore'
import { useScopeStore } from '@/store/scopeStore'
import { useOptionalStore } from '@/store/optionalStore'
import { usePriceStore } from '@/store/priceStore'
import ExpansionPanel from '@/components/ExpansionPanel.vue'
import ExpansionPanelContent from '@/components/ExpansionPanelContent.vue'
import ConfiguratorHeader from '@/components/ConfiguratorHeader.vue'
import Progressbar from '@/components/Progressbar.vue'
import TypeSummary from '@/components/summary/TypeSummary.vue'
import AssetSummary from '@/components/summary/AssetSummary.vue'
import ContingentSummary from '@/components/summary/ContingentSummary.vue'
import ScenarioSummary from '@/components/summary/ScenarioSummary.vue'
import ScopeSummary from '@/components/summary/ScopeSummary.vue'
import OptionalSummary from '@/components/summary/OptionalSummary.vue'
import OrderForm from '@/components/OrderForm.vue'
import FeedbackForm from '@/components/FeedbackForm.vue'
// import AssetPrice from '@/components/price/AssetPrice.vue'
// import ContingentPrice from '@/components/price/ContingentPrice.vue'
// import ScenarioPrice from '@/components/price/ScenarioPrice.vue'

export default defineComponent({
    name: 'ProjectSummary',

    components: {
        ExpansionPanel,
        ExpansionPanelContent,
        ConfiguratorHeader,
        Progressbar,
        TypeSummary,
        AssetSummary,
        ContingentSummary,
        ScenarioSummary,
        OptionalSummary,
        ScopeSummary,
        FeedbackForm,
        OrderForm
        // AssetPrice,
        // ContingentPrice,
        // ScenarioPrice
    },

    setup() {
        const { router, route } = useRouter()

        const { modal: orderModal, openModal: openOrderModal, closeModal: closeOrderModal } = useModal()
        const { modal: successModal, openModal: openSuccessModal, closeModal: closeSuccessModal } = useModal()
        const { modal: errorModal, openModal: openErrorModal, closeModal: closeErrorModal } = useModal()

        const projectStore = useProjectStore()
        const projectId = projectStore.uuid
        const projectName = projectStore.name
        const projectType = projectStore.type

        onMounted(async() => {
            if (projectId.value === '') {
                const assetStore = useAssetStore()
                const scenarioStore = useScenarioStore()
                const contingentStore = useContingentStore()
                const scopeStore = useScopeStore()
                const optionalStore = useOptionalStore()
                await projectStore.loadProject(route.value.params.id)
                if (projectType.value === 'asset') {
                    await assetStore.updateAssetList(route.value.params.id)
                    await calculateAssetPrice()
                } else if (projectType.value === 'contingent') {
                    await contingentStore.loadContingent(route.value.params.id)
                    await calculateContingentPrice()
                } else if (projectType.value === 'scenario') {
                    await scenarioStore.updateSelectedScenarioList(route.value.params.id)
                    await calculateScenarioPrice()
                }
                await scopeStore.loadScope(route.value.params.id)
                await priceStore.fetchScopePrice(route.value.params.id)
                await optionalStore.loadOptionals(route.value.params.id)
                await priceStore.fetchOptionalsPrices(route.value.params.id)
            } else {
                await projectStore.loadProject(route.value.params.id)
            }
        })

        const priceStore = usePriceStore()
        const calculateScenarioPrice = async() => {
            await priceStore.calculateScenarioPrice(projectId.value)
            await priceStore.fetchProjectPrice(projectId.value)
        }
        const calculateAssetPrice = async() => {
            await priceStore.calculateAssetPrice(projectId.value)
            await priceStore.fetchProjectPrice(projectId.value)
        }
        const calculateContingentPrice = async() => {
            await priceStore.fetchContingentPrice(projectId.value)
            await priceStore.fetchProjectPrice(projectId.value)
        }

        const newProject = () => {
            router.replace({ name: 'new project' })
            window.scrollTo(0, 0)
            window.location.reload()
        }

        const finalizeOrder = () => {
            openOrderModal()
        }

        const sendOrder = async() => {
            const customerStore = useCustomerStore()
            try {
                await customerStore.saveCustomer(projectId.value)
                await customerStore.createInquiryTask(projectId.value)
                await customerStore.sendMail(projectId.value)
                openSuccessModal()
            } catch (error) {
                openErrorModal()
            }
            closeOrderModal()
        }

        const sendFeedback = async() => {
            const feedbackStore = useFeedbackStore()
            await feedbackStore.sendFeedback()
            newProject()
        }

        return {
            projectName,
            projectType,
            orderModal,
            closeOrderModal,
            successModal,
            closeSuccessModal,
            errorModal,
            closeErrorModal,
            newProject,
            finalizeOrder,
            sendOrder,
            sendFeedback
        }
    }
})
</script>

<style lang="scss" scoped>
@import "../../styles/breakpoints";

.project-summary-outer {
    @include default-padding();
    background: url('../../../public/image-summary.jpg') bottom no-repeat var(--color-white);
}
.project-summary-container {
    display: flex;
}
.summary-container {
    //width: 70%;
    margin-right: 1%;
    h3 {
        color: var(--color-brand-secondary);
        font-size: 22px;
    }
    button {
        margin-top: 3em;
    }
}
//.summary-sidebar {
//    width: 30%;
//}
.summary-container-header {
    color: var(--color-brand-primary);
}
.summary-container-description {
    border-bottom: 1px solid rgba(0,0,0,0.12);
}
.help-container {
    text-align: center;
    img {
        height: 120px;
    }
    p {
        margin: 0;
    }
}
.buttons {
    justify-content: center;
}

.button-back-wrapper {
    display: flex;
    justify-content: space-around;
    margin-top: 2em;
    margin-bottom: 10em;
}

@media screen and (max-width: $bp-summary-large) {
    .project-summary-outer {
        padding: 1.5% 5%;
    }

    //.contingent-price, .scenario-price, .asset-price {
    //    padding: 1em;
    //}
}

@media screen and (max-width: $bp-large) {
    //.project-summary-container {
    //    flex-direction: column;
    //}
    //
    //.summary-container {
    //    width: 100%;
    //}
    //
    //.summary-sidebar {
    //    width: 100%;
    //}

    .button-back-wrapper {
        margin-bottom: 2em;
        text-align: center;
    }
}
</style>
