<template>
    <div class="advantages">
        <h1>Ihre Vorteile</h1>

        <div class="advantages-container">
            <div class="advantage">
                <div class="advantage-image">
                    <img src="../../../public/icons-advantages/icons-assistent.svg">
                </div>
                <div class="advantage-text">
                    <h2>
                        Konfigurationsassistent
                    </h2>
                    <p>
                        Intuitive Bedienung in fünf geführten Schritten.
                    </p>
                </div>
            </div>

            <div class="advantage">
                <div class="advantage-image">
                    <img src="../../../public/icons-advantages/icons-verfuegbarkeit.svg">
                </div>
                <div class="advantage-text">
                    <h2>
                        24/7 Verfügbarkeit
                    </h2>
                    <p>
                        Der Konfigurator ist jederzeit für Sie erreichbar.
                    </p>
                </div>
            </div>

            <div class="advantage">
                <div class="advantage-image">
                    <img src="../../../public/icons-advantages/icons-planung.svg">
                </div>
                <div class="advantage-text">
                    <h2>
                        Zügige Planung
                    </h2>
                    <p>
                        Einfache Projekte planen Sie in nur wenigen Minuten schnell und unkompliziert.
                    </p>
                </div>
            </div>

            <div class="advantage">
                <div class="advantage-image">
                    <img src="../../../public/icons-advantages/icons-check.svg">
                </div>
                <div class="advantage-text">
                    <h2>
                        Konfigurationscheck
                    </h2>
                    <p>
                        Wir prüfen Ihre Projektanfrage und beraten Sie bei Bedarf.
                    </p>
                </div>
            </div>

            <div class="advantage">
                <div class="advantage-image">
                    <img src="../../../public/icons-advantages/icons-rabatt.svg">
                </div>
                <div class="advantage-text">
                    <h2>
                        Online-Rabatt
                    </h2>
                    <p>
                        Profitieren Sie von unseren attraktiven Sonderkonditionen.
                    </p>
                </div>
            </div>

            <div class="advantage">
                <div class="advantage-image">
                    <img src="../../../public/icons-advantages/icons-datensicherheit.svg">
                </div>
                <div class="advantage-text">
                    <h2>
                        Hohe Datensicherheit
                    </h2>
                    <p>
                        Ihre Informationen werden verschlüsselt übertragen und von uns sicher verarbeitet und gespeichert.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Advantages'
})
</script>

<style lang="scss">
@import "../../styles/breakpoints";

.advantages {
    background-color: var(--color-lightest-grey);
    padding: 2.5% 10%;
    h1 {
        font-size: 3rem;
    }
}

.advantages-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 3%;
    flex-flow: row wrap;
}

.advantage {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: center;
    width: 33%;
}

.advantage-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    img {
        width: 100px;
        height: 100px;
    }
}

.advantage-text {
    display: block;
    margin-left: auto;
    margin-right: auto;
    h2 {
        margin-bottom: 1em;
        text-align: center;
    }
    p {
        padding: 0 40px;
        font-weight: 300;
    }
}

@media screen and (max-width: $bp-large) {
    .advantage {
        width: 45%;
    }
}

@media screen and (max-width: $bp-medium) {
    .advantages {
        padding: 2em 1em;
    }

    .advantages h1 {
        font-size: 30px;
        text-align: center;
    }
}

@media screen and (max-width: $bp-small) {
    .advantage {
        width: 100%;
        margin-top: 2em;
    }
}
</style>
