<template>
    <div class='audit-report-optional'>
        <div class="optional-services-header">
            <h2>{{ fromJson.auditReport.heading }}</h2>

            <p>
                {{ fromJson.auditReport.body }}
            </p>
        </div>

        <tristate-switch-field id="audit-report-switch" v-model="auditReport" @input="updateOptionals()" disable-null label="" label-pre="Ja" label-post="Nein"/>

        <div class="buttons-info">
            <button class="button-info" @click="openAuditReportModal">{{ fromJson.auditReport.button }}</button>
        </div>

        <modal class="info-modal" v-model="auditReportModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="icon info">
            <h2 slot="title">{{ fromJson.auditReport.modal.heading }}</h2>

            <p v-for="paragraph in fromJson.auditReport.modal.body" :key="paragraph">
                {{ paragraph }}
            </p>

            <ul>
                <li v-for="entry in fromJson.auditReport.modal.list" :key="entry">
                    <p>
                        {{ entry }}
                    </p>
                </li>
            </ul>

            <p>
                {{ fromJson.auditReport.modal.bodyAfter }}
            </p>

            <form-actions slot="footer">
                <button @click="closeAuditReportModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useModal } from '@/composables/use-modal'
import { useProjectStore } from '@/store/projectStore'
import { useOptionalStore } from '@/store/optionalStore'
import { usePriceStore } from '@/store/priceStore'
import json from '@/json/OptionalDE.json'

export default defineComponent({
    name: 'AuditReport',

    setup() {
        const { modal: auditReportModal, openModal: openAuditReportModal, closeModal: closeAuditReportModal } = useModal()

        const projectStore = useProjectStore()
        const projectId = projectStore.uuid
        const projectType = projectStore.type

        const fromJson = json

        const optionalStore = useOptionalStore()
        const auditReport = optionalStore.auditReport

        const priceStore = usePriceStore()

        const processing = ref<Boolean>(false)

        const updateOptionals = async() => {
            if (processing.value === false) {
                processing.value = true
                await optionalStore.updateOptionals(projectId.value)
                await priceStore.fetchOptionalsPrices(projectId.value)
                await priceStore.fetchProjectPrice(projectId.value)
                processing.value = false
            }
        }

        return {
            fromJson,
            projectType,
            auditReport,
            updateOptionals,
            auditReportModal,
            openAuditReportModal,
            closeAuditReportModal
        }
    }
})
</script>

<style lang="scss" scoped>
@import "../../styles/breakpoints";

.audit-report-optional {
    @include default-padding();
}

.optional-services-header {
    padding-bottom: 1.5%;
    text-align: left;
    h2 {
        color: var(--color-brand-primary);
    }
}

.optional-services-options-container {
    border: 1px solid var(--color-brand-secondary);
    margin-top: 1.5%;
    background-color: var(--color-white);
    h2 {
        color: var(--color-brand-secondary);
    }
}

.optional-services-options {
    padding: 2.5% 4%;
}

.optional-services-radio-options {
    display: flex;
    margin-top: 1.5%;
    border: 1px solid var(--color-black);
    background-color: var(--color-white);
}

ul {
    margin-left: -1.5em;
}
</style>
