<template>
    <div class='retesting-optional' :class="{ 'retesting-optional-white': projectType === 'contingent' }">
        <div class="optional-services-header">
            <h2>{{ fromJson.retest.heading }}</h2>

            <p>
                {{ fromJson.retest.body }}
            </p>
        </div>

        <tristate-switch-field id="retest-switch" v-model="retest" @input="updateOptionals()" disable-null label="" label-pre="Ja" label-post="Nein"/>

        <div class="buttons-info">
            <button class="button-info" @click="openRetestModal">{{ fromJson.retest.button }}</button>
        </div>

        <modal class="info-modal" v-model="retestModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="icon info">
            <h2 slot="title">{{ fromJson.retest.modal.heading }}</h2>

            <p v-for="paragraph in fromJson.retest.modal.body" :key="paragraph">
                {{ paragraph }}
            </p>

            <form-actions slot="footer">
                <button @click="closeRetestModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useModal } from '@/composables/use-modal'
import { useProjectStore } from '@/store/projectStore'
import { useOptionalStore } from '@/store/optionalStore'
import { usePriceStore } from '@/store/priceStore'
import json from '@/json/OptionalDE.json'

export default defineComponent({
    name: 'RetestingOptional',

    setup() {
        const { modal: retestModal, openModal: openRetestModal, closeModal: closeRetestModal } = useModal()

        const projectStore = useProjectStore()
        const projectId = projectStore.uuid
        const projectType = projectStore.type

        const fromJson = json

        const optionalStore = useOptionalStore()
        const retest = optionalStore.retest

        const priceStore = usePriceStore()

        const processing = ref<Boolean>(false)

        const updateOptionals = async() => {
            if (processing.value === false) {
                processing.value = true
                await optionalStore.updateOptionals(projectId.value)
                await priceStore.fetchOptionalsPrices(projectId.value)
                await priceStore.fetchProjectPrice(projectId.value)
                processing.value = false
            }
        }

        return {
            fromJson,
            projectType,
            retest,
            updateOptionals,
            retestModal,
            openRetestModal,
            closeRetestModal
        }
    }
})
</script>

<style lang="scss" scoped>
@import "../../styles/breakpoints";

.retesting-optional {
    @include default-padding();
}

.retesting-optional-white {
    background-color: var(--color-white);
}

.optional-services-header {
    padding-bottom: 1.5%;
    text-align: left;
    h2 {
        color: var(--color-brand-primary);
    }
}

.optional-services-options-container {
    border: 1px solid var(--color-brand-secondary);
    margin-top: 1.5%;
    background-color: var(--color-white);
    h2 {
        color: var(--color-brand-secondary);
    }
}

.optional-services-options {
    padding: 2.5% 4%;
}

.optional-services-radio-options {
    display: flex;
    margin-top: 1.5%;
    border: 1px solid var(--color-black);
    background-color: var(--color-white);
}

ul {
    margin-left: -1.5em;
}
</style>
