<template>
    <div class='asset-config'>
        <ConfiguratorHeader />

        <Progressbar :active="2" />

        <div v-if="this.$route.name === 'asset-based project'">

            <div class="configured-assets">
                <div class="configured-assets-text">
                    <h2>
                        Ihre Assets *
                    </h2>
                    <p>
                        Es wird ein Pentest von Assets durchgeführt. Im aktuellen Schritt müssen Sie diese
                        konfigurieren. Bereits angelegte Assets werden unten aufgeführt.
                    </p>
                </div>

                <div class="configured-assets-list">

                    <AssetList />

                    <div v-if="typeError !== ''" class="invalid-feedback">
                        {{ typeError }}
                    </div>

                    <button class="secondary" @click="createAsset">Neues Asset Anlegen</button>

                    <div class="buttons-info">
                        <button class="button-info" @click="openAssetModal">Weitere Infos</button>
                    </div>
                </div>
            </div>

            <div class="buttons">
                <button class="ghost-primary configurator-button" @click="openConfirmationModal">Zurück</button>
                <button class="primary configurator-button" @click="gotoProjectScope">Weiter</button>
            </div>
        </div>

        <div v-else>
            <router-view />
        </div>

<!--        <modal class="info-modal" v-model="assetModal">-->
<!--            <img slot="title" src="./../../public/icon-info.svg" alt="info icon">-->
<!--            <h2 slot="title">Prüfumfang definieren und Assets anlegen</h2>-->

<!--            <p>-->
<!--                Im Asset-basierten Ansatz führen wir einen Pentest der von Ihnen spezifizierten Assets durch. Das heißt,-->
<!--                Sie definieren im nachfolgenden Prozess den konkreten Prüfumfang bestehend aus einzelnen Assets, den wir-->
<!--                im Rahmen des Pentests anschließend auf Schwachstellen und Verwundbarkeiten überprüfen.-->
<!--            </p>-->

<!--            <hr>-->

<!--            <p>-->
<!--                Auf Basis unserer Erfahrungswerte umfasst der Prüfumfang in den allermeisten Fällen mehrere Assets, die-->
<!--                jeweils zusammenarbeiten oder interagieren, um einen übergreifenden Zweck, z.B. die Bereitstellung eines-->
<!--                digitalen Services für Mitarbeiter oder Kunden, zu erfüllen.-->
<!--            </p>-->

<!--            <p>-->
<!--                Damit wir im Rahmen des Pentests alle Schwachstellen und Verwundbarkeiten identifizieren können, müssen-->
<!--                Sie den Prüfumfang korrekt beschreiben. Hierzu müssen Sie alle eingesetzten Assets zunächst identifizieren-->
<!--                und daraufhin im Konfigurator anlegen.-->
<!--            </p>-->

<!--            <p>-->
<!--                Um die Zusammensetzung des Prüfumfangs aus mehreren Assets zu veranschaulichen, haben wir für Sie nachfolgende aufbereitet.-->
<!--            </p>-->

<!--            <p>-->
<!--                <strong>-->
<!--                    Pentest einer Unternehmenswebseite mit integriertem Bewerber-Portal:-->
<!--                </strong>-->
<!--            </p>-->

<!--            <ul>-->
<!--                <li>-->
<!--                    1 x Router-->
<!--                </li>-->
<!--                <li>-->
<!--                    1 x Firewall-->
<!--                </li>-->
<!--                <li>-->
<!--                    1 x Webserver-->
<!--                </li>-->
<!--                <li>-->
<!--                    1 x Datenbankserver-->
<!--                </li>-->
<!--                <li>-->
<!--                    1 x Drupal Content Management System-->
<!--                </li>-->
<!--                <li>-->
<!--                    1 x HR Portal für Bewerber (vollintegriert in die Webseite)-->
<!--                </li>-->
<!--            </ul>-->

<!--            <p>-->
<!--                <strong>-->
<!--                    Pentest einer Unternehmenswebseite mit einem separaten Blog für Newsbeiträge:-->
<!--                </strong>-->
<!--            </p>-->

<!--            <ul>-->
<!--                <li>-->
<!--                    1 x Router-->
<!--                </li>-->
<!--                <li>-->
<!--                    1 x Firewall-->
<!--                </li>-->
<!--                <li>-->
<!--                    1 x Webserver-->
<!--                </li>-->
<!--                <li>-->
<!--                    1 x Datenbankserver-->
<!--                </li>-->
<!--                <li>-->
<!--                    1 x Typo3 Content Management System-->
<!--                </li>-->
<!--                <li>-->
<!--                    1 x Wordpress Blog System-->
<!--                </li>-->
<!--            </ul>-->

<!--            <p>-->
<!--                <strong>-->
<!--                    Pentest eines Online-Shops:-->
<!--                </strong>-->
<!--            </p>-->

<!--            <ul>-->
<!--                <li>-->
<!--                    1 x Router-->
<!--                </li>-->
<!--                <li>-->
<!--                    1 x Firewall-->
<!--                </li>-->
<!--                <li>-->
<!--                    1 x Webserver-->
<!--                </li>-->
<!--                <li>-->
<!--                    1 x Datenbankserver-->
<!--                </li>-->
<!--                <li>-->
<!--                    1 x Mailserver-->
<!--                </li>-->
<!--                <li>-->
<!--                    1 x Magento E-Commerce System-->
<!--                </li>-->
<!--            </ul>-->

<!--            <p>-->
<!--                <strong>-->
<!--                    Pentest einer interaktiven mobile App für Kunden:-->
<!--                </strong>-->
<!--            </p>-->

<!--            <ul>-->
<!--                <li>-->
<!--                    Mobile App für Android-->
<!--                </li>-->
<!--                <li>-->
<!--                    Mobile App für iOS-->
<!--                </li>-->
<!--                <li>-->
<!--                    1 x Router-->
<!--                </li>-->
<!--                <li>-->
<!--                    1 x Firewall-->
<!--                </li>-->
<!--                <li>-->
<!--                    1 x Applikations- und Datenbankserver-->
<!--                </li>-->
<!--                <li>-->
<!--                    1 x Webservice-->
<!--                </li>-->
<!--            </ul>-->

<!--            <p>-->
<!--                <strong>-->
<!--                    Pentest eines Fat Clients zur internen Zeiterfassung:-->
<!--                </strong>-->
<!--            </p>-->

<!--            <ul>-->
<!--                <li>-->
<!--                    1 x Fat Client zur Erfassung von Arbeitszeiten sowie administrativen Zwecken-->
<!--                </li>-->
<!--                <li>-->
<!--                    1 x Applikations- und Datenbankserver-->
<!--                </li>-->
<!--            </ul>-->

<!--            <p>-->
<!--                <strong>-->
<!--                    Pentest eines Webservice zur Validierung von eingegeben Adressdaten:-->
<!--                </strong>-->
<!--            </p>-->

<!--            <ul>-->
<!--                <li>-->
<!--                    1 x Router-->
<!--                </li>-->
<!--                <li>-->
<!--                    1 x Firewall-->
<!--                </li>-->
<!--                <li>-->
<!--                    1 x Applikations- und Datenbankserver-->
<!--                </li>-->
<!--                <li>-->
<!--                    1 x Webservice-->
<!--                </li>-->
<!--            </ul>-->

<!--            <hr>-->

<!--            <p>-->
<!--                Wie bereits in den Beispielen ersichtlich, umfasst der Prüfumfang häufig nicht nur mehrere Assets, sondern-->
<!--                auch Assets verschiedener Typen. Damit Sie Ihren Prüfumfang korrekt erfassen können, bieten wir Ihnen-->
<!--                deshalb die Auswahl aus fünf verschiedenen Asset-Typen an.-->
<!--            </p>-->

<!--            <p>-->
<!--                <strong>-->
<!--                    IT-System:-->
<!--                </strong>-->
<!--            </p>-->

<!--            <p>-->
<!--                Ein IT-System kann ein Server, wie z.B. ein Webserver, ein Datenbankserver, ein Applikationsserver oder-->
<!--                ein Mailserver sein. Ein IT-System kann auch eine Netzwerkkomponente, wie z.B. ein Switch, ein Router-->
<!--                oder eine Firewall sein. Ein IT-System kann weiterhin auch eine Workstation eines Mitarbeiters sein.-->
<!--            </p>-->

<!--            <p>-->
<!--                Natürlich kann auch ein Verbund aus verschiedenen Servern, Netzwerkkomponenten oder Workstations als Asset-->
<!--                angelegt werden. Gleichermaßen ist die Anlage eines heterogenen Verbunds aus Servern, Netzwerkkomponenten-->
<!--                und Workstations möglich. Letztlich entscheiden Sie, wie detailliert Sie die zu prüfenden Assets im-->
<!--                Konfigurator erfassen. Grundsätzlich empfiehlt sich jedoch, die Assets granularer zu erstellen und uns-->
<!--                so für jedes Asset detaillierter die prüfungsrelevanten Informationen zu übermitteln.-->
<!--            </p>-->

<!--            <p>-->
<!--                <strong>-->
<!--                    Webapplikation:-->
<!--                </strong>-->
<!--            </p>-->

<!--            <p>-->
<!--                Wir unterscheiden einfache Webseiten und komplexe Webanwendungen. Jede zu prüfende Webapplikation muss-->
<!--                separat von Ihnen angelegt und die technischen Informationen hierzu spezifiziert werden. Nutzt eine-->
<!--                Webapplikation intern Webservices zur Verarbeitung von Daten, müssen Sie diese nicht separat anlegen.-->
<!--                Nutzt die Webapplikation hingegen zusätzliche (externe) Webservices, die ebenfalls geprüft werden sollen,-->
<!--                müssen Sie diese separat als Asset des Typs "Webservice" anlegen.-->
<!--            </p>-->

<!--            <p>-->
<!--                <strong>-->
<!--                    Webservice:-->
<!--                </strong>-->
<!--            </p>-->

<!--            <p>-->
<!--                Webservices werden heutzutage regelmäßig und sehr häufig genutzt, um z.B. Daten automatisiert zwischen-->
<!--                IT-Systemen eines Unternehmens, aber auch zwischen verschiedenen Unternehmen, auszutauschen. Weiterhin-->
<!--                werden Webservices oft genutzt, um Funktionen einfach Unternehmensintern oder -extern bereitzustellen-->
<!--                und einen automatisierbaren Zugriff sowie Einsatz zu gewährleisten. Jeder zu prüfende Webservice muss-->
<!--                separat angelegt und die jeweiligen technischen Informationen hierzu von Ihnen spezifiziert werden.-->
<!--            </p>-->

<!--            <p>-->
<!--                <strong>-->
<!--                    Mobile App:-->
<!--                </strong>-->
<!--            </p>-->

<!--            <p>-->
<!--                Wir prüfen mobile Apps für die zwei relevantesten Betriebssysteme iOS und Android. Die mobile App wird-->
<!--                dabei entweder separat für jedes Betriebssystem entwickelt oder über eine Cross-Plattform-Lösung für-->
<!--                beide Systeme gleichzeitig umgesetzt. Es existieren sowohl native mobile Apps wie auch hybride Weblösungen.-->
<!--                Beides wird von uns unterstützt. Während es sich bei den ersten mobile Apps häufig um rein lokale Anwendungen-->
<!--                handelte, verfügen heute nahezu alle mobile Apps über eine externe Serverinstanz inklusive Webservice zum-->
<!--                Austausch, zur Verarbeitung sowie Speicherung der Nutzerdaten. Entsprechende Informationen werden bei der-->
<!--                Asset-Anlage abgefragt und im Pentest berücksichtigt. Sie müssen in diesem Fall nicht jeden eingesetzten-->
<!--                Server sowie Webservice separat anlegen.-->
<!--            </p>-->

<!--            <p>-->
<!--                <strong>-->
<!--                    Fat Client:-->
<!--                </strong>-->
<!--            </p>-->

<!--            <p>-->
<!--                Fat Clients sind lokale oder Server-basierte Anwendungen, die für einen bestimmten Zweck sowie nativ für-->
<!--                das jeweilige Betriebssystem (Windows, Linux) entwickelt werden. Fat Clients existieren für die-->
<!--                unterschiedlichsten Anwendungsfälle. Verfügt eine Anwendung über einen separaten Fat Client für-->
<!--                Benutzer sowie Administratoren, müssen Sie die Anwendung nur einmalig als Fat Client anlegen. Sollen-->
<!--                jedoch mehrere verschiedene Fat Clients überprüft werden, müssen sie diese separat erfassen. Handelt es-->
<!--                sich um einen Fat Client, der Netzwerk-basiert mit einer Serverinstanz kommuniziert, müssen Sie den oder-->
<!--                die eingesetzten Server separat als IT-System(e) erfassen.-->
<!--            </p>-->

<!--            <hr>-->

<!--            <p>-->
<!--                Sollten Sie bei der Definition des Prüfumfangs unsicher sein oder Fragen während der Anlage einzelner Assets-->
<!--                haben, stehen wir Ihnen gerne jederzeit telefonisch oder per E-Mail zur Verfügung. Sollten wir bei der-->
<!--                Prüfung Ihrer eingegangen Projektanfrage feststellen, dass Fehler in der Definition des Prüfumfangs-->
<!--                vorliegen, kommen wir selbstverständlich auf Sie zu und klären diese, bevor wir Ihnen ein Angebot unterbreiten.-->
<!--            </p>-->

<!--            <form-actions slot="footer">-->
<!--                <button @click="closeAssetModal" class="primary button-modal">Schließen</button>-->
<!--            </form-actions>-->
<!--        </modal>-->

        <modal class="info-modal" v-model="assetModal">
            <img slot="title" src="./../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Prüfumfang definieren und Assets anlegen</h2>

            <p>
                Der Asset-basierte Ansatz bietet Ihnen die Möglichkeit den Prüfumfang konkret und individuell zu spezifizieren.
                Das ist sinnvoll, wenn Sie Ihre Umgebung gut kennen bzw. bereits wissen was geprüft werden soll.
            </p>

            <p>
                Der Prüfumfang oder auch „Scope“ eines Pentests definiert die zu testenden IT-Assets. Typischerweise
                interagieren mehrere IT-Assets miteinander oder bedingen sich gegenseitig, um einen übergeordneten Zweck
                zu erfüllen. Beispielsweise werden Webseiten in der Regel auf Webservern betrieben. In der Konsequenz
                sollten sowohl die Webserver, als auch die Webseite gemeinsam geprüft werden, um die Sicherheit des
                Verbunds sinnvoll bewerten zu können.
            </p>

            <p>
                Um den Prüfumfang möglichst vollständig zu beschreiben, empfehlen wir die Zuhilfenahme eines Architekturdiagrams
                bzw. eines Datenflussdiagrams. Hier sieht man in der Regel deutlich, welche IT-Assets miteinander interagieren,
                Einfluss auf die Sicherheit des Verbunds haben und im Rahmen eines Pentests geprüft werden sollten.
            </p>

            <form-actions slot="footer">
                <button @click="closeAssetModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="confirmationModal">
            <img slot="title" src="./../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Möchten Sie Ihren Projektansatz wirklich ändern?</h2>

            <div class="buttons-modal">
                <div class="buttons-left">
                    <button class="ghost-primary button-modal-back-dialog" @click="gotoProjectType()">Ja, zurück zur Projektansatzauswahl.</button>
                </div>
                <div class="buttons-right">
                    <button class="primary button-modal-back-dialog" @click="closeConfirmationModal()">Nein, der Projektansatz wird weiterverfolgt.</button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, onMounted, nextTick } from 'vue'
import { useRouter } from '@/composables/use-router'
import { useModal } from '@/composables/use-modal'
import { useProjectStore } from '@/store/projectStore'
import { useAssetStore } from '@/store/assetStore'
import { useScopeStore } from '@/store/scopeStore'
import { useOptionalStore } from '@/store/optionalStore'
import { usePriceStore } from '@/store/priceStore'
import ConfiguratorHeader from '@/components/ConfiguratorHeader.vue'
import Progressbar from '@/components/Progressbar.vue'
import AssetList from '@/components/asset/AssetList.vue'

export default defineComponent({
    name: 'AssetConfig',

    components: {
        ConfiguratorHeader,
        Progressbar,
        AssetList
    },

    setup() {
        const { router, route } = useRouter()

        const { modal: assetModal, openModal: openAssetModal, closeModal: closeAssetModal } = useModal()
        const { modal: confirmationModal, openModal: openConfirmationModal, closeModal: closeConfirmationModal } = useModal()

        const projectStore = useProjectStore()
        const projectId = projectStore.uuid

        const assetStore = useAssetStore()
        const assetList = assetStore.getAll

        const priceStore = usePriceStore()

        onMounted(async() => {
            if (projectId.value === '') {
                const scopeStore = useScopeStore()
                const optionalStore = useOptionalStore()
                await projectStore.loadProject(route.value.params.id)
                await assetStore.updateAssetList(route.value.params.id)
                await calculateAssetPrice()
                await scopeStore.loadScope(route.value.params.id)
                await priceStore.fetchScopePrice(route.value.params.id)
                await optionalStore.loadOptionals(route.value.params.id)
                await priceStore.fetchOptionalsPrices(route.value.params.id)
            }
        })

        const calculateAssetPrice = async() => {
            await priceStore.calculateAssetPrice(projectId.value)
            await priceStore.fetchProjectPrice(projectId.value)
        }

        const createAsset = () => {
            window.scrollTo(0, 0)
            router.push({ name: 'add asset' })
        }

        const typeError = ref<string>('')

        watch(() => assetList.value.length, (value) => {
            typeError.value = value > 0 ? '' : 'Sie müssen mindestens ein Asset erstellen.'
        })

        const validate = async() => {
            typeError.value = assetList.value.length > 0 ? '' : 'Sie müssen mindestens ein Asset erstellen.'

            if (typeError.value === '') {
                return true
            }
            else {
                await nextTick()
                const element = document.querySelector('.invalid-feedback')?.parentNode?.parentNode as HTMLElement
                element.scrollIntoView({behavior: 'smooth'})
            }
        }

        const gotoProjectType = async() => {
            // await assetList.value.forEach(async(asset: any) => {
            //     await assetStore.deleteAsset(asset.id, projectId.value)
            // })
            // await assetStore.updateAssetList(projectId.value)
            // assetStore.clearAsset()
            // await priceStore.calculateAssetPrice(projectId.value)
            // await priceStore.fetchProjectPrice(projectId.value)
            window.scrollTo(0, 0)
            closeConfirmationModal()
            await router.push({ name: 'new project' })
        }

        const gotoProjectScope = async() => {
            if (await validate()) {
                window.scrollTo(0, 0)
                const scopeStore = useScopeStore()
                await scopeStore.initializeScope(projectId.value)
                await router.push({ name: 'project scope', params: { id: projectId.value } })
            }
        }

        return {
            assetModal,
            openAssetModal,
            closeAssetModal,
            confirmationModal,
            openConfirmationModal,
            closeConfirmationModal,
            assetList,
            createAsset,
            gotoProjectType,
            gotoProjectScope,
            typeError
        }
    }
})

</script>

<style lang="scss" scoped>
@import "../styles/breakpoints";

.asset-header {
    @include default-padding();
}

.configured-assets {
    @include default-padding();
    background-color: var(--color-lightest-grey);
}

.configured-assets-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.asset-header, .configured-assets-text {
    text-align: left;
    h2 {
        color: var(--color-brand-primary);
    }
}

</style>
