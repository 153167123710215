import { ref } from 'vue'
import Repository from '@/repository/Repository'

// Store
// Variables are used as two-way bindings
const days = ref<number>(10)

const agreement = ref<string>('default')

const dailyHours = ref<number>(8)

const billingUnit = ref<string>('hours')

const performanceProof = ref<boolean>(false)

const duration = ref<number>(12)

const obligation = ref<boolean>(false)

const actions = {
    initializeContingent: async(projectId: string) => {
        const params = { projectUuid: projectId }
        try {
            await Repository.get('/contingent', { params })
        } catch (error) {
            await Repository.post('/contingent/init', params)
            // console.log('Save error: ' + error)
        }
    },
    updateContingent: async(projectId: string) => {
        try {
            const data = {
                projectUuid: projectId,
                days: days.value,
                agreement: agreement.value,
                dailyHours: dailyHours.value,
                billingUnit: billingUnit.value,
                performanceProof: performanceProof.value,
                duration: duration.value,
                obligation: obligation.value
            }
            await Repository.post('/contingent', data)
        } catch (error) {
            // console.log('Update contingent days error: ' + error)
        }
    },
    loadContingent: async(projectId: string) => {
        try {
            const params = { projectUuid: projectId }
            const response = await Repository.get('/contingent', { params })
            days.value = response.data.days as number
            agreement.value = response.data.agreement as string
            dailyHours.value = response.data.dailyHours as number
            billingUnit.value = response.data.billingUnit as string
            performanceProof.value = response.data.performanceProof as boolean
            duration.value = response.data.duration as number
            obligation.value = response.data.obligation as boolean
        } catch (error) {
            // console.log('Load contingent error: ' + error)
        }
    },
    deleteContingent: async(projectId: string) => {
        try {
            const params = { projectUuid: projectId }
            await Repository.delete('/contingent', { params })
        } catch (error) {
            // console.log('Delete error: ' + error)
        }
    },
    clearContingentStore: () => {
        days.value = 10
        agreement.value = 'default'
        dailyHours.value = 8
        billingUnit.value = 'hours'
        performanceProof.value = false
        duration.value = 12
        obligation.value = false
    }
}

export const useContingentStore = () => {
    return {
        days,
        agreement,
        dailyHours,
        billingUnit,
        performanceProof,
        duration,
        obligation,
        ...actions
    } as const
}
