<template>
    <div class='box-select-checkbox'>
        <div class="checkbox-option">
            <label v-for="option in options" :key="getOptionId(id, option.id)">
                <input
                    class="option-box"
                    type="checkbox"
                    :id="getOptionId(id, option.id)"
                    :value="option.id"
                    v-model="payload"
                />
                <p>
                    {{ option.label }}
                </p>
            </label>
        </div>

        <div v-if="error !== ''" class="invalid-feedback">
            {{ error }}
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
    name: 'BoxSelectCheckbox',

    props: {
        id: {
            type: String,
            required: true
        },
        value: {
            type: Array,
            default: () => { return [] }
        },
        options: {
            type: Array,
            required: true
        },
        error: {
            type: String,
            default: ''
        }
    },

    setup(props, { emit }) {
        const payload = ref(props.value)

        watch(payload, (value, prevValue) => {
            if (prevValue !== value && props.value !== value) {
                emit('input', value)
            }
        })

        watch(() => props.value, (value) => {
            if (payload.value !== value) {
                payload.value = value
            }
        })

        const hasValue = (check: any) => {
            return payload.value.indexOf(check) !== -1
        }

        const checkboxVal = (id: any) => {
            return hasValue(id) ? 'checkbox-marked' : 'checkbox-blank-outline'
        }

        const getOptionId = (id: any, optionId: any) => {
            return id + '-' + optionId
        }

        return {
            payload,
            checkboxVal,
            getOptionId
        }
    }
})
</script>

<style lang="scss" scoped>
@import "../styles/breakpoints";

.checkbox-option {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.5%;
}

.checkbox-option label {
    display: flex;
    justify-content: center;
    align-items: stretch;
    width: 32%;
    max-width: 50%;
    min-height: 6em;
    p {
        cursor: pointer;
        width: 100%;
        margin: 0.7em 0.5em;
        padding: 2em 4em;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border: 1px solid var(--color-light-grey);
        color: var(--color-light-grey);
        background-color: var(--color-white);
    }
}

.option-box {
    display: none;
    margin: 2px 10px 0 0;
}

.checkbox-option label p:hover {
    border: 1px solid var(--color-brand-secondary);
    transition: background-color 0.2s ease-in-out;
}

.option-box:checked + p {
    border-color: var(--color-brand-secondary);
    background-color: var(--color-brand-secondary);
    color: var(--color-white);
}

@media screen and (max-width: $bp-large) {
    .checkbox-option {
        justify-content: space-between;
    }

    .checkbox-option label {
        width: 49%;
        max-width: initial;

        p {
            margin: 0;
            //display: flex;
            //align-items: center;
            //justify-content: center;
        }
    }
}

@media screen and (max-width: $bp-medium) {
    .checkbox-option {
        flex-direction: column;
    }

    .checkbox-option label {
        width: 100%;
        max-width: initial;

        p {
            margin: 0;
            //display: flex;
            //align-items: center;
            //justify-content: center;
        }
    }
}
</style>
