<template>
    <div class='scope-separate-environment'>
        <div class="scope-header">
            <h2>Separate Testumgebung *</h2>

            <p>
                Erfordert der Pentest das Aufsetzen einer separaten Testumgebung durch die usd?
            </p>
        </div>

        <tristate-switch-field id="separate-environment-radio" v-model="separateEnvironment" disable-null label="" label-pre="Ja" label-post="Nein"/>

        <div class="buttons-info">
            <button class="button-info" @click="openSeparateEnvironmentModal">Weitere Infos</button>
        </div>

        <modal class="info-modal" v-model="separateEnvironmentModal">
            <img slot="title" src="../../../../public/icon-info.svg">
            <h2 slot="title">Separate Testumgebung</h2>

            <p>
                In seltenen Fällen erfordert die Durchführung eines Pentests neben einer vorhandenen Systemumgebung beim Kunden zusätzlich
                das Aufsetzen einer Testumgebung bei usd.
            </p>

            <p>
                Häufig ist dies der Fall, wenn komplexe Anwendungen, bestehend aus mehreren Komponenten überprüft werden sollen. Diese
                Anwendungen bestehen zumeist aus zentralen Server- und Applikationskomponenten (z.B. Schnittstellen, Datenbanken, Web-
                und Applikationsserver) sowie unterschiedlichen Anwendungskomponenten (z.B. Fat Clients, Systemdienste, mobile Apps,
                Webapplikationen) für die Endnutzer.
            </p>

            <p>
                Die zentralen Komponenten werden im Rahmen des Pentests dann vom Auftraggeber bereitgestellt, während die Anwendungsumgebung
                von usd separat in der Vorbereitung des Pentests aufgesetzt werden muss.
            </p>

            <form-actions slot="footer">
                <button @click="closeSeparateEnvironmentModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useModal } from '@/composables/use-modal'
import { useScopeStore } from '@/store/scopeStore'

export default defineComponent({
    name: 'ScopeSeparateEnvironment',

    setup() {
        const { modal: separateEnvironmentModal, openModal: openSeparateEnvironmentModal, closeModal: closeSeparateEnvironmentModal } = useModal()

        const scopeStore = useScopeStore()
        const separateEnvironment = scopeStore.separateEnvironment

        return {
            separateEnvironment,
            separateEnvironmentModal,
            openSeparateEnvironmentModal,
            closeSeparateEnvironmentModal
        }
    }
})
</script>

<style lang="scss" scoped>
@import "../../../styles/breakpoints";

.scope-separate-environment {
    @include default-padding();
}

.scope-header {
    padding-bottom: 1.5%;
    text-align: left;
    h2 {
        color: var(--color-brand-primary);
    }
}

.separate-environment-radio-options {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.5%;
    border: 1px solid var(--color-black);
    background-color: var(--color-white);
}

.separate-environment-radio-options label {
    display: flex;
    margin: 2% 3%;
    p {
        margin: 0;
    }
}

.option-radio {
    display: inline-block;
    margin: 2px 10px 0 0;
}
</style>
