<template>
    <transition name="expand" @enter="enter" @afterEnter="afterEnter" @leave="leave">
        <slot></slot>
    </transition>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'TransitionExpand',

    setup() {
        const afterEnter = (element) => {
            element.style.height = 'auto'
        }

        const enter = (element) => {
            const { height } = getComputedStyle(element)

            element.style.height = 0

            setTimeout(() => {
                element.style.height = height
            })
        }

        const leave = (element) => {
            const { height } = getComputedStyle(element)

            element.style.height = height

            setTimeout(() => {
                element.style.height = 0
            })
        }

        return {
            afterEnter,
            enter,
            leave
        }
    }
})
</script>

<style lang="scss" scoped>
    .expand-enter-active,
    .expand-leave-active {
        transition-duration: 0.3s;
        transition-property: height, opacity;
        transition-timing-function: ease;
        overflow: hidden;
    }

    .expand-enter,
    .expand-leave-active {
        opacity: 0
    }
</style>
