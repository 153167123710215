import { ref, computed } from 'vue'
import Repository from '@/repository/Repository'

// Interfaces
interface Asset {
    id: string,
    name: string,
    description: string,
    type: string,
    config: Object
    location: string,
    timing: string,
    duration: number,
    price: number
}

// Store
const assetList = ref<Array<Asset>>([])

const asset = ref<Asset>({
    id: '',
    name: '',
    description: '',
    type: '',
    config: {},
    location: 'remote',
    timing: 'default',
    duration: 0,
    price: 0
})

// Getters
const getters = {
    getAll: computed(() => assetList.value),
    getCurrentAsset: computed(() => asset.value)
}

// Actions
const actions = {
    setType: async(type: string) => {
        asset.value.type = type
    },
    setConfig: async(config: Object) => {
        asset.value.config = config
    },
    clearAsset: () => {
        asset.value.id = ''
        asset.value.name = ''
        asset.value.description = ''
        asset.value.type = ''
        asset.value.config = {}
        asset.value.location = 'remote'
        asset.value.timing = 'default'
        asset.value.duration = 0
        asset.value.price = 0
    },
    checkAssetExists: async(assetId: string, projectId: string) => {
        try {
            const params = {
                projectUuid: projectId,
                assetId
            }
            await Repository.get('/asset', { params })
            return true
        } catch (error) {
            return false
        }
    },
    saveAsset: async(asset: any, projectId: string) => {
        try {
            const data = {
                projectUuid: projectId,
                name: asset.name,
                description: asset.description,
                type: asset.type,
                config: asset.config,
                location: asset.location,
                timing: asset.timing,
                duration: asset.duration,
                price: asset.price
            }
            const response = await Repository.post('/asset', data)
            asset.value.id = response.data as string
        } catch (error) {
            // console.log('Save error: ' + error)
        }
    },
    updateAsset: async(asset: any, projectId: string) => {
        try {
            const data = {
                id: asset.id,
                projectUuid: projectId,
                name: asset.name,
                description: asset.description,
                type: asset.type,
                config: asset.config,
                location: asset.location,
                timing: asset.timing,
                duration: asset.duration,
                price: asset.price
            }
            await Repository.post('/asset/update', data)
        } catch (error) {
            // // console.log('Update error: ' + error)
        }
    },
    deleteAsset: async(assetId: string, projectId: string) => {
        try {
            const params = {
                projectUuid: projectId,
                assetId
            }
            await Repository.delete('/asset', { params })
        } catch (error) {
            // console.log('Delete error: ' + error)
        }
    },
    updateAssetList: async(projectId: string) => {
        try {
            const params = { projectUuid: projectId }
            const response = await Repository.get('/asset/list', { params })
            assetList.value = response.data
        } catch (error) {
            // console.log('Update assetList error: ' + error)
        }
    },
    updateAssetTiming: async(projectId: string, assetId: string, timing: string) => {
        try {
            const data = { projectUuid: projectId, assetId, timing }
            await Repository.post('/asset/timing', data)
        } catch (error) {
            // console.log('Update timing error: ' + error)
        }
    },
    updateAssetLocation: async(projectId: string, assetId: string, location: string) => {
        try {
            const data = { projectUuid: projectId, assetId, location }
            await Repository.post('/asset/location', data)
        } catch (error) {
            // console.log('Update location error: ' + error)
        }
    }
}

export const useAssetStore = () => {
    return {
        ...getters,
        ...actions
    } as const
}
