<template>
    <div class='scope-report'>
        <div class="scope-header">
            <h2>Ergebnisbericht</h2>

            <p>
                Bitte konkretisieren Sie Ihre Anforderungen an den Ergebnisbericht.
            </p>
        </div>

        <div>
            <div class="heading-report-radio">
                <img src="../../../../public/icons-report/icon-layout.svg" alt="icon layout">
                <h3>Berichtslayout *</h3>
            </div>

            <p class="report-radio-text">
                Welches Berichtslayout sollen wir im Rahmen des Pentests einsetzen?
            </p>

            <BoxSelectRadio id="report-layout-radio" v-model="report.layout" :options="layoutOptions" @input="updateScope()"/>

            <div class="buttons-info">
                <button class="button-info" @click="openLayoutModal">Weitere Infos</button>
            </div>
        </div>

        <div>
            <div class="heading-report-radio">
                <img src="../../../../public/icons-report/icon-risk-assessment.svg" alt="icon risikobewertung">
                <h3>Eingesetztes Bewertungssystem für Schwachstellen *</h3>
            </div>

            <p class="report-radio-text">
                Soll die Risikobewertung der Schwachstellen neben dem Bewertungssystem der usd auch nach internationalen Standards erfolgen?
            </p>

            <BoxSelectCheckbox id="report-risk-assessment-checkbox" v-model="riskAssessment" @input="addRiskAssessmentToScope" :options="riskAssessmentOptions" />

            <div class="buttons-info">
                <button class="button-info" @click="openRiskAssessmentModal">Weitere Infos</button>
            </div>
        </div>

        <div>
            <div class="heading-report-radio">
                <img src="../../../../public/icons-report/icon-customer-risk-assessment.svg" alt="icon risikobewertung businessperspektive">
                <h3>Risikobewertung aus Business-Perspektive *</h3>
            </div>

            <p class="report-radio-text">
                Soll neben der technischen Risikobewertung der usd Ihre eigene Business-Bewertung der Schwachstellenrisiken im Bericht berücksichtigt werden?
            </p>

            <tristate-switch-field id="report-customer-risk-assessment-radio" v-model="report.customerRiskAssessment" @input="updateScope()" :error="customerRiskAssessmentError" disable-null label="" label-pre="Ja" label-post="Nein"/>

            <div class="buttons-info">
                <button class="button-info" @click="openCustomerRiskAssessmentModal">Weitere Infos</button>
            </div>
        </div>

        <div>
            <div class="heading-report-radio">
                <img src="../../../../public/icons-report/icon-language.svg" alt="icon sprache">
                <h3>Sprache des Berichts *</h3>
            </div>

            <p class="report-radio-text">
                In welchen Sprachen soll der Ergebnisbericht von der usd erstellt werden?
            </p>

            <BoxSelectCheckbox id="report-language-radio" v-model="report.language" :options="languageOptions" @input="updateScope()" :error="languageError"/>
        </div>

        <modal class="info-modal" v-model="layoutModal">
            <img slot="title" src="../../../../public/icon-info.svg" alt="icon info">
            <h2 slot="title">Berichtslayout</h2>

            <p>
                Der usd Standardbericht wird Ihnen im PDF Format zur Verfügung gestellt und umfasst die folgenden Informationen:
            </p>

            <ul>
                <li>
                    Management Summary
                </li>
                <li>
                    Beschreibung der Schwachstellen
                </li>
                <li>
                    Risikobewertung der Schwachstellen
                </li>
                <li>
                    Maßnahmenempfehlungen zur Korrektur
                </li>
                <li>
                    Beschreibung der Vorgehensweise
                </li>
            </ul>

            <p>
                Darüber hinaus erhalten Sie von usd eine Excel-Übersicht, die alle Schwachstellen sowie technische Details darstellt.
            </p>

            <p>
                Gerne nutzen wir optional eine vom usd Standardbericht abweichende, kundenspezifische Berichtsvorlage (z.B. im Word-Format
                mit eigener Struktur und abweichenden Inhalten), um die Ergebnisse für Sie zu dokumentieren.
            </p>

            <form-actions slot="footer">
                <button @click="closeLayoutModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="riskAssessmentModal">
            <img slot="title" src="../../../../public/icon-info.svg" alt="icon info">
            <h2 slot="title">Eingesetztes Bewertungssystem für Schwachstellen</h2>

            <p>
                Für alle identifizierten Schwachstellen nimmt die usd eine Risikobewertung aus technischer Sicht vor. Hierbei kommt ein eigenes
                Bewertungsschema zum Einsatz, das nachfolgend erläutert wird.
            </p>

            <div class="criticality-container">
                <img src="../../../../public/icons-criticality/kritikalitaet-kritisch.svg" alt="icon kritikalitaet kritisch">

                <div class="criticality-text">
                    <h3 class="criticality-critical">
                        Kritisch
                    </h3>

                    <p>
                        Die Vertraulichkeit und Integrität zu schützender Informationen ist unmittelbar gefährdet. Ein erfolgreiches Ausnutzen
                        dieser Schwachstelle ist an keine weiteren Bedingungen geknüpft. Somit stellt die Schwachstelle ein direktes Risiko für
                        die Systemsicherheit dar. Häufig sind Informationen zu solchen Verwundbarkeiten weit verbreitet. Dies ermöglicht es auch
                        technisch unerfahrenen Personen diese auszunutzen.
                    </p>
                </div>
            </div>

            <div class="criticality-container">
                <img src="../../../../public/icons-criticality/kritikalitaet-hoch.svg" alt="icon kritikalitaet hoch">

                <div class="criticality-text">
                    <h3 class="criticality-high">
                        Hoch
                    </h3>

                    <p>
                        Die Vertraulichkeit oder Integrität zu schützender Informationen ist gefährdet. Allerdings ist das erfolgreiche Ausnutzen
                        der Schwachstelle an eine weitere Bedingung geknüpft, wie beispielsweise die Interaktion durch Dritte. Auch Schwachstellen,
                        deren Ausnutzung potenziell unberechtigten Zugriff auf eine Applikation, ein System oder sensible Daten erlaubt, die jedoch
                        nicht definitiv verifiziert werden konnten, werden in dieser Kategorie gelistet.
                    </p>
                </div>
            </div>

            <div class="criticality-container">
                <img src="../../../../public/icons-criticality/kritikalitaet-mittel.svg" alt="icon kritikalitaet mittel">

                <div class="criticality-text">
                    <h3 class="criticality-medium">
                        Mittel
                    </h3>

                    <p>
                        In Kombination mit weiteren Einflussfaktoren, wie anderen Verwundbarkeiten oder Benutzerinteraktionen, können diese
                        Schwachstellen die Vertraulichkeit oder Integrität der Daten verletzen. Außerdem fallen Schwachstellen, welche die
                        Verfügbarkeit zu schützender Informationen gefährden, in diese Kategorie.
                    </p>
                </div>
            </div>

            <div class="criticality-container">
                <img src="../../../../public/icons-criticality/kritikalitaet-niedrig.svg" alt="icon kritikalitaet niedrig">

                <div class="criticality-text">
                    <h3 class="criticality-low">
                        Niedrig
                    </h3>

                    <p>
                        Theoretisch und unter Laborbedingungen ist eine Verletzung der Vertraulichkeit und Integrität vereinzelter Datensätze
                        möglich. Der Aufwand, diese Schwachstellen auszunutzen, spiegelt in den meisten Fällen nicht den dadurch erreichten
                        Nutzen wider.
                    </p>
                </div>
            </div>

            <div class="criticality-container">
                <img src="../../../../public/icons-criticality/kritikalitaet-informationell.svg" alt="icon kritikalitaet informationell">

                <div class="criticality-text">
                    <h3 class="criticality-info">
                        Informationell
                    </h3>

                    <p>
                        Diese Schwachstellen an sich stellen keine Gefahr dar. Sie dienen lediglich dem Zweck Informationen zu sammeln,
                        welche möglicherweise weitere Angriffe ermöglichen.
                    </p>
                </div>
            </div>

            <p>
                Darüber hinaus bietet die usd optional an, die identifizierten Schwachstellen zusätzlich nach internationalen Bewertungssystemen vorzunehmen.
            </p>

            <p>
                Unterstützt werden CVSS (Common Vulnerability Scoring System, Details hier: <a href="https://www.first.org/cvss">https://www.first.org/cvss</a>)
                und CWSS (Common Weakness Scoring System, Details hier: <a href="https://cwe.mitre.org/cwss">https://cwe.mitre.org/cwss</a>).
            </p>

            <p>
                Im Rahmen der CVSS Bewertung erhalten Sie von uns für jede Schwachstelle den CVSS Basis-Wert sowie den zugrundeliegenden CVSS Vektor. Aktuell
                unterstützen wir die CVSS Version 3.1.
            </p>

            <p>
                Bei der CWSS Bewertung stellen wir Ihnen für alle identifizierten Schwachstellen die entsprechende CWE Referenz zur Verfügung.
            </p>

            <form-actions slot="footer">
                <button @click="closeRiskAssessmentModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="customerRiskAssessmentModal">
            <img slot="title" src="../../../../public/icon-info.svg" alt="icon info">
            <h2 slot="title">Risikobewertung aus Business-Perspektive</h2>

            <p>
                Alle im Rahmen eines Pentests identifizierten Schwachstellen werden von usd aus technischer Sicht mit einem Risiko
                bewertet. Eine Bewertung der Risiken aus Geschäftsperspektive ist aufgrund des fehlenden Umgebungs-Know-hows nicht
                durch usd möglich.
            </p>

            <p>
                Die hierfür notwendigen Informationen liegen Unternehmen jedoch sehr häufig vor, sodass eine Bewertung der Risiken
                identifizierter Schwachstellen sowohl aus technischer, wie aus geschäftlicher Perspektive, vorgenommen werden kann.
            </p>

            <p>
                Sofern dies möglich und gewünscht ist, bietet usd optional an, eine Bewertung der Risiken identifizierter
                Schwachstellen aus geschäftlicher Perspektive in den Ergebnisbericht einzuarbeiten. Die usd Einschätzung aus
                technischer Perspektive bleibt weiterhin Bestandteil vom Bericht und wird in diesem Fall durch die Kundeneinschätzung
                ergänzt.
            </p>

            <form-actions slot="footer">
                <button @click="closeCustomerRiskAssessmentModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

    </div>
</template>

<script lang="ts">
import { defineComponent, nextTick, ref, watch } from 'vue'
import { useModal } from '@/composables/use-modal'
import { useProjectStore } from '@/store/projectStore'
import { useScopeStore } from '@/store/scopeStore'
import { usePriceStore } from '@/store/priceStore'
import { RequiredValidatorArray, RequiredValidatorBoolean } from '@/validator/validator'
import BoxSelectCheckbox from '@/components/BoxSelectCheckbox.vue'
import BoxSelectRadio from '@/components/BoxSelectRadio.vue'

export default defineComponent({
    name: 'ScopeReport',

    components: {
        BoxSelectCheckbox,
        BoxSelectRadio
    },

    setup() {
        const { modal: layoutModal, openModal: openLayoutModal, closeModal: closeLayoutModal } = useModal()
        const { modal: riskAssessmentModal, openModal: openRiskAssessmentModal, closeModal: closeRiskAssessmentModal } = useModal()
        const { modal: customerRiskAssessmentModal, openModal: openCustomerRiskAssessmentModal, closeModal: closeCustomerRiskAssessmentModal } = useModal()

        const projectStore = useProjectStore()
        const projectId = projectStore.uuid

        const layoutOptions = [
            {
                id: 'default',
                label: 'usd Standard Pentest-Bericht (PDF)'
            },
            {
                id: 'customer',
                label: 'Kundenspezifische Berichtsvorlage'
            }
        ]

        const riskAssessmentOptions = [
            {
                id: 'usd',
                label: 'Risikobewertung der usd'
            },
            {
                id: 'cvss',
                label: 'Risikobewertung gemäß CVSS'
            },
            {
                id: 'cwss',
                label: 'Risikobewertung gemäß CWSS'
            }
        ]

        const languageOptions = [
            {
                id: 'de',
                label: 'Deutsch'
            },
            {
                id: 'en',
                label: 'Englisch'
            }
        ]

        const scopeStore = useScopeStore()
        const report = scopeStore.report
        const riskAssessment = scopeStore.report.riskAssessment

        const addRiskAssessmentToScope = (assessments: any) => {
            scopeStore.setRiskAssessment(assessments)
            updateScope()
        }

        const priceStore = usePriceStore()

        const processing = ref<Boolean>(false)

        const updateScope = async() => {
            if (await validate()) {
                if (processing.value === false) {
                    processing.value = true
                    await scopeStore.updateScope(projectId.value)
                    await priceStore.fetchScopePrice(projectId.value)
                    await priceStore.fetchProjectPrice(projectId.value)
                    processing.value = false
                }
            }
        }

        const languageError = ref<string>('')
        const customerRiskAssessmentError = ref<string>('')

        watch(() => report.language, (value) => {
            languageError.value = RequiredValidatorArray(value)
        })

        watch(() => report.customerRiskAssessment, (value) => {
            customerRiskAssessmentError.value = RequiredValidatorBoolean(value)
        })

        const validate = async() => {
            languageError.value = RequiredValidatorArray(report.language)
            customerRiskAssessmentError.value = RequiredValidatorBoolean(report.customerRiskAssessment)
            if (languageError.value === '' && customerRiskAssessmentError.value === '') {
                return true
            } else {
                await nextTick()
                const element = document.querySelector('.invalid-feedback')?.parentNode?.parentNode as HTMLElement
                element.scrollIntoView({behavior: 'smooth'})
            }
        }

        return {
            riskAssessment,
            addRiskAssessmentToScope,
            layoutOptions,
            riskAssessmentOptions,
            languageOptions,
            report,
            updateScope,
            languageError,
            customerRiskAssessmentError,
            layoutModal,
            openLayoutModal,
            closeLayoutModal,
            riskAssessmentModal,
            openRiskAssessmentModal,
            closeRiskAssessmentModal,
            customerRiskAssessmentModal,
            openCustomerRiskAssessmentModal,
            closeCustomerRiskAssessmentModal
        }
    },

    watch: {
        riskAssessment: (riskAssessment) => {
            if (!riskAssessment.includes('usd')) {
                riskAssessment.push('usd')
            }
        }
    }
})
</script>

<style lang="scss" scoped>
@import "../../../styles/breakpoints";

.scope-report {
    @include default-padding();
}

.scope-header {
    text-align: left;
    h2 {
        color: var(--color-brand-primary);
    }
}

.heading-report-radio {
    display: flex;
    align-items: center;
    img {
        margin-top: 2em;
        height: 80px;
    }
    h3 {
        margin: 80px 0 0 20px;
        color: var(--color-brand-secondary);
    }
}

.report-radio-text {
    padding: 2em 0 0 0;
 }

.report-radio-options, .report-checkbox-options {
    display: flex;
    margin-top: 1.5%;
    border: 1px solid var(--color-black);
    background-color: var(--color-white);
}

.criticality-container {
    display: flex;
    img {
        margin: 1.5em 2em 0 1.5em;
        scale: 250%;
    }
}

.criticality-critical {
    color: #8f0d2d;
}

.criticality-high {
    color: #e21538;
}

.criticality-medium {
    color: #f0801d;
}

.criticality-low {
    color: #fab725;
}

.criticality-info {
    color: #57bfc9;
}
</style>
