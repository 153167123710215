<template>
    <div class="faq-footer">
        <button class="primary faq-button">FAQ einsehen</button>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'FAQFooter'
})
</script>

<style lang="scss">
@import "../styles/breakpoints";

.faq-footer {
    text-align: center;
    background-color: var(--color-brand-primary);
    padding: 10px 0;
}

@media screen and (max-width: $bp-small) {
    .faq-footer {
        padding: 10px 1em;
    }
}
</style>
