<template>
    <div class='box-select-radio'>
        <div class="radio-options">
            <div v-for="option in options" :key="getOptionId(id, option.id)" class="radio-option" :class="{ selected: value === option.id}">
                <input
                    type="radio"
                    :id="getOptionId(id, option.id)"
                    :value="option.id"
                    v-model="payload"
                />
                <label :for="getOptionId(id, option.id)">
                    <material-icon :icon="optionVal(option.id)" />
                    <p>
                        {{ option.label }}
                    </p>
                </label>
            </div>
        </div>

        <div v-if="error !== ''" class="invalid-feedback">
            {{ error }}
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
    name: 'BoxSelectRadio',

    props: {
        id: {
            type: String,
            required: true
        },
        value: {
            type: String,
            default: () => { return '' }
        },
        options: {
            type: Array,
            required: true
        },
        classes: {
            type: String,
            default: ''
        },
        error: {
            type: String,
            default: ''
        }
    },

    setup(props, { emit }) {
        const payload = ref(props.value)

        const optionVal = (id: any) => {
            return payload.value === id ? 'radiobox-marked' : 'radiobox-blank'
        }

        const getOptionId = (id: any, optionId: any) => {
            return id + '-' + optionId
        }

        watch(payload, (value) => {
            if (value !== props.value) {
                emit('input', value)
            }
        })

        watch(() => props.value, (value) => {
            if (value !== payload.value) {
                payload.value = value
            }
        })

        return {
            payload,
            optionVal,
            getOptionId
        }
    }
})
</script>

<style lang="scss" scoped>
@import "../styles/breakpoints";

label {
    display: flex;
    align-items: center;
    flex: 1 1 100%;
    cursor: pointer;
    font-size: 0.9em;
    color: var(--color-ash);
    margin-bottom: 0;
}

label .material-icon {
    color: var(--color-light-grey);
}

.radio-options {
    display: flex;
    flex-direction: column;
    margin-top: 1.5%;
}

.radio-option {
    display: flex;
    //justify-content: center;
    //align-items: center;
    width: 32%;
    padding: 1rem;
    border: 2px solid transparent;
    border-radius: 10px;
    p {
        width: 100%;
        margin: 0.7em 0.5em;
    }
}

.selected {
    border: 2px solid var(--color-brand-secondary);
    border-radius: 10px;
}

@media screen and (max-width: $bp-large) {
    .radio-option {
        width: 100%;
    }
}
</style>
