<template>
    <div class='scope-hardware'>
        <div class="scope-header">
            <h2>Hardware *</h2>

            <p>
                Ist gesonderte Hardware (bspw. TAN-Generator, PIN-Pad oder Smartcard-Reader) für die Durchführung des Pentests notwendig?
            </p>
        </div>

        <tristate-switch-field id="hardware-radio" v-model="hardware" disable-null label="" label-pre="Ja" label-post="Nein"/>

        <div class="buttons-info">
            <button class="button-info" @click="openHardwareModal">Weitere Infos</button>
        </div>

        <modal class="info-modal" v-model="hardwareModal">
            <img slot="title" src="../../../../public/icon-info.svg">
            <h2 slot="title">Hardware</h2>

            <p>
                Insofern für die Durchführung des Pentests Spezialhardware benötigt wird, ist der Auftraggeber verpflichtet diese für das Projekt bereitzustellen.
            </p>

            <form-actions slot="footer">
                <button @click="closeHardwareModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useModal } from '@/composables/use-modal'
import { useScopeStore } from '@/store/scopeStore'
export default defineComponent({
    name: 'ScopeHardware',

    setup() {
        const { modal: hardwareModal, openModal: openHardwareModal, closeModal: closeHardwareModal } = useModal()

        const scopeStore = useScopeStore()
        const hardware = scopeStore.hardware

        return {
            hardware,
            hardwareModal,
            openHardwareModal,
            closeHardwareModal
        }
    }
})
</script>

<style lang="scss" scoped>
@import "../../../styles/breakpoints";

.scope-hardware {
    @include default-padding();
}

.scope-header {
    padding-bottom: 1.5%;
    text-align: left;
    h2 {
        color: var(--color-brand-primary);
    }
}

.hardware-radio-options {
    display: flex;
    margin-top: 1.5%;
    border: 1px solid var(--color-black);
    background-color: var(--color-white);
}

</style>
