<template>
    <div class='scope-date'>
        <div class="scope-header">
            <h2>Wunschzeitraum <sup>(optional)</sup></h2>

            <p>
                Sofern bereits bekannt, dürfen Sie uns nachfolgend gerne Ihren Wunschzeitraum für die Durchführung des Pentests nennen.
            </p>
        </div>

        <div>
            {{ dateList }}
            <div class="date-picker-container">
                <div v-for="(date, currentIndex) in dateList" :key="currentIndex + JSON.stringify(date)" class="date-picker-container">
                    <div class="date-picker">
                        <date-field :id="'date-field-' + currentIndex" label="Zeitraum" :value="date" @input="(value) => updateDateList(value, currentIndex)" :range="true" :minDate="currentDate" />

                        <div class="date-icon-remove" v-if="currentIndex > 0">
                            <button class="icon-button-date" @click="removeDateFromList(currentIndex)">
                                <i class="material-icon mdi mdi-close" />
                            </button>
                        </div>
                    </div>
                </div>

                <div class="date-add-button">
                    <button class="ghost-primary" @click="addDateField()">Weiteren Wunschzeitraum hinzufügen</button>
                </div>
            </div>

        </div>

        <div class="buttons-info">
            <button class="button-info" @click="openDateModal">Weitere Infos</button>
        </div>

        <modal class="info-modal" v-model="dateModal">
            <img slot="title" src="../../../../public/icon-info.svg" alt="icon info">
            <h2 slot="title">Wunschzeitraum</h2>

            <p>
                Pentests der usd erfolgen in folgenden Phasen:
            </p>

            <ul>
                <li>Kick-off und Vorbereitung</li>
                <li>Durchführung der technischen Analyse</li>
                <li>Berichterstellung und Abschlussmeeting</li>
            </ul>

            <p>
                Zwischen dem Start der Vorbereitung und dem Beginn der technischen Analyse sollten mindestens zwei Wochen liegen, um
                alle technischen und organisatorischen Vorbereitungen umsetzen zu können. Die Berichterstellung und das Abschlussmeeting
                erfolgen innerhalb von zwei Wochen nach dem Ende der technischen Analyse. Daraus ergeben sich folgende Rahmenbedingungen für den
                Wunschzeitraum:
            </p>

            <ul>
                <li>Der Startzeitpunkt sollte zwei Wochen in der Zukunft liegen.</li>
                <li>Der Wunschzeitraum sollte vier Wochen umfassen.</li>
            </ul>

            <p>
                Wir bemühen uns davon abweichende Wunschtermine trotzdem zu ermöglichen und damit bestmöglich auf Ihre individuellen Bedürfnisse einzugehen.
            </p>

            <form-actions slot="footer">
                <button @click="closeDateModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

    </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import { useModal } from '@/composables/use-modal'
import { useScopeStore } from '@/store/scopeStore'

export default defineComponent({
    name: 'ScopeDate',

    setup() {
        const { modal: dateModal, openModal: openDateModal, closeModal: closeDateModal } = useModal()

        const scopeStore = useScopeStore()
        const currentDate = new Date()
        const dateList = ref<Array<any>>([])

        onMounted(() => {
            addDateField()
        })

        const updateDateList = async(value: any, index: number) => {
            dateList.value[index] = value
            dateList.value = dateList.value.slice(0)
        }

        const removeDateFromList = (index: number) => {
            // scopeStore.removeDate(index)
            dateList.value.splice(index, 1)
        }

        const addDateField = () => {
            dateList.value.push({ start: null, end: null })
        }

        return {
            updateDateList,
            currentDate,
            dateList,
            removeDateFromList,
            addDateField,
            dateModal,
            openDateModal,
            closeDateModal
        }
    }
})
</script>

<style lang="scss" scoped>
@import "../../../styles/breakpoints";

.scope-date {
    @include default-padding();
}

.scope-header {
    padding-bottom: 1.5%;
    text-align: left;
    h2 {
        color: var(--color-brand-primary);
    }
}

.date-picker {
    display: flex;
}

.date-add-button {
    margin: 2em 0 0 -0.5em;
}
</style>
