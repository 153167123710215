import { computed, getCurrentInstance } from 'vue'
import { Route } from 'vue-router'
import { VueRouter } from 'vue-router/types/router'

export const useRouter = () => {
    const vm = getCurrentInstance()
    if (!vm) {
        throw new ReferenceError('Not found vue instance.')
    }
    const route = computed(() => vm.proxy.$route as Route)
    return { route, router: vm.proxy.$router as VueRouter } as const
}
