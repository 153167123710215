<template>
    <div class="how-we-protect-you">
        <div class="mission-container">
            <div class="mission-image">
                <img src="../../../public/usd-herolab-logo-untereinander.svg">
            </div>

            <div class="mission-text">
                <h1>
                    So schützen wir Sie
                </h1>

                <p>
                    Ob Sie Anwendungen und Systeme sicher einsetzen oder als Produkthersteller nachweislich mehr Sicherheit
                    für Ihre Kunden schaffen möchten: Mit dem usd HeroLab haben Sie einen der führenden Pentest-Anbieter in
                    Europa an Ihrer Seite.
                </p>

                <p>
                    Das Ziel unserer Sicherheitsanalysen ist es, Schwachstellen zu identifizieren, daraus resultierende Risiken
                    zu benennen und Wege aufzuzeigen, Ihre Sicherheit zu verbessern. Gemeinsam mit Ihnen finden wir die für Sie
                    passende Lösung und sprechen über Ziele, potentielle Risiken und Verbesserungsmöglichkeiten. Unser
                    Leistungsversprechen ist mehr Sicherheit!
                </p>

                <form-actions>
                    <button class="primary" @click="openUrl('https://www.usd.de/security-analysis-pentests/')">Mehr erfahren</button>
                </form-actions>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'HowWeProtectYou',

    setup() {
        const openUrl = (url: string) => {
            // open given URL in a new tab with a null-opener to make it more secure
            window.open(url, '_blank', 'noopener')
        }

        return {
            openUrl
        }
    }
})
</script>

<style lang="scss">
@import "../../styles/breakpoints";

.how-we-protect-you {
    padding: 2.5% 10%;
    background: url('../../../public/background-herolab.jpg') no-repeat;
    background-size: cover;

    .mission-container {
        display: flex;
        justify-content: flex-start;

        .mission-image {
            align-self: center;

            img {
                width: 300px;
            }
        }

        .mission-text {
            padding-left: 100px;
            color: var(--color-white);
            max-width: 900px;

            h1 {
                font-size: 50px;
                color: var(--color-white);
                margin-bottom: 20px;
            }
        }

        .form-actions {
            justify-content: flex-start;
        }
    }
}

@media screen and (max-width: $bp-medium) {
    .how-we-protect-you {
        padding: 2em 1em;

        .mission-container {
            flex-direction: column;
        }

        .mission-container .mission-image {
            margin-bottom: 2em;
            display: flex;
            align-items: center;
            max-width: 80%;
        }

        .mission-container .mission-text {
            padding-left: 0;
        }

        .mission-container .mission-text h1 {
            font-size: 30px;
            text-align: center;
        }

        .form-actions {
            justify-content: center;
        }
    }
}
</style>
