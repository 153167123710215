<template>
    <div class='mobile-asset'>
        <div v-if="currentStep === 0">
            <div class="asset-question">
                <h2>
                    Assetname *
                </h2>
                <p>
                    Bitte geben Sie einen Namen für das Asset ein.
                </p>

                <text-field id="asset-name" label="" v-model="currentAsset.name" :error="nameError"></text-field>

                <div class="buttons-info">
                    <button class="button-info" @click="openAssetNameModal">Weitere Infos</button>
                </div>
            </div>

            <div class="asset-question asset-question-white">
                <h2>
                    Kurzbeschreibung *
                </h2>
                <p>
                    Bitte beschreiben Sie in wenigen Sätzen, wofür die von Ihnen angelegte mobile App eingesetzt wird.
                </p>

                <text-area id="mobile-description" label="" v-model="currentAsset.description" :error="descriptionError"/>

                <div class="buttons-info">
                    <button class="button-info" @click="openDescriptionModal">Weitere Infos</button>
                </div>
            </div>
        </div>

        <div v-if="currentStep === 1">
            <div class="asset-question">
                <h2>
                    App-Typ *
                </h2>
                <p>
                    Bitte stufen Sie Ihre mobile App in einen der zwei folgenden Typen ein.
                </p>

                <BoxSelectRadio id="mobile-type" v-model="mobile.type" :options="typeOptions" @input="checkClearValuesType" :error="typeError"/>

                <div class="buttons-info">
                    <button class="button-info" @click="openTypeModal">Weitere Infos</button>
                </div>
            </div>
        </div>

        <div v-if="currentStep === 2 && mobile.type === 'native'">
            <div class="asset-question">
                <h2>
                    Unterstütze Betriebssysteme *
                </h2>
                <p>
                    Für welches Betriebssystem wird die mobile App angeboten?
                </p>

                <BoxSelectCheckbox id="mobile-native-operating-system" v-model="mobile.nativeOperatingSystem" :options="nativeOperatingSystemOptions" @input="checkClearValueNativeMultiOperatingSystemTest" :error="nativeOperatingSystemError"/>

                <div v-if="mobile.nativeOperatingSystem.length > 1">
                    <hr>

                    <h2>
                        Testabdeckung *
                    </h2>
                    <p>
                        Soll die mobile App auf beiden Betriebssystemen (iOS und Android) überprüft werden?
                    </p>

                    <tristate-switch-field id="mobile-native-multi-operating-system-test" v-model="mobile.nativeMultiOperatingSystemTest" @input="checkClearValueNativeCrossPlattformNativeOperatingSystemTest" :error="nativeMultiOperatingSystemTestError" label="" label-pre="Ja" label-post="Nein"/>

                    <div class="buttons-info">
                        <button class="button-info" @click="openNativeMultiOperatingSystemTestModal">Weitere Infos</button>
                    </div>

                    <div v-if="mobile.nativeMultiOperatingSystemTest">
                        <hr>

                        <h2>
                            Cross-Plattform-Lösung *
                        </h2>
                        <p>
                            Wurde die mobile App als Cross-Plattform-Lösung entwickelt?
                        </p>

                        <tristate-switch-field id="mobile-native-cross-platform" v-model="mobile.nativeCrossPlatform" :error="nativeCrossPlatformError" label="" label-pre="Ja" label-post="Nein"/>

                        <div class="buttons-info">
                            <button class="button-info" @click="openNativeCrossPlatformModal">Weitere Infos</button>
                        </div>
                    </div>

                    <div v-else-if="!mobile.nativeMultiOperatingSystemTest">
                        <hr>

                        <h2>
                            Auswahl des zu prüfenden Betriebssystems *
                        </h2>
                        <p>
                            Welche Version der mobile App soll geprüft werden?
                        </p>

                        <BoxSelectRadio id="mobile-native-operating-system-test" v-model="mobile.nativeOperatingSystemTest" :options="nativeOperatingSystemOptions" :error="nativeOperatingSystemTestError"/>
                    </div>
                </div>
            </div>

            <div class="asset-question asset-question-white">
                <h2>
                    App-Architektur *
                </h2>
                <p>
                    Bitte beschreiben Sie die Architektur der mobile App.
                </p>

                <BoxSelectRadio id="mobile-native-architecture" v-model="mobile.nativeArchitecture" :options="nativeArchitectureOptions" :error="nativeArchitectureError"/>

                <div class="buttons-info">
                    <button class="button-info" @click="openNativeArchitectureModal">Weitere Infos</button>
                </div>
            </div>
        </div>

        <div v-else-if="currentStep === 2 && mobile.type === 'hybrid'">
            <div class="asset-question">
                <h2>
                    Funktionsumfang <sup>(optional)</sup>
                </h2>
                <p>
                    Wie groß ist die (progressive/hybride) Web App, gemessen an dem bereitgestellten Funktionsumfang?
                </p>

                <radio-field id="mobile-hybrid-size" label="" v-model="mobile.hybridSize" :options="hybridSizeOptions" continuous/>

                <div class="buttons-info">
                    <button class="button-info" @click="openHybridSizeModal">Weitere Infos</button>
                </div>
            </div>

            <div class="asset-question asset-question-white">
                <h2>
                    Geschützter Benutzerbereich *
                </h2>
                <p>
                    Verfügt die hybride Web App über einen geschützten Benutzerbereich, der ausschließlich authentisiert,
                    also mit einem gültigen Benutzer, nach erfolgreicher Anmeldung (z.B. mittels gültigem Passwort,
                    Fingerabdruck oder Gesichtsüberprüfung), zugänglich ist und über den sensible/vertrauliche Daten und
                    diese verarbeitende Funktionen bereitgestellt werden?
                </p>

                <tristate-switch-field id="mobile-hybrid-authentication" v-model="mobile.hybridAuthentication" :error="hybridAuthenticationError" label="" label-pre="Ja" label-post="Nein"/>

                <div class="buttons-info">
                    <button class="button-info" @click="openHybridAuthenticationModal">Weitere Infos</button>
                </div>
            </div>

            <div class="asset-question">
                <h2>
                    Interaktion mit mobilem Endgerät <sup>(optional)</sup>
                </h2>
                <p>
                    Auf welche Funktionen bzw. Daten des mobilen Endgeräts greift die (progressive/hybride) Web App zu?
                </p>

                <BoxSelectCheckbox id="mobile-hybrid-permissions" v-model="mobile.hybridPermissions" :options="permissionsOptions" />

                <div class="buttons-info">
                    <button class="button-info" @click="openPermissionsModal">Weitere Infos</button>
                </div>
            </div>
        </div>

        <div v-if="currentStep === 3 && mobile.type === 'native'">
            <div class="asset-question">
                <h2>
                    Geschützter Benutzerbereich *
                </h2>
                <p>
                    Verfügt die mobile App über einen geschützten Benutzerbereich, der ausschließlich authentisiert, also
                    mit einem gültigen Benutzer, nach erfolgreicher Anmeldung (z.B. mittels gültigem Passwort, Fingerabdruck
                    oder Gesichtsüberprüfung), zugänglich ist und über den sensible bzw. vertrauliche Daten und diese
                    verarbeitende Funktionen bereitgestellt werden?
                </p>

                <tristate-switch-field id="mobile-native-authentication" v-model="mobile.nativeAuthentication" :error="nativeAuthenticationError" label="" label-pre="Ja" label-post="Nein"/>

                <div class="buttons-info">
                    <button class="button-info" @click="openNativeAuthenticationModal">Weitere Infos</button>
                </div>
            </div>

            <div class="asset-question asset-question-white">
                <h2>
                    Interaktion mit mobilem Endgerät <sup>(optional)</sup>
                </h2>
                <p>
                    Auf welche Funktionen bzw. Daten des mobilen Endgeräts greift die mobile App zu?
                </p>

                <BoxSelectCheckbox id="mobile-native-permissions" v-model="mobile.nativePermissions" :options="permissionsOptions" />

                <div class="buttons-info">
                    <button class="button-info" @click="openPermissionsModal">Weitere Infos</button>
                </div>
            </div>

            <div class="asset-question">
                <h2>
                    Eingesetzte Schutzmechanismen <sup>(optional)</sup>
                </h2>
                <p>
                    Welche Schutzmechanismen werden von der mobile App genutzt?
                </p>

                <BoxSelectCheckbox id="mobile-native-security-mechanisms" v-model="mobile.nativeSecurityMechanisms" :options="nativeSecurityMechanismsOptions" />

                <div class="buttons-info">
                    <button class="button-info" @click="openNativeSecurityMechanismsModal">Weitere Infos</button>
                </div>
            </div>

            <div class="asset-question asset-question-white">
                <h2>
                    Bereitstellung der mobile App *
                </h2>
                <p>
                    Wie stellen Sie uns die mobile App im Rahmen des Pentests zur Verfügung?
                </p>

                <BoxSelectRadio id="mobile-native-availability" v-model="mobile.nativeAvailability" :options="nativeAvailabilityOptions" :error="nativeAvailabilityError"/>

                <div class="buttons-info">
                    <button class="button-info" @click="openNativeAvailabilityModal">Weitere Infos</button>
                </div>
            </div>
        </div>

        <div v-if="currentStep === 3 && mobile.type === 'hybrid'">
            <div class="asset-question">
                <h2>
                    Programmiersprache <sup>(optional)</sup>
                </h2>
                <p>
                    Welche Programmiersprache kommt bei der Entwicklung zum Einsatz?
                </p>

                <BoxSelectCheckbox id="mobile-hybrid-programming-language" label="" v-model="mobile.hybridProgrammingLanguageList" :options="hybridProgrammingLanguageOptions" @input="checkClearValueHybridProgrammingLanguageIndividual" />

                <div class="buttons-info">
                    <button class="button-info" @click="openHybridProgrammingLanguageModal">Weitere Infos</button>
                </div>

                <div v-if="mobile.hybridProgrammingLanguageList.includes('individual')">
                    <hr>

                    <h2>
                        Sonstige Programmiersprachen <sup>(optional)</sup>
                    </h2>
                    <p>
                        Bitte geben sie die verwendeten Programmiersprachen an.
                    </p>

                    <text-field id="mobile-hybrid-programming-language-individual" label="" v-model="mobile.hybridProgrammingLanguageIndividualList[0]" />

                    <div v-if="numberAdditionalProgrammingLanguages === 0" class="add-button">
                        <button class="ghost-primary" @click="addProgrammingLanguage()">Weitere Programmiersprache hinzufügen</button>
                    </div>

                    <div v-for="currentIndex in numberAdditionalProgrammingLanguages" :key="currentIndex">
                        <div class="additional-field">
                            <text-field :id="'mobile-hybrid-programming-language-individual' + currentIndex" label="" v-model="mobile.hybridProgrammingLanguageIndividualList[currentIndex]" />
                            <div class="icon-remove">
                                <button class="icon-button" @click="removeProgrammingLanguageFromList(currentIndex)">
                                    <i class="material-icon mdi mdi-close" />
                                </button>
                            </div>
                        </div>

                        <div v-if="numberAdditionalProgrammingLanguages === currentIndex" class="add-button">
                            <button class="ghost-primary" @click="addProgrammingLanguage()">Weitere Programmiersprache hinzufügen</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="asset-question asset-question-white">
                <h2>
                    Web-Frameworks <sup>(optional)</sup>
                </h2>
                <p>
                    Wird in der Entwicklung eines oder mehrere der folgenden Web-Frameworks eingesetzt?
                </p>

                <BoxSelectCheckbox id="mobile-hybrid-framework" label="" v-model="mobile.hybridFrameworkList" :options="hybridFrameworkOptions" @input="checkClearValueHybridFrameworkIndividual" />

                <div class="buttons-info">
                    <button class="button-info" @click="openHybridFrameworkModal">Weitere Infos</button>
                </div>

                <div v-if="mobile.hybridFrameworkList.includes('individual')">
                    <hr>

                    <h2>
                        Sonstige Web-Frameworks <sup>(optional)</sup>
                    </h2>
                    <p>
                        Bitte geben sie die verwendeten Web-Frameworks an.
                    </p>

                    <text-field id="mobile-hybrid-framework-individual" label="" v-model="mobile.hybridFrameworkIndividualList[0]" />

                    <div v-if="numberAdditionalFrameworks === 0" class="add-button">
                        <button class="ghost-primary" @click="addFramework()">Weiteres Framework hinzufügen</button>
                    </div>

                    <div v-for="currentIndex in numberAdditionalFrameworks" :key="currentIndex">
                        <div class="additional-field">
                            <text-field :id="'mobile-hybrid-framework-individual' + currentIndex" label="" v-model="mobile.hybridFrameworkIndividualList[currentIndex]" />
                            <div class="icon-remove">
                                <button class="icon-button" @click="removeFrameworkFromList(currentIndex)">
                                    <i class="material-icon mdi mdi-close" />
                                </button>
                            </div>
                        </div>

                        <div v-if="numberAdditionalFrameworks === currentIndex" class="add-button">
                            <button class="ghost-primary" @click="addFramework()">Weiteres Framework hinzufügen</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="asset-question">
                <h2>
                    Plugins/Bibliotheken <sup>(optional)</sup>
                </h2>
                <p>
                    Werden kostenfreie oder kommerzielle Programmerweiterungen (z.B. Plugins, Bibliotheken) im Rahmen der
                    Softwareentwicklung eingesetzt? Wenn ja, nennen Sie diese bitte im Folgenden.
                </p>

                <text-field id="mobile-hybrid-extensions" label="" v-model="mobile.hybridExtensionList[0]" />

                <div v-if="numberAdditionalExtensions === 0" class="add-button">
                    <button class="ghost-primary" @click="addExtension()">Weitere Erweiterung hinzufügen</button>
                </div>

                <div v-for="currentIndex in numberAdditionalExtensions" :key="currentIndex">
                    <div class="additional-field">
                        <text-field :id="'mobile-hybrid-extension' + currentIndex" label="" v-model="mobile.hybridExtensionList[currentIndex]" />
                        <div class="icon-remove">
                            <button class="icon-button" @click="removeExtensionFromList(currentIndex)">
                                <i class="material-icon mdi mdi-close" />
                            </button>
                        </div>
                    </div>

                    <div v-if="numberAdditionalExtensions === currentIndex" class="add-button">
                        <button class="ghost-primary" @click="addExtension()">Weitere Erweiterung hinzufügen</button>
                    </div>
                </div>

                <div class="buttons-info">
                    <button class="button-info" @click="openHybridExtensionsModal">Weitere Infos</button>
                </div>
            </div>
        </div>

        <div v-if="currentStep === 4">
            <div class="asset-question">
                <h2>
                    Schutzbedarf <sup>(optional)</sup>
                </h2>
                <p>
                    Haben Sie den Schutzbedarf der mobile App erhoben?
                </p>

                <tristate-switch-field id="mobile-security-assessment" v-model="mobile.securityAssessment" @input="checkClearValueSecurityAssessmentLevel" label="" label-pre="Ja" label-post="Nein"/>

                <div class="buttons-info">
                    <button class="button-info" @click="openSecurityAssessmentModal">Weitere Infos</button>
                </div>

                <div v-if="mobile.securityAssessment">
                    <hr>

                    <h2>
                        Höhe des Schutzbedarfs <sup>(optional)</sup>
                    </h2>
                    <p>
                        Wie hoch ist der Schutzbedarf?
                    </p>

                    <radio-field label="" id="mobile-security-assessment-level" v-model="mobile.securityAssessmentLevel" :options="securityAssessmentLevelOptions" continuous/>

                    <div class="buttons-info">
                        <button class="button-info" @click="openSecurityAssessmentLevelModal">Weitere Infos</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="buttons">
            <div class="buttons-left">
                <button class="ghost-primary space-right" @click="back">Zurück</button>
            </div>
            <div class="buttons-center">
                <button v-if="route.path.includes('/edit')" class="ghost configurator-button" @click="openConfirmationEditModal">Asset-Bearbeitung abbrechen</button>
                <button v-else class="ghost configurator-button" @click="openConfirmationCreateModal">Asset-Erstellung abbrechen</button>
            </div>
            <div class="buttons-right">
                <button v-if="currentStep === 4 && !route.path.includes('/edit')" class="primary" @click="completeAsset">Asset-Konfiguration abschließen</button>
                <button v-else-if="currentStep === 4 && route.path.includes('/edit')" class="primary" @click="completeAsset">Asset-Bearbeitung abschließen</button>
                <button v-else class="primary" @click="advance">Weiter</button>
            </div>
        </div>

        <modal class="info-modal" v-model="confirmationCreateModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Möchten Sie die Asset-Erstellung wirklich abbrechen?</h2>

            <p>
                Dabei geht Ihre bisherige Konfiguration verloren.
            </p>

            <div class="buttons-modal">
                <div class="buttons-left">
                    <button class="ghost-primary button-modal-back-dialog" @click="gotoAssetList()">Ja, zurück zur Assetübersicht.</button>
                </div>
                <div class="buttons-right">
                    <button class="primary button-modal-back-dialog" @click="closeConfirmationCreateModal()">Nein, Asset-Konfiguration behalten.</button>
                </div>
            </div>
        </modal>

        <modal class="info-modal" v-model="confirmationEditModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Möchten Sie die Asset-Bearbeitung wirklich abbrechen?</h2>

            <p>
                Dabei gehen Ihre bisherigen Änderungen verloren.
            </p>

            <div class="buttons-modal">
                <div class="buttons-left">
                    <button class="ghost-primary button-modal-back-dialog" @click="gotoAssetList()">Ja, zurück zur Assetübersicht.</button>
                </div>
                <div class="buttons-right">
                    <button class="primary button-modal-back-dialog" @click="closeConfirmationEditModal()">Nein, Asset-Konfiguration behalten.</button>
                </div>
            </div>
        </modal>

        <modal class="info-modal" v-model="assetNameModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Assetname</h2>

            <p>
                Ihre Antwort hilft uns, die Anfrage intern besser zu verarbeiten. Bitte geben Sie einen für Sie eindeutigen
                Asset-Namen ein.
            </p>

            <form-actions slot="footer">
                <button @click="closeAssetNameModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="assetNameExistsModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Asset mit identischem Namen</h2>

            <p>
                Es existiert bereits ein Asset mit dem gleichen Namen. Möchten Sie den Namen des Assets ändern?
            </p>

            <div class="buttons-modal">
                <div class="buttons-left">
                    <button class="ghost-primary button-modal-back-dialog" @click="closeAssetNameExistsModal()">Ja, Namen ändern.</button>
                </div>
                <div class="buttons-right">
                    <button class="primary button-modal-back-dialog" @click="acceptAssetNameExists()">Nein, so fortfahren.</button>
                </div>
            </div>
        </modal>

        <modal class="info-modal" v-model="descriptionModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Kurzbeschreibung</h2>

            <p>
                Bitte beschreiben Sie in wenigen Sätzen, wofür die von Ihnen angelegte mobile App eingesetzt wird.
                Zentrale Fragen sind:
            </p>

            <ul>
                <li>
                    Von wem und wofür wird die mobile App genutzt?
                </li>
                <li>
                    Welche Daten werden von der mobile App verarbeitet und/oder gespeichert?
                </li>
            </ul>

            <p>
                Ihre Beschreibung hilft uns, Ihren Prüfumfang besser zu verstehen. Je detaillierter Sie auf das Asset
                eingehen, umso besser können wir Ihren Prüfumfang im Vorfeld analysieren und kritisch hinterfragen.
            </p>

            <form-actions slot="footer">
                <button @click="closeDescriptionModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="typeModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Mobile App Typen</h2>

            <p>
                Als Native Mobile App werden Anwendungen auf mobilen Endgeräten (Smartphones oder Tablets) bezeichnet,
                die speziell für das Betriebssystem (iOS oder Android) des mobilen Endgerätes konzipiert und entwickelt
                werden. Native Mobile Apps werden häufig über App Stores, die an das jeweilige Betriebssystem gekoppelt
                sind, als kostenfreie oder kostenpflichtige Anwendungen den Nutzern bereitgestellt.
            </p>
            <p>
                Eine (progressive/hybride) Web App ist eine Webseite, die neben einer App-ähnlichen Oberfläche zahlreiche
                weitere Merkmale besitzt, die lange nativen mobile Apps  vorbehalten waren. (Progressive/hybride) Web Apps
                sind z.B. auf dem mobilen Endgerät installierbar, sie sind Netzwerk-unabhängig und sie können ebenfalls
                über offizielle App Stores den Nutzern angeboten werden. (Progressive/hybride) Web Apps werden wie eine
                Webseite z.B. mit HTML5, CSS3 und JavaScript erstellt. Sie sind Betriebssystem-unabhängig und laufen
                damit sowohl unter iOS als auch Android.
            </p>

            <form-actions slot="footer">
                <button @click="closeTypeModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="nativeMultiOperatingSystemTestModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Testabdeckung</h2>

            <p>
                Häufig sind Schwachstellen vom eingesetzten Betriebssystem (iOS, Android) abhängig. Um sicherzugehen,
                dass die mobile App sicher ist und keine Schwachstellen aufweist, sollten die Versionen für alle angebotenen
                Betriebssysteme überprüft werden.
            </p>

            <form-actions slot="footer">
                <button @click="closeNativeMultiOperatingSystemTestModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="nativeCrossPlatformModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Cross-Plattform-Lösung</h2>

            <p>
                Eine Cross-Plattform App wird in einer "zwischengeschalteten" Programmiersprache entwickelt, die nicht
                zum Betriebssystem des mobilen Endgeräts gehört.
            </p>
            <p>
                In einem nachgelagerten Schritt wird die Cross-Plattform App anhand eines Plattform-übergreifenden
                Anwendungsframeworks wie z.B. React Native, Xamarin oder Native Script für das jeweilige Betriebssystem
                (iOS oder Android) des mobilen Endgeräts kompiliert.
            </p>
            <p>
                Hierbei kann entweder ein Teil oder der gesamte Quellcode auf den Zielplattformen gemeinsam genutzt werden.
            </p>

            <form-actions slot="footer">
                <button @click="closeNativeCrossPlatformModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="nativeArchitectureModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">App-Architektur</h2>

            <p>
                Nach unserer Erfahrung basieren die meisten mobile Apps heutzutage auf einer Server-Client Architektur.
            </p>
            <p>
                Informationen zur Architektur können beim Entwickler bzw. Hersteller der mobile App erfragt werden.
            </p>
            <p>
                Übrigens: Handelt es sich um eine Server-Client App, die bspw. mit einem Webserver/Webservice kommuniziert,
                so ist die Prüfung dieser Komponenten in unserem Angebot enthalten. Dabei beschränken wir die Prüfung auf
                diejenigen Funktionen, die von der mobile App genutzt werden.
            </p>

            <form-actions slot="footer">
                <button @click="closeNativeArchitectureModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="hybridSizeModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Funktionsumfang</h2>

            <p>
                Im Rahmen des Pentests werden die von der (progressiven/hybriden) Web App bereitgestellte Funktionen auf
                Schwachstellen überprüft. Je mehr Funktionen bereitgestellt werden, umso aufwändiger wird die
                Sicherheitsanalyse.
            </p>
            <p>
                Damit wir einen Eindruck von der Größe Ihrer zu testenden progressiven/hybriden Web App gewinnen, bitten
                wir um eine grobe Abschätzung der Anzahl bereitgestellter Funktionen. Es ist nicht notwendig eine exakte
                Aussage zu treffen.
            </p>
            <p>
                Nachfolgend haben wir für Sie einige exemplarische Funktionen aus verschiedenen Einsatzbereichen gesammelt.
                Die Aufzählung ist selbstverständlich nicht vollständig und dient ausschließlich der Veranschaulichung
                unseres Verständnisses für Funktionen einer (progressiven/hybriden) Web App.
            </p>
            <p>
                Bitte berücksichtigen Sie, dass wir den Pentest bei sehr großen (progressiven/hybriden) Web Apps auf Basis
                eines risikobasierten Ansatzes durchführen. Dabei werden nicht alle Funktionen im Detail geprüft, sondern
                eine umfangreiche Auswahl der wichtigsten bzw. kritischsten Funktionen untersucht. Gerne stimmen wir die
                Auswahl im Rahmen des Kick-offs mit Ihnen ab.
            </p>

            <h3>Users & Accounts</h3>
            <ul>
                <li>Authentisierung von Nutzern</li>
                <li>Registrierungsprozess für neue Nutzer</li>
                <li>Einladung neuer Nutzer</li>
                <li>Passwort wiederherstellen</li>
                <li>Zwei-Faktor-Authentifizierung</li>
                <li>Single Sign-on</li>
            </ul>

            <h3>Content</h3>
            <ul>
                <li>Static Content</li>
                <li>Einsatz eines Content Management Systems (CMS)</li>
                <li>Dashboard</li>
                <li>Nutzerprofile</li>
                <li>Einsatz eines Blogs</li>
                <li>Umfragen und Abstimmungen</li>
                <li>Quiz</li>
                <li>Bezahlter Content</li>
                <li>Knowledge Base</li>
                <li>Landing Page</li>
                <li>Kontaktformular</li>
                <li>Gewinnspiel</li>
                <li>Tags & Kategorien</li>
                <li>Nutzerbewertungen und Reviews</li>
                <li>Externer Dateiupload</li>
                <li>Such- und Filterfunktion</li>
                <li>E-Mail-Benachrichtigung</li>
                <li>Newsletter Abonnement</li>
                <li>Multilingual Support</li>
                <li>Workflows</li>
            </ul>

            <h3>Dates & Locations</h3>
            <ul>
                <li>Kalenderfunktion</li>
                <li>Termine buchen</li>
                <li>Reservierungen anfragen</li>
                <li>Streckenplanung</li>
                <li>Karten einsehen</li>
            </ul>

            <h3>Social</h3>
            <ul>
                <li>Nachrichten senden</li>
                <li>Live Chat</li>
                <li>Community Forum</li>
                <li>Nutzerkommentare</li>
                <li>Nutzer blockieren</li>
                <li>Nutzern folgen</li>
                <li>Freundeslisten</li>
                <li>Inhalte teilen</li>
                <li>Dateien senden / austauschen</li>
            </ul>

            <h3>E-Commerce</h3>
            <ul>
                <li>Produkte verwalten</li>
                <li>Digitale Produkte</li>
                <li>Abonnements</li>
                <li>Rabatte und Sonderangebote</li>
                <li>Produkte empfehlen</li>
                <li>Produkte vergleichen</li>
                <li>Wunschlisten</li>
                <li>Warenkorb</li>
                <li>Auktionen</li>
                <li>Gutscheine</li>
                <li>Bestellverwaltung</li>
            </ul>

            <h3>Finance & Payments</h3>
            <ul>
                <li>Angebotserstellung</li>
                <li>Rechnungserstellung</li>
                <li>Zahlungsabwicklung</li>
            </ul>

            <h3>Productivity & Task management</h3>
            <ul>
                <li>Projekt Management</li>
                <li>Aufgaben Management</li>
                <li>Checklisten</li>
                <li>Ticketsystem</li>
            </ul>

            <h3>Gamification</h3>
            <ul>
                <li>Ranglisten</li>
                <li>User Credits</li>
            </ul>

            <h3>Media</h3>
            <ul>
                <li>Fotobibliothek</li>
                <li>Videobibliothek</li>
                <li>Audiobibliothek</li>
                <li>Bildbearbeitung</li>
                <li>Videobearbeitung</li>
            </ul>

            <h3>Analytics & Reporting</h3>
            <ul>
                <li>Daten filtern und analysieren</li>
                <li>Berichte erstellen</li>
                <li>Daten exportieren</li>
            </ul>

            <form-actions slot="footer">
                <button @click="closeHybridSizeModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="hybridAuthenticationModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Geschützter Benutzerbereich</h2>

            <p>
                Sensible, vertrauliche Personen- oder Unternehmensdaten sowie Funktionen, die diese Daten verarbeiten,
                werden üblicherweise in einem geschützten Benutzerbereich, also nicht öffentlich, den Nutzern zur Verfügung
                gestellt. Der Benutzerbereich ist dabei nur mit einem gültigen Benutzernamen sowie einer Form der
                Authentisierung (z.B. einem Passwort) zugänglich.
            </p>
            <p>
                (Progressive/hybride) Web Apps, die über einen geschützten Benutzerbereich verfügen, werden von uns immer
                authentisiert überprüft. Hierdurch steigt der Prüfaufwand und damit die Kosten, dafür erhalten Sie jedoch
                ein wesentlich besseres Lagebild von der Sicherheit Ihrer (progressiven/hybriden) Web App und ggf.
                vorhandenen Risiken.
            </p>

            <form-actions slot="footer">
                <button @click="closeHybridAuthenticationModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="permissionsModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Interaktion mit mobilem Endgerät</h2>

            <p>
                Informationen zu eingesetzten Funktionen des Betriebssystems (Android, iOS) können beim Entwickler der
                (progressiven/hybriden) Web App erfragt werden.
            </p>
            <p>
                Liegen Ihnen keine Informationen vor oder sind diese nicht in einer angemessenen Zeit beschaffbar,
                lassen Sie die Frage gerne offen.
            </p>

            <form-actions slot="footer">
                <button @click="closePermissionsModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="nativeAuthenticationModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Geschützter Benutzerbereich</h2>

            <p>
                Sensible, vertrauliche Personen- und/oder Unternehmensdaten sowie Funktionen, die diese Daten verarbeiten,
                werden üblicherweise in einem geschützten Benutzerbereich, also nicht öffentlich, den Nutzern zur Verfügung
                gestellt. Der Benutzerbereich ist dabei nur mit einem gültigen Benutzernamen sowie nach erfolgreicher
                Authentisierung (z.B. mit einem Passwort, Fingerabdruck oder mittels Gesichtsüberprüfung) zugänglich.
            </p>
            <p>
                Mobile Apps, die über einen geschützten Benutzerbereich verfügen, werden von uns immer authentisiert überprüft.
                Hierdurch steigt der Prüfaufwand und damit die Kosten, dafür erhalten Sie jedoch ein wesentlich besseres
                Lagebild von der Sicherheit der mobile App und ggf. vorhandener Risiken.
            </p>

            <form-actions slot="footer">
                <button @click="closeNativeAuthenticationModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="nativeSecurityMechanismsModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Eingesetzte Schutzmechanismen</h2>

            <p>
                Informationen zu eingesetzten Schutzmechanismen können beim Entwickler bzw. Hersteller der mobile App
                erfragt werden.
            </p>
            <p>
                Liegen Ihnen keine Informationen vor oder sind diese nicht in einer angemessenen Zeit beschaffbar, lassen
                Sie die Frage gerne offen.
            </p>

            <form-actions slot="footer">
                <button @click="closeNativeSecurityMechanismsModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="nativeAvailabilityModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Bereitstellung der mobile App</h2>

            <p>
                Mobile Apps, die bereits produktiv von Kunden eingesetzt werden, sind in den allermeisten Fällen über die
                offiziellen App Stores von Apple und Google verfügbar.
            </p>
            <p>
                Befindet sich die mobile App noch in Entwicklung oder wird an einer neuen Version gearbeitet, die noch
                nicht veröffentlich wurde, empfiehlt sich die mobile App entweder über Apple Testflight, Microsoft App
                Center oder eine alternative Lösung oder als Datei bereitzustellen.
            </p>
            <p>
                In sehr seltenen Fällen werden mobile Apps über Testgeräte bereitgestellt. Dies ist meistens der Fall,
                wenn es sich um Anwendungen handelt, die ausschließlich Unternehmens-intern eingesetzt werden.
            </p>

            <form-actions slot="footer">
                <button @click="closeNativeAvailabilityModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="hybridProgrammingLanguageModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Programmiersprache</h2>

            <p>
                Informationen zur eingesetzten Programmiersprache müssen beim Entwickler erfragt werden.
            </p>
            <p>
                In seltenen Fällen kommt es vor, dass mehrere Programmiersprachen eingesetzt werden. Gerne dürfen Sie in
                diesem Fall bei Ihrer Antwort alle eingesetzten Programmiersprachen angeben.
            </p>
            <p>
                Liegen Ihnen keine Informationen vor oder sind nicht in einer angemessenen Zeit beschaffbar,
                lassen Sie die Frage gerne offen.
            </p>

            <form-actions slot="footer">
                <button @click="closeHybridProgrammingLanguageModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="hybridFrameworkModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Web-Frameworks</h2>

            <p>
                Informationen zu eingesetzten Web-Frameworks müssen beim Entwickler erfragt werden.
            </p>
            <p>
                Liegen Ihnen keine Informationen vor oder sind diese nicht in einer angemessenen Zeit beschaffbar, lassen
                Sie die Frage gerne offen.
            </p>

            <form-actions slot="footer">
                <button @click="closeHybridFrameworkModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="hybridExtensionsModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Plugins/Bibliotheken</h2>

            <p>
                Ein Plugin bezeichnet ein optional verfügbares Softwaremodul, das die Anwendung des Hauptprogramms
                hinsichtlich verfügbarer Funktionen erweitert oder verändert. Das Hauptprogramm lädt das Plugin während
                des Programmstarts, um bei Bedarf darauf zugreifen zu können. Plugins sind ohne rahmengebende Hauptanwendung
                nicht ausführbar.
            </p>
            <p>
                Bibliotheken enthalten Unterprogramme, die mit Hilfsfunktionen die Programmierung erleichtern sollen.
                Im Unterschied zu einem Framework wird eine Bibliothek stärker auf eine bestimmte Verwendung hin
                entwickelt und besitzt hierfür aufeinander abgestimmte Funktionen. Bekannte Beispiele sind jQuery,
                React, Zepto und CreateJS.
            </p>
            <p>
                Liegen Ihnen keine Informationen vor oder sind diese nicht in einer angemessenen Zeit beschaffbar, lassen
                Sie die Frage gerne unbeantwortet.
            </p>

            <form-actions slot="footer">
                <button @click="closeHybridExtensionsModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="securityAssessmentModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Schutzbedarf</h2>

            <p>
                Der Schutzbedarf misst sich an den potenziellen Schäden, die mit der Beeinträchtigung der
                betroffenen mobile App einhergehen. 
                Dabei werden üblicherweise die drei Schutzziele Integrität, Vertraulichkeit und Verfügbarkeit betrachtet. 
                Bei der Bewertung der Schäden sollten neben finanziellen Aspekten weitere Schadensszenarien, wie zum Beispiel: 
            </p>
            <ul>
                <li>Verstöße gegen Gesetze, Vorschriften oder Verträge</li>
                <li>Beeinträchtigungen des informationellen Selbstbestimmungsrechts</li>
                <li>Beeinträchtigungen der persönlichen Unversehrtheit</li>
                <li>Beeinträchtigungen der Aufgabenerfüllung</li>
            </ul>
            <p>
                berücksichtigt werden.
            </p>

            <form-actions slot="footer">
                <button @click="closeSecurityAssessmentModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="securityAssessmentLevelModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Höhe des Schutzbedarfs</h2>

            <p>
                Die konkrete Ausprägung der vorgenannten Schutzbedarfskategorien wird von jedem Unternehmen individuell
                vorgenommen und kann sich unterscheiden. Das Bundesamt für Informationssicherheit (BSI) liefert im
                BSI-Standard 100-4 folgendes Beispiel:
            </p>
            <ul>
                <li>Niedrig: kaum spürbare Auswirkung</li>
                <li>Normal: spürbare Auswirkungen</li>
                <li>Hoch: erhebliche Auswirkungen</li>
                <li>Sehr hoch: existentielle Auswirkungen</li>
            </ul>

            <form-actions slot="footer">
                <button @click="closeSecurityAssessmentLevelModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, onMounted, computed, watch, nextTick } from 'vue'
import { useModal } from '@/composables/use-modal'
import { useRouter } from '@/composables/use-router'
import { useProjectStore } from '@/store/projectStore'
import { useAssetStore } from '@/store/assetStore'
import Slider from '@/components/Slider.vue'
import BoxSelectRadio from '@/components/BoxSelectRadio.vue'
import BoxSelectCheckbox from '@/components/BoxSelectCheckbox.vue'
import { RequiredValidator, RequiredValidatorArray, RequiredValidatorBoolean } from '@/validator/validator'

export default defineComponent({
    name: 'MobileAsset',

    props: {
        passedAsset: {
            type: Object,
            default: null
        }
    },

    components: {
        Slider,
        BoxSelectRadio,
        BoxSelectCheckbox
    },

    setup(props, { emit }) {
        const { router, route } = useRouter()

        const assetStore = useAssetStore()
        const currentAsset = computed(() => {
            if (route.value.path.includes('/edit')) {
                const assetList = assetStore.getAll.value
                return assetList.filter(asset => asset.id === route.value.params.assetId)[0]
            } else {
                return assetStore.getCurrentAsset.value
            }
        })

        const projectStore = useProjectStore()
        const projectId = projectStore.uuid

        const currentStep = ref<number>(0)

        const mobile = reactive<any>({
            type: typeof props.passedAsset.config.type === 'undefined' ? '' : props.passedAsset.config.type,
            nativeOperatingSystem: typeof props.passedAsset.config.nativeOperatingSystem === 'undefined' ? [] : props.passedAsset.config.nativeOperatingSystem,
            nativeMultiOperatingSystemTest: typeof props.passedAsset.config.nativeMultiOperatingSystemTest === 'undefined' ? null : props.passedAsset.config.nativeMultiOperatingSystemTest,
            nativeCrossPlatform: typeof props.passedAsset.config.nativeCrossPlatform === 'undefined' ? null : props.passedAsset.config.nativeCrossPlatform,
            nativeOperatingSystemTest: typeof props.passedAsset.config.nativeOperatingSystemTest === 'undefined' ? '' : props.passedAsset.config.nativeOperatingSystemTest,
            nativeArchitecture: typeof props.passedAsset.config.nativeArchitecture === 'undefined' ? '' : props.passedAsset.config.nativeArchitecture,
            nativeAuthentication: typeof props.passedAsset.config.nativeAuthentication === 'undefined' ? null : props.passedAsset.config.nativeAuthentication,
            nativePermissions: typeof props.passedAsset.config.nativePermissions === 'undefined' ? [] : props.passedAsset.config.nativePermissions,
            nativeSecurityMechanisms: typeof props.passedAsset.config.nativeSecurityMechanisms === 'undefined' ? [] : props.passedAsset.config.nativeSecurityMechanisms,
            nativeAvailability: typeof props.passedAsset.config.nativeAvailability === 'undefined' ? '' : props.passedAsset.config.nativeAvailability,
            hybridSize: typeof props.passedAsset.config.hybridSize === 'undefined' ? '' : props.passedAsset.config.hybridSize,
            hybridAuthentication: typeof props.passedAsset.config.hybridAuthentication === 'undefined' ? null : props.passedAsset.config.hybridAuthentication,
            hybridPermissions: typeof props.passedAsset.config.hybridPermissions === 'undefined' ? [] : props.passedAsset.config.hybridPermissions,
            hybridProgrammingLanguageList: typeof props.passedAsset.config.hybridProgrammingLanguageList === 'undefined' ? [] : props.passedAsset.config.hybridProgrammingLanguageList,
            hybridProgrammingLanguageIndividualList: typeof props.passedAsset.config.hybridProgrammingLanguageIndividualList === 'undefined' ? [] : props.passedAsset.config.hybridProgrammingLanguageIndividualList,
            hybridFrameworkList: typeof props.passedAsset.config.hybridFrameworkList === 'undefined' ? [] : props.passedAsset.config.hybridFrameworkList,
            hybridFrameworkIndividualList: typeof props.passedAsset.config.hybridFrameworkIndividualList === 'undefined' ? [] : props.passedAsset.config.hybridFrameworkIndividualList,
            hybridExtensionList: typeof props.passedAsset.config.hybridExtensionList === 'undefined' ? [] : props.passedAsset.config.hybridExtensionList,
            securityAssessment: typeof props.passedAsset.config.securityAssessment === 'undefined' ? null : props.passedAsset.config.securityAssessment,
            securityAssessmentLevel: typeof props.passedAsset.config.securityAssessmentLevel === 'undefined' ? '' : props.passedAsset.config.securityAssessmentLevel
        })

        const { modal: assetNameModal, openModal: openAssetNameModal, closeModal: closeAssetNameModal } = useModal()
        const { modal: assetNameExistsModal, openModal: openAssetNameExistsModal, closeModal: closeAssetNameExistsModal } = useModal()
        const { modal: descriptionModal, openModal: openDescriptionModal, closeModal: closeDescriptionModal } = useModal()
        const { modal: typeModal, openModal: openTypeModal, closeModal: closeTypeModal } = useModal()
        const { modal: nativeMultiOperatingSystemTestModal, openModal: openNativeMultiOperatingSystemTestModal, closeModal: closeNativeMultiOperatingSystemTestModal } = useModal()
        const { modal: nativeCrossPlatformModal, openModal: openNativeCrossPlatformModal, closeModal: closeNativeCrossPlatformModal } = useModal()
        const { modal: nativeArchitectureModal, openModal: openNativeArchitectureModal, closeModal: closeNativeArchitectureModal } = useModal()
        const { modal: hybridSizeModal, openModal: openHybridSizeModal, closeModal: closeHybridSizeModal } = useModal()
        const { modal: hybridAuthenticationModal, openModal: openHybridAuthenticationModal, closeModal: closeHybridAuthenticationModal } = useModal()
        const { modal: nativeAuthenticationModal, openModal: openNativeAuthenticationModal, closeModal: closeNativeAuthenticationModal } = useModal()
        const { modal: nativeSecurityMechanismsModal, openModal: openNativeSecurityMechanismsModal, closeModal: closeNativeSecurityMechanismsModal } = useModal()
        const { modal: nativeAvailabilityModal, openModal: openNativeAvailabilityModal, closeModal: closeNativeAvailabilityModal } = useModal()
        const { modal: hybridProgrammingLanguageModal, openModal: openHybridProgrammingLanguageModal, closeModal: closeHybridProgrammingLanguageModal } = useModal()
        const { modal: hybridFrameworkModal, openModal: openHybridFrameworkModal, closeModal: closeHybridFrameworkModal } = useModal()
        const { modal: hybridExtensionsModal, openModal: openHybridExtensionsModal, closeModal: closeHybridExtensionsModal } = useModal()
        const { modal: permissionsModal, openModal: openPermissionsModal, closeModal: closePermissionsModal } = useModal()
        const { modal: securityAssessmentModal, openModal: openSecurityAssessmentModal, closeModal: closeSecurityAssessmentModal } = useModal()
        const { modal: securityAssessmentLevelModal, openModal: openSecurityAssessmentLevelModal, closeModal: closeSecurityAssessmentLevelModal } = useModal()
        const { modal: confirmationCreateModal, openModal: openConfirmationCreateModal, closeModal: closeConfirmationCreateModal } = useModal()
        const { modal: confirmationEditModal, openModal: openConfirmationEditModal, closeModal: closeConfirmationEditModal } = useModal()

        const typeOptions = [
            {
                id: 'native',
                label: 'Native Mobile App'
            },
            {
                id: 'hybrid',
                label: '(Progressive/hybride) Web App'
            }
        ]

        const nativeOperatingSystemOptions = [
            {
                id: 'ios',
                label: 'iOS'
            },
            {
                id: 'android',
                label: 'Android'
            }
        ]

        const nativeArchitectureOptions = [
            {
                id: 'client',
                label: 'Lokale App ohne Datenübertragung'
            },
            {
                id: 'client-server',
                label: 'Client-Server App mit Datenübertragung'
            }
        ]

        const nativeSecurityMechanismsOptions = [
            {
                id: 'root recognition',
                label: 'Root-/Jailbreak Erkennung'
            },
            {
                id: 'certificate pinning',
                label: 'Certificate Pinning'
            },
            {
                id: 'code obfuscation',
                label: 'Code Obfuscation'
            },
            {
                id: 'emulated detection',
                label: 'Emulated Detection'
            },
            {
                id: 'device binding',
                label: 'Device Binding'
            }
        ]

        const nativeAvailabilityOptions = [
            {
                id: 'live store',
                label: 'Download über App Store'
            },
            {
                id: 'beta store',
                label: 'Apple Testflight, Microsoft App Center oder eine Alternative'
            },
            {
                id: 'file',
                label: 'Als IPA oder APK-Datei'
            },
            {
                id: 'device',
                label: 'Auf einem Testgerät'
            }
        ]

        const hybridSizeOptions = [
            {
                id: 'small',
                label: 'Klein<br>(< 20 Funktionen)'
            },
            {
                id: 'medium',
                label: 'Mittel<br>(20-40 Funktionen)'
            },
            {
                id: 'large',
                label: 'Groß<br>(41-60 Funktionen)'
            },
            {
                id: 'very large',
                label: 'Sehr groß<br>(> 60 Funktionen)'
            }
        ]

        const hybridProgrammingLanguageOptions = [
            {
                id: 'html',
                label: 'HTML'
            },
            {
                id: 'js',
                label: 'JavaScript'
            },
            {
                id: 'java',
                label: 'Java'
            },
            {
                id: 'php',
                label: 'PHP'
            },
            {
                id: 'python',
                label: 'Python'
            },
            {
                id: 'c#',
                label: 'C#'
            },
            {
                id: 'ruby',
                label: 'Ruby'
            },
            {
                id: 'individual',
                label: 'Sonstige'
            }
        ]

        const hybridFrameworkOptions = [
            {
                id: 'aspnet',
                label: 'ASP.Net'
            },
            {
                id: 'angular',
                label: 'AngularJS'
            },
            {
                id: 'django',
                label: 'Django'
            },
            {
                id: 'flash',
                label: 'Flash'
            },
            {
                id: 'gwt',
                label: 'Google Web Toolkit'
            },
            {
                id: 'react',
                label: 'React'
            },
            {
                id: 'struts',
                label: 'Struts'
            },
            {
                id: 'ruby on rails',
                label: 'Ruby on Rails'
            },
            {
                id: 'vaadin',
                label: 'Vaadin'
            },
            {
                id: 'individual',
                label: 'Sonstige'
            }
        ]

        const permissionsOptions = [
            {
                id: 'connection',
                label: 'Datenverbindungen'
            },
            {
                id: 'storage',
                label: 'Speicher'
            },
            {
                id: 'camera',
                label: 'Kamera'
            },
            {
                id: 'microphone',
                label: 'Mikrofon'
            },
            {
                id: 'location',
                label: 'Standort'
            },
            {
                id: 'sensors',
                label: 'Körpersensoren'
            },
            {
                id: 'files',
                label: 'Dateien und Medien'
            },
            {
                id: 'text messages',
                label: 'Kurznachrichten (SMS)'
            },
            {
                id: 'calendar',
                label: 'Kalender'
            },
            {
                id: 'contacts',
                label: 'Kontakte'
            }
        ]

        const securityAssessmentLevelOptions = [
            {
                id: 'low',
                label: 'Niedrig'
            },
            {
                id: 'normal',
                label: 'Normal'
            },
            {
                id: 'high',
                label: 'Hoch'
            },
            {
                id: 'very high',
                label: 'Sehr hoch'
            }
        ]

        const numberAdditionalProgrammingLanguages = ref<number>(0)
        const numberAdditionalFrameworks = ref<number>(0)
        const numberAdditionalExtensions = ref<number>(0)

        const checkProgrammingLanguageListLength = () => {
            if (mobile.hybridProgrammingLanguageIndividualList.length !== 0) {
                numberAdditionalProgrammingLanguages.value = mobile.hybridProgrammingLanguageIndividualList.length - 1
            }
        }

        const checkFrameworkListLength = () => {
            if (mobile.hybridFrameworkIndividualList.length !== 0) {
                numberAdditionalFrameworks.value = mobile.hybridFrameworkIndividualList.length - 1
            }
        }

        const checkExtensionListLength = () => {
            if (mobile.hybridExtensionList.length !== 0) {
                numberAdditionalExtensions.value = mobile.hybridExtensionList.length - 1
            }
        }

        const removeProgrammingLanguageFromList = (index: number) => {
            mobile.hybridProgrammingLanguageIndividualList.splice(index, 1)
            numberAdditionalProgrammingLanguages.value--
        }

        const removeFrameworkFromList = (index: number) => {
            mobile.hybridFrameworkIndividualList.splice(index, 1)
            numberAdditionalFrameworks.value--
        }

        const removeExtensionFromList = (index: number) => {
            mobile.hybridExtensionList.splice(index, 1)
            numberAdditionalExtensions.value--
        }

        const addProgrammingLanguage = () => {
            numberAdditionalProgrammingLanguages.value++
        }

        const addFramework = () => {
            numberAdditionalFrameworks.value++
        }

        const addExtension = () => {
            numberAdditionalExtensions.value++
        }

        onMounted(() => {
            checkProgrammingLanguageListLength()
            checkFrameworkListLength()
            checkExtensionListLength()
        })

        const markAssetDone = () => {
            props.passedAsset.config = mobile
            emit('mobileAssetDone', props.passedAsset)
        }

        const completeAsset = async() => {
            if (await checkAdvance()) {
                markAssetDone()
            }
        }

        const checkClearValuesType = () => {
            if (mobile.type === 'native') {
                mobile.hybridSize = ''
                mobile.hybridAuthentication = null
                mobile.hybridPermissions = []
                mobile.hybridProgrammingLanguageList = []
                mobile.hybridProgrammingLanguageIndividualList = []
                mobile.hybridFrameworkList = []
                mobile.hybridFrameworkIndividualList = []
                mobile.hybridExtensions = []
            } else if (mobile.type === 'hybrid') {
                mobile.nativeOperatingSystem = []
                mobile.nativeMultiOperatingSystemTest = null
                mobile.nativeCrossPlatform = null
                mobile.nativeOperatingSystemTest = ''
                mobile.nativeArchitecture = ''
                mobile.nativeAuthentication = null
                mobile.nativePermissions = []
                mobile.nativeSecurityMechanisms = []
                mobile.nativeAvailability = ''
            }
        }

        const checkClearValueNativeMultiOperatingSystemTest = () => {
            if (mobile.nativeOperatingSystem.length < 2) {
                mobile.nativeMultiOperatingSystemTest = null
            }
        }

        const checkClearValueNativeCrossPlattformNativeOperatingSystemTest = () => {
            if (mobile.nativeMultiOperatingSystemTest !== true) {
                mobile.nativeCrossPlatform = null
            } else {
                mobile.nativeOperatingSystemTest = ''
            }
        }

        const checkClearValueHybridProgrammingLanguageIndividual = () => {
            if (!mobile.hybridProgrammingLanguageList.includes('individual')) {
                mobile.hybridProgrammingLanguageIndividualList = []
            }
        }

        const checkClearValueHybridFrameworkIndividual = () => {
            if (!mobile.hybridFrameworkList.includes('individual')) {
                mobile.hybridFrameworkIndividualList = []
            }
        }

        const checkClearValueSecurityAssessmentLevel = () => {
            if (mobile.securityAssessment !== true) {
                mobile.securityAssessmentLevel = ''
            }
        }

        const nameUnique = ref<boolean>(true)
        const checkAssetNameUnique = () => {
            nameUnique.value = true
            const assetList = assetStore.getAll.value
            assetList.forEach((asset: any) => {
                if (currentAsset.value.name === asset.name && !route.value.path.includes('/edit')) {
                    nameUnique.value = false
                    openAssetNameExistsModal()
                }
            })
        }

        const acceptAssetNameExists = async() => {
            nameUnique.value = true
            closeAssetNameExistsModal()
            if (await validateFirstStepWithoutAssetNameUnique()) {
                window.scrollTo(0, 0)
                currentStep.value++
            }
        }

        const checkAdvance = async() => {
            if (currentStep.value === 0) {
                if (await validateFirstStep()) {
                    return true
                }
            } else if (currentStep.value === 1) {
                if (await validateSecondStep()) {
                    return true
                }
            } else if (currentStep.value === 2) {
                if (await validateThirdStep()) {
                    return true
                }
            } else if (currentStep.value === 3) {
                if (await validateFourthStep()) {
                    return true
                }
            } else if (currentStep.value === 4) {
                return true
            }
            return false
        }

        const advance = async() => {
            if (await checkAdvance()) {
                if (currentStep.value < 5) {
                    window.scrollTo(0, 0)
                    currentStep.value++
                }
            }
        }

        const back = () => {
            if (currentStep.value > 0) {
                currentStep.value--
            } else {
                assetStore.clearAsset()
                router.go(-1)
            }
        }

        const reset = () => {
            currentStep.value = 0
        }

        const nameError = ref<string>('')
        const descriptionError = ref<string>('')
        const typeError = ref<string>('')
        const nativeOperatingSystemError = ref<string>('')
        const nativeMultiOperatingSystemTestError = ref<string>('')
        const nativeCrossPlatformError = ref<string>('')
        const nativeOperatingSystemTestError = ref<string>('')
        const nativeArchitectureError = ref<string>('')
        const hybridAuthenticationError = ref<string>('')
        const nativeAuthenticationError = ref<string>('')
        const nativeAvailabilityError = ref<string>('')

        watch(() => currentAsset.value.name, (value) => {
            nameError.value = RequiredValidator(value)
        })

        watch(() => currentAsset.value.description, (value) => {
            descriptionError.value = RequiredValidator(value)
        })

        watch(() => mobile.type, (value) => {
            typeError.value = RequiredValidator(value)
        })

        watch(() => mobile.nativeOperatingSystem, (value) => {
            nativeOperatingSystemError.value = RequiredValidator(value)
        })

        watch(() => mobile.nativeMultiOperatingSystemTest, (value) => {
            nativeMultiOperatingSystemTestError.value = RequiredValidatorBoolean(value)
        })

        watch(() => mobile.nativeCrossPlatform, (value) => {
            nativeCrossPlatformError.value = RequiredValidatorBoolean(value)
        })

        watch(() => mobile.nativeOperatingSystemTest, (value) => {
            nativeOperatingSystemTestError.value = RequiredValidator(value)
        })

        watch(() => mobile.nativeArchitecture, (value) => {
            nativeArchitectureError.value = RequiredValidator(value)
        })

        watch(() => mobile.hybridAuthentication, (value) => {
            hybridAuthenticationError.value = RequiredValidatorBoolean(value)
        })

        watch(() => mobile.nativeAuthentication, (value) => {
            nativeAuthenticationError.value = RequiredValidatorBoolean(value)
        })

        watch(() => mobile.nativeAvailability, (value) => {
            nativeAvailabilityError.value = RequiredValidator(value)
        })

        const validateFirstStep = async() => {
            nameError.value = RequiredValidator(currentAsset.value.name)
            descriptionError.value = RequiredValidator(currentAsset.value.description)
            if (nameError.value === '' &&
                descriptionError.value === ''
            ) {
                checkAssetNameUnique()
                if (nameUnique.value) {
                    return true
                }
            } else {
                await nextTick()
                const element = document.querySelector('.invalid-feedback')?.parentNode?.parentNode as HTMLElement
                element.scrollIntoView({behavior: 'smooth'})
            }
        }

        const validateFirstStepWithoutAssetNameUnique = async() => {
            nameError.value = RequiredValidator(currentAsset.value.name)
            descriptionError.value = RequiredValidator(currentAsset.value.description)
            if (nameError.value === '' &&
                descriptionError.value === ''
            ) {
                return true
            } else {
                await nextTick()
                const element = document.querySelector('.invalid-feedback')?.parentNode?.parentNode as HTMLElement
                element.scrollIntoView({behavior: 'smooth'})
            }
        }

        const validateSecondStep = async() => {
            typeError.value = RequiredValidator(mobile.type)
            if (typeError.value === '') {
                return true
            } else {
                await nextTick()
                const element = document.querySelector('.invalid-feedback')?.parentNode?.parentNode as HTMLElement
                element.scrollIntoView({behavior: 'smooth'})
            }
        }

        const validateThirdStep = async() => {
            if (mobile.type === 'native') {
                nativeOperatingSystemError.value = RequiredValidatorArray(mobile.nativeOperatingSystem)
                if (mobile.nativeOperatingSystem.length > 1) {
                    nativeMultiOperatingSystemTestError.value = RequiredValidatorBoolean(mobile.nativeMultiOperatingSystemTest)
                    if (mobile.nativeMultiOperatingSystemTest) {
                        nativeCrossPlatformError.value = RequiredValidatorBoolean(mobile.nativeCrossPlatform)
                    } else {
                        nativeOperatingSystemTestError.value = RequiredValidator(mobile.nativeOperatingSystemTest)
                    }
                }
                nativeArchitectureError.value = RequiredValidator(mobile.nativeArchitecture)
            } else if (mobile.type === 'hybrid') {
                hybridAuthenticationError.value = RequiredValidatorBoolean(mobile.hybridAuthentication)
            }
            if (
                (mobile.type === 'native' &&
                    nativeOperatingSystemError.value === '' &&
                    (nativeMultiOperatingSystemTestError.value === '' || mobile.nativeOperatingSystem.length <= 1) &&
                    (nativeCrossPlatformError.value === '' || mobile.nativeOperatingSystem.length <= 1 || !mobile.nativeMultiOperatingSystemTest) &&
                    (nativeOperatingSystemTestError.value === '' || mobile.nativeOperatingSystem.length <= 1 || mobile.nativeMultiOperatingSystemTest) &&
                    nativeArchitectureError.value === ''
                ) ||
                (mobile.type === 'hybrid' &&
                    hybridAuthenticationError.value === ''
                )
            ) {
                return true
            } else {
                await nextTick()
                const element = document.querySelector('.invalid-feedback')?.parentNode?.parentNode as HTMLElement
                element.scrollIntoView({behavior: 'smooth'})
            }
        }

        const validateFourthStep = async() => {
            if (mobile.type === 'native') {
                nativeAuthenticationError.value = RequiredValidatorBoolean(mobile.nativeAuthentication)
                nativeAvailabilityError.value = RequiredValidator(mobile.nativeAvailability)
            }
            if (mobile.type === 'hybrid' ||
                (mobile.type === 'native' &&
                    nativeAuthenticationError.value === '' &&
                    nativeAvailabilityError.value === ''
                )
            ) {
                return true
            } else {
                await nextTick()
                const element = document.querySelector('.invalid-feedback')?.parentNode?.parentNode as HTMLElement
                element.scrollIntoView({behavior: 'smooth'})
            }
        }

        const gotoAssetList = () => {
            if (!route.value.path.includes('/edit')) {
                const assetStore = useAssetStore()
                assetStore.clearAsset()
            }
            window.scrollTo(0, 0)
            router.replace({ name: 'asset-based project', params: { id: projectId.value } })
        }

        return {
            route,
            currentAsset,
            currentStep,
            mobile,
            acceptAssetNameExists,
            numberAdditionalProgrammingLanguages,
            removeProgrammingLanguageFromList,
            addProgrammingLanguage,
            numberAdditionalFrameworks,
            removeFrameworkFromList,
            addFramework,
            numberAdditionalExtensions,
            removeExtensionFromList,
            addExtension,
            assetNameModal,
            openAssetNameModal,
            closeAssetNameModal,
            assetNameExistsModal,
            openAssetNameExistsModal,
            closeAssetNameExistsModal,
            descriptionModal,
            openDescriptionModal,
            closeDescriptionModal,
            typeModal,
            openTypeModal,
            closeTypeModal,
            nativeMultiOperatingSystemTestModal,
            openNativeMultiOperatingSystemTestModal,
            closeNativeMultiOperatingSystemTestModal,
            nativeCrossPlatformModal,
            openNativeCrossPlatformModal,
            closeNativeCrossPlatformModal,
            nativeArchitectureModal,
            openNativeArchitectureModal,
            closeNativeArchitectureModal,
            hybridSizeModal,
            openHybridSizeModal,
            closeHybridSizeModal,
            hybridAuthenticationModal,
            openHybridAuthenticationModal,
            closeHybridAuthenticationModal,
            nativeAuthenticationModal,
            openNativeAuthenticationModal,
            closeNativeAuthenticationModal,
            nativeSecurityMechanismsModal,
            openNativeSecurityMechanismsModal,
            closeNativeSecurityMechanismsModal,
            nativeAvailabilityModal,
            openNativeAvailabilityModal,
            closeNativeAvailabilityModal,
            hybridProgrammingLanguageModal,
            openHybridProgrammingLanguageModal,
            closeHybridProgrammingLanguageModal,
            hybridFrameworkModal,
            openHybridFrameworkModal,
            closeHybridFrameworkModal,
            hybridExtensionsModal,
            openHybridExtensionsModal,
            closeHybridExtensionsModal,
            permissionsModal,
            openPermissionsModal,
            closePermissionsModal,
            securityAssessmentModal,
            openSecurityAssessmentModal,
            closeSecurityAssessmentModal,
            securityAssessmentLevelModal,
            openSecurityAssessmentLevelModal,
            closeSecurityAssessmentLevelModal,
            confirmationCreateModal,
            openConfirmationCreateModal,
            closeConfirmationCreateModal,
            confirmationEditModal,
            openConfirmationEditModal,
            closeConfirmationEditModal,
            typeOptions,
            nativeOperatingSystemOptions,
            nativeArchitectureOptions,
            nativeSecurityMechanismsOptions,
            nativeAvailabilityOptions,
            hybridSizeOptions,
            hybridProgrammingLanguageOptions,
            hybridFrameworkOptions,
            permissionsOptions,
            securityAssessmentLevelOptions,
            checkClearValuesType,
            checkClearValueNativeMultiOperatingSystemTest,
            checkClearValueNativeCrossPlattformNativeOperatingSystemTest,
            checkClearValueHybridProgrammingLanguageIndividual,
            checkClearValueHybridFrameworkIndividual,
            checkClearValueSecurityAssessmentLevel,
            nameError,
            descriptionError,
            typeError,
            nativeOperatingSystemError,
            nativeMultiOperatingSystemTestError,
            nativeCrossPlatformError,
            nativeOperatingSystemTestError,
            nativeArchitectureError,
            hybridAuthenticationError,
            nativeAuthenticationError,
            nativeAvailabilityError,
            completeAsset,
            advance,
            back,
            reset,
            gotoAssetList
        }
    }
})
</script>

<style lang="scss" scoped>
@import "../../styles/breakpoints";

.mobile-asset {
    h2 {
        color: var(--color-brand-primary);
    }

    .asset-question {
        @include default-padding();
    }

    .asset-question-white {
        background-color: var(--color-white);
    }

    .additional-field {
        display: flex;
    }

    .add-button {
        margin: 2em 0 0 -0.5em;
    }
}
</style>
