<template>
    <div class='webapp-asset'>
        <div v-if="currentStep === 0">
            <div class="asset-question">
                <h2>
                    Assetname *
                </h2>
                <p>
                    Bitte geben Sie einen Namen für das Asset ein.
                </p>

                <text-field id="asset-name" label="" v-model="currentAsset.name" :error="nameError"></text-field>

                <div class="buttons-info">
                    <button class="button-info" @click="openAssetNameModal">Weitere Infos</button>
                </div>
            </div>

            <div class="asset-question asset-question-white">
                <h2>
                    Kurzbeschreibung *
                </h2>
                <p>
                    Bitte beschreiben Sie in wenigen Sätzen, wofür die von Ihnen angelegte Webapplikation eingesetzt wird.
                </p>

                <text-area id="webapp-description" label="" v-model="currentAsset.description" :error="descriptionError"/>

                <div class="buttons-info">
                    <button class="button-info" @click="openDescriptionModal">Weitere Infos</button>
                </div>
            </div>
        </div>

        <div v-if="currentStep === 1">
            <div class="asset-question">
                <h2>
                    Applikationstyp *
                </h2>
                <p>
                    Bitte ordnen Sie die Webapplikation einem der zwei Applikationstypen zu.
                </p>

                <BoxSelectRadio id="webapp-type" v-model="webapp.type" :options="typeOptions" @input="checkClearValuesType" :error="typeError"/>

                <div class="buttons-info">
                    <button class="button-info" @click="openTypeModal">Weitere Infos</button>
                </div>
            </div>
        </div>

        <div v-if="currentStep === 2 && webapp.type === 'webapp'">
            <div class="asset-question">
                <h2>
                    Funktionsumfang *
                </h2>
                <p>
                    Wie groß ist die Webanwendung, gemessen an dem bereitgestellten Funktionsumfang?
                </p>

                <radio-field label="" id="webapp-size" v-model="webapp.size" :options="sizeOptions" :error="sizeError" continuous/>

                <div class="buttons-info">
                    <button class="button-info" @click="openSizeModal">Weitere Infos</button>
                </div>
            </div>

            <div class="asset-question asset-question-white">
                <h2>
                    Eigen-/Fremdentwicklung <sup>(optional)</sup>
                </h2>
                <p>
                    Entwickeln Sie die Webanwendung selbst oder beziehen Sie diese von einem Unternehmen?
                </p>

                <BoxSelectRadio id="webapp-company-role" v-model="webapp.companyRole" :options="companyRoleOptions" @input="checkClearValuesSoftwareTypeVendor"/>

                <div v-if="webapp.companyRole === 'consumer'">
                    <hr>

                    <h2>
                        Softwaretyp <sup>(optional)</sup>
                    </h2>
                    <p>
                        Wählen Sie den für die Webanwendung zutreffenden Softwaretyp aus.
                    </p>

                    <BoxSelectRadio id="webapp-software-type" v-model="webapp.softwareType" :options="softwareTypeOptions" @input="checkClearValuesVendor"/>

                    <div class="buttons-info">
                        <button class="button-info" @click="openSoftwareTypeModal">Weitere Infos</button>
                    </div>

                    <div v-if="webapp.softwareType === 'standard' || webapp.softwareType === 'standard extended'">
                        <hr>

                        <h2>
                            Hersteller und Produktangaben <sup>(optional)</sup>
                        </h2>
                        <p>
                            Gerne dürfen Sie uns nachfolgend den Hersteller, den Produktnamen, die eingesetzte Version sowie
                            einen Link auf die Webseite des Produkts nennen.
                        </p>

                        <text-field id="webapp-vendor" label="Hersteller:" v-model="webapp.vendor" />
                        <text-field id="webapp-product-name" label="Produktname:" v-model="webapp.productName" />
                        <text-field id="webapp-version" label="Version:" v-model="webapp.version" />
                        <text-field id="webapp-website" label="Webseite:" v-model="webapp.website" />
                    </div>
                </div>
            </div>

            <div class="asset-question">
                <h2>
                    Workflows *
                </h2>
                <p>
                    Existieren komplexe Verarbeitungsprozesse (Workflows) in der Webanwendung, die durchlaufen werden
                    müssen, um den vollständigen Funktionsumfang zu testen?
                </p>

                <tristate-switch-field id="webapp-complex-processes" v-model="webapp.complexProcesses" :error="complexProcessesError" label="" label-pre="Ja" label-post="Nein"/>

                <div class="buttons-info">
                    <button class="button-info" @click="openComplexProcessesModal">Weitere Infos</button>
                </div>
            </div>
        </div>

        <div v-if="currentStep === 3 && webapp.type === 'webapp'">
            <div class="asset-question">
                <h2>
                    Geschützter Benutzerbereich *
                </h2>
                <p>
                    Verfügt die Webanwendung über einen geschützten Benutzerbereich, der ausschließlich authentisiert,
                    also mit einem gültigen Benutzer, nach erfolgreicher Anmeldung, zugänglich ist und über den
                    sensible/vertrauliche Daten und diese verarbeitende Funktionen bereitgestellt werden?
                </p>

                <tristate-switch-field id="webapp-authentication" v-model="webapp.authentication" @input="checkClearValuesRolesAuthenticationMethod" :error="authenticationError" label="" label-pre="Ja" label-post="Nein"/>

                <div class="buttons-info">
                    <button class="button-info" @click="openAuthenticationModal">Weitere Infos</button>
                </div>

                <div v-if="webapp.authentication">
                    <hr>

                    <h2>
                        Benutzerrollen *
                    </h2>
                    <p>
                        Wie viele Rollen mit jeweils unterschiedlichen Berechtigungen auf Funktionen und Daten existieren in der Webanwendung?
                    </p>

                    <radio-field id="webapp-roles" label="" v-model="webapp.roles" :options="rolesOptions" :error="rolesError" continuous/>

                    <div class="buttons-info">
                        <button class="button-info" @click="openRolesModal">Weitere Infos</button>
                    </div>
                </div>

                <div v-if="webapp.authentication">
                    <hr>

                    <h2>
                        Authentisierungsverfahren <sup>(optional)</sup>
                    </h2>
                    <p>
                        Welche Form der Authentisierung wird dem Nutzer von der Webanwendung zur Verfügung gestellt?
                    </p>

                    <BoxSelectCheckbox id="webapp-authentication-method" v-model="webapp.authenticationMethod" :options="authenticationMethodOptions" />

                    <div class="buttons-info">
                        <button class="button-info" @click="openAuthenticationMethodModal">Weitere Infos</button>
                    </div>
                </div>
            </div>

            <div class="asset-question asset-question-white">
                <h2>
                    Mehrmandantensystem *
                </h2>
                <p>
                    Handelt es sich bei der Webanwendung um ein Mehrmandantensystem?
                </p>

                <tristate-switch-field id="webapp-multi-user" v-model="webapp.multiUser" :error="multiUserError" label="" label-pre="Ja" label-post="Nein"/>

                <div class="buttons-info">
                    <button class="button-info" @click="openMultiUserModal">Weitere Infos</button>
                </div>
            </div>
        </div>

        <div v-if="(currentStep === 4 && webapp.type === 'webapp') || (currentStep === 2 && webapp.type === 'website')">
            <div class="asset-question">
                <h2>
                    Programmiersprache <sup>(optional)</sup>
                </h2>
                <p>
                    Welche Programmiersprache kommt bei der Entwicklung zum Einsatz?
                </p>

                <BoxSelectCheckbox id="webapp-programming-language" v-model="webapp.programmingLanguageList" :options="programmingLanguageOptions" @input="checkClearValueProgrammingLanguageIndividual" />

                <div class="buttons-info">
                    <button class="button-info" @click="openProgrammingLanguageModal">Weitere Infos</button>
                </div>

                <div v-if="webapp.programmingLanguageList.includes('individual')">
                    <hr>

                    <h2>
                        Sonstige Programmiersprachen <sup>(optional)</sup>
                    </h2>
                    <p>
                        Bitte geben sie die verwendeten Programmiersprachen an.
                    </p>

                    <text-field id="webapp-programming-language-individual" label="" v-model="webapp.programmingLanguageIndividualList[0]" />

                    <div v-if="numberAdditionalProgrammingLanguages === 0" class="add-button">
                        <button class="ghost-primary" @click="addProgrammingLanguage()">Weitere Programmiersprache hinzufügen</button>
                    </div>

                    <div v-for="currentIndex in numberAdditionalProgrammingLanguages" :key="currentIndex">
                        <div class="additional-field">
                            <text-field :id="'webapp-programming-language-individual' + currentIndex" label="" v-model="webapp.programmingLanguageIndividualList[currentIndex]" />
                            <div class="icon-remove">
                                <button class="icon-button" @click="removeProgrammingLanguageFromList(currentIndex)">
                                    <i class="material-icon mdi mdi-close" />
                                </button>
                            </div>
                        </div>

                        <div v-if="numberAdditionalProgrammingLanguages === currentIndex" class="add-button">
                            <button class="ghost-primary" @click="addProgrammingLanguage()">Weitere Programmiersprache hinzufügen</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="asset-question asset-question-white">
                <h2>
                    Web-Frameworks <sup>(optional)</sup>
                </h2>
                <p>
                    Wird in der Entwicklung eines oder mehrere der folgenden Web-Frameworks eingesetzt?
                </p>

                <BoxSelectCheckbox id="webapp-framework" v-model="webapp.frameworkList" :options="frameworkOptions" @input="checkClearValueFrameworkIndividual" />

                <div class="buttons-info">
                    <button class="button-info" @click="openFrameworkModal">Weitere Infos</button>
                </div>

                <div v-if="webapp.frameworkList.includes('individual')">
                    <hr>

                    <h2>
                        Sonstige Frameworks <sup>(optional)</sup>
                    </h2>
                    <p>
                        Bitte geben sie die verwendeten Web-Frameworks ein.
                    </p>

                    <text-field id="webapp-framework-individual" label="" v-model="webapp.frameworkIndividualList[0]" />

                    <div v-if="numberAdditionalFrameworks === 0" class="add-button">
                        <button class="ghost-primary" @click="addFramework()">Weiteres Framework hinzufügen</button>
                    </div>

                    <div v-for="currentIndex in numberAdditionalFrameworks" :key="currentIndex">
                        <div class="additional-field">
                            <text-field :id="'webapp-framework-individual' + currentIndex" label="" v-model="webapp.frameworkIndividualList[currentIndex]" />
                            <div class="icon-remove">
                                <button class="icon-button" @click="removeFrameworkFromList(currentIndex)">
                                    <i class="material-icon mdi mdi-close" />
                                </button>
                            </div>
                        </div>

                        <div v-if="numberAdditionalFrameworks === currentIndex" class="add-button">
                            <button class="ghost-primary" @click="addFramework()">Weiteres Framework hinzufügen</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="asset-question">
                <h2>
                    Plugins/Bibliotheken <sup>(optional)</sup>
                </h2>
                <p>
                    Werden kostenfreie oder kommerzielle Programmerweiterungen (z.B. Plugins, Bibliotheken) im Rahmen der
                    Softwareentwicklung eingesetzt? Wenn ja, nennen Sie diese bitte im Folgenden.
                </p>

                <text-field id="webapp-extensions" label="" v-model="webapp.extensionList[0]" />

                <div v-if="numberAdditionalExtensions === 0" class="add-button">
                    <button class="ghost-primary" @click="addExtension()">Weitere Erweiterung hinzufügen</button>
                </div>

                <div v-for="currentIndex in numberAdditionalExtensions" :key="currentIndex">
                    <div class="additional-field">
                        <text-field :id="'webapp-extension' + currentIndex" label="" v-model="webapp.extensionList[currentIndex]" />
                        <div class="icon-remove">
                            <button class="icon-button" @click="removeExtensionFromList(currentIndex)">
                                <i class="material-icon mdi mdi-close" />
                            </button>
                        </div>
                    </div>

                    <div v-if="numberAdditionalExtensions === currentIndex" class="add-button">
                        <button class="ghost-primary" @click="addExtension()">Weitere Erweiterung hinzufügen</button>
                    </div>
                </div>

                <div class="buttons-info">
                    <button class="button-info" @click="openExtensionsModal">Weitere Infos</button>
                </div>
            </div>
        </div>

        <div v-if="(currentStep === 5 && webapp.type === 'webapp') || (currentStep === 3 && webapp.type === 'website')">
            <div class="asset-question">
                <h2>
                    Erreichbarkeit im Internet *
                </h2>
                <p>
                    Ist die Webapplikation direkt aus dem Internet erreichbar?
                </p>

                <tristate-switch-field id="webapp-reachability" v-model="webapp.reachability" :error="reachabilityError" label="" label-pre="Ja" label-post="Nein"/>

                <div class="buttons-info">
                    <button class="button-info" @click="openReachabilityModal">Weitere Infos</button>
                </div>
            </div>

            <div class="asset-question asset-question-white">
                <h2>
                    Applikationsbetrieb <sup>(optional)</sup>
                </h2>
                <p>
                    Wie erfolgt der Betrieb der Webapplikation?
                </p>

                <BoxSelectRadio id="webapp-operation" v-model="webapp.operation" :options="operationOptions" @input="checkClearValueOperator"/>

                <div v-if="webapp.operation === 'external' || webapp.operation === 'mixed'">
                    <hr>

                    <h2>
                        Liste der Dienstleister <sup>(optional)</sup>
                    </h2>
                    <p>
                        Gerne dürfen Sie uns den oder die Dienstleister für den Betrieb der Webapplikation nennen.
                    </p>

                    <text-field id="webapp-operator" label="" v-model="webapp.operatorList[0]" />

                    <div v-if="numberAdditionalOperators === 0" class="add-button">
                        <button class="ghost-primary" @click="addOperator()">Weiteren Dienstleister hinzufügen</button>
                    </div>

                    <div v-for="currentIndex in numberAdditionalOperators" :key="currentIndex">
                        <div class="additional-field">
                            <text-field :id="'webapp-operator' + currentIndex" label="" v-model="webapp.operatorList[currentIndex]" />
                            <div class="icon-remove">
                                <button class="icon-button" @click="removeOperatorFromList(currentIndex)">
                                    <i class="material-icon mdi mdi-close" />
                                </button>
                            </div>
                        </div>

                        <div v-if="numberAdditionalOperators === currentIndex" class="add-button">
                            <button class="ghost-primary" @click="addOperator()">Weiteren Dienstleister hinzufügen</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="asset-question">
                <h2>
                    Cloud <sup>(optional)</sup>
                </h2>
                <p>
                    Erfolgt der Betrieb der Webapplikation ganz oder teilweise in der Cloud?
                </p>

                <tristate-switch-field id="webapp-cloud" v-model="webapp.cloud" @input="checkClearValueCloudProviderCloudProviderIndividual" label="" label-pre="Ja" label-post="Nein"/>

                <div v-if="webapp.cloud">
                    <hr>

                    <h2>
                        Cloudanbieter <sup>(optional)</sup>
                    </h2>
                    <p>
                        Welcher Cloudanbieter wird von Ihnen eingesetzt?
                    </p>

                    <BoxSelectCheckbox id="webapp-cloud-provider" label="" v-model="webapp.cloudProviderList" :options="cloudProviderOptions" @input="checkClearValueCloudProviderIndividual"/>

                    <div class="buttons-info">
                        <button class="button-info" @click="openCloudProviderModal">Weitere Infos</button>
                    </div>

                    <div v-if="webapp.cloudProviderList.includes('individual')">
                        <hr>

                        <h2>
                            Liste der Cloudanbieter <sup>(optional)</sup>
                        </h2>
                        <p>
                            Bitte nennen Sie uns nachfolgend den eingesetzten Cloudanbieter.
                        </p>

                        <text-field id="webapp-cloud-provider-individual" label="" v-model="webapp.cloudProviderIndividualList[0]"/>

                        <div v-if="numberAdditionalCloudProviders === 0" class="add-button">
                            <button class="ghost-primary" @click="addCloudProvider()">Weiteren Cloudanbieter hinzufügen</button>
                        </div>

                        <div v-for="currentIndex in numberAdditionalCloudProviders" :key="currentIndex">
                            <div class="additional-field">
                                <text-field :id="'webapp-cloud-provider-individual' + currentIndex" label="" v-model="webapp.cloudProviderIndividualList[currentIndex]" />
                                <div class="icon-remove">
                                    <button class="icon-button" @click="removeCloudProviderFromList(currentIndex)">
                                        <i class="material-icon mdi mdi-close" />
                                    </button>
                                </div>
                            </div>

                            <div v-if="numberAdditionalCloudProviders === currentIndex" class="add-button">
                                <button class="ghost-primary" @click="addCloudProvider()">Weiteren Cloudanbieter hinzufügen</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="(currentStep === 6 && webapp.type === 'webapp') || (currentStep === 4 && webapp.type === 'website')">
            <div class="asset-question">
                <h2>
                    Schutzbedarf <sup>(optional)</sup>
                </h2>
                <p>
                    Haben Sie den Schutzbedarf der Webapplikation ermittelt?
                </p>

                <tristate-switch-field id="webapp-security-assessment" v-model="webapp.securityAssessment" @input="checkClearValueSecurityAssessmentLevel" label="" label-pre="Ja" label-post="Nein"/>

                <div class="buttons-info">
                    <button class="button-info" @click="openSecurityAssessmentModal">Weitere Infos</button>
                </div>

                <div v-if="webapp.securityAssessment">
                    <hr>

                    <h2>
                        Höhe des Schutzbedarfs <sup>(optional)</sup>
                    </h2>
                    <p>
                        Wie hoch ist der Schutzbedarf?
                    </p>

                    <radio-field label="" id="webapp-security-assessment-level" v-model="webapp.securityAssessmentLevel" :options="securityAssessmentLevelOptions" continuous/>

                    <div class="buttons-info">
                        <button class="button-info" @click="openSecurityAssessmentLevelModal">Weitere Infos</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="buttons">
            <div class="buttons-left">
                <button class="ghost-primary space-right" @click="back">Zurück</button>
            </div>
            <div class="buttons-center">
                <button v-if="route.path.includes('/edit')" class="ghost configurator-button" @click="openConfirmationEditModal()">Asset-Bearbeitung abbrechen</button>
                <button v-else class="ghost configurator-button" @click="openConfirmationCreateModal()">Asset-Erstellung abbrechen</button>
            </div>
            <div class="buttons-right">
                <button v-if="((currentStep === 4 && webapp.type === 'website') || currentStep === 6) && !route.path.includes('/edit')" class="primary" @click="completeAsset">Asset-Konfiguration abschließen</button>
                <button v-else-if="((currentStep === 4 && webapp.type === 'website') || currentStep === 6) && route.path.includes('/edit')" class="primary" @click="completeAsset">Asset-Bearbeitung abschließen</button>
                <button v-else class="primary" @click="advance">Weiter</button>
            </div>
        </div>

        <modal class="info-modal" v-model="confirmationCreateModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Möchten Sie die Asset-Erstellung wirklich abbrechen?</h2>

            <p>
                Dabei geht Ihre bisherige Konfiguration verloren.
            </p>

            <div class="buttons-modal">
                <div class="buttons-left">
                    <button class="ghost-primary button-modal-back-dialog" @click="gotoAssetList()">Ja, zurück zur Assetübersicht.</button>
                </div>
                <div class="buttons-right">
                    <button class="primary button-modal-back-dialog" @click="closeConfirmationCreateModal()">Nein, Asset-Konfiguration behalten.</button>
                </div>
            </div>
        </modal>

        <modal class="info-modal" v-model="confirmationEditModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Möchten Sie die Asset-Bearbeitung wirklich abbrechen?</h2>

            <p>
                Dabei gehen Ihre bisherigen Änderungen verloren.
            </p>

            <div class="buttons-modal">
                <div class="buttons-left">
                    <button class="ghost-primary button-modal-back-dialog" @click="gotoAssetList()">Ja, zurück zur Assetübersicht.</button>
                </div>
                <div class="buttons-right">
                    <button class="primary button-modal-back-dialog" @click="closeConfirmationEditModal()">Nein, Asset-Konfiguration behalten.</button>
                </div>
            </div>
        </modal>

        <modal class="info-modal" v-model="assetNameModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Assetname</h2>

            <p>
                Ihre Antwort hilft uns, die Anfrage intern besser zu verarbeiten. Bitte geben Sie einen für Sie eindeutigen
                Asset-Namen ein.
            </p>

            <form-actions slot="footer">
                <button @click="closeAssetNameModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="assetNameExistsModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Asset mit identischem Namen</h2>

            <p>
                Es existiert bereits ein Asset mit dem gleichen Namen. Möchten Sie den Namen des Assets ändern?
            </p>

            <div class="buttons-modal">
                <div class="buttons-left">
                    <button class="ghost-primary button-modal-back-dialog" @click="closeAssetNameExistsModal()">Ja, Namen ändern.</button>
                </div>
                <div class="buttons-right">
                    <button class="primary button-modal-back-dialog" @click="acceptAssetNameExists()">Nein, so fortfahren.</button>
                </div>
            </div>
        </modal>

        <modal class="info-modal" v-model="descriptionModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Kurzbeschreibung</h2>

            <p>
                Bitte beschreiben Sie in wenigen Sätzen, wofür die von Ihnen angelegte Webapplikation eingesetzt wird.
                Zentrale Fragen sind:
            </p>

            <ul>
                <li>
                    Von wem und wofür wird die Webapplikation genutzt?
                </li>
                <li>
                    Welche Daten werden von der Webapplikation verarbeitet und/oder gespeichert?
                </li>
            </ul>

            <p>
                Ihre Beschreibung hilft uns, Ihren Prüfumfang besser zu verstehen. Je detaillierter Sie auf das Asset
                eingehen, umso besser können wir Ihren Prüfumfang im Vorfeld analysieren und kritisch hinterfragen.
            </p>

            <form-actions slot="footer">
                <button @click="closeDescriptionModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="typeModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Applikationstyp</h2>

            <p>
                Nachfolgend haben wir für Sie typische Beispiele für einfache Webseiten und komplexe
                Webanwendungen zusammengestellt.
            </p>

            <ul>
                <li>
                    <p>
                        Einfache Webseiten vermitteln meistens statische Inhalte und haben nur wenige, nicht komplexe
                        Interaktionsmöglichkeiten. Oft handelt es sich um Unternehmenswebseiten, Blogs oder einfache
                        Werbeseiten. Interaktionsmöglichkeiten sind z.B. eine Suchfunktion, ein Kontaktformular oder die
                        Möglichkeit vorhandene Inhalte zu kommentieren.
                    </p>
                </li>
                <li>
                    <p>
                        Komplexe Webanwendungen werden im Kontext von Geschäftsprozessen eingesetzt und verfügen
                        über zahlreiche Interaktionsmöglichkeiten. Online-Shops oder Enterprise Applikationen wie z.B.
                        Einkaufsportale, Recruiting Lösungen oder Ticket-Systeme stellen komplexe Webanwendungen
                        dar.
                    </p>
                </li>
            </ul>

            <form-actions slot="footer">
                <button @click="closeTypeModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="sizeModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Funktionsumfang</h2>

            <p>
                Im Rahmen des Pentests werden die von der Webanwendung bereitgestellten Funktionen auf Schwachstellen
                überprüft. Je mehr Funktionen bereitgestellt werden, umso aufwändiger wird die Sicherheitsanalyse.
            </p>
            <p>
                Damit wir einen Eindruck von der Größe der zu testenden Webanwendung gewinnen, bitten wir um eine grobe
                Abschätzung der Anzahl bereitgestellter Funktionen. Es ist nicht notwendig eine exakte Aussage zu treffen.
            </p>
            <p>
                Nachfolgend haben wir einige exemplarische Funktionen aus verschiedenen Einsatzbereichen gesammelt. Die
                Aufzählung ist selbstverständlich nicht vollständig und dient ausschließlich der Veranschaulichung unseres
                Verständnisses für Funktionen einer Webanwendung.
            </p>
            <p>
                Bitte berücksichtigen Sie, dass wir den Pentest bei sehr großen Webanwendungen auf Basis eines risikobasierten
                Ansatzes durchführen. Dabei werden nicht alle Funktionen im Detail von uns geprüft, sondern eine umfangreiche
                Auswahl der wichtigsten bzw. kritischsten Funktionen untersucht. Gerne stimmen wir die Auswahl im Rahmen
                des Kick-offs mit Ihnen ab.
            </p>

            <h3>Users & Accounts</h3>
            <ul>
                <li>Authentisierung von Nutzern</li>
                <li>Registrierungsprozess für neue Nutzer</li>
                <li>Einladung neuer Nutzer</li>
                <li>Passwort wiederherstellen</li>
                <li>Zwei-Faktor-Authentifizierung</li>
                <li>Single Sign-on</li>
            </ul>

            <h3>Content</h3>
            <ul>
                <li>Static Content</li>
                <li>Einsatz eines Content Management Systems (CMS)</li>
                <li>Dashboard</li>
                <li>Nutzerprofile</li>
                <li>Einsatz eines Blogs</li>
                <li>Umfragen und Abstimmungen</li>
                <li>Quiz</li>
                <li>Bezahlter Content</li>
                <li>Knowledge Base</li>
                <li>Landing Page</li>
                <li>Kontaktformular</li>
                <li>Gewinnspiel</li>
                <li>Tags & Kategorien</li>
                <li>Nutzerbewertungen und Reviews</li>
                <li>Externer Dateiupload</li>
                <li>Such- und Filterfunktion</li>
                <li>E-Mail-Benachrichtigung</li>
                <li>Newsletter Abonnement</li>
                <li>Multilingual Support</li>
                <li>Workflows</li>
            </ul>

            <h3>Dates & Locations</h3>
            <ul>
                <li>Kalenderfunktion</li>
                <li>Termine buchen</li>
                <li>Reservierungen anfragen</li>
                <li>Streckenplanung</li>
                <li>Karten einsehen</li>
            </ul>

            <h3>Social</h3>
            <ul>
                <li>Nachrichten senden</li>
                <li>Live Chat</li>
                <li>Community Forum</li>
                <li>Nutzerkommentare</li>
                <li>Nutzer blockieren</li>
                <li>Nutzern folgen</li>
                <li>Freundeslisten</li>
                <li>Inhalte teilen</li>
                <li>Dateien senden / austauschen</li>
            </ul>

            <h3>E-Commerce</h3>
            <ul>
                <li>Produkte verwalten</li>
                <li>Digitale Produkte</li>
                <li>Abonnements</li>
                <li>Rabatte und Sonderangebote</li>
                <li>Produkte empfehlen</li>
                <li>Produkte vergleichen</li>
                <li>Wunschlisten</li>
                <li>Warenkorb</li>
                <li>Auktionen</li>
                <li>Gutscheine</li>
                <li>Bestellverwaltung</li>
            </ul>

            <h3>Finance & Payments</h3>
            <ul>
                <li>Angebotserstellung</li>
                <li>Rechnungserstellung</li>
                <li>Zahlungsabwicklung</li>
            </ul>

            <h3>Productivity & Task management</h3>
            <ul>
                <li>Projekt Management</li>
                <li>Aufgaben Management</li>
                <li>Checklisten</li>
                <li>Ticketsystem</li>
            </ul>

            <h3>Gamification</h3>
            <ul>
                <li>Ranglisten</li>
                <li>User Credits</li>
            </ul>

            <h3>Media</h3>
            <ul>
                <li>Fotobibliothek</li>
                <li>Videobibliothek</li>
                <li>Audiobibliothek</li>
                <li>Bildbearbeitung</li>
                <li>Videobearbeitung</li>
            </ul>

            <h3>Analytics & Reporting</h3>
            <ul>
                <li>Daten filtern und analysieren</li>
                <li>Berichte erstellen</li>
                <li>Daten exportieren</li>
            </ul>

            <form-actions slot="footer">
                <button @click="closeSizeModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="softwareTypeModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Softwaretyp</h2>

            <p>
                Bei einer Individualentwicklung wurde die Webanwendung für Ihr Unternehmen passgenau und individuell
                hergestellt. Es ist dabei nicht relevant, ob die Software von Ihnen (inhouse) oder von einem Dienstleister
                entwickelt wurde.
            </p>
            <p>
                Eine Standardsoftware wird von einem Hersteller entwickelt und am Markt vertrieben. Typische Beispiele
                sind bekannte E-Commerce oder CMS Lösungen, die man als kostenfreie Open Source Lösung oder als
                kostenpflichtiges Tools bezieht. Bekannte Beispiele sind Microsoft Outlook Web Access  oder Salesforce CRM.
            </p>
            <p>
                Die Standardsoftware wird von Ihnen mit oder ohne individuelle Erweiterungen eingesetzt. Individuelle
                Erweiterungen stellen Anpassungen an der Standardsoftware dar, die nur für Ihr Unternehmen vom Hersteller
                entwickelt und in die Standardsoftware integriert werden. Gemeint sind dabei keine individuellen
                Konfigurationen der Standardsoftware.
            </p>

            <form-actions slot="footer">
                <button @click="closeSoftwareTypeModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="complexProcessesModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Workflows</h2>

            <p>
                Nachfolgend beschreiben wir ein Beispiel für einen komplexen Verarbeitungsprozess. Natürlich sind in der
                Praxis beliebige Ausprägungen für komplexe Verarbeitungsprozesse möglich.
            </p>

            <ul>
                <li>Bei der zu prüfenden Webanwendung handelt es sich um eine Urlaubsverwaltung.</li>
                <li>Ein Mitarbeiter beantragt bei seinem Vorgesetzten über eine entsprechende Funktion in der Webanwendung seinen Urlaub.</li>
                <li>Daraufhin erhält der Vorgesetzte per E-Mail einen Hinweis zur Freigabe des beantragten Urlaubs seines Mitarbeiters.</li>
                <li>Die Freigabe des Urlaubs erfolgt vom Vorgesetzten über eine entsprechende Funktion der Webanwendung.</li>
                <li>Die Funktion steht dem Vorgesetzten erst zur Verfügung , sofern ein Mitarbeiter einen Urlaubsantrag gestellt hat.</li>
                <li>Ohne vorhergehenden Urlaubsantrag kann die Funktion zur Freigabe nicht aufgerufen werden</li>
            </ul>

            <form-actions slot="footer">
                <button @click="closeComplexProcessesModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="authenticationModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Geschützter Benutzerbereich</h2>

            <p>
                Sensible, vertrauliche Personen- und/oder Unternehmensdaten sowie Funktionen, die diese Daten verarbeiten,
                werden üblicherweise in einem geschützten Benutzerbereich, also nicht öffentlich, den Nutzern zur
                Verfügung gestellt. Der Benutzerbereich ist dabei nur mit einem gültigen Benutzernamen sowie nach
                erfolgreicher Authentisierung (z.B. mit einem Passwort) zugänglich.
            </p>
            <p>
                Webanwendungen, die über einen geschützten Benutzerbereich verfügen, werden von uns immer authentisiert
                überprüft. Hierdurch steigt der Prüfaufwand und damit die Kosten, dafür erhalten Sie jedoch ein wesentlich
                besseres Lagebild von der Sicherheit der Webanwendung und ggf. vorhandenen Risiken.
            </p>

            <form-actions slot="footer">
                <button @click="closeAuthenticationModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="rolesModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Benutzerrollen</h2>

            <p>
                Häufig ist es in der Praxis so, dass ausgewählte Funktionen oder Daten einer Webanwendungen ausschließlich
                bestimmten Nutzern zur Verfügung gestellt werden sollen. Das heißt, nicht jeder Nutzer soll auf alle
                Funktionen und alle Daten zugreifen können.
            </p>
            <p>
                Der Zugriff auf die jeweiligen Funktionen und Daten wird dabei über Rollen mit entsprechenden Zugriffsrechten
                realisiert. Es können beliebig viele Rollen mit unterschiedlichen Rechten existieren.
            </p>
            <p>
                Typisch sind folgende Rollen:
            </p>
            <ul>
                <li>Normale Nutzer</li>
                <li>Privilegierte Nutzer mit zusätzlichen Rechten</li>
                <li>Administrative Nutzer mit vollen Rechten</li>
            </ul>
            <p>
                Verfügt ein Webservice über mehr als 2 Rollen, empfehlen wir die Prüfung von maximal 2 Rollen, um den
                notwendigen Prüfaufwand und verbundene Kosten zu beschränken. Dabei werden die Rolle mit den meisten und
                die Rolle mit den wenigsten Rechten überprüft.
            </p>

            <form-actions slot="footer">
                <button @click="closeRolesModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="authenticationMethodModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Authentisierungsverfahren</h2>

            <p>
                Authentisierung bezeichnet den Vorgang, bei dem der Nutzer seine Identität gegenüber dem System nachweist.
                Hierzu bieten sich drei Optionen an.
            </p>
            <ul>
                <li>Wissen: Üblich ist die Abfrage eines Passworts.</li>
                <li>Biometrie: Möglichkeiten sind das Auslesen des Fingerabdrucks oder der Iris eines Nutzers über entsprechende Eingabegeräte.</li>
                <li>Besitz: Häufig eingesetzt werden Zertifikate, Smart Cards oder USB-Dongles.</li>
            </ul>
            <p>
                Möglich ist auch eine Kombination der drei vorgenannten Optionen, also z.B. muss der Nutzer entsprechendes
                Wissen und Besitz zur Bestätigung seiner Identität nachweisen.
            </p>
            <p>
                Hierbei spricht man von einer Zwei-Faktor-Authentifizierung (2FA). Sofern die Webanwendung über 2FA verfügt,
                wählen Sie bitte die eingesetzten Formen der Authentisierung aus.
            </p>

            <form-actions slot="footer">
                <button @click="closeAuthenticationMethodModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="multiUserModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Mehrmandantensystem</h2>

            <p>
                Eine Webapplikation ist mehrmandantenfähig, wenn sie gleichzeitig mehrere Mandanten, also Kunden oder
                Auftraggeber, bedienen kann, ohne das diese gegenseitigen Einblick in ihre Daten bekommen.
            </p>
            <p>
                Unsere Erfahrung hat gezeigt, dass die meisten Webapplikationen nicht mehrmandantenfähig sind.
            </p>


            <form-actions slot="footer">
                <button @click="closeMultiUserModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="programmingLanguageModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Programmiersprache</h2>

            <p>
                Informationen zur eingesetzten Programmiersprache müssen beim Entwickler erfragt werden.
            </p>
            <p>
                In seltenen Fällen kommt es vor, dass mehrere Programmiersprachen eingesetzt werden. Gerne dürfen Sie in
                diesem Fall bei Ihrer Antwort alle eingesetzten Programmiersprachen angeben.
            </p>
            <p>
                Liegen Ihnen keine Informationen vor oder sind nicht in einer angemessenen Zeit beschaffbar,
                lassen Sie die Frage gerne offen.
            </p>

            <form-actions slot="footer">
                <button @click="closeProgrammingLanguageModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="frameworkModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Web-Frameworks</h2>

            <p>
                Informationen zu eingesetzten Web-Frameworks müssen beim Entwickler bzw. Hersteller erfragt werden.
            </p>
            <p>
                Handelt es sich um eine Standardsoftware, lassen sich entsprechende Informationen ggf. aus dem Handbuch
                oder von der Webseite des Herstellers entnehmen.
            </p>
            <p>
                Liegen Ihnen keine Informationen vor oder sind diese nicht in einer angemessenen Zeit beschaffbar, lassen
                Sie die Frage gerne unbeantwortet.
            </p>

            <form-actions slot="footer">
                <button @click="closeFrameworkModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="extensionsModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Plugins/Bibliotheken</h2>

            <p>
                Ein Plugin bezeichnet ein optional verfügbares Softwaremodul, das die Anwendung des Hauptprogramms
                hinsichtlich verfügbarer Funktionen erweitert oder verändert. Das Hauptprogramm lädt das Plugin während
                des Programmstarts, um bei Bedarf darauf zugreifen zu können. Plugins sind ohne rahmengebende Hauptanwendung
                nicht ausführbar.
            </p>
            <p>
                Bibliotheken enthalten Unterprogramme, die mit Hilfsfunktionen die Programmierung erleichtern sollen.
                Im Unterschied zu einem Framework wird eine Bibliothek stärker auf eine bestimmte Verwendung hin
                entwickelt und besitzt hierfür aufeinander abgestimmte Funktionen. Bekannte Beispiele sind jQuery,
                React, Zepto und CreateJS.
            </p>
            <p>
                Liegen Ihnen keine Informationen vor oder sind diese nicht in einer angemessenen Zeit beschaffbar, lassen
                Sie die Frage gerne unbeantwortet
            </p>

            <form-actions slot="footer">
                <button @click="closeExtensionsModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="reachabilityModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Erreichbarkeit im Internet</h2>

            <p>
                Webapplikationen, die Unternehmens-intern, also im internen Firmen-Netzwerk, betrieben werden, sind nicht direkt
                über das Internet erreichbar.
            </p>
            <p>
                Webapplikationen, die öffentlich im Internet oder alternativ in einer an das Internet angebundenen
                demilitarisierten Zone mit oder ohne Schutz durch eine Firewall betrieben werden, sind direkt über das
                Internet erreichbar.
            </p>
            <p>
                Häufig kommt es dabei vor, dass der Internet-Zugriff mittels Black- bzw. White Listing auf bestimmte Nutzer
                beschränkt wird. Für die Beantwortung der Frage ist dies unerheblich; die Webapplikation ist direkt aus dem
                Internet erreichbar.
            </p>

            <form-actions slot="footer">
                <button @click="closeReachabilityModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="cloudProviderModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Cloudanbieter</h2>

            <p>
                Im Rahmen des Pentests müssen ggf. besondere Vorgaben des Cloudanbieters eingehalten werden. Dies kann
                beispielsweise eine Vorankündigung der Sicherheitsanalyse bedeuten.
            </p>

            <form-actions slot="footer">
                <button @click="closeCloudProviderModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="securityAssessmentModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Schutzbedarf</h2>

            <p>
                Der Schutzbedarf misst sich an den potenziellen Schäden, die mit der Beeinträchtigung der
                betroffenen Webapplikation einhergehen. 
                Dabei werden üblicherweise die drei Schutzziele Integrität, Vertraulichkeit und Verfügbarkeit betrachtet. 
                Bei der Bewertung der Schäden sollten neben finanziellen Aspekten weitere Schadensszenarien, wie zum Beispiel: 
            </p>
            <ul>
                <li>Verstöße gegen Gesetze, Vorschriften oder Verträge</li>
                <li>Beeinträchtigungen des informationellen Selbstbestimmungsrechts</li>
                <li>Beeinträchtigungen der persönlichen Unversehrtheit</li>
                <li>Beeinträchtigungen der Aufgabenerfüllung</li>
            </ul>
            <p>
                berücksichtigt werden.
            </p>

            <form-actions slot="footer">
                <button @click="closeSecurityAssessmentModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="securityAssessmentLevelModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Höhe des Schutzbedarfs</h2>

            <p>
                Die konkrete Ausprägung der vorgenannten Schutzbedarfskategorien wird von jedem Unternehmen individuell
                vorgenommen und kann sich unterscheiden. Das Bundesamt für Informationssicherheit (BSI) liefert im
                BSI-Standard 100-4 folgendes Beispiel:
            </p>
            <ul>
                <li>Niedrig: kaum spürbare Auswirkung</li>
                <li>Normal: spürbare Auswirkungen</li>
                <li>Hoch: erhebliche Auswirkungen</li>
                <li>Sehr hoch: existentielle Auswirkungen</li>
            </ul>

            <form-actions slot="footer">
                <button @click="closeSecurityAssessmentLevelModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, onMounted, computed, watch, nextTick } from 'vue'
import { useModal } from '@/composables/use-modal'
import { useRouter } from '@/composables/use-router'
import { useProjectStore } from '@/store/projectStore'
import { useAssetStore } from '@/store/assetStore'
import Slider from '@/components/Slider.vue'
import BoxSelectCheckbox from '@/components/BoxSelectCheckbox.vue'
import BoxSelectRadio from '@/components/BoxSelectRadio.vue'
import { RequiredValidator, RequiredValidatorBoolean } from '@/validator/validator'

export default defineComponent({
    name: 'WebappAsset',

    props: {
        passedAsset: {
            type: Object,
            default: null
        }
    },

    components: {
        Slider,
        BoxSelectCheckbox,
        BoxSelectRadio
    },

    setup(props, { emit }) {
        const { router, route } = useRouter()

        const assetStore = useAssetStore()
        const currentAsset = computed(() => {
            if (route.value.path.includes('/edit')) {
                const assetList = assetStore.getAll.value
                return assetList.filter(asset => asset.id === route.value.params.assetId)[0]
            } else {
                return assetStore.getCurrentAsset.value
            }
        })

        const projectStore = useProjectStore()
        const projectId = projectStore.uuid

        const currentStep = ref<number>(0)

        const webapp = reactive<any>({
            type: typeof props.passedAsset.config.type === 'undefined' ? '' : props.passedAsset.config.type,
            size: typeof props.passedAsset.config.size === 'undefined' ? '' : props.passedAsset.config.size,
            companyRole: typeof props.passedAsset.config.companyRole === 'undefined' ? '' : props.passedAsset.config.companyRole,
            softwareType: typeof props.passedAsset.config.softwareType === 'undefined' ? '' : props.passedAsset.config.softwareType,
            vendor: typeof props.passedAsset.config.vendor === 'undefined' ? '' : props.passedAsset.config.vendor,
            productName: typeof props.passedAsset.config.productName === 'undefined' ? '' : props.passedAsset.config.productName,
            version: typeof props.passedAsset.config.version === 'undefined' ? '' : props.passedAsset.config.version,
            website: typeof props.passedAsset.config.website === 'undefined' ? '' : props.passedAsset.config.website,
            complexProcesses: typeof props.passedAsset.config.complexProcesses === 'undefined' ? null : props.passedAsset.config.complexProcesses,
            authentication: typeof props.passedAsset.config.authentication === 'undefined' ? null : props.passedAsset.config.authentication,
            roles: typeof props.passedAsset.config.roles === 'undefined' ? '' : props.passedAsset.config.roles,
            authenticationMethod: typeof props.passedAsset.config.authenticationMethod === 'undefined' ? [] : props.passedAsset.config.authenticationMethod,
            multiUser: typeof props.passedAsset.config.multiUser === 'undefined' ? null : props.passedAsset.config.multiUser,
            programmingLanguageList: typeof props.passedAsset.config.programmingLanguageList === 'undefined' ? [] : props.passedAsset.config.programmingLanguageList,
            programmingLanguageIndividualList: typeof props.passedAsset.config.programmingLanguageIndividualList === 'undefined' ? [] : props.passedAsset.config.programmingLanguageIndividualList,
            frameworkList: typeof props.passedAsset.config.frameworkList === 'undefined' ? [] : props.passedAsset.config.frameworkList,
            frameworkIndividualList: typeof props.passedAsset.config.frameworkIndividualList === 'undefined' ? [] : props.passedAsset.config.frameworkIndividualList,
            extensionList: typeof props.passedAsset.config.extensionList === 'undefined' ? [] : props.passedAsset.config.extensionList,
            reachability: typeof props.passedAsset.config.reachability === 'undefined' ? null : props.passedAsset.config.reachability,
            operation: typeof props.passedAsset.config.operation === 'undefined' ? '' : props.passedAsset.config.operation,
            operatorList: typeof props.passedAsset.config.operatorList === 'undefined' ? [] : props.passedAsset.config.operatorList,
            cloud: typeof props.passedAsset.config.cloud === 'undefined' ? null : props.passedAsset.config.cloud,
            cloudProviderList: typeof props.passedAsset.config.cloudProviderList === 'undefined' ? [] : props.passedAsset.config.cloudProviderList,
            cloudProviderIndividualList: typeof props.passedAsset.config.cloudProviderIndividualList === 'undefined' ? [] : props.passedAsset.config.cloudProviderIndividualList,
            securityAssessment: typeof props.passedAsset.config.securityAssessment === 'undefined' ? null : props.passedAsset.config.securityAssessment,
            securityAssessmentLevel: typeof props.passedAsset.config.securityAssessmentLevel === 'undefined' ? '' : props.passedAsset.config.securityAssessmentLevel
        })

        const { modal: assetNameModal, openModal: openAssetNameModal, closeModal: closeAssetNameModal } = useModal()
        const { modal: assetNameExistsModal, openModal: openAssetNameExistsModal, closeModal: closeAssetNameExistsModal } = useModal()
        const { modal: descriptionModal, openModal: openDescriptionModal, closeModal: closeDescriptionModal } = useModal()
        const { modal: typeModal, openModal: openTypeModal, closeModal: closeTypeModal } = useModal()
        const { modal: sizeModal, openModal: openSizeModal, closeModal: closeSizeModal } = useModal()
        const { modal: softwareTypeModal, openModal: openSoftwareTypeModal, closeModal: closeSoftwareTypeModal } = useModal()
        const { modal: complexProcessesModal, openModal: openComplexProcessesModal, closeModal: closeComplexProcessesModal } = useModal()
        const { modal: authenticationModal, openModal: openAuthenticationModal, closeModal: closeAuthenticationModal } = useModal()
        const { modal: rolesModal, openModal: openRolesModal, closeModal: closeRolesModal } = useModal()
        const { modal: authenticationMethodModal, openModal: openAuthenticationMethodModal, closeModal: closeAuthenticationMethodModal } = useModal()
        const { modal: multiUserModal, openModal: openMultiUserModal, closeModal: closeMultiUserModal } = useModal()
        const { modal: programmingLanguageModal, openModal: openProgrammingLanguageModal, closeModal: closeProgrammingLanguageModal } = useModal()
        const { modal: frameworkModal, openModal: openFrameworkModal, closeModal: closeFrameworkModal } = useModal()
        const { modal: extensionsModal, openModal: openExtensionsModal, closeModal: closeExtensionsModal } = useModal()
        const { modal: reachabilityModal, openModal: openReachabilityModal, closeModal: closeReachabilityModal } = useModal()
        const { modal: cloudProviderModal, openModal: openCloudProviderModal, closeModal: closeCloudProviderModal } = useModal()
        const { modal: securityAssessmentModal, openModal: openSecurityAssessmentModal, closeModal: closeSecurityAssessmentModal } = useModal()
        const { modal: securityAssessmentLevelModal, openModal: openSecurityAssessmentLevelModal, closeModal: closeSecurityAssessmentLevelModal } = useModal()
        const { modal: confirmationCreateModal, openModal: openConfirmationCreateModal, closeModal: closeConfirmationCreateModal } = useModal()
        const { modal: confirmationEditModal, openModal: openConfirmationEditModal, closeModal: closeConfirmationEditModal } = useModal()

        const typeOptions = [
            {
                id: 'website',
                label: 'Einfache Webseite'
            },
            {
                id: 'webapp',
                label: 'Komplexe Webanwendung'
            }
        ]

        const sizeOptions = [
            {
                id: 'small',
                label: 'Klein<br>(< 20 Funktionen)'
            },
            {
                id: 'medium',
                label: 'Mittel<br>(20-40 Funktionen)'
            },
            {
                id: 'large',
                label: 'Groß<br>(41-60 Funktionen)'
            },
            {
                id: 'very large',
                label: 'Sehr groß<br>(> 60 Funktionen)'
            }
        ]

        const companyRoleOptions = [
            {
                id: 'consumer',
                label: 'Fremdentwicklung'
            },
            {
                id: 'vendor',
                label: 'Eigenentwicklung'
            }
        ]

        const softwareTypeOptions = [
            {
                id: 'inhouse',
                label: 'Individualentwicklung'
            },
            {
                id: 'standard',
                label: 'Standardsoftware'
            },
            {
                id: 'standard extended',
                label: 'Standardsoftware mit individuellen Erweiterungen'
            }
        ]

        const rolesOptions = [
            {
                id: '1',
                label: '1 Rolle'
            },
            {
                id: '2',
                label: '2 Rollen'
            },
            {
                id: '2+',
                label: 'mehr als 2 Rollen'
            }
        ]

        const authenticationMethodOptions = [
            {
                id: 'knowledge',
                label: 'Wissen'
            },
            {
                id: 'biometric',
                label: 'Biometrie'
            },
            {
                id: 'hardware',
                label: 'Besitz'
            }
        ]

        const programmingLanguageOptions = [
            {
                id: 'js',
                label: 'JavaScript'
            },
            {
                id: 'java',
                label: 'Java'
            },
            {
                id: 'python',
                label: 'Python'
            },
            {
                id: 'c#',
                label: 'C#'
            },
            {
                id: 'ruby',
                label: 'Ruby'
            },
            {
                id: 'individual',
                label: 'Sonstige'
            }
        ]

        const frameworkOptions = [
            {
                id: 'aspnet',
                label: 'ASP.Net'
            },
            {
                id: 'angular',
                label: 'AngularJS'
            },
            {
                id: 'vue',
                label: 'Vue'
            },
            {
                id: 'meteor',
                label: 'Meteor'
            },
            {
                id: 'django',
                label: 'Django'
            },
            {
                id: 'flash',
                label: 'Flash'
            },
            {
                id: 'gwt',
                label: 'Google Web Toolkit'
            },
            {
                id: 'react',
                label: 'React'
            },
            {
                id: 'struts',
                label: 'Struts'
            },
            {
                id: 'ruby on rails',
                label: 'Ruby on Rails'
            },
            {
                id: 'vaadin',
                label: 'Vaadin'
            },
            {
                id: 'individual',
                label: 'Sonstige'
            }
        ]

        const operationOptions = [
            {
                id: 'customer',
                label: 'Durch uns selbst'
            },
            {
                id: 'external',
                label: 'Durch einen Dienstleister'
            },
            {
                id: 'mixed',
                label: 'Gemeinsam mit einem Dienstleister'
            }
        ]

        const cloudProviderOptions = [
            {
                id: 'gcp',
                label: 'Google Cloud Platform (GCP)'
            },
            {
                id: 'aws',
                label: 'Amazon Web Services (AWS)'
            },
            {
                id: 'azure',
                label: 'Microsoft Azure'
            },
            {
                id: 'individual',
                label: 'Andere'
            }
        ]

        const securityAssessmentLevelOptions = [
            {
                id: 'low',
                label: 'Niedrig'
            },
            {
                id: 'normal',
                label: 'Normal'
            },
            {
                id: 'high',
                label: 'Hoch'
            },
            {
                id: 'very high',
                label: 'Sehr hoch'
            }
        ]

        const numberAdditionalProgrammingLanguages = ref<number>(0)
        const numberAdditionalFrameworks = ref<number>(0)
        const numberAdditionalExtensions = ref<number>(0)
        const numberAdditionalOperators = ref<number>(0)
        const numberAdditionalCloudProviders = ref<number>(0)

        const checkProgrammingLanguageListLength = () => {
            if (webapp.programmingLanguageIndividualList.length !== 0) {
                numberAdditionalProgrammingLanguages.value = webapp.programmingLanguageIndividualList.length - 1
            }
        }

        const checkFrameworkListLength = () => {
            if (webapp.frameworkIndividualList.length !== 0) {
                numberAdditionalFrameworks.value = webapp.frameworkIndividualList.length - 1
            }
        }

        const checkExtensionListLength = () => {
            if (webapp.extensionList.length !== 0) {
                numberAdditionalExtensions.value = webapp.extensionList.length - 1
            }
        }

        const checkOperatorListLength = () => {
            if (webapp.operatorList.length !== 0) {
                numberAdditionalOperators.value = webapp.operatorList.length - 1
            }
        }

        const checkCloudProviderIndividualListLength = () => {
            if (webapp.cloudProviderIndividualList.length !== 0) {
                numberAdditionalCloudProviders.value = webapp.cloudProviderIndividualList.length - 1
            }
        }

        const removeProgrammingLanguageFromList = (index: number) => {
            webapp.programmingLanguageIndividualList.splice(index, 1)
            numberAdditionalProgrammingLanguages.value--
        }

        const removeFrameworkFromList = (index: number) => {
            webapp.frameworkIndividualList.splice(index, 1)
            numberAdditionalFrameworks.value--
        }

        const removeExtensionFromList = (index: number) => {
            webapp.extensionList.splice(index, 1)
            numberAdditionalExtensions.value--
        }

        const removeOperatorFromList = (index: number) => {
            webapp.operatorList.splice(index, 1)
            numberAdditionalOperators.value--
        }

        const removeCloudProviderFromList = (index: number) => {
            webapp.cloudProviderIndividualList.splice(index, 1)
            numberAdditionalCloudProviders.value--
        }

        const addProgrammingLanguage = () => {
            numberAdditionalProgrammingLanguages.value++
        }

        const addFramework = () => {
            numberAdditionalFrameworks.value++
        }

        const addExtension = () => {
            numberAdditionalExtensions.value++
        }

        const addOperator = () => {
            numberAdditionalOperators.value++
        }

        const addCloudProvider = () => {
            numberAdditionalCloudProviders.value++
        }

        onMounted(() => {
            checkProgrammingLanguageListLength()
            checkFrameworkListLength()
            checkExtensionListLength()
            checkOperatorListLength()
            checkCloudProviderIndividualListLength()
        })

        const markAssetDone = () => {
            props.passedAsset.config = webapp
            emit('webappAssetDone', props.passedAsset)
        }

        const completeAsset = async() => {
            if (await checkAdvance()) {
                markAssetDone()
            }
        }

        const checkClearValuesType = () => {
            if (webapp.type === 'website') {
                webapp.size = ''
                webapp.softwareType = ''
                webapp.vendor = ''
                webapp.productName = ''
                webapp.version = ''
                webapp.website = ''
                webapp.complexProcesses = null
                webapp.authentication = null
                webapp.roles = ''
                webapp.authenticationMethod = []
            }
        }

        const checkClearValuesSoftwareTypeVendor = () => {
            if (webapp.companyRole === 'vendor') {
                webapp.softwareType = ''
                webapp.vendor = ''
                webapp.productName = ''
                webapp.version = ''
                webapp.website = ''
            }
        }

        const checkClearValuesVendor = () => {
            if (webapp.softwareType === 'inhouse') {
                webapp.vendor = ''
                webapp.productName = ''
                webapp.version = ''
                webapp.website = ''
            }
        }

        const checkClearValuesRolesAuthenticationMethod = () => {
            if (webapp.authentication !== true) {
                webapp.roles = ''
                webapp.authenticationMethod = []
            }
        }

        const checkClearValueProgrammingLanguageIndividual = () => {
            if (!webapp.programmingLanguageList.includes('individual')) {
                webapp.programmingLanguageIndividualList = []
            }
        }

        const checkClearValueFrameworkIndividual = () => {
            if (!webapp.frameworkList.includes('individual')) {
                webapp.frameworkIndividualList = []
            }
        }

        const checkClearValueOperator = () => {
            if (webapp.operation === 'customer') {
                webapp.operatorList = []
            }
        }

        const checkClearValueCloudProviderCloudProviderIndividual = () => {
            if (webapp.cloud !== true) {
                webapp.cloudProviderList = []
                webapp.cloudProviderIndividualList = []
            }
        }

        const checkClearValueCloudProviderIndividual = () => {
            if (!webapp.cloudProviderList.includes('individual')) {
                webapp.cloudProviderIndividual = []
            }
        }

        const checkClearValueSecurityAssessmentLevel = () => {
            if (webapp.securityAssessment !== true) {
                webapp.securityAssessmentLevel = ''
            }
        }

        const nameUnique = ref<boolean>(true)
        const checkAssetNameUnique = () => {
            nameUnique.value = true
            const assetList = assetStore.getAll.value
            assetList.forEach((asset: any) => {
                if (currentAsset.value.name === asset.name && !route.value.path.includes('/edit')) {
                    nameUnique.value = false
                    openAssetNameExistsModal()
                }
            })
        }

        const acceptAssetNameExists = async() => {
            nameUnique.value = true
            closeAssetNameExistsModal()
            if (await validateFirstStepWithoutAssetNameUnique()) {
                window.scrollTo(0, 0)
                currentStep.value++
            }
        }

        const checkAdvance = async() => {
            if (currentStep.value === 0) {
                if (await validateFirstStep()) {
                    return true
                }
            } else if (currentStep.value === 1) {
                if (await validateSecondStep()) {
                    return true
                }
            } else if (currentStep.value === 2) {
                if (await validateThirdStep()) {
                    return true
                }
            } else if (currentStep.value === 3) {
                if (webapp.type === 'website') {
                    if (await validateFifthStep()) {
                        return true
                    }
                } else if (webapp.type === 'webapp') {
                    if (await validateFourthStep()) {
                        return true
                    }
                }
            } else if (currentStep.value === 4) {
                return true
            } else if (currentStep.value === 5) {
                if (await validateFifthStep()) {
                    return true
                }
            } else if (currentStep.value === 6) {
                return true
            }
            return false
        }

        const advance = async() => {
            if (await checkAdvance()) {
                if (currentStep.value < 6) {
                    window.scrollTo(0, 0)
                    currentStep.value++
                }
            }
        }

        const back = () => {
            if (currentStep.value > 0) {
                currentStep.value--
            } else {
                assetStore.clearAsset()
                router.go(-1)
            }
        }

        const reset = () => {
            currentStep.value = 0
        }

        const nameError = ref<string>('')
        const descriptionError = ref<string>('')
        const typeError = ref<string>('')
        const sizeError = ref<string>('')
        const complexProcessesError = ref<string>('')
        const authenticationError = ref<string>('')
        const rolesError = ref<string>('')
        const multiUserError = ref<string>('')
        const reachabilityError = ref<string>('')

        watch(() => currentAsset.value.name, (value) => {
            nameError.value = RequiredValidator(value)
        })

        watch(() => currentAsset.value.description, (value) => {
            descriptionError.value = RequiredValidator(value)
        })

        watch(() => webapp.type, (value) => {
            typeError.value = RequiredValidator(value)
        })

        watch(() => webapp.size, (value) => {
            sizeError.value = RequiredValidator(value)
        })

        watch(() => webapp.complexProcesses, (value) => {
            complexProcessesError.value = RequiredValidatorBoolean(value)
        })

        watch(() => webapp.authentication, (value) => {
            authenticationError.value = RequiredValidatorBoolean(value)
        })

        watch(() => webapp.roles, (value) => {
            rolesError.value = RequiredValidator(value)
        })

        watch(() => webapp.multiUser, (value) => {
            multiUserError.value = RequiredValidatorBoolean(value)
        })

        watch(() => webapp.reachability, (value) => {
            reachabilityError.value = RequiredValidatorBoolean(value)
        })

        const validateFirstStep = async() => {
            nameError.value = RequiredValidator(currentAsset.value.name)
            descriptionError.value = RequiredValidator(currentAsset.value.description)
            if (nameError.value === '' &&
                descriptionError.value === ''
            ) {
                checkAssetNameUnique()
                if (nameUnique.value) {
                    return true
                }
            } else {
                await nextTick()
                const element = document.querySelector('.invalid-feedback')?.parentNode?.parentNode as HTMLElement
                element.scrollIntoView({behavior: 'smooth'})
            }
        }

        const validateFirstStepWithoutAssetNameUnique = async() => {
            nameError.value = RequiredValidator(currentAsset.value.name)
            descriptionError.value = RequiredValidator(currentAsset.value.description)
            if (nameError.value === '' &&
                descriptionError.value === ''
            ) {
                return true
            } else {
                await nextTick()
                const element = document.querySelector('.invalid-feedback')?.parentNode?.parentNode as HTMLElement
                element.scrollIntoView({behavior: 'smooth'})
            }
        }

        const validateSecondStep = async() => {
            typeError.value = RequiredValidator(webapp.type)
            if (typeError.value === '') {
                return true
            } else {
                await nextTick()
                const element = document.querySelector('.invalid-feedback')?.parentNode?.parentNode as HTMLElement
                element.scrollIntoView({behavior: 'smooth'})
            }
        }

        const validateThirdStep = async() => {
            if (webapp.type === 'webapp') {
                sizeError.value = RequiredValidator(webapp.size)
                complexProcessesError.value = RequiredValidatorBoolean(webapp.complexProcesses)
            }
            if (webapp.type === 'website' ||
                (webapp.type === 'webapp' &&
                    sizeError.value === '' &&
                    complexProcessesError.value === ''
                )
            ) {
                return true
            } else {
                await nextTick()
                const element = document.querySelector('.invalid-feedback')?.parentNode?.parentNode as HTMLElement
                element.scrollIntoView({behavior: 'smooth'})
            }
        }

        const validateFourthStep = async() => {
            if (webapp.type === 'webapp') {
                authenticationError.value = RequiredValidatorBoolean(webapp.authentication)
                if (webapp.authentication) {
                    rolesError.value = RequiredValidator(webapp.roles)
                }
                multiUserError.value = RequiredValidatorBoolean(webapp.multiUser)
            }
            if (webapp.type === 'website' ||
                (webapp.type === 'webapp' &&
                    authenticationError.value === '' &&
                    (rolesError.value === '' || webapp.authentication === false) &&
                    multiUserError.value === ''
                )
            ) {
                return true
            } else {
                await nextTick()
                const element = document.querySelector('.invalid-feedback')?.parentNode?.parentNode as HTMLElement
                element.scrollIntoView({behavior: 'smooth'})
            }
        }

        const validateFifthStep = async() => {
            reachabilityError.value = RequiredValidatorBoolean(webapp.reachability)
            if (reachabilityError.value === '') {
                return true
            } else {
                await nextTick()
                const element = document.querySelector('.invalid-feedback')?.parentNode?.parentNode as HTMLElement
                element.scrollIntoView({behavior: 'smooth'})
            }
        }


        const gotoAssetList = () => {
            if (!route.value.path.includes('/edit')) {
                const assetStore = useAssetStore()
                assetStore.clearAsset()
            }
            window.scrollTo(0, 0)
            router.replace({ name: 'asset-based project', params: { id: projectId.value } })
        }

        return {
            route,
            currentAsset,
            currentStep,
            webapp,
            acceptAssetNameExists,
            numberAdditionalProgrammingLanguages,
            removeProgrammingLanguageFromList,
            addProgrammingLanguage,
            numberAdditionalFrameworks,
            removeFrameworkFromList,
            addFramework,
            numberAdditionalExtensions,
            removeExtensionFromList,
            addExtension,
            numberAdditionalOperators,
            removeOperatorFromList,
            addOperator,
            numberAdditionalCloudProviders,
            removeCloudProviderFromList,
            addCloudProvider,
            assetNameModal,
            openAssetNameModal,
            closeAssetNameModal,
            assetNameExistsModal,
            openAssetNameExistsModal,
            closeAssetNameExistsModal,
            descriptionModal,
            openDescriptionModal,
            closeDescriptionModal,
            typeModal,
            openTypeModal,
            closeTypeModal,
            sizeModal,
            openSizeModal,
            closeSizeModal,
            softwareTypeModal,
            openSoftwareTypeModal,
            closeSoftwareTypeModal,
            complexProcessesModal,
            openComplexProcessesModal,
            closeComplexProcessesModal,
            authenticationModal,
            openAuthenticationModal,
            closeAuthenticationModal,
            rolesModal,
            openRolesModal,
            closeRolesModal,
            authenticationMethodModal,
            openAuthenticationMethodModal,
            closeAuthenticationMethodModal,
            multiUserModal,
            openMultiUserModal,
            closeMultiUserModal,
            programmingLanguageModal,
            openProgrammingLanguageModal,
            closeProgrammingLanguageModal,
            frameworkModal,
            openFrameworkModal,
            closeFrameworkModal,
            extensionsModal,
            openExtensionsModal,
            closeExtensionsModal,
            reachabilityModal,
            openReachabilityModal,
            closeReachabilityModal,
            cloudProviderModal,
            openCloudProviderModal,
            closeCloudProviderModal,
            securityAssessmentModal,
            openSecurityAssessmentModal,
            closeSecurityAssessmentModal,
            securityAssessmentLevelModal,
            openSecurityAssessmentLevelModal,
            closeSecurityAssessmentLevelModal,
            confirmationCreateModal,
            openConfirmationCreateModal,
            closeConfirmationCreateModal,
            confirmationEditModal,
            openConfirmationEditModal,
            closeConfirmationEditModal,
            typeOptions,
            sizeOptions,
            companyRoleOptions,
            softwareTypeOptions,
            rolesOptions,
            authenticationMethodOptions,
            programmingLanguageOptions,
            frameworkOptions,
            operationOptions,
            cloudProviderOptions,
            securityAssessmentLevelOptions,
            checkClearValuesType,
            checkClearValuesSoftwareTypeVendor,
            checkClearValuesVendor,
            checkClearValuesRolesAuthenticationMethod,
            checkClearValueProgrammingLanguageIndividual,
            checkClearValueFrameworkIndividual,
            checkClearValueOperator,
            checkClearValueCloudProviderCloudProviderIndividual,
            checkClearValueCloudProviderIndividual,
            checkClearValueSecurityAssessmentLevel,
            nameError,
            descriptionError,
            typeError,
            sizeError,
            complexProcessesError,
            authenticationError,
            rolesError,
            multiUserError,
            reachabilityError,
            completeAsset,
            advance,
            back,
            reset,
            gotoAssetList
        }
    }
})
</script>

<style lang="scss" scoped>
@import "../../styles/breakpoints";

.webapp-asset {
    h2 {
        color: var(--color-brand-primary);
    }

    .asset-question {
        @include default-padding();
    }

    .asset-question-white {
        background-color: var(--color-white);
    }

    .additional-field {
        display: flex;
    }

    .add-button {
        margin: 2em 0 0 -0.5em;
    }
}
</style>
