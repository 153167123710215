<template>
    <div class="project-optional">
        <ConfiguratorHeader />

        <Progressbar :active="4" />

        <FinalPresentation />

        <AuditReport v-if="projectType !== 'scenario'"/>

        <LiveDemo :class="{'inverted-background': projectType === 'scenario'}"/>

        <RemediationSupport v-if="projectType !== 'contingent'" :class="{'inverted-background': projectType === 'scenario'}"/>

        <Retesting :class="{'inverted-background': projectType !== 'asset'}"/>

        <div class="buttons">
            <button class="ghost-primary configurator-button" @click="$router.go(-1)">Zurück</button>
            <button class="primary configurator-button" @click="gotoProjectSummary">Weiter</button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue'
import { useRouter } from '@/composables/use-router'
import { useProjectStore } from '@/store/projectStore'
import { useAssetStore } from '@/store/assetStore'
import { useContingentStore } from '@/store/contingentStore'
import { useScenarioStore } from '@/store/scenarioStore'
import { useScopeStore } from '@/store/scopeStore'
import { useOptionalStore } from '@/store/optionalStore'
import { usePriceStore } from '@/store/priceStore'
import ConfiguratorHeader from '@/components/ConfiguratorHeader.vue'
import Progressbar from '@/components/Progressbar.vue'
import FinalPresentation from '@/components/optional/FinalPresentation.vue'
import AuditReport from '@/components/optional/AuditReport.vue'
import LiveDemo from '@/components/optional/LiveDemo.vue'
import RemediationSupport from '@/components/optional/RemediationSupport.vue'
import Retesting from '@/components/optional/Retesting.vue'

export default defineComponent({
    name: 'ProjectOptional',

    components: {
        ConfiguratorHeader,
        Progressbar,
        FinalPresentation,
        AuditReport,
        LiveDemo,
        RemediationSupport,
        Retesting
    },

    setup() {
        const { router, route } = useRouter()

        const projectStore = useProjectStore()
        const projectId = projectStore.uuid
        const projectType = projectStore.type

        const assetStore = useAssetStore()
        const contingentStore = useContingentStore()
        const scenarioStore = useScenarioStore()

        const optionalStore = useOptionalStore()

        onMounted(async() => {
            if (projectId.value === '') {
                const scopeStore = useScopeStore()
                await projectStore.loadProject(route.value.params.id)
                if (projectType.value === 'asset') {
                    await assetStore.updateAssetList(route.value.params.id)
                    await calculateAssetPrice()
                } else if (projectType.value === 'contingent') {
                    await contingentStore.loadContingent(route.value.params.id)
                    await calculateContingentPrice()
                } else if (projectType.value === 'scenario') {
                    await scenarioStore.updateSelectedScenarioList(route.value.params.id)
                    await calculateScenarioPrice()
                }
                await scopeStore.loadScope(route.value.params.id)
                await priceStore.fetchScopePrice(route.value.params.id)
                await optionalStore.loadOptionals(route.value.params.id)
                await priceStore.fetchOptionalsPrices(route.value.params.id)
            }
        })

        const priceStore = usePriceStore()
        const calculateScenarioPrice = async() => {
            await priceStore.calculateScenarioPrice(projectId.value)
            await priceStore.fetchProjectPrice(projectId.value)
        }
        const calculateAssetPrice = async() => {
            await priceStore.calculateAssetPrice(projectId.value)
            await priceStore.fetchProjectPrice(projectId.value)
        }
        const calculateContingentPrice = async() => {
            await priceStore.fetchContingentPrice(projectId.value)
            await priceStore.fetchProjectPrice(projectId.value)
        }

        const gotoProjectSummary = async() => {
            await optionalStore.updateOptionals(projectId.value)
            await priceStore.fetchOptionalsPrices(projectId.value)
            if (projectType.value === 'asset') {
                await calculateAssetPrice()
            } else if (projectType.value === 'scenario') {
                await scenarioStore.updateSelectedScenarioList(projectId.value)
                await calculateScenarioPrice()
            } else {
                await priceStore.fetchProjectPrice(projectId.value)
            }
            window.scrollTo(0, 0)
            await router.push({ name: 'project summary', params: { id: projectId.value } })
        }

        return {
            projectType,
            gotoProjectSummary
        }
    }
})
</script>

<style lang="scss" scoped>
@import "../../styles/breakpoints";

.project-optional {
    text-align: left;
}

.final-presentation-optional, .live-demo-optional, .retesting-optional {
    background-color: var(--color-lightest-grey);

    &.inverted-background {
        background-color: var(--color-white);
    }
}

.audit-report-optional, .remediation-support-optional {
    background-color: var(--color-white);

    &.inverted-background {
        background-color: var(--color-lightest-grey);
    }
}

@media screen and (max-width: 560px) {
    .buttons {
        display: flex;
        flex-direction: column;
    }
}
</style>
