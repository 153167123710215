<template>
    <div class='scope-timing'>
        <div class="scope-header">
            <h2>Arbeitszeiten *</h2>

            <p>
                Ist es erforderlich den Pentest außerhalb der regulären Geschäftszeiten (montags bis freitags zwischen
                8.00 und 18.00 Uhr, keine bundesweiten Feiertage) durchzuführen?
            </p>
        </div>

        <tristate-switch-field id="timing-radio" v-model="timing" @input="updateTiming()" :error="timingError" :disabled="processing" disable-null label="" label-pre="Ja" label-post="Nein"/>

        <div v-if="timing && projectType !== 'contingent'" class="timing-list-container">
            <div v-if="processing" class="processing">
                <div class="processing-spinner">
                </div>
            </div>
            <div class="timing-list">
                <p>
                    Bitte konkretisieren Sie welche Prüfungen wann erfolgen sollen.
                </p>

                <div v-if="projectType === 'asset'">
                    <table class="asset-table">
                        <tr class="table-header-row">
                            <th class="table-header-name">Asset-Name</th>
                            <th class="table-header-type">Asset-Typ</th>
                            <th class="table-header-option">
                                <span class="timing-header-option">reguläre usd Geschäftszeiten</span>
                                <span class="timing-header-option">Nachtarbeit</span>
                                <span class="timing-header-option">Wochenende/Feiertage</span>
                            </th>
                        </tr>

                        <tr v-for="asset in assetList" :key="asset.id" class="table-row">
                            <td>{{ asset.name }}</td>
                            <td v-if="asset.type === 'system'">
                                IT-System(e)
                            </td>
                            <td v-else-if="asset.type === 'webapp'">
                                Webapplikation
                            </td>
                            <td v-else-if="asset.type === 'webservice'">
                                Webservices und APIs
                            </td>
                            <td v-else-if="asset.type === 'mobile'">
                                Mobile Apps
                            </td>
                            <td v-else-if="asset.type === 'fatclient'">
                                Fat Client
                            </td>
                            <td>
                                <label>
                                    <input
                                        type="radio"
                                        class="timing-select"
                                        :id="asset.id  + '-timing'"
                                        :name="asset.name + '-timing'"
                                        value="default"
                                        v-model="asset.timing"
                                        @input="updateAssetTiming(asset.id, 'default')"
                                        :disabled="processing"
                                    >
                                    <i class="material-icon mdi" :class="[ asset.timing === 'default' ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' ]" />
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        class="timing-select"
                                        :id="asset.id  + '-timing'"
                                        :name="asset.name + '-timing'"
                                        value="nights"
                                        v-model="asset.timing"
                                        @input="updateAssetTiming(asset.id, 'nights')"
                                        :disabled="processing"
                                    >
                                    <i class="material-icon mdi" :class="[ asset.timing === 'nights' ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' ]" />
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        class="timing-select"
                                        :id="asset.id  + '-timing'"
                                        :name="asset.name + '-timing'"
                                        value="weekends"
                                        v-model="asset.timing"
                                        @input="updateAssetTiming(asset.id, 'weekends')"
                                        :disabled="processing"
                                    >
                                    <i class="material-icon mdi" :class="[ asset.timing === 'weekends' ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' ]" />
                                </label>
                            </td>
                        </tr>
                    </table>
                </div>

                <div v-else-if="projectType === 'scenario'">
                    <table class="scenario-table">
                        <tr class="table-header-row">
                            <th class="table-header-type">Szenario-Bezeichnung</th>
                            <th class="table-header-option">
                                <span class="timing-header-option">reguläre usd Geschäftszeiten</span>
                                <span class="timing-header-option">Nachtarbeit</span>
                                <span class="timing-header-optiono">Wochenende/Feiertage</span>
                            </th>
                        </tr>

                        <tbody v-for="scenario in scenarioList" :key="scenario.name">
                            <tr class="table-row">
                                <td v-if="scenario.name === 'compromisedWebsite'">
                                    Website Defacement
                                </td>
                                <td v-else-if="scenario.name === 'compromisedShop'">
                                    Cyber-Angriff auf Online-Shop
                                </td>
                                <td v-else-if="scenario.name === 'localPrivilegeEscalation'">
                                    Lokale Rechteausweitung
                                </td>
<!--                                <td v-else-if="scenario.name === 'homeofficeAttack'">-->
<!--                                    Homeoffice Angriff-->
<!--                                </td>-->
                                <td v-else-if="scenario.name === 'compromisedNetwork'">
                                    Netzwerk Angriff
                                </td>
                                <!--                                <td v-else-if="scenario.name === 'activeDirectory'">-->
                                <!--                                    Active Directory-->
                                <!--                                </td>-->
                                <td v-else-if="scenario.name === 'externalAttack'">
                                    Externer Angriff
                                </td>
                                <td v-else-if="scenario.name === 'externalEnumeration'">
                                    Externe Enumeration
                                </td>
                                <td v-else-if="scenario.name === 'insiderAttack'">
                                    Insiderangriff
                                </td>
                                <!--                                <td v-else-if="scenario.name === 'kioskAttack'">-->
                                <!--                                    Kiosk-Angriff-->
                                <!--                                </td>-->
                                <td v-else-if="scenario.name === 'stolenLaptop'">
                                    Gestohlener Laptop
                                </td>
<!--                                <td v-else-if="scenario.name === 'informationLeak'">-->
<!--                                    Leaked Information-->
<!--                                </td>-->
<!--                                <td v-else-if="scenario.name === 'maliciousCleaner'">-->
<!--                                    Böswillige Reinigungsfachkraft-->
<!--                                </td>-->
                                <td v-else-if="scenario.name === 'ransomware'">
                                    Ransomware Angriff
                                </td>
                                <td v-else-if="scenario.name === 'phishing'">
                                    Phishing Angriff
                                </td>
                                <td v-else-if="scenario.name === 'cloudSecurity'">
                                    Cloud Angriff
                                </td>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="timing-select"
                                            :id="scenario.name  + '-timing'"
                                            :name="scenario.name + '-timing'"
                                            value="default"
                                            v-model="scenario.timing"
                                            @input="updateScenarioTiming(scenario.name, 'default')"
                                            :disabled="processing"
                                        >
                                        <i class="material-icon mdi" :class="[ scenario.timing === 'default' ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' ]" />
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="timing-select"
                                            :id="scenario.name  + '-timing'"
                                            :name="scenario.name + '-timing'"
                                            value="nights"
                                            v-model="scenario.timing"
                                            @input="updateScenarioTiming(scenario.name, 'nights')"
                                            :disabled="processing"
                                        >
                                        <i class="material-icon mdi" :class="[ scenario.timing === 'nights' ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' ]" />
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="timing-select"
                                            :id="scenario.name  + '-timing'"
                                            :name="scenario.name + '-timing'"
                                            value="weekends"
                                            v-model="scenario.timing"
                                            @input="updateScenarioTiming(scenario.name, 'weekends')"
                                            :disabled="processing"
                                        >
                                        <i class="material-icon mdi" :class="[ scenario.timing === 'weekends' ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' ]" />
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="buttons-info">
            <button class="button-info" @click="openTimingModal">Weitere Infos</button>
        </div>

        <modal class="info-modal" v-model="timingModal">
            <img slot="title" src="../../../../public/icon-info.svg" alt="icon info">
            <h2 slot="title">Arbeitszeiten</h2>

            <p>
                Für Einsätze, die auf Wunsch des Auftraggebers werktags (Montags bis Freitag) zwischen 18:00 und 8:00 Uhr stattfinden, werden 
                die Preise mit dem Faktor 1.5 multipliziert. An Samstagen, Sonn- und Feiertagen entspricht der Faktor 2.0.
            </p>

            <form-actions slot="footer">
                <button @click="closeTimingModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

    </div>
</template>

<script lang="ts">
import { defineComponent, nextTick, ref, watch } from 'vue'
import { useModal } from '@/composables/use-modal'
import { useScopeStore } from '@/store/scopeStore'
import { useProjectStore } from '@/store/projectStore'
import { useAssetStore } from '@/store/assetStore'
import { useScenarioStore } from '@/store/scenarioStore'
import { usePriceStore } from '@/store/priceStore'
import BoxSelectRadio from '@/components/BoxSelectRadio.vue'
import { RequiredValidatorBoolean } from '@/validator/validator'

export default defineComponent({
    name: 'ScopeTiming',

    components: {
        BoxSelectRadio
    },

    setup() {
        const { modal: timingModal, openModal: openTimingModal, closeModal: closeTimingModal } = useModal()

        const processing = ref<Boolean>(false)

        const projectStore = useProjectStore()
        const projectId = projectStore.uuid
        const projectType = projectStore.type

        const scopeStore = useScopeStore()
        const timing = scopeStore.timing

        const assetStore = useAssetStore()
        const assetList = assetStore.getAll

        const scenarioStore = useScenarioStore()
        scenarioStore.updateSelectedScenarioList(projectId.value)
        const scenarioList = scenarioStore.getSelectedScenarios

        const priceStore = usePriceStore()
        const calculateAssetPrice = async() => {
            await priceStore.calculateAssetPrice(projectId.value)
            await priceStore.fetchProjectPrice(projectId.value)
        }
        const calculateScenarioPrice = async() => {
            await priceStore.calculateScenarioPrice(projectId.value)
            await priceStore.fetchProjectPrice(projectId.value)
        }

        const timingError = ref<string>('')

        watch(() => timing.value, (value) => {
            timingError.value = RequiredValidatorBoolean(value)
        })

        const validateTiming = async() => {
            timingError.value = RequiredValidatorBoolean(timing.value)
            if (timingError.value === '') {
                return true
            } else {
                await nextTick()
                const element = document.querySelector('.invalid-feedback')?.parentNode?.parentNode as HTMLElement
                element.scrollIntoView({behavior: 'smooth'})
            }
        }

        const updateTiming = async() => {
            if (await validateTiming()) {
                if (!timing.value) {
                    processing.value = true
                    if (projectType.value === 'asset') {
                        assetList.value.forEach(async(asset: any) => {
                            await assetStore.updateAssetTiming(projectId.value, asset.assetId, 'default')
                        })
                        await assetStore.updateAssetList(projectId.value)
                        await calculateAssetPrice()
                    } else if (projectType.value === 'scenario') {
                        scenarioList.value.forEach(async(scenario: any) => {
                            await scenarioStore.updateScenarioTiming(projectId.value, scenario.name, 'default')
                        })
                        await scenarioStore.updateSelectedScenarioList(projectId.value)
                        await calculateScenarioPrice()
                    }
                    processing.value = false
                }
            }
        }

        const updateAssetTiming = async(assetId: string, timing: string) => {
            processing.value = true
            await assetStore.updateAssetTiming(projectId.value, assetId, timing)
            await assetStore.updateAssetList(projectId.value)
            await calculateAssetPrice()
            processing.value = false
        }

        const updateScenarioTiming = async(name: string, timing: string) => {
            processing.value = true
            await scenarioStore.updateScenarioTiming(projectId.value, name, timing)
            await scenarioStore.updateSelectedScenarioList(projectId.value)
            await calculateScenarioPrice()
            processing.value = false
        }

        return {
            processing,
            projectType,
            timing,
            assetList,
            scenarioList,
            updateTiming,
            updateAssetTiming,
            updateScenarioTiming,
            timingError,
            timingModal,
            openTimingModal,
            closeTimingModal
        }
    }
})
</script>

<style lang="scss" scoped>
@import "../../../styles/breakpoints";

.scope-timing {
    @include default-padding();
}

.scope-header {
    padding-bottom: 1.5%;
    text-align: left;
    h2 {
        color: var(--color-brand-primary);
    }
}

.timing-list-container {
    position: relative;
    margin-top: 1.5%;
    border: 1px solid var(--color-brand-secondary);
    background-color: var(--color-white);
}

.timing-list {
    padding: 1.5% 3.5%;
}

.timing-radio-options {
    display: flex;
    margin-top: 1.5%;
    border: 1px solid var(--color-black);
    background-color: var(--color-white);
}

.asset-table, .scenario-table {
    margin: 2% 0;
    width: 100%;
}

th {
    color: var(--color-brand-secondary);
}

.table-header-name {
    width: 20%;
}

.table-header-type {
    min-width: 30%;
}

.table-header-option {
    width: 50%;
    .timing-header-option {
        display: inline-block;
        text-align: center;
        width: 33%;
    }
}
</style>
