import { render, staticRenderFns } from "./ScopeSeparateEnvironment.vue?vue&type=template&id=459d66c3&scoped=true"
import script from "./ScopeSeparateEnvironment.vue?vue&type=script&lang=ts"
export * from "./ScopeSeparateEnvironment.vue?vue&type=script&lang=ts"
import style0 from "./ScopeSeparateEnvironment.vue?vue&type=style&index=0&id=459d66c3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "459d66c3",
  null
  
)

export default component.exports