import { ref, computed } from 'vue'
import Repository from '@/repository/Repository'

// Interface
interface ExistingScenarios {
    // compromisedWebsite: boolean,
    // compromisedShop: boolean,
    // localPrivilegeEscalation: boolean,
    // compromisedNetwork: boolean,
    // stolenLaptop: boolean,
    // externalEnumeration: boolean,
    // externalAttack: boolean,
    // insiderAttack: boolean,
    // ransomware: boolean,
    // phishing: boolean,
    // cloudSecurity: boolean
    [index: string]: boolean
}

// Store
const scenarios = ref<ExistingScenarios>({
    compromisedWebsite: false,
    compromisedShop: false,
    localPrivilegeEscalation: false,
    compromisedNetwork: false,
    stolenLaptop: false,
    externalEnumeration: false,
    externalAttack: false,
    insiderAttack: false,
    ransomware: false,
    phishing: false,
    cloudSecurity: false
})

const scenarioList = ref<Array<any>>([])

// Getters
const getters = {
    getScenarios: computed(() => scenarios.value),
    getSelectedScenarios: computed(() => scenarioList.value)
}

// Actions
const actions = {
    updateSelectedScenarioList: async(projectId: string) => {
        try {
            const params = { projectUuid: projectId }
            const response = await Repository.get('/scenario/list', { params })
            scenarioList.value = response.data
        } catch (error) {
            // console.log('Get all scenarios error: ' + error)
        }
    },
    checkScenarioExists: async(projectId: string, name: string) => {
        try {
            const params = {
                projectUuid: projectId,
                name: name
            }
            await Repository.get('/scenario', { params })
            return true
        } catch (error) {
            return false
        }
    },
    saveScenario: async(projectId: string, name: string) => {
        try {
            const data = {
                projectUuid: projectId,
                name: name
            }
            await Repository.post('/scenario', data)
        } catch (error) {
            // console.log('Save error: ' + error)
        }
    },
    updateScenarioTiming: async(projectId: string, name: string, timing: string) => {
        try {
            const data = {
                projectUuid: projectId,
                name: name,
                timing: timing }
            await Repository.post('/scenario/timing', data)
        } catch (error) {
            // console.log('Update timing error: ' + error)
        }
    },
    updateScenarioLocation: async(projectId: string, name: string, location: string) => {
        try {
            const data = {
                projectUuid: projectId,
                name: name,
                location: location
            }
            await Repository.post('/scenario/location', data)
        } catch (error) {
            // console.log('Update location error: ' + error)
        }
    },
    deleteScenario: async(projectId: string, name: string) => {
        try {
            const params = {
                projectUuid: projectId,
                name: name
            }
            await Repository.delete('/scenario', { params })
        } catch (error) {
            // console.log('Delete error: ' + error)
        }
    }
}

export const useScenarioStore = () => {
    return {
        ...getters,
        ...actions
    } as const
}
