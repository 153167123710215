<template>
    <div class="optional-summary">
        <expansion-panel closed v-if="finalPresentation || auditReport || liveDemo || remediationSupport || retest">
            <expansion-panel-content>
                <template v-slot:header>
                    <h3>
                        Zusatzleistungen
                    </h3>
                </template>
                <template>
                    <div class="summary-optional-container">
                        <div>
                            <p>
                                <strong>
                                    Nachfolgende Zusatzleistungen wurden zusätzlich zum Pentest gebucht:
                                </strong>
                            </p>

                            <ul>
                                <li v-if="finalPresentation">
                                    <p>
                                        Erstellung einer Management-Präsentation zur Vorstellung und Veranschaulichung der Pentest-Ergebnisse im Rahmen der Abschlussbesprechung.
                                    </p>
                                </li>

                                <li v-if="auditReport">
                                    <p>
                                        Erstellung des usd Prüfbericht, der alle durchgeführten Prüfungen bis ins Detail transparent und nachvollziehbar dokumentiert.
                                    </p>
                                </li>

                                <li v-if="liveDemo">
                                    <p>
                                        Live-Demo zur Ausnutzung der gefundenen Schwachstellen im Rahmen der Abschlussbesprechung.
                                    </p>
                                </li>

                                <li v-if="remediationSupport">
                                    <p v-if="projectType !=='contingent'">
                                        Unterstützung durch Sicherheitsexperten der usd bei der Korrektur identifizierter Schwachstellen.
                                    </p>
                                    <p v-else>
                                        Unterstützung durch Sicherheitsexperten der usd bei der Korrektur identifizierter Schwachstellen.
                                    </p>
                                </li>

                                <li v-if="retest">
                                    <p>
                                        Einmalige Nachprüfung der gefundenen Schwachstellen.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </template>
            </expansion-panel-content>
        </expansion-panel>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useProjectStore } from '@/store/projectStore'
import { useOptionalStore } from '@/store/optionalStore'
import ExpansionPanel from '@/components/ExpansionPanel.vue'
import ExpansionPanelContent from '@/components/ExpansionPanelContent.vue'

export default defineComponent({
    name: 'OptionalSummary',

    components: {
        ExpansionPanel,
        ExpansionPanelContent
    },

    setup() {
        const projectStore = useProjectStore()
        const projectType = projectStore.type

        const optionalStore = useOptionalStore()
        const finalPresentation = optionalStore.finalPresentation
        const auditReport = optionalStore.auditReport
        const remediationSupport = optionalStore.remediationSupport
        const liveDemo = optionalStore.liveDemo
        const retest = optionalStore.retest

        return {
            projectType,
            finalPresentation,
            auditReport,
            remediationSupport,
            liveDemo,
            retest
        }
    }
})
</script>

<style lang="scss" scoped>
.optional-summary {
    margin: 1em 0;

    h3 {
        color: var(--color-brand-secondary);
        font-size: 22px;
    }

    ul {
        margin-left: -1.5em;
    }
}
</style>
