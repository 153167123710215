<template>
    <div class='fatclient-asset'>

        <div v-if="currentStep === 0">
            <div class="asset-question">
                <h2>
                    Assetname *
                </h2>
                <p>
                    Bitte geben Sie einen Namen für das Asset ein.
                </p>

                <text-field id="asset-name" label="" v-model="currentAsset.name" :error="nameError"/>

                <div class="buttons-info">
                    <button class="button-info" @click="openAssetNameModal">Weitere Infos</button>
                </div>
            </div>

            <div class="asset-question asset-question-white">
                <h2>
                    Kurzbeschreibung *
                </h2>
                <p>
                    Bitte beschreiben Sie in wenigen Sätzen, wofür der von Ihnen angelegte Fat Client eingesetzt wird.
                </p>

                <text-area id="fatclient-description" label="" v-model="currentAsset.description" :error="descriptionError"/>

                <div class="buttons-info">
                    <button class="button-info" @click="openDescriptionModal">Weitere Infos</button>
                </div>
            </div>
        </div>

        <div v-if="currentStep === 1">
            <div class="asset-question">
                <h2>
                    Funktionsumfang *
                </h2>
                <p>
                    Wie groß ist der Fat Client, gemessen an dem bereitgestellten Funktionsumfang?
                </p>

                <radio-field label="" id="fatclient-size" v-model="fatclient.size" :options="sizeOptions" :error="sizeError" continuous/>

                <div class="buttons-info">
                    <button class="button-info" @click="openSizeModal">Weitere Infos</button>
                </div>
            </div>

            <div class="asset-question asset-question-white">
                <h2>
                    Eigen-/Fremdentwicklung <sup>(optional)</sup>
                </h2>
                <p>
                    Entwickeln Sie den Fat Client selbst oder beziehen Sie diesen von einem Unternehmen?
                </p>

                <BoxSelectRadio id="webapp-company-role" v-model="fatclient.companyRole" :options="companyRoleOptions" @input="checkClearValuesSoftwareTypeVendor"/>

                <div v-if="fatclient.companyRole === 'consumer'">
                    <hr>

                    <h2>
                        Softwaretyp <sup>(optional)</sup>
                    </h2>
                    <p>
                        Wählen Sie den für den Fat Client zutreffenden Softwaretyp aus
                    </p>

                    <BoxSelectRadio id="fatclient-software-type" v-model="fatclient.softwareType" :options="softwareTypeOptions" @input="checkClearValuesVendor"/>

                    <div class="buttons-info">
                        <button class="button-info" @click="openSoftwareTypeModal">Weitere Infos</button>
                    </div>

                    <div v-if="fatclient.softwareType === 'standard' || fatclient.softwareType === 'standard extended'">
                        <hr>

                        <h2>
                            Hersteller und Produktangaben <sup>(optional)</sup>
                        </h2>
                        <p>
                            Gerne dürfen Sie uns nachfolgend den Hersteller, den Produktnamen, die eingesetzte Version sowie
                            einen Link auf die Webseite des Produkts nennen.
                        </p>

                        <text-field id="fatclient-vendor" label="Hersteller:" v-model="fatclient.vendor" />
                        <text-field id="fatclient-product-name" label="Produktname:" v-model="fatclient.productName" />
                        <text-field id="fatclient-version" label="Version:" v-model="fatclient.version" />
                        <text-field id="fatclient-website" label="Webseite:" v-model="fatclient.website" />
                    </div>
                </div>
            </div>

            <div class="asset-question">
                <h2>
                    Geschützter Benutzerbereich *
                </h2>
                <p>
                    Verfügt der Fat Client über einen geschützten Benutzerbereich, der ausschließlich authentisiert,
                    also mit einem gültigen Benutzer, nach erfolgreicher Anmeldung, zugänglich ist und über den
                    sensible/vertrauliche Daten und diese verarbeitende Funktionen bereitgestellt werden?
                </p>

                <tristate-switch-field id="fatclient-authentication" v-model="fatclient.authentication" :error="authenticationError" label="" label-pre="Ja" label-post="Nein"/>

                <div class="buttons-info">
                    <button class="button-info" @click="openAuthenticationModal">Weitere Infos</button>
                </div>
            </div>
        </div>

        <div v-if="currentStep === 2">
            <div class="asset-question">
                <h2>
                    Betriebssystem *
                </h2>
                <p>
                    Auf welchem Betriebssystem wird der Fat Client bei Ihnen genutzt?
                </p>

                <BoxSelectCheckbox id="fatclient-operation-system" v-model="fatclient.operatingSystem" :options="operatingSystemOptions" :error="operatingSystemError"/>
            </div>

            <div class="asset-question asset-question-white">
                <h2>
                    Architektur *
                </h2>
                <p>
                    Bitte beschreiben Sie die Architektur des Fat Clients.
                </p>

                <BoxSelectRadio id="fatclient-architecture" v-model="fatclient.architecture" :options="architectureOptions" :error="architectureError"/>

                <div class="buttons-info">
                    <button class="button-info" @click="openArchitectureModal">Weitere Infos</button>
                </div>
            </div>

            <div class="asset-question">
                <h2>
                    Programmiersprache *
                </h2>
                <p>
                    Welche Programmiersprache kommt bei der Entwicklung des Fat Clients zum Einsatz?
                </p>

                <BoxSelectCheckbox id="fatclient-programming-language" v-model="fatclient.programmingLanguageList" :options="programmingLanguageOptions" @input="checkClearValueProgrammingLanguageIndividual" :error="programmingLanguageError"/>

                <div class="buttons-info">
                    <button class="button-info" @click="openProgrammingLanguageModal">Weitere Infos</button>
                </div>

                <div v-if="fatclient.programmingLanguageList.includes('individual')">
                    <hr>

                    <h2>
                        Sonstige Programmiersprachen *
                    </h2>
                    <p>
                        Bitte geben sie die verwendeten Programmiersprachen an.
                    </p>

                    <text-field id="webapp-programming-language-individual" label="" v-model="fatclient.programmingLanguageIndividualList[0]" :error="programmingLanguageIndividualError"/>

                    <div v-if="numberAdditionalProgrammingLanguages === 0" class="add-button">
                        <button class="ghost-primary" @click="addProgrammingLanguage()">Weitere Programmiersprache hinzufügen</button>
                    </div>

                    <div v-for="currentIndex in numberAdditionalProgrammingLanguages" :key="currentIndex">
                        <div class="additional-field">
                            <text-field :id="'webapp-programming-language-individual' + currentIndex" label="" v-model="fatclient.programmingLanguageIndividualList[currentIndex]" />
                            <div class="icon-remove">
                                <button class="icon-button" @click="removeProgrammingLanguageFromList(currentIndex)">
                                    <i class="material-icon mdi mdi-close" />
                                </button>
                            </div>
                        </div>

                        <div v-if="numberAdditionalProgrammingLanguages === currentIndex" class="add-button">
                            <button class="ghost-primary" @click="addProgrammingLanguage()">Weitere Programmiersprache hinzufügen</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="asset-question asset-question-white">
                <h2>
                    Code Obfuscation <sup>(optional)</sup>
                </h2>
                <p>
                    Wird Code Obfuscation als Schutzmechanismus gegen ungewünschte Reverse Engineering Versuche verwendet?
                </p>

                <tristate-switch-field id="fatclient-obfuscation" v-model="fatclient.obfuscation" label="" label-pre="Ja" label-post="Nein"/>

                <div class="buttons-info">
                    <button class="button-info" @click="openObfuscationModal">Weitere Infos</button>
                </div>
            </div>
        </div>

        <div v-if="currentStep === 3">
            <div class="asset-question">
                <h2>
                    Schutzbedarf <sup>(optional)</sup>
                </h2>
                <p>
                    Haben Sie den Schutzbedarf des Fat Clients ermittelt?
                </p>

                <tristate-switch-field id="fatclient-security-assessment" v-model="fatclient.securityAssessment" @input="checkClearValueSecurityAssessmentLevel" label="" label-pre="Ja" label-post="Nein"/>

                <div class="buttons-info">
                    <button class="button-info" @click="openSecurityAssessmentModal">Weitere Infos</button>
                </div>

                <div v-if="fatclient.securityAssessment">
                    <hr>

                    <h2>
                        Höhe des Schutzbedarfs <sup>(optional)</sup>
                    </h2>
                    <p>
                        Wie hoch ist der Schutzbedarf?
                    </p>

                    <radio-field label="" id="fatclient-security-assessment-level" v-model="fatclient.securityAssessmentLevel" :options="securityAssessmentLevelOptions" continuous/>

                    <div class="buttons-info">
                        <button class="button-info" @click="openSecurityAssessmentLevelModal">Weitere Infos</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="buttons">
            <div class="buttons-left">
                <button class="ghost-primary space-right" @click="back">Zurück</button>
            </div>
            <div class="buttons-center">
                <button v-if="route.path.includes('/edit')" class="ghost configurator-button" @click="openConfirmationEditModal()">Asset-Bearbeitung abbrechen</button>
                <button v-else class="ghost configurator-button" @click="openConfirmationCreateModal()">Asset-Erstellung abbrechen</button>
            </div>
            <div class="buttons-right">
                <button v-if="currentStep === 3 && !route.path.includes('/edit')" class="primary" @click="completeAsset">Asset-Konfiguration abschließen</button>
                <button v-else-if="currentStep === 3 && route.path.includes('/edit')" class="primary" @click="completeAsset">Asset-Bearbeitung abschließen</button>
                <button v-else class="primary" @click="advance">Weiter</button>
            </div>
        </div>

        <modal class="info-modal" v-model="confirmationCreateModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Möchten Sie die Asset-Erstellung wirklich abbrechen?</h2>

            <p>
                Dabei geht Ihre bisherige Konfiguration verloren.
            </p>

            <div class="buttons-modal">
                <div class="buttons-left">
                    <button class="ghost-primary button-modal-back-dialog" @click="gotoAssetList()">Ja, zurück zur Assetübersicht.</button>
                </div>
                <div class="buttons-right">
                    <button class="primary button-modal-back-dialog" @click="closeConfirmationCreateModal()">Nein, Asset-Konfiguration behalten.</button>
                </div>
            </div>
        </modal>

        <modal class="info-modal" v-model="confirmationEditModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Möchten Sie die Asset-Bearbeitung wirklich abbrechen?</h2>

            <p>
                Dabei gehen Ihre bisherigen Änderungen verloren.
            </p>

            <div class="buttons-modal">
                <div class="buttons-left">
                    <button class="ghost-primary button-modal-back-dialog" @click="gotoAssetList()">Ja, zurück zur Assetübersicht.</button>
                </div>
                <div class="buttons-right">
                    <button class="primary button-modal-back-dialog" @click="closeConfirmationEditModal()">Nein, Asset-Konfiguration behalten.</button>
                </div>
            </div>
        </modal>

        <modal class="info-modal" v-model="assetNameModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Assetname</h2>

            <p>
                Ihre Antwort hilft uns, die Anfrage intern besser zu verarbeiten. Bitte geben Sie einen für Sie eindeutigen
                Asset-Namen ein.
            </p>

            <form-actions slot="footer">
                <button @click="closeAssetNameModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="assetNameExistsModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Asset mit identischem Namen</h2>

            <p>
                Es existiert bereits ein Asset mit dem gleichen Namen. Möchten Sie den Namen des Assets ändern?
            </p>

            <div class="buttons-modal">
                <div class="buttons-left">
                    <button class="ghost-primary button-modal-back-dialog" @click="closeAssetNameExistsModal()">Ja, Namen ändern.</button>
                </div>
                <div class="buttons-right">
                    <button class="primary button-modal-back-dialog" @click="acceptAssetNameExists()">Nein, so fortfahren.</button>
                </div>
            </div>
        </modal>

        <modal class="info-modal" v-model="descriptionModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Kurzbeschreibung</h2>

            <p>
                Bitte beschreiben Sie in wenigen Sätzen, wofür der von Ihnen angelegte Fat Client eingesetzt wird.
                Zentrale Fragen sind:
            </p>

            <ul>
                <li>
                    Von wem und wofür wird der Fat Client genutzt?
                </li>
                <li>
                    Welche Daten werden von dem Fat Client verarbeitet und/oder gespeichert?
                </li>
            </ul>

            <p>
                Ihre Beschreibung hilft uns, Ihren Prüfumfang besser zu verstehen. Je detaillierter Sie auf das Asset
                eingehen, umso besser können wir Ihren Prüfumfang im Vorfeld analysieren und kritisch hinterfragen.
            </p>

            <form-actions slot="footer">
                <button @click="closeDescriptionModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="sizeModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Funktionsumfang</h2>

            <p>
                Im Rahmen des Pentests werden die von dem Fat Client bereitgestellten Funktionen auf Schwachstellen überprüft.
                Je mehr Funktionen bereitgestellt werden, umso aufwändiger wird die Sicherheitsanalyse.
            </p>
            <p>
                Damit wir einen Eindruck von der Größe des zu testenden Fat Clients gewinnen, bitten wir um eine grobe
                Abschätzung der Anzahl bereitgestellter Funktionen. Es ist nicht notwendig eine exakte Aussage zu treffen.
            </p>
            <p>
                Nachfolgend haben wir für Sie einige exemplarische Funktionen aus verschiedenen Einsatzbereichen gesammelt.
                Die Aufzählung ist selbstverständlich nicht vollständig und dient ausschließlich der Veranschaulichung unseres
                Verständnisses für Funktionen eines Fat Clients.
            </p>
            <p>
                Bitte berücksichtigen Sie, dass wir den Pentest bei sehr großen Fat Clients auf Basis eines risikobasierten
                Ansatzes durchführen. Dabei werden nicht alle Funktion im Detail von uns geprüft, sondern eine umfangreiche
                Auswahl der wichtigsten bzw. kritischsten Funktionen untersucht. Gerne stimmen wir die Auswahl im Rahmen
                des Kick-offs mit Ihnen ab
            </p>

            <h3>Users & Accounts</h3>
            <ul>
                <li>Authentisierung von Nutzern</li>
                <li>Registrierungsprozess für neue Nutzer</li>
                <li>Einladung neuer Nutzer</li>
                <li>Passwort wiederherstellen</li>
                <li>Zwei-Faktor-Authentifizierung</li>
                <li>Single Sign-on</li>
            </ul>

            <h3>Content</h3>
            <ul>
                <li>Static Content</li>
                <li>Einsatz eines Content Management Systems (CMS)</li>
                <li>Dashboard</li>
                <li>Nutzerprofile</li>
                <li>Einsatz eines Blogs</li>
                <li>Umfragen und Abstimmungen</li>
                <li>Quiz</li>
                <li>Bezahlter Content</li>
                <li>Knowledge Base</li>
                <li>Landing Page</li>
                <li>Kontaktformular</li>
                <li>Gewinnspiel</li>
                <li>Tags & Kategorien</li>
                <li>Nutzerbewertungen und Reviews</li>
                <li>Externer Dateiupload</li>
                <li>Such- und Filterfunktion</li>
                <li>E-Mail-Benachrichtigung</li>
                <li>Newsletter Abonnement</li>
                <li>Multilingual Support</li>
                <li>Workflows</li>
            </ul>

            <h3>Dates & Locations</h3>
            <ul>
                <li>Kalenderfunktion</li>
                <li>Termine buchen</li>
                <li>Reservierungen anfragen</li>
                <li>Streckenplanung</li>
                <li>Karten einsehen</li>
            </ul>

            <h3>Social</h3>
            <ul>
                <li>Nachrichten senden</li>
                <li>Live Chat</li>
                <li>Community Forum</li>
                <li>Nutzerkommentare</li>
                <li>Nutzer blockieren</li>
                <li>Nutzern folgen</li>
                <li>Freundeslisten</li>
                <li>Inhalte teilen</li>
                <li>Dateien senden / austauschen</li>
            </ul>

            <h3>E-Commerce</h3>
            <ul>
                <li>Produkte verwalten</li>
                <li>Digitale Produkte</li>
                <li>Abonnements</li>
                <li>Rabatte und Sonderangebote</li>
                <li>Produkte empfehlen</li>
                <li>Produkte vergleichen</li>
                <li>Wunschlisten</li>
                <li>Warenkorb</li>
                <li>Auktionen</li>
                <li>Gutscheine</li>
                <li>Bestellverwaltung</li>
            </ul>

            <h3>Finance & Payments</h3>
            <ul>
                <li>Angebotserstellung</li>
                <li>Rechnungserstellung</li>
                <li>Zahlungsabwicklung</li>
            </ul>

            <h3>Productivity & Task management</h3>
            <ul>
                <li>Projekt Management</li>
                <li>Aufgaben Management</li>
                <li>Checklisten</li>
                <li>Ticketsystem</li>
            </ul>

            <h3>Gamification</h3>
            <ul>
                <li>Ranglisten</li>
                <li>User Credits</li>
            </ul>

            <h3>Media</h3>
            <ul>
                <li>Fotobibliothek</li>
                <li>Videobibliothek</li>
                <li>Audiobibliothek</li>
                <li>Bildbearbeitung</li>
                <li>Videobearbeitung</li>
            </ul>

            <h3>Analytics & Reporting</h3>
            <ul>
                <li>Daten filtern und analysieren</li>
                <li>Berichte erstellen</li>
                <li>Daten exportieren</li>
            </ul>

            <form-actions slot="footer">
                <button @click="closeSizeModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="softwareTypeModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Softwaretyp</h2>

            <p>
                Bei einer Individualentwicklung wurde der Fat Client für Ihr Unternehmen passgenau und individuell
                hergestellt. Es ist dabei nicht relevant, ob die Software von Ihnen (inhouse) oder von einem Dienstleister
                entwickelt wurde.
            </p>
            <p>
                Eine Standardsoftware wird von einem Hersteller entwickelt und am Markt vertrieben. Bekannte Beispiele
                sind Office Produkte, die man als kostenfreie Open Source (z.B. Open Office) oder als kostenpflichtige
                Lizenz (Microsoft Office) bezieht.
            </p>
            <p>
                Die Standardsoftware wird von Ihnen mit oder ohne individuelle Erweiterungen eingesetzt.  Individuelle
                Erweiterungen stellen Anpassungen an der Standardsoftware dar, die nur für Ihr Unternehmen vom Hersteller
                entwickelt und in die Standardsoftware integriert werden. Gemeint sind dabei keine individuellen
                Konfigurationen der Standardsoftware.
            </p>

            <form-actions slot="footer">
                <button @click="closeSoftwareTypeModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="authenticationModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Geschützter Benutzerbereich</h2>

            <p>
                Sensible, vertrauliche Personen- und/oder Unternehmensdaten sowie Funktionen, die diese Daten verarbeiten,
                werden üblicherweise in einem geschützten Benutzerbereich, also nicht öffentlich, den Nutzern zur Verfügung
                gestellt. Der Benutzerbereich ist dabei nur mit einem gültigen Benutzernamen sowie nach erfolgreicher
                Authentisierung (z.B. mit einem Passwort) zugänglich.
            </p>
            <p>
                Fat Clients, die über einen geschützten Benutzerbereich verfügen, werden von uns immer authentisiert überprüft.
                Hierdurch steigt der Prüfaufwand und damit die Kosten, dafür erhalten Sie jedoch ein wesentlich besseres
                Lagebild von der Sicherheit Ihrer Webanwendung und ggf. vorhandenen Risiken.
            </p>

            <form-actions slot="footer">
                <button @click="closeAuthenticationModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="obfuscationModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Code Obfuscation</h2>

            <p>
                Mittels Code Obfuscation kann der Quellcode einer Anwendung einem Angreifer gegenüber verschleiert werden.
                Dadurch wird es diesem erschwert die Anwendung zu analysieren, da er den Quellcode nicht einfach aus der
                Anwendung wiederherstellen und deren Verhalten "nachlesen" kann.
            </p>
            <p>
                Liegen Ihnen keine Informationen vor oder sind diese nicht in einer angemessenen Zeit beschaffbar, lassen
                Sie die Frage gerne unbeantwortet.
            </p>

            <form-actions slot="footer">
                <button @click="closeObfuscationModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="architectureModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Architektur</h2>

            <p>
                Nach unserer Erfahrung basieren die Fat Clients heutzutage auf einer Server-Client Architektur.
            </p>
            <p>
                Informationen zur Architektur können beim Entwickler bzw. Hersteller des Fat Clients erfragt werden.
            </p>
            <p>
                Liegen Ihnen keine Informationen vor oder sind diese nicht in einer angemessenen Zeit beschaffbar, lassen
                Sie die Frage gerne offen.
            </p>
            <p>
                Übrigens: Handelt es sich um eine Server-Client App, die bspw. mit einem Webserver/Webservice kommuniziert,
                so ist die Prüfung dieser Komponenten in unserem Angebot enthalten. Dabei beschränken wir die Prüfung auf
                diejenigen Funktionen, die von dem Fat Client genutzt werden.
            </p>

            <form-actions slot="footer">
                <button @click="closeArchitectureModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="programmingLanguageModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Programmiersprache</h2>

            <p>
                Informationen zur eingesetzten Programmiersprache müssen beim Entwickler erfragt werden.
            </p>
            <p>
                In seltenen Fällen kommt es vor, dass mehrere Programmiersprachen eingesetzt werden. Gerne dürfen Sie in
                diesem Fall bei Ihrer Antwort alle eingesetzten Programmiersprachen angeben.
            </p>
            <p>
                Liegen Ihnen keine Informationen vor oder sind nicht in einer angemessenen Zeit beschaffbar,
                lassen Sie die Frage gerne offen.
            </p>

            <form-actions slot="footer">
                <button @click="closeProgrammingLanguageModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="securityAssessmentModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Schutzbedarf</h2>

            <p>
                Der Schutzbedarf misst sich an den potenziellen Schäden, die mit der Beeinträchtigung des
                betroffenen Fat Clients einhergehen. 
                Dabei werden üblicherweise die drei Schutzziele Integrität, Vertraulichkeit und Verfügbarkeit betrachtet. 
                Bei der Bewertung der Schäden sollten neben finanziellen Aspekten weitere Schadensszenarien, wie zum Beispiel: 
            </p>
            <ul>
                <li>Verstöße gegen Gesetze, Vorschriften oder Verträge</li>
                <li>Beeinträchtigungen des informationellen Selbstbestimmungsrechts</li>
                <li>Beeinträchtigungen der persönlichen Unversehrtheit</li>
                <li>Beeinträchtigungen der Aufgabenerfüllung</li>
            </ul>
            <p>
                berücksichtigt werden.
            </p>

            <form-actions slot="footer">
                <button @click="closeSecurityAssessmentModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="securityAssessmentLevelModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Höhe des Schutzbedarfs</h2>

            <p>
                Die konkrete Ausprägung der vorgenannten Schutzbedarfskategorien wird von jedem Unternehmen individuell
                vorgenommen und kann sich unterscheiden. Das Bundesamt für Informationssicherheit (BSI) liefert im
                BSI-Standard 100-4 folgendes Beispiel:
            </p>
            <ul>
                <li>Niedrig: kaum spürbare Auswirkung</li>
                <li>Normal: spürbare Auswirkungen</li>
                <li>Hoch: erhebliche Auswirkungen</li>
                <li>Sehr hoch: existentielle Auswirkungen</li>
            </ul>

            <form-actions slot="footer">
                <button @click="closeSecurityAssessmentLevelModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, onMounted, computed, watch, nextTick } from 'vue'
import { useModal } from '@/composables/use-modal'
import { useRouter } from '@/composables/use-router'
import { useProjectStore } from '@/store/projectStore'
import { useAssetStore } from '@/store/assetStore'
import BoxSelectRadio from '@/components/BoxSelectRadio.vue'
import BoxSelectCheckbox from '@/components/BoxSelectCheckbox.vue'
import { RequiredValidator, RequiredValidatorArray, RequiredValidatorBoolean } from '@/validator/validator'

export default defineComponent({
    name: 'FatclientAsset',

    props: {
        passedAsset: {
            type: Object,
            default: null
        }
    },

    components: {
        BoxSelectRadio,
        BoxSelectCheckbox
    },

    setup(props, { emit }) {
        const { router, route } = useRouter()

        const assetStore = useAssetStore()
        const currentAsset = computed(() => {
            if (route.value.path.includes('/edit')) {
                const assetList = assetStore.getAll.value
                return assetList.filter(asset => asset.id === route.value.params.assetId)[0]
            } else {
                return assetStore.getCurrentAsset.value
            }
        })

        const projectStore = useProjectStore()
        const projectId = projectStore.uuid

        const currentStep = ref<number>(0)

        const fatclient = reactive<any>({
            size: typeof props.passedAsset.config.size === 'undefined' ? '' : props.passedAsset.config.size,
            architecture: typeof props.passedAsset.config.architecture === 'undefined' ? '' : props.passedAsset.config.architecture,
            obfuscation: typeof props.passedAsset.config.obfuscation === 'undefined' ? null : props.passedAsset.config.obfuscation,
            companyRole: typeof props.passedAsset.config.companyRole === 'undefined' ? '' : props.passedAsset.config.companyRole,
            softwareType: typeof props.passedAsset.config.softwareType === 'undefined' ? '' : props.passedAsset.config.softwareType,
            vendor: typeof props.passedAsset.config.vendor === 'undefined' ? '' : props.passedAsset.config.vendor,
            productName: typeof props.passedAsset.config.productName === 'undefined' ? '' : props.passedAsset.config.productName,
            version: typeof props.passedAsset.config.version === 'undefined' ? '' : props.passedAsset.config.version,
            website: typeof props.passedAsset.config.website === 'undefined' ? '' : props.passedAsset.config.website,
            authentication: typeof props.passedAsset.config.authentication === 'undefined' ? null : props.passedAsset.config.authentication,
            operatingSystem: typeof props.passedAsset.config.operatingSystem === 'undefined' ? [] : props.passedAsset.config.operatingSystem,
            programmingLanguageList: typeof props.passedAsset.config.programmingLanguageList === 'undefined' ? [] : props.passedAsset.config.programmingLanguageList,
            programmingLanguageIndividualList: typeof props.passedAsset.config.programmingLanguageIndividual === 'undefined' ? '' : props.passedAsset.config.programmingLanguageIndividual,
            securityAssessment: typeof props.passedAsset.config.securityAssessment === 'undefined' ? null : props.passedAsset.config.securityAssessment,
            securityAssessmentLevel: typeof props.passedAsset.config.securityAssessmentLevel === 'undefined' ? '' : props.passedAsset.config.securityAssessmentLevel
        })

        const { modal: assetNameModal, openModal: openAssetNameModal, closeModal: closeAssetNameModal } = useModal()
        const { modal: assetNameExistsModal, openModal: openAssetNameExistsModal, closeModal: closeAssetNameExistsModal } = useModal()
        const { modal: descriptionModal, openModal: openDescriptionModal, closeModal: closeDescriptionModal } = useModal()
        const { modal: sizeModal, openModal: openSizeModal, closeModal: closeSizeModal } = useModal()
        const { modal: softwareTypeModal, openModal: openSoftwareTypeModal, closeModal: closeSoftwareTypeModal } = useModal()
        const { modal: authenticationModal, openModal: openAuthenticationModal, closeModal: closeAuthenticationModal } = useModal()
        const { modal: architectureModal, openModal: openArchitectureModal, closeModal: closeArchitectureModal } = useModal()
        const { modal: programmingLanguageModal, openModal: openProgrammingLanguageModal, closeModal: closeProgrammingLanguageModal } = useModal()
        const { modal: obfuscationModal, openModal: openObfuscationModal, closeModal: closeObfuscationModal } = useModal()
        const { modal: securityAssessmentModal, openModal: openSecurityAssessmentModal, closeModal: closeSecurityAssessmentModal } = useModal()
        const { modal: securityAssessmentLevelModal, openModal: openSecurityAssessmentLevelModal, closeModal: closeSecurityAssessmentLevelModal } = useModal()
        const { modal: confirmationCreateModal, openModal: openConfirmationCreateModal, closeModal: closeConfirmationCreateModal } = useModal()
        const { modal: confirmationEditModal, openModal: openConfirmationEditModal, closeModal: closeConfirmationEditModal } = useModal()

        const sizeOptions = [
            {
                id: 'small',
                label: 'Klein<br>(< 20 Funktionen)'
            },
            {
                id: 'medium',
                label: 'Mittel<br>(20-40 Funktionen)'
            },
            {
                id: 'large',
                label: 'Groß<br>(41-60 Funktionen)'
            },
            {
                id: 'very large',
                label: 'Sehr groß<br>(> 60 Funktionen)'
            }
        ]

        const architectureOptions = [
            {
                id: 'client',
                label: '(Lokale) Client Anwendung'
            },
            {
                id: 'client-server',
                label: 'Client-Server Anwendung'
            }
        ]

        const companyRoleOptions = [
            {
                id: 'consumer',
                label: 'Fremdentwicklung'
            },
            {
                id: 'vendor',
                label: 'Eigenentwicklung'
            }
        ]

        const softwareTypeOptions = [
            {
                id: 'inhouse',
                label: 'Individualentwicklung'
            },
            {
                id: 'standard',
                label: 'Standardsoftware'
            },
            {
                id: 'standard extended',
                label: 'Standardsoftware mit individuellen Erweiterungen'
            }
        ]

        const operatingSystemOptions = [
            {
                id: 'windows',
                label: 'Windows'
            },
            {
                id: 'linux',
                label: 'Linux'
            },
            {
                id: 'macos',
                label: 'MacOS'
            }
        ]

        const programmingLanguageOptions = [
            {
                id: '.net',
                label: '.Net'
            },
            {
                id: 'c',
                label: 'C/C++'
            },
            {
                id: 'java',
                label: 'Java'
            },
            {
                id: 'individual',
                label: 'Sonstige'
            }
        ]

        const securityAssessmentLevelOptions = [
            {
                id: 'low',
                label: 'Niedrig'
            },
            {
                id: 'normal',
                label: 'Normal'
            },
            {
                id: 'high',
                label: 'Hoch'
            },
            {
                id: 'very high',
                label: 'Sehr hoch'
            }
        ]

        const numberAdditionalProgrammingLanguages = ref<number>(0)

        const checkProgrammingLanguageListLength = () => {
            if (fatclient.programmingLanguageIndividualList.length !== 0) {
                numberAdditionalProgrammingLanguages.value = fatclient.programmingLanguageIndividualList.length - 1
            }
        }

        const removeProgrammingLanguageFromList = (index: number) => {
            fatclient.programmingLanguageIndividualList.splice(index, 1)
            numberAdditionalProgrammingLanguages.value--
        }

        const addProgrammingLanguage = () => {
            numberAdditionalProgrammingLanguages.value++
        }

        onMounted(() => {
            checkProgrammingLanguageListLength()
        })

        const markAssetDone = () => {
            props.passedAsset.config = fatclient
            emit('fatclientAssetDone', props.passedAsset)
        }

        const completeAsset = async() => {
            if (await checkAdvance()) {
                markAssetDone()
            }
        }

        const checkClearValuesSoftwareTypeVendor = () => {
            if (fatclient.companyRole === 'vendor') {
                fatclient.softwareType = ''
                fatclient.vendor = ''
                fatclient.productName = ''
                fatclient.version = ''
                fatclient.website = ''
            }
        }

        const checkClearValuesVendor = () => {
            if (fatclient.softwareType === 'inhouse') {
                fatclient.vendor = ''
                fatclient.productName = ''
                fatclient.version = ''
                fatclient.website = ''
            }
        }

        const checkClearValueProgrammingLanguageIndividual = () => {
            if (!fatclient.programmingLanguageList.includes('individual')) {
                fatclient.programmingLanguageIndividualList = []
            }
        }

        const checkClearValueSecurityAssessmentLevel = () => {
            if (fatclient.securityAssessment !== true) {
                fatclient.securityAssessmentLevel = ''
            }
        }

        const nameUnique = ref<boolean>(true)
        const checkAssetNameUnique = () => {
            nameUnique.value = true
            const assetList = assetStore.getAll.value
            assetList.forEach((asset: any) => {
                if (currentAsset.value.name === asset.name && !route.value.path.includes('/edit')) {
                    nameUnique.value = false
                    openAssetNameExistsModal()
                }
            })
        }

        const acceptAssetNameExists = async() => {
            nameUnique.value = true
            closeAssetNameExistsModal()
            if (await validateFirstStepWithoutAssetNameUnique()) {
                window.scrollTo(0, 0)
                currentStep.value++
            }
        }

        const checkAdvance = async() => {
            if (currentStep.value === 0) {
                if (await validateFirstStep()) {
                    return true
                }
            } else if (currentStep.value === 1) {
                if (await validateSecondStep()) {
                    return true
                }
            } else if (currentStep.value === 2) {
                if (await validateThirdStep()) {
                    return true
                }
            } else if (currentStep.value === 3) {
                return true
            }
            return false
        }

        const advance = async() => {
            if (await checkAdvance()) {
                if (currentStep.value < 4) {
                    window.scrollTo(0, 0)
                    currentStep.value++
                }
            }
        }

        const back = () => {
            if (currentStep.value > 0) {
                currentStep.value--
            } else {
                assetStore.clearAsset()
                router.go(-1)
            }
        }

        const reset = () => {
            currentStep.value = 0
        }

        const nameError = ref<string>('')
        const descriptionError = ref<string>('')
        const sizeError = ref<string>('')
        const authenticationError = ref<string>('')
        const operatingSystemError = ref<string>('')
        const architectureError = ref<string>('')
        const programmingLanguageError = ref<string>('')
        const programmingLanguageIndividualError = ref<string>('')

        watch(() => currentAsset.value.name, (value) => {
            nameError.value = RequiredValidator(value)
        })

        watch(() => currentAsset.value.description, (value) => {
            descriptionError.value = RequiredValidator(value)
        })

        watch(() => fatclient.size, (value) => {
            sizeError.value = RequiredValidator(value)
        })

        watch(() => fatclient.authentication, (value) => {
            authenticationError.value = RequiredValidatorBoolean(value)
        })

        watch(() => fatclient.operatingSystem, (value) => {
            operatingSystemError.value = RequiredValidatorArray(value)
        })

        watch(() => fatclient.architecture, (value) => {
            architectureError.value = RequiredValidator(value)
        })

        watch(() => fatclient.programmingLanguageList, (value) => {
            programmingLanguageError.value = RequiredValidatorArray(value)
        })

        watch(() => fatclient.programmingLanguageIndividualList, (value) => {
            programmingLanguageIndividualError.value = RequiredValidatorArray(value)
        })

        const validateFirstStep = async() => {
            nameError.value = RequiredValidator(currentAsset.value.name)
            descriptionError.value = RequiredValidator(currentAsset.value.description)
            if (nameError.value === '' &&
                descriptionError.value === ''
            ) {
                checkAssetNameUnique()
                if (nameUnique.value) {
                    return true
                }
            } else {
                await nextTick()
                const element = document.querySelector('.invalid-feedback')?.parentNode?.parentNode as HTMLElement
                element.scrollIntoView({behavior: 'smooth'})
            }
        }

        const validateFirstStepWithoutAssetNameUnique = async() => {
            nameError.value = RequiredValidator(currentAsset.value.name)
            descriptionError.value = RequiredValidator(currentAsset.value.description)
            if (nameError.value === '' &&
                descriptionError.value === ''
            ) {
                return true
            } else {
                await nextTick()
                const element = document.querySelector('.invalid-feedback')?.parentNode?.parentNode as HTMLElement
                element.scrollIntoView({behavior: 'smooth'})
            }
        }

        const validateSecondStep = async() => {
            sizeError.value = RequiredValidator(fatclient.size)
            authenticationError.value = RequiredValidatorBoolean(fatclient.authentication)
            if (sizeError.value === '' &&
                authenticationError.value === ''
            ) {
                return true
            } else {
                await nextTick()
                const element = document.querySelector('.invalid-feedback')?.parentNode?.parentNode as HTMLElement
                element.scrollIntoView({behavior: 'smooth'})
            }
        }

        const validateThirdStep = async() => {
            operatingSystemError.value = RequiredValidatorArray(fatclient.operatingSystem)
            architectureError.value = RequiredValidator(fatclient.architecture)
            programmingLanguageError.value = RequiredValidatorArray(fatclient.programmingLanguageList)
            if (fatclient.programmingLanguageList.includes('individual')) {
                programmingLanguageIndividualError.value = RequiredValidatorArray(fatclient.programmingLanguageIndividualList)
            }
            if (operatingSystemError.value === '' &&
                architectureError.value === '' &&
                programmingLanguageError.value === '' &&
                (programmingLanguageIndividualError.value === '' || !fatclient.programmingLanguageList.includes('individual'))
            ) {
                return true
            } else {
                await nextTick()
                const element = document.querySelector('.invalid-feedback')?.parentNode?.parentNode as HTMLElement
                element.scrollIntoView({behavior: 'smooth'})
            }
        }

        const gotoAssetList = () => {
            if (!route.value.path.includes('/edit')) {
                const assetStore = useAssetStore()
                assetStore.clearAsset()
            }
            window.scrollTo(0, 0)
            router.replace({ name: 'asset-based project', params: { id: projectId.value } })
        }

        return {
            route,
            currentAsset,
            currentStep,
            fatclient,
            acceptAssetNameExists,
            numberAdditionalProgrammingLanguages,
            removeProgrammingLanguageFromList,
            addProgrammingLanguage,
            sizeModal,
            assetNameModal,
            openAssetNameModal,
            closeAssetNameModal,
            assetNameExistsModal,
            openAssetNameExistsModal,
            closeAssetNameExistsModal,
            descriptionModal,
            openDescriptionModal,
            closeDescriptionModal,
            openSizeModal,
            closeSizeModal,
            softwareTypeModal,
            openSoftwareTypeModal,
            closeSoftwareTypeModal,
            authenticationModal,
            openAuthenticationModal,
            closeAuthenticationModal,
            architectureModal,
            openArchitectureModal,
            closeArchitectureModal,
            programmingLanguageModal,
            openProgrammingLanguageModal,
            closeProgrammingLanguageModal,
            obfuscationModal,
            openObfuscationModal,
            closeObfuscationModal,
            securityAssessmentModal,
            openSecurityAssessmentModal,
            closeSecurityAssessmentModal,
            securityAssessmentLevelModal,
            openSecurityAssessmentLevelModal,
            closeSecurityAssessmentLevelModal,
            confirmationCreateModal,
            openConfirmationCreateModal,
            closeConfirmationCreateModal,
            confirmationEditModal,
            openConfirmationEditModal,
            closeConfirmationEditModal,
            sizeOptions,
            architectureOptions,
            companyRoleOptions,
            softwareTypeOptions,
            operatingSystemOptions,
            programmingLanguageOptions,
            securityAssessmentLevelOptions,
            checkClearValuesSoftwareTypeVendor,
            checkClearValuesVendor,
            checkClearValueProgrammingLanguageIndividual,
            checkClearValueSecurityAssessmentLevel,
            nameError,
            descriptionError,
            sizeError,
            authenticationError,
            operatingSystemError,
            architectureError,
            programmingLanguageError,
            programmingLanguageIndividualError,
            completeAsset,
            advance,
            back,
            reset,
            gotoAssetList
        }
    }
})
</script>

<style lang="scss" scoped>
@import "../../styles/breakpoints";

.fatclient-asset {
    h2 {
        color: var(--color-brand-primary);
    }

    .asset-question {
        @include default-padding();
    }

    .asset-question-white {
        background-color: var(--color-white);
    }
}
</style>
