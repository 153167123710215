<template>
    <div class="error-page">
        <ConfiguratorHeader />

        <div class="error-window">
            <div class="error-box">
                <h1>
                    404 - Seite nicht gefunden
                </h1>

                <i class="material-icon mdi mdi-bug"></i>

                <p>
                    Es tut uns leid, die von Ihnen angeforderte Seite wurde nicht gefunden.
                </p>

                <button class="primary" @click="gotoHome()">Zurück auf die Startseite</button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useRouter } from '@/composables/use-router'
import { useProjectStore } from '@/store/projectStore'
import ConfiguratorHeader from '@/components/ConfiguratorHeader.vue'

export default defineComponent({
    name: "ErrorPage",

    components: {
        ConfiguratorHeader
    },

    setup() {
        const { router } = useRouter()

        const projectStore = useProjectStore()

        const gotoHome = () => {
            projectStore.deleteProject()
            router.replace({ name: 'home' })
            window.scrollTo(0, 0)
            window.location.reload()
        }

        return {
            gotoHome
        }
    }
})
</script>

<style lang="scss" scoped>
@import "../styles/breakpoints";

.error-window {
    @include default-padding();
}

.error-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 2em;
    width: 100%;
    max-width: 600px;
    margin: 4em auto;
    background-color: var(--color-white);
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.4);

    .material-icon {
        color: #aaa;
        font-size: 6rem;
    }
}

@media screen and (max-width: $bp-medium) {
    .error-window {
        margin: 2em;
    }
}
</style>
