import { render, staticRenderFns } from "./AssetType.vue?vue&type=template&id=2b823939&scoped=true"
import script from "./AssetType.vue?vue&type=script&lang=ts"
export * from "./AssetType.vue?vue&type=script&lang=ts"
import style0 from "./AssetType.vue?vue&type=style&index=0&id=2b823939&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b823939",
  null
  
)

export default component.exports