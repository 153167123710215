<template>
    <div class="order-form">
        <div class="user-form">
            <h3>Ihre Kontaktdaten</h3>

            <select-field id="salutation" label="Anrede *" v-model="customer.salutation" :options="salutationOptions" :error="salutationError"/>
            <text-field id="title" label="Titel" v-model="customer.title"></text-field>
            <text-field id="firstname" label="Vorname *" v-model="customer.firstname" :error="firstnameError"></text-field>
            <text-field id="lastname" label="Nachname *" v-model="customer.lastname" :error="lastnameError"></text-field>
            <text-field id="email" label="E-Mail *" v-model="customer.email" :error="emailError"></text-field>
            <text-field id="phone" label="Telefon *" v-model="customer.telephone" :error="telephoneError"></text-field>
            <text-field id="position" label="Position *" v-model="customer.position" :error="positionError"></text-field>
        </div>

        <div class="company-form">
            <h3>Ihre Firmendaten</h3>

            <text-field id="company-name" label="Firma *" v-model="company.name" :error="companyNameError"></text-field>
            <text-field id="address" label="Straße und Hausnummer *" v-model="company.street" :error="companyStreetError"></text-field>
            <text-field id="zipcode" label="PLZ *" v-model="company.zipcode" :error="companyZipcodeError"></text-field>
            <text-field id="city" label="Ort *" v-model="company.city" :error="companyCityError"></text-field>
            <text-field id="country" label="Land *" v-model="company.country" :error="companyCountryError"></text-field>

            <p class="mandatory">* Pflichtfelder</p>
        </div>

        <div class="data-usage-hint">
            <h4>Detaillierte Informationen zum Umgang mit Ihren Nutzerdaten finden Sie in unseren <a href="https://www.usd.de/datenschutz/" target="_blank" rel="noopener noreferrer">Datenschutzhinweisen</a>.</h4>
        </div>

        <div class="consent-form">
            <h3>Einwilligung in Werbung</h3>

            <p>
                Ich bin damit einverstanden, dass mir die usd AG persönlich auf mich zugeschnittene Werbung (z.B. Kundeninformationen,
                Einladungen und Informationen zu Produktvorstellungen, Informationen zu bestehenden und neuen Services) zukommen lässt per:
            </p>

            <checkboxes-field id="consent-options" label="" v-model="advertisingConsent" :options="advertisingConsentOptions" />

            <p>
                <strong>
                    Widerrufsmöglichkeit
                </strong>
            </p>

            <p>
                Selbstverständlich können Sie Ihre Einwilligung jederzeit ohne Angabe von Gründen gegenüber der usd AG per E-Mail an
                <a href="mailto:kontakt@usd.sd">kontakt@usd.de</a> mit Wirkung für die Zukunft ganz oder teilweise widerrufen.
            </p>
        </div>

        <div class="validate-button">
            <button @click="validateForm" class="primary button-modal">Anfrage absenden</button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, nextTick, ref, watch } from 'vue'
import { useCustomerStore } from '@/store/customerStore'
import { RequiredValidator, EmailValidator } from '@/validator/validator'

export default defineComponent({
    name: 'OrderForm',

    setup(_, { emit }) {
        const customerStore = useCustomerStore()

        const customer = customerStore.getCustomer
        const company = customerStore.getCompany
        const advertisingConsent = customerStore.advertisingConsent

        const salutationOptions = [
            {
                id: 'mr',
                label: 'Herr'
            },
            {
                id: 'mrs',
                label: 'Frau'
            },
            {
                id: 'other',
                label: '-'
            }
        ]

        const dataConsentOptions = [
            {
                id: 'true',
                label: 'Ich bin mit der Verarbeitung meiner Daten im Rahmen dieser Anfrage einverstanden*'
            }
        ]

        const advertisingConsentOptions = [
            {
                id: 'email',
                label: 'E-Mail'
            },
            {
                id: 'tel',
                label: 'Telefon'
            }
        ]

        const salutationError = ref<string>('')
        const firstnameError = ref<string>('')
        const lastnameError = ref<string>('')
        const emailError = ref<string>('')
        const telephoneError = ref<string>('')
        const positionError = ref<string>('')
        const companyNameError = ref<string>('')
        const companyStreetError = ref<string>('')
        const companyZipcodeError = ref<string>('')
        const companyCityError = ref<string>('')
        const companyCountryError = ref<string>('')
        const dataConsentError = ref<string>('')

        watch(() => customer.value.salutation, (value) => {
            salutationError.value = RequiredValidator(value)
        })

        watch(() => customer.value.firstname, (value) => {
            firstnameError.value = RequiredValidator(value)
        })

        watch(() => customer.value.lastname, (value) => {
            lastnameError.value = RequiredValidator(value)
        })

        watch(() => customer.value.email, (value) => {
            emailError.value = EmailValidator(value)
        })

        watch(() => customer.value.telephone, (value) => {
            telephoneError.value = RequiredValidator(value)
        })

        watch(() => customer.value.position, (value) => {
            positionError.value = RequiredValidator(value)
        })

        watch(() => company.value.name, (value) => {
            companyNameError.value = RequiredValidator(value)
        })

        watch(() => company.value.street, (value) => {
            companyStreetError.value = RequiredValidator(value)
        })

        watch(() => company.value.zipcode, (value) => {
            companyZipcodeError.value = RequiredValidator(value)
        })

        watch(() => company.value.city, (value) => {
            companyCityError.value = RequiredValidator(value)
        })

        watch(() => company.value.country, (value) => {
            companyCountryError.value = RequiredValidator(value)
        })

        const validateForm = async() => {
            salutationError.value = RequiredValidator(customer.value.salutation)
            firstnameError.value = RequiredValidator(customer.value.firstname)
            lastnameError.value = RequiredValidator(customer.value.lastname)
            emailError.value = EmailValidator(customer.value.email)
            telephoneError.value = RequiredValidator(customer.value.telephone)
            positionError.value = RequiredValidator(customer.value.position)
            companyNameError.value = RequiredValidator(company.value.name)
            companyStreetError.value = RequiredValidator(company.value.street)
            companyZipcodeError.value = RequiredValidator(company.value.zipcode)
            companyCityError.value = RequiredValidator(company.value.city)
            companyCountryError.value = RequiredValidator(company.value.country)

            if (salutationError.value === '' &&
                firstnameError.value === '' &&
                lastnameError.value === '' &&
                emailError.value === '' &&
                telephoneError.value === '' &&
                positionError.value === '' &&
                companyNameError.value === '' &&
                companyStreetError.value === '' &&
                companyZipcodeError.value === '' &&
                companyCityError.value === '' &&
                companyCountryError.value === ''
            ) {
                emit('finished')
            } else {
                await nextTick()
                const element = document.getElementsByClassName('modal is-open')[0].getElementsByClassName('invalid-feedback')[0].parentNode as HTMLElement
                element.scrollIntoView({behavior: 'smooth'})
            }
        }

        return {
            customer,
            company,
            advertisingConsent,
            salutationOptions,
            dataConsentOptions,
            advertisingConsentOptions,
            validateForm,
            salutationError,
            firstnameError,
            lastnameError,
            emailError,
            telephoneError,
            positionError,
            companyNameError,
            companyStreetError,
            companyZipcodeError,
            companyCityError,
            companyCountryError,
            dataConsentError
        }
    }
})
</script>

<style lang="scss" scoped>
h3 {
    color: var(--color-brand-secondary);
}

.user-form, .company-form {
    margin-bottom: 3em;
}

.validate-button {
    display: flex;
    justify-content: center;
}

.data-usage-hint {
    margin: 3em 0;
}

</style>
