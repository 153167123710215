<template>
    <div class="expansion-panel_content">
        <div class="expansion-panel_content_header" @click="toggle(!open)">
            <slot name="header"></slot>
            <material-icon v-if="open" :class="getArrowClass" icon="chevron-down" />
            <material-icon v-else :class="getArrowClass" icon="chevron-right" />
        </div>
        <transition-expand>
            <div class="expansion-panel_content_content" v-if="open">
                <slot></slot>
            </div>
        </transition-expand>
    </div>
</template>

<script>
import MaterialIcon from '@/components/MaterialIcon'
import TransitionExpand from '@/components/TransitionExpand'

export default {
    name: 'ExpansionPanelContent',

    inject: ['register', 'unregister', 'setOpen'],

    components: {
        MaterialIcon,
        TransitionExpand
    },

    data: () => ({
        open: false,
        computedHeight: 0
    }),

    beforeMount() {
        this.register(this)
    },

    beforeDestroy() {
        this.unregister(this)
    },

    computed: {
        getArrowClass() {
            if (this.activated) {
                return 'indicator open'
            } else {
                return 'indicator'
            }
        }
    },

    methods: {
        toggle(value) {
            if (value !== this.open) {
                this.open = value
                this.setOpen(this, this.open)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .expansion-panel_content {
        border-bottom: 1px solid rgba(0,0,0,0.12);
    }

    .expansion-panel_content_header {
        padding: 1em;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
    }

    .indicator {
        transform: rotate(0deg);
        transition: transform .2s linear;
        float: right;

        &.open {
            transform: rotate(180deg);
            transition: transform .2s linear;
        }
    }
</style>
