<template>
    <div class="timing-summary">
        <p v-if="!timing">
            Der Pentest erfolgt zu üblichen Büroarbeitszeiten montags bis freitags zwischen 8.00 und 18.00 Uhr, außer an bundesweiten Feiertagen.
        </p>

        <p v-else-if="timing && projectType === 'contingent'">
            Der Pentest erfolgt außerhalb der üblichen Büroarbeitszeiten der usd.
        </p>

        <div v-else-if="timing && projectType === 'asset'" >
            <p>
                Der Pentest erfolgt außerhalb der üblichen Büroarbeitszeiten der usd mit folgenden Nebenbedingungen.
            </p>

            <table class="timing-table">
                <tr class="table-header-row">
                    <th class="table-header-name">Asset-Name</th>
                    <th class="table-header-timing">Arbeitszeit</th>
                </tr>

                <tr v-for="asset in assetList" :key="asset.id" class="table-row">
                    <td>{{ asset.name }}</td>
                    <td v-if="asset.timing === 'default'">Büroarbeitszeiten</td>
                    <td v-else-if="asset.timing === 'nights'">Nachtarbeit</td>
                    <td v-else-if="asset.timing === 'weekends'">Feiertag/Wochenende</td>
                </tr>
            </table>
        </div>

        <div v-else-if="timing && projectType === 'scenario'" >
            <p>
                Der Pentest erfolgt außerhalb der üblichen Büroarbeitszeiten der usd mit folgenden Nebenbedingungen.
            </p>

            <table class="timing-table">
                <tr class="table-header-row">
                    <th class="table-header-name">Asset-Name</th>
                    <th class="table-header-timing">Arbeitszeit</th>
                </tr>

                <tr v-for="scenario in scenarioList" :key="scenario.name" class="table-row">
                    <td v-if="scenario.name === 'compromisedWebsite'">Website Defacement</td>
                    <td v-else-if="scenario.name === 'compromisedShop'">Cyber-Angriff auf Online-Shop</td>
                    <td v-else-if="scenario.name === 'localPrivilegeEscalation'">Lokale Rechteausweitung</td>
                    <td v-else-if="scenario.name === 'homeofficeAttack'">Homeoffice Angriff</td>
                    <td v-else-if="scenario.name === 'compromisedNetwork'">Netzwerk Angriff</td>
                    <td v-else-if="scenario.name === 'externalAttack'">Externer Angriff</td>
                    <td v-else-if="scenario.name === 'externalEnumeration'">Externe Enumeration</td>
                    <td v-else-if="scenario.name === 'insiderAttack'">Insiderangriff</td>
                    <td v-else-if="scenario.name === 'stolenLaptop'">Gestohlener Laptop</td>
                    <td v-else-if="scenario.name === 'ransomware'">Ransomware Angriff</td>
                    <td v-else-if="scenario.name === 'phishing'">Phishing Angriff</td>
                    <td v-else-if="scenario.name === 'cloudSecurity'">Cloud Angriff</td>

                    <td v-if="scenario.timing === 'default'">Büroarbeitszeiten</td>
                    <td v-else-if="scenario.timing === 'nights'">Nachtarbeit</td>
                    <td v-else-if="scenario.timing === 'weekends'">Feiertag/Wochenende</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useScopeStore } from '@/store/scopeStore'
import { useProjectStore } from '@/store/projectStore'
import { useAssetStore } from '@/store/assetStore'
import { useScenarioStore } from '@/store/scenarioStore'

export default defineComponent({
    name: 'TimingSummary',

    setup() {
        const projectStore = useProjectStore()
        const projectId = projectStore.uuid
        const projectType = projectStore.type

        const assetStore = useAssetStore()
        const assetList = assetStore.getAll.value

        const scenarioStore = useScenarioStore()
        scenarioStore.updateSelectedScenarioList(projectId.value)
        const scenarioList = scenarioStore.getSelectedScenarios.value

        const scopeStore = useScopeStore()
        const timing = scopeStore.timing

        return {
            assetList,
            scenarioList,
            timing,
            projectType
        }
    }
})
</script>

<style lang="scss" scoped>
.timing-summary {
    .timing-table {
        margin: 2em;
        width: 80%;

        th {
            color: var(--color-brand-secondary);
            text-align: center;
            border-bottom: 1px solid;
        }
        td {
            padding: 10px 20px 10px 0;
        }
        .table-header-name {
            width: 45%;
        }
        .table-row {
            text-align: center;
        }
    }
}
</style>
