<template>
    <div class="motivation-summary">
        <div v-if="motivation.length > 0">
            <div v-if="motivation.length > 1">
                <p>
                    <strong>
                        Der Pentest erfolgt aus folgenden Motivationen.
                    </strong>
                </p>

                <ul>
                    <li v-for="item in motivation" :key="item">
                        <p v-if="item === 'interest'">
                            Eigeninteresse
                        </p>
                        <p v-else-if="item === 'requirement'">
                            Regulatorik
                        </p>
                        <p v-else-if="item === 'customer-requirement'">
                            Kundenanforderung
                        </p>
                        <p v-else-if="item === 'hacked'">
                            Hackerangriff
                        </p>
                    </li>
                </ul>
            </div>

            <div v-else>
                <p>
                    <strong>
                        Der Pentest erfolgt aus folgender Motivation.
                    </strong>
                </p>

                <div>
                    <p v-if="motivation[0] === 'interest'">
                        Eigeninteresse
                    </p>
                    <p v-else-if="motivation[0] === 'requirement'">
                        Regulatorik
                    </p>
                    <p v-else-if="motivation[0] === 'customer-requirement'">
                        Kundenanforderung
                    </p>
                    <p v-else-if="motivation[0] === 'hacked'">
                        Hackerangriff
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useScopeStore } from '@/store/scopeStore'

export default defineComponent({
    name: 'MotivationSummary',

    setup() {
        const scopeStore = useScopeStore()
        const motivation = scopeStore.motivation

        return {
            motivation
        }
    }
})
</script>

<style lang="scss" scoped>
ul {
    margin-left: -1.5em;
}
</style>
