<template>
    <div class="report-summary">
        <p>
            <strong>
                Für den Bericht gelten folgende Bedingungen:
            </strong>
        </p>

        <ul>
            <li v-if="report.layout === 'default' && report.language.length === 1 && report.language.includes('de')">
                <p>
                    Es wird ein ausführlicher Bericht im usd Standardformat (PDF) in deutscher Sprache verfasst.
                </p>
            </li>

            <li v-else-if="report.layout === 'default' && report.language.length === 1 && report.language.includes('en')">
                <p>
                    Es wird ein ausführlicher Bericht im usd Standardformat (PDF) in englischer Sprache verfasst.
                </p>
            </li>

            <li v-else-if="report.layout === 'default' && report.language.length > 1">
                <p>
                    Es wird ein ausführlicher Bericht im usd Standardformat (PDF) in deutscher und englischer Sprache verfasst.
                </p>
            </li>

            <li v-else-if="report.layout === 'customer' && report.language.length === 1 && report.language.includes('de')">
                <p>
                    Es wird ein ausführlicher Bericht auf Basis einer kundenspezifischen Vorlage in deutscher Sprache verfasst.
                </p>
            </li>

            <li v-else-if="report.layout === 'customer' && report.language.length === 1 && report.language.includes('en')">
                <p>
                    Es wird ein ausführlicher Bericht auf Basis einer kundenspezifischen Vorlage in englischer Sprache verfasst.
                </p>
            </li>

            <li v-else-if="report.layout === 'customer' && report.language.length > 1">
                <p>
                    Es wird ein ausführlicher Bericht auf Basis einer kundenspezifischen Vorlage in deutscher und englischer Sprache verfasst.
                </p>
            </li>

            <li>
                <p>
                    Schwachstellen werden nach den folgenden Risikoschemata bewertet und Maßnahmenempfehlungen ausgesprochen: {{ riskAssessmentList }}
                </p>
            </li>

            <li v-if="report.customerRiskAssessment">
                <p>
                    Eine davon abweichende Risikobewertung des Kunden wird von der usd in den Bericht eingearbeitet.
                </p>
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useScopeStore } from '@/store/scopeStore'

export default defineComponent({
    name: 'ReportSummary',

    setup() {
        const scopeStore = useScopeStore()
        const report = scopeStore.report

        const riskAssessmentList = computed(() => {
            let riskAssessments = ''
            report.riskAssessment.forEach((assessment, index) => {
                if (assessment === 'usd') {
                    riskAssessments += 'Risikobewertung durch usd'
                } else if (assessment === 'cvss') {
                    riskAssessments += 'Risikobewertung nach CVSS'
                } else if (assessment === 'cwss') {
                    riskAssessments += 'Risikobewertung nach CWSS'
                }
                if (index < report.riskAssessment.length - 1) {
                    riskAssessments += ', '
                }
            })
            return riskAssessments
        })

        return {
            report,
            riskAssessmentList
        }
    }
})
</script>

<style lang="scss" scoped>
.scope-item {
    display: flex;
    margin-bottom: 2em;
}
.scope-item-name {
    width: 30%;
}
ul {
    margin-left: -1.5em;
}
</style>
