import { render, staticRenderFns } from "./StandardScope.vue?vue&type=template&id=5c00d5fb&scoped=true"
import script from "./StandardScope.vue?vue&type=script&lang=ts"
export * from "./StandardScope.vue?vue&type=script&lang=ts"
import style0 from "./StandardScope.vue?vue&type=style&index=0&id=5c00d5fb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c00d5fb",
  null
  
)

export default component.exports