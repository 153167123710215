<template>
  <div id="app">
    <Navbar />

    <div class="content">
      <router-view></router-view>
    </div>

    <SupportFooter v-if="$route.name !== 'project summary'" />
    <FAQFooter />
    <Footer />
  </div>
</template>

<script>
import '@/styles/customstyle.scss'

import Navbar from '@/components/Navbar.vue'
import SupportFooter from '@/components/SupportFooter.vue'
import FAQFooter from '@/components/FAQFooter.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: 'App',
    components: {
        Navbar,
        SupportFooter,
        FAQFooter,
        Footer
    }
}
</script>

<style lang="scss">

</style>
