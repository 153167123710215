import { ref, reactive, computed } from 'vue'
import Repository from '@/repository/Repository'

// Interfaces
interface Customer {
    salutation: string,
    title: string,
    firstname: string,
    lastname: string,
    email: string,
    telephone: string,
    position: string
}

interface Company {
    name: string,
    street: string,
    zipcode: string,
    city: string,
    country: string
}

// Store
const customer = reactive<Customer>({
    salutation: '',
    title: '',
    firstname: '',
    lastname: '',
    email: '',
    telephone: '',
    position: ''
})

const company = reactive<Company>({
    name: '',
    street: '',
    zipcode: '',
    city: '',
    country: ''
})

const advertisingConsent = ref<Array<string>>([])

// Getters
const getters = {
    getCustomer: computed(() => customer),
    getCompany: computed(() => company)
}

const actions = {
    // saveCustomer: async(projectId: string) => {
    //     try {
    //
    //         const data = {
    //             projectUuid: projectId,
    //             salutation: customer.salutation,
    //             firstname: customer.firstname,
    //             lastname: customer.lastname,
    //             email: customer.email,
    //             company: company.name
    //         }
    //         await Repository.post('/customer', data)
    //     } catch (error) {
    //         // console.log('Save customer error: ' + error)
    //     }
    // },
    saveCustomer: async(projectId: string) => {
        try {

            const data = {
                projectUuid: projectId,
                customer: customer,
                company: company,
                advertisingConsent: advertisingConsent.value
            }
            await Repository.post('/customer', data)
        } catch (error) {
            // console.log('Update customer error: ' + error)
        }
    },
    sendMail: async(projectId: string) => {
        try {
            const data = { projectUuid: projectId }
            await Repository.post('/email', data)
        } catch (error: any) {
            throw new Error(error.statusCode)
            // console.log('Send email error: ' + error)
        }
    },
    createInquiryTask: async(projectId: string) => {
        try {
            const data = { projectUuid: projectId }
            await Repository.post('/asana/inquiry', data)
        } catch (error: any) {
            throw new Error(error.statusCode)
            // console.log('Create inquiry task error: ' + error)
        }
    }
}

export const useCustomerStore = () => {
    return {
        advertisingConsent,
        ...getters,
        ...actions
    } as const
}
