<template>
    <div class="contingent-config">
        <ConfiguratorHeader />

        <Progressbar :active="2" />

        <div class="contingent-config-options">

            <div class="consultant-days">
                <h2>
                    Volumen des Beratungskontingents *
                </h2>

                <p>
                    Bitte wählen Sie wie viele Beratertage Ihr Kontingent umfassen soll.
                </p>

                <Slider v-model="days" :min="5" :max="80" :scale="10" :unit="'Beratertage'" @input="updateContingent()"/>

            </div>

            <div class="contingent-agreement">
                <h2>
                    Rahmenbedingungen für das vereinbarte Kontingent *
                </h2>

                <p>
                    Nachfolgende Standardbedingungen gelten für vereinbarte Beratungskontingente der usd:
                </p>

                <ul>
                    <li>
                        Die Anzahl der vereinbarten Beratertage stellt eine Obergrenze dar.
                    </li>
                    <li>
                        Es gibt keine Abnahmeverpflichtung. Nicht genutzte Kontingente verfallen zum Vertragsende.
                    </li>
                    <li>
                        Das Kontingent hat eine Laufzeit von 12 Monaten.
                    </li>
                    <li>
                        Ein Beratertag entspricht 8 Stunden.
                    </li>
                    <li>
                        Es werden konkrete Arbeitspakete mit dem Auftraggeber abgestimmt.
                    </li>
                    <li>
                        Terminvereinbarungen erfolgen in gemeinsamer Absprache.
                    </li>
                    <li>
                        Die Abrechnung erfolgt auf Stundenbasis nach Aufwand zum Monatsende.
                    </li>
                    <li>
                        Abgerechnet wird jede angefangene halbe Stunde.
                    </li>
                    <li>
                        Der Auftraggeber erhält jeweils einen detaillierten Leistungsnachweis.
                    </li>
                </ul>

                <p>
                    Stimmen Sie den vorgenannten Standardbedingungen der usd zu  oder wünschen Sie individuelle Regelungen?
                </p>

                <BoxSelectRadio id="contingent-agreement-radio" v-model="agreement" :options="agreementOptions" @input="updateContingent()"/>
            </div>

            <div v-if="agreement === 'custom'" class="individual-configuration">
                <div class="consultant-days-slider">
                    <h2>
                        Definition eines Beratertages *
                    </h2>
                    <p>
                        Bitte konkretisieren Sie wie viele Stunden einem Beratertag entsprechen.
                    </p>

                    <Slider v-model="dailyHours" :min="6.0" :max="8.0" :step="0.5" :unit="'Stunden'" @input="updateContingent()"/>

                    <div class="buttons-info">
                        <button class="button-info" @click="openConsultantDaysModal">Weitere Infos</button>
                    </div>

                    <modal class="info-modal" v-model="consultantDaysModal">
                        <img slot="title" src="../../public/icon-info.svg" alt="info icon">
                        <h2 slot="title">Beratertage</h2>

                        <p>
                            Marktüblich sind Arbeitsverträge mit einer Arbeitszeit von 40 Stunden pro Woche.
                            Dies entspricht einer Arbeitswoche von 5 Tagen und einer Arbeitszeit von 8 Stunden
                            pro Tag exklusive Pausen.
                        </p>

                        <p>
                            Abweichungen zu dieser Regelung sind möglich. Wird beispielsweise die Wochenarbeitszeit
                            auf 37,5 Stunden festgelegt, entspricht dies einer Arbeitszeit von 7,5 Stunden pro Tag.
                            Um diese Sonderregelung zu adressieren, erlauben wir unseren Kunden eine flexible Definition
                            der Arbeitszeit pro Tag.
                        </p>

                        <form-actions slot="footer">
                            <button @click="closeConsultantDaysModal" class="primary button-modal">Schließen</button>
                        </form-actions>
                    </modal>
                </div>

                <hr>

                <div class="billing-unit">
                    <h2>
                        Abrechnungseinheit *
                    </h2>
                    <p>
                        Wie soll die Abrechnung erfolgen?
                    </p>

                    <BoxSelectRadio id="billing-unit-radio" v-model="billingUnit" :options="billingUnitOptions" @input="updateContingent()"/>

                    <div class="buttons-info">
                        <button class="button-info" @click="openBillingUnitModal">Weitere Infos</button>
                    </div>

                    <modal class="info-modal" v-model="billingUnitModal">
                        <img slot="title" src="../../public/icon-info.svg" alt="info icon">
                        <h2 slot="title">Abrechnungseinheit</h2>

                        <p>
                            Wir weisen auf unseren Rechnungen und Leistungsnachweisen die Arbeitszeit in Stunden aus.
                            Sofern gewünscht, können wir die Leistung gerne auch in Beratertagen ausweisen.
                        </p>

                        <p>
                            Hinweis: Abgerechnet wird in jedem Fall jede angefangene halbe Stunde.
                        </p>

                        <form-actions slot="footer">
                            <button @click="closeBillingUnitModal" class="primary button-modal">Schließen</button>
                        </form-actions>
                    </modal>
                </div>

                <hr>

                <div class="performance-proof">
                    <h2>
                        Leistungsnachweise *
                    </h2>
                    <p>
                        Wird ergänzend zum usd Leistungsnachweis ein kundenspezifischer Nachweis benötigt?
                    </p>

                    <tristate-switch-field id="performance-proof-radio" v-model="performanceProof" @input="updateContingent()" :error="performanceProofError" disable-null label="" label-pre="Ja" label-post="Nein"/>

                    <div class="buttons-info">
                        <button class="button-info" @click="openPerformanceProofModal">Weitere Infos</button>
                    </div>

                    <modal class="info-modal" v-model="performanceProofModal">
                        <img slot="title" src="../../public/icon-info.svg" alt="info icon">
                        <h2 slot="title">Leistungsnachweise</h2>

                        <p>
                            Nachfolgend abgebildet ist ein exemplarischer Leistungsnachweis der usd. Gerne stellen wir Ihnen
                            auf Wunsch einen davon abweichenden Leistungsnachweis, z.B. mit ergänzenden Angaben, zur Verfügung.
                        </p>

                        <p>
                            Darüber hinaus ist es selbstverständlich möglich, dass ein von Ihnen unterschriebener und somit
                            freigegebener Leistungsnachweis der Rechnung beigefügt wird.
                        </p>

                        <img class="hours-example" src="../../public/Stundenuebersicht.png"  alt="beispiel leistungsnachweis">

                        <form-actions slot="footer">
                            <button @click="closePerformanceProofModal" class="primary button-modal">Schließen</button>
                        </form-actions>
                    </modal>
                </div>

                <hr>

                <div class="duration">
                    <h2>
                        Laufzeit *
                    </h2>
                    <p>
                        Die maximale Laufzeit des Kontigents beträgt 12 Monate. Bitte wählen Sie die für Sie passende Laufzeit.
                    </p>

                    <Slider v-model="duration" :max="12" :unit="'Monate'" :unitSingular="'Monat'" @input="updateContingent()"/>

                </div>

                <hr>

                <div class="accepted-days">
                    <h2>
                        Abnahmeverpflichtung *
                    </h2>
                    <p>
                        Verpflichten Sie sich das vereinbarte Kontingent abzunehmen, ermöglicht uns dies eine bessere
                        Planbarkeit, die wir gerne bei größeren Kontingenten über Sonderkonditionen honorieren. Bitte geben
                        Sie nachfolgend an, ob Sie eine Abnahmeverpflichtung eingehen möchten.
                    </p>

                    <tristate-switch-field id="obligation-radio" v-model="obligation" @input="updateContingent()" disable-null label="" label-pre="Ja" label-post="Nein"/>

                    <div class="buttons-info">
                        <button class="button-info" @click="openObligationModal">Weitere Infos</button>
                    </div>

                    <modal class="info-modal" v-model="obligationModal">
                        <img slot="title" src="../../public/icon-info.svg" alt="info icon">
                        <h2 slot="title">Abnahmeverpflichtung</h2>

                        <p>
                            Sofern Sie eine Abnahmeverpflichtung eingehen, muss das vereinbarte Kontingent abgerufen
                            werden. Geschieht dies nicht, wird die Differenz aus den abgerufenen Beratertagen und der
                            vereinbarten Gesamtanzahl zum Vertragende von usd in Rechnung gestellt.
                        </p>

                        <p>
                            Bei Kontingenten von mehr als 40 Beratertagen honorieren wir Abnahmeverpflichtungen über
                            Sonderkonditionen.
                        </p>

                        <form-actions slot="footer">
                            <button @click="closeObligationModal" class="primary button-modal">Schließen</button>
                        </form-actions>
                    </modal>
                </div>

            </div>

        </div>

        <div class="buttons">
            <button class="ghost-primary configurator-button" @click="confirmBack">Zurück</button>
            <button class="primary configurator-button" @click="gotoProjectScope">Weiter</button>
        </div>

        <modal class="info-modal" v-model="confirmationModal">
            <img slot="title" src="./../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Möchten Sie Ihren Projektansatz wirklich ändern?</h2>

            <div class="buttons-modal">
                <div class="buttons-left">
                    <button class="ghost-primary button-modal-back-dialog" @click="gotoProjectType()">Ja, zurück zur Projektansatzauswahl.</button>
                </div>
                <div class="buttons-right">
                    <button class="primary button-modal-back-dialog" @click="closeConfirmationModal()">Nein, der Projektansatz wird weiterverfolgt.</button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch, nextTick } from 'vue'
import { useModal } from '@/composables/use-modal'
import { useRouter } from '@/composables/use-router'
import { useProjectStore } from '@/store/projectStore'
import { useContingentStore } from '@/store/contingentStore'
import { useScopeStore } from '@/store/scopeStore'
import { useOptionalStore } from '@/store/optionalStore'
import { usePriceStore } from '@/store/priceStore'
import ConfiguratorHeader from '@/components/ConfiguratorHeader.vue'
import Progressbar from '@/components/Progressbar.vue'
import Slider from '@/components/Slider.vue'
import BoxSelectRadio from '@/components/BoxSelectRadio.vue'
import { RequiredValidatorBoolean } from '@/validator/validator'

export default defineComponent({
    name: 'ContingentConfig',

    components: {
        ConfiguratorHeader,
        Progressbar,
        Slider,
        BoxSelectRadio
    },

    setup() {
        const { router, route } = useRouter()

        const { modal: confirmationModal, openModal: openConfirmationModal, closeModal: closeConfirmationModal } = useModal()
        const { modal: consultantDaysModal, openModal: openConsultantDaysModal, closeModal: closeConsultantDaysModal } = useModal()
        const { modal: billingUnitModal, openModal: openBillingUnitModal, closeModal: closeBillingUnitModal } = useModal()
        const { modal: performanceProofModal, openModal: openPerformanceProofModal, closeModal: closePerformanceProofModal } = useModal()
        const { modal: obligationModal, openModal: openObligationModal, closeModal: closeObligationModal } = useModal()

        const agreementOptions = [
            {
                id: 'default',
                label: 'Standardbedingungen'
            },
            {
                id: 'custom',
                label: 'Individuelle Bedingungen'
            }
        ]

        const billingUnitOptions = [
            {
                id: 'hours',
                label: 'auf Stundenbasis'
            },
            {
                id: 'days',
                label: 'auf Tagesbasis'
            }
        ]

        const projectStore = useProjectStore()
        const projectId = projectStore.uuid

        const contingentStore = useContingentStore()

        onMounted(async() => {
            if (projectId.value === '') {
                const scopeStore = useScopeStore()
                const optionalStore = useOptionalStore()
                await projectStore.loadProject(route.value.params.id)
                await contingentStore.loadContingent(route.value.params.id)
                await calculateContingentPrice()
                await scopeStore.loadScope(route.value.params.id)
                await priceStore.fetchScopePrice(route.value.params.id)
                await optionalStore.loadOptionals(route.value.params.id)
                await priceStore.fetchOptionalsPrices(route.value.params.id)
            }
        })

        const days = contingentStore.days
        const agreement = contingentStore.agreement
        const dailyHours = contingentStore.dailyHours
        const billingUnit = contingentStore.billingUnit
        const performanceProof = contingentStore.performanceProof
        const duration = contingentStore.duration
        const obligation = contingentStore.obligation

        const processing = ref<boolean>(false)

        const performanceProofError = ref<string>('')
        watch(() => performanceProof.value, (value) => {
            performanceProofError.value = RequiredValidatorBoolean(value)
        })

        const validate = async() => {
            performanceProofError.value = RequiredValidatorBoolean(performanceProof.value)
            if (performanceProofError.value === '') {
                return true
            } else {
                await nextTick()
                const element = document.querySelector('.invalid-feedback')?.parentNode?.parentNode as HTMLElement
                element.scrollIntoView({behavior: 'smooth'})
            }
        }

        const updateContingent = async() => {
            if (processing.value === false) {
                if (await validate()) {
                    processing.value = true
                    await contingentStore.updateContingent(projectId.value)
                    await calculateContingentPrice()
                    processing.value = false
                }
            }
        }

        const priceStore = usePriceStore()
        const calculateContingentPrice = async() => {
            await priceStore.fetchContingentPrice(projectId.value)
            await priceStore.fetchProjectPrice(projectId.value)
        }

        const confirmBack = () => {
            openConfirmationModal()
        }

        const gotoProjectType = async() => {
            window.scrollTo(0, 0)
            closeConfirmationModal()
            await router.push({ name: 'new project' })
        }

        const gotoProjectScope = async() => {
            if(await validate()) {
                await updateContingent()
                const scopeStore = useScopeStore()
                await scopeStore.initializeScope(projectId.value)
                window.scrollTo(0, 0)
                await router.push({ name: 'project scope', params: { id: projectId.value } })
            }
        }

        return {
            agreementOptions,
            billingUnitOptions,
            days,
            agreement,
            dailyHours,
            billingUnit,
            performanceProof,
            duration,
            obligation,
            updateContingent,
            confirmBack,
            gotoProjectType,
            gotoProjectScope,
            calculateContingentPrice,
            performanceProofError,
            confirmationModal,
            openConfirmationModal,
            closeConfirmationModal,
            consultantDaysModal,
            openConsultantDaysModal,
            closeConsultantDaysModal,
            billingUnitModal,
            openBillingUnitModal,
            closeBillingUnitModal,
            performanceProofModal,
            openPerformanceProofModal,
            closePerformanceProofModal,
            obligationModal,
            openObligationModal,
            closeObligationModal
        }
    }
})
</script>

<style lang="scss" scoped>
@import "../styles/breakpoints";

h2 {
    color: var(--color-brand-primary);
}

.contingent-header {
    @include default-padding();
}

.individual-configuration {
    background-color: var(--color-lightest-grey);
}

.consultant-days, .performance-proof, .accepted-days, .consultant-days-slider, .billing-unit, .contingent-agreement, .duration {
    @include default-padding();
}

.contingent-agreement {
    background-color: var(--color-white);

    ul {
        padding: 0 0 0 2em;
    }
}

.contingent-options {
    display: flex;
    margin-top: 1.5%;
    border: 1px solid var(--color-black);
    background-color: var(--color-white);
}

.contingent-options label {
    display: flex;
    margin: 2% 3%;
    p {
        margin: 0;
    }
}

.form-element-textfield {
    width: 50%;
}

.form-element-date input {
    width: 100%;
    border: none;
    background: none;
    border-bottom: 1px solid #ccc;
    padding: 0.2em 0.5em;
}

.form-element-date label {
    display: block;
    font-size: 0.8em;
    color: #666;
}

.hours-example {
    background-color: var(--color-lightest-grey);
    margin: 0 10%;
    padding: 5%;
    max-width: 100%;
    height: 900px;
}

hr {
    margin: 0 15%;
}

</style>
