import { ref } from 'vue'
import Repository from '@/repository/Repository'

// Store
const uuid = ref<string>('')

const name = ref<string>('')

const type = ref<string>('')

const inquiryId = ref<string>('')

// Actions
const actions = {
    initializeProject: async() => {
        try {
            const data = {
                name: name.value,
                type: type.value
            }
            const response = await Repository.post('/project', data)
            uuid.value = response.data as string
        } catch (error) {
            // console.log(error)
        }
    },
    loadProject: async(projectId: string) => {
        try {
            const params = { projectUuid: projectId }
            const response = await Repository.get('/project', { params })
            uuid.value = response.data.id as string
            name.value = response.data.name as string
            type.value = response.data.type as string
            inquiryId.value = response.data.inquiryId as string
        } catch (error) {
            // console.log('Load project error: ' + error)
        }
    },
    deleteProject: async() => {
        try {
            const params = { projectUuid: uuid.value }
            await Repository.delete('/project', { params })
        } catch (error) {
            // console.log('Delete error: ' + error)
        }
    }
}

export const useProjectStore = () => {
    return {
        uuid,
        name,
        type,
        inquiryId,
        ...actions
    } as const
}
