<template>
    <div class="project-scope">
        <ConfiguratorHeader />

        <Progressbar :active="3" />

        <StandardScope />

        <div v-if="standardScope !== 'default'">
            <div class="individual-configuration">
                <ScopeLocation />

                <hr>

                <ScopeTiming />

                <hr>

                <div v-if="projectType === 'asset'">
                    <ScopeSeparateEnvironment />

                    <hr>

                    <ScopeHardware />

                    <hr>
                </div>

                <ScopeReport />

                <hr>

                <ScopeGuidelines />
            </div>
        </div>

<!--        <ScopeDate :class="{'inverted-background': standardScope !== 'default'}"/>-->

        <ScopeMotivation :class="{'inverted-background': standardScope !== 'default'}"/>

        <div class="buttons">
            <button class="ghost-primary configurator-button" @click="$router.go(-1)">Zurück</button>
            <button class="primary configurator-button" @click="gotoProjectOptional">Weiter</button>
        </div>

    </div>
</template>

<script lang="ts">
import { defineComponent, nextTick, onMounted, ref, watch } from 'vue'
import { useRouter } from '@/composables/use-router'
import { useProjectStore } from '@/store/projectStore'
import { useScopeStore } from '@/store/scopeStore'
import { useAssetStore } from '@/store/assetStore'
import { useContingentStore } from '@/store/contingentStore'
import { useScenarioStore } from '@/store/scenarioStore'
import { usePriceStore } from '@/store/priceStore'
import ConfiguratorHeader from '@/components/ConfiguratorHeader.vue'
import Progressbar from '@/components/Progressbar.vue'
import StandardScope from '@/components/scope/StandardScope.vue'
import ScopeLocation from '@/components/scope/components/ScopeLocation.vue'
import ScopeTiming from '@/components/scope/components/ScopeTiming.vue'
import ScopeSeparateEnvironment from '@/components/scope/components/ScopeSeparateEnvironment.vue'
import ScopeHardware from '@/components/scope/components/ScopeHardware.vue'
import ScopeReport from '@/components/scope/components/ScopeReport.vue'
import ScopeGuidelines from '@/components/scope/components/ScopeGuidelines.vue'
import ScopeDate from '@/components/scope/components/ScopeDate.vue'
import ScopeMotivation from '@/components/scope/components/ScopeMotivation.vue'
import { useOptionalStore } from '@/store/optionalStore'
import { RequiredValidatorArray, RequiredValidatorBoolean } from '@/validator/validator'

export default defineComponent({
    name: 'ProjectScope',

    components: {
        ConfiguratorHeader,
        Progressbar,
        StandardScope,
        ScopeLocation,
        ScopeTiming,
        ScopeSeparateEnvironment,
        ScopeHardware,
        ScopeReport,
        ScopeGuidelines,
        ScopeDate,
        ScopeMotivation
    },

    setup() {
        const { router, route } = useRouter()

        const projectStore = useProjectStore()
        const projectId = projectStore.uuid
        const projectType = projectStore.type

        const assetStore = useAssetStore()
        const contingentStore = useContingentStore()
        const scenarioStore = useScenarioStore()

        const scopeStore = useScopeStore()

        onMounted(async() => {
            if (projectId.value === '') {
                const optionalStore = useOptionalStore()
                await projectStore.loadProject(route.value.params.id)
                if (projectType.value === 'asset') {
                    await assetStore.updateAssetList(route.value.params.id)
                    await calculateAssetPrice()
                } else if (projectType.value === 'contingent') {
                    await contingentStore.loadContingent(route.value.params.id)
                    await calculateContingentPrice()
                } else if (projectType.value === 'scenario') {
                    await scenarioStore.updateSelectedScenarioList(route.value.params.id)
                    await calculateScenarioPrice()
                }
                await scopeStore.loadScope(route.value.params.id)
                await priceStore.fetchScopePrice(route.value.params.id)
                await optionalStore.loadOptionals(route.value.params.id)
                await priceStore.fetchOptionalsPrices(route.value.params.id)
            }
        })

        const standardScope = scopeStore.standardSelection

        const priceStore = usePriceStore()
        const calculateScenarioPrice = async() => {
            await priceStore.calculateScenarioPrice(projectId.value)
            await priceStore.fetchProjectPrice(projectId.value)
        }
        const calculateAssetPrice = async() => {
            await priceStore.calculateAssetPrice(projectId.value)
            await priceStore.fetchProjectPrice(projectId.value)
        }
        const calculateContingentPrice = async() => {
            await priceStore.fetchContingentPrice(projectId.value)
            await priceStore.fetchProjectPrice(projectId.value)
        }

        const updateAssetList = async() => {
            await assetStore.updateAssetList(projectId.value)
        }

        const timing = scopeStore.timing
        const report = scopeStore.report

        const timingError = ref<string>('')
        const languageError = ref<string>('')
        const customerRiskAssessmentError = ref<string>('')

        watch(() => timing.value, (value) => {
            timingError.value = RequiredValidatorBoolean(value)
        })

        watch(() => report.language, (value) => {
            languageError.value = RequiredValidatorArray(value)
        })

        watch(() => report.customerRiskAssessment, (value) => {
            customerRiskAssessmentError.value = RequiredValidatorBoolean(value)
        })

        const validate = async() => {
            timingError.value = RequiredValidatorBoolean(timing.value)
            languageError.value = RequiredValidatorArray(report.language)
            customerRiskAssessmentError.value = RequiredValidatorBoolean(report.customerRiskAssessment)
            if (timingError.value === '' &&
                languageError.value === '' &&
                customerRiskAssessmentError.value === ''
            ) {
                return true
            } else {
                await nextTick()
                const element = document.querySelector('.invalid-feedback')?.parentNode?.parentNode as HTMLElement
                element.scrollIntoView({behavior: 'smooth'})
            }
        }

        const gotoProjectOptional = async() => {
            await scopeStore.updateScope(projectId.value)
            if (await validate()) {
                if (projectType.value === 'asset') {
                    await updateAssetList()
                    await calculateAssetPrice()
                } else if (projectType.value === 'scenario') {
                    await scenarioStore.updateSelectedScenarioList(projectId.value)
                    await calculateScenarioPrice()
                } else {
                    await priceStore.fetchProjectPrice(projectId.value)
                }
                window.scrollTo(0, 0)
                const optionalStore = useOptionalStore()
                await optionalStore.initializeOptionals(projectId.value)
                await router.push({ name: 'project optional', params: { id: projectId.value } })
            }
        }

        return {
            projectType,
            standardScope,
            gotoProjectOptional
        }
    }
})
</script>

<style lang="scss" scoped>
.project-scope {
    text-align: left;
    background-color: var(--color-lightest-grey);
}

.individual-configuration {
    background-color: var(--color-white);
}

.scope-date {
    background-color: var(--color-white);

    &.inverted-background {
        background-color: var(--color-lightest-grey);
    }
}

.scope-motivation {
    // Change background-color back when ScopeDate is activated again
    //background-color: var(--color-lightest-grey);
    background-color: var(--color-white);

    &.inverted-background {
        //background-color: var(--color-white);
        background-color: var(--color-lightest-grey);
    }
}

hr {
    margin: 0 15%;
}

@media screen and (max-width: 560px) {
    .buttons {
        display: flex;
        flex-direction: column;
    }
}
</style>
