<template>
    <div class='system-asset'>
        <div v-if="currentStep === 0">
            <div class="asset-question">
                <h2>
                    Assetname *
                </h2>
                <p>
                    Bitte geben Sie einen Namen für das Asset ein.
                </p>

                <text-field id="asset-name" label="" v-model="currentAsset.name" :error="nameError"></text-field>

                <div class="buttons-info">
                    <button class="button-info" @click="openAssetNameModal">Weitere Infos</button>
                </div>
            </div>

            <div class="asset-question asset-question-white">
                <h2>
                    Kurzbeschreibung *
                </h2>
                <p>
                    Bitte beschreiben Sie in wenigen Sätzen, wofür das oder die von Ihnen angelegte(n) IT-System(e)
                    eingesetzt wird/werden.
                </p>

                <text-area id="system-description" label="" v-model="currentAsset.description" :error="descriptionError"/>

                <div class="buttons-info">
                    <button class="button-info" @click="openDescriptionModal">Weitere Infos</button>
                </div>
            </div>
        </div>

        <div v-if="currentStep === 1">
            <div class="asset-question">
                <h2>
                    Anzahl zu prüfender IP-Adressen *
                </h2>
                <p>
                    Bitte geben Sie die Anzahl der zu prüfenden IP-Adressen an.
                </p>

                <Slider v-model="system.ipAmount" :min="1" :max="250" :startingValue="10" :scale="25" :unit="'IP-Adressen'" :unitSingular="'IP-Adresse'" @input="checkClearValueSampling" />

                <div class="buttons-info">
                    <button class="button-info" @click="openIpAmountModal">Weitere Infos</button>
                </div>

                <div v-if="(parseInt(system.ipAmount) > 60)">
                    <hr>

                    <h2>
                        Sampling *
                    </h2>
                    <p>
                        Sie haben eine große Anzahl an IP-Adressen ausgewählt, deren Überprüfung erfahrungsgemäß mit hohen
                        Aufwänden und Kosten verbunden ist. Um die Kosten zu reduzieren, bieten wir die Durchführung eines
                        Samplings an. Das heißt, IT-Systeme, die identisch konfiguriert sind, werden nur einmal überprüft.
                        Aufwände können dadurch eingespart und Kosten minimiert, die hohe Qualität der Pentest-Ergebnisse
                        jedoch beibehalten werden. Wünschen Sie den Einsatz von Sampling im Rahmen Ihres Pentests?
                    </p>

                    <tristate-switch-field id="system-sampling" v-model="system.sampling" :error="samplingError" label="" label-pre="Ja" label-post="Nein"/>

                    <div class="buttons-info">
                        <button class="button-info" @click="openSamplingModal">Weitere Infos</button>
                    </div>
                </div>
            </div>

            <div v-if="parseInt(system.ipAmount) > 1" class="asset-question asset-question-white">
                <h2>
                    Subnetze <sup>(optional)</sup>
                </h2>
                <p>
                    Werden die zu prüfenden IT-Systeme in verschiedenen Subnetzen betrieben?
                </p>

                <tristate-switch-field id="system-subnetting" v-model="system.subnetting" @input="checkClearValueSubnetAmount" label="" label-pre="Ja" label-post="Nein"/>

                <div class="buttons-info">
                    <button class="button-info" @click="openSubnetModal">Weitere Infos</button>
                </div>

                <div v-if="system.subnetting">
                    <hr>

                    <h2>
                        Anzahl der Subnetze <sup>(optional)</sup>
                    </h2>
                    <p>
                        Bitte nennen Sie uns die Anzahl der Subnetze.
                    </p>

                    <radio-field id="system-subnetAmount" label="" v-model="system.subnetAmount" :options="subnetAmountOptions" continuous/>
                </div>
            </div>
        </div>

        <div v-if="currentStep === 2">
            <div class="asset-question">
                <h2>
                    Erreichbarkeit im Internet *
                </h2>
                <p>
                    Sind die IT-Systeme direkt aus dem Internet erreichbar?
                </p>

                <tristate-switch-field id="system-reachability" v-model="system.reachability" :error="reachabilityError" label="" label-pre="Ja" label-post="Nein"/>

                <div class="buttons-info">
                    <button class="button-info" @click="openReachabilityModal">Weitere Infos</button>
                </div>
            </div>

            <div class="asset-question asset-question-white">
                <h2>
                    Systembetrieb <sup>(optional)</sup>
                </h2>
                <p>
                    Wie erfolgt der Betrieb der IT-Systeme?
                </p>

                <BoxSelectRadio id="system-operation" v-model="system.operation" :options="operationOptions" @input="checkClearValueOperatorList"/>

                <div v-if="system.operation === 'external' || system.operation === 'mixed'">
                    <hr>

                    <h2>
                        Liste der Dienstleister <sup>(optional)</sup>
                    </h2>
                    <p>
                        Gerne dürfen Sie uns den oder die Dienstleister für den Betrieb der IT-Systeme nennen.
                    </p>

                    <text-field id="system-operator" label="" v-model="system.operatorList[0]" />

                    <div v-if="numberAdditionalOperators === 0" class="add-button">
                        <button class="ghost-primary" @click="addOperator()">Weiteren Dienstleister hinzufügen</button>
                    </div>

                    <div v-for="currentIndex in numberAdditionalOperators" :key="currentIndex">
                        <div class="additional-field">
                            <text-field :id="'system-operator' + currentIndex" label="" v-model="system.operatorList[currentIndex]" />
                            <div class="icon-remove">
                                <button class="icon-button" @click="removeOperatorFromList(currentIndex)">
                                    <i class="material-icon mdi mdi-close" />
                                </button>
                            </div>
                        </div>

                        <div v-if="numberAdditionalOperators === currentIndex" class="add-button">
                            <button class="ghost-primary" @click="addOperator()">Weiteren Dienstleister hinzufügen</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="asset-question">
                <h2>
                    Cloud <sup>(optional)</sup>
                </h2>
                <p>
                    Erfolgt der Betrieb der IT-Systeme ganz oder teilweise in der Cloud?
                </p>

                <tristate-switch-field id="system-cloud" v-model="system.cloud" @input="checkClearValueCloudProviderCloudProviderIndividualList" label="" label-pre="Ja" label-post="Nein"/>

                <div v-if="system.cloud">
                    <hr>

                    <h2>
                        Cloudanbieter <sup>(optional)</sup>
                    </h2>
                    <p>
                        Welcher Cloudanbieter wird von Ihnen eingesetzt?
                    </p>

                    <BoxSelectCheckbox id="system-cloud-provider" label="" v-model="system.cloudProviderList" :options="cloudProviderOptions" @input="checkClearValueCloudProviderIndividualList"/>

                    <div class="buttons-info">
                        <button class="button-info" @click="openCloudProviderModal">Weitere Infos</button>
                    </div>

                    <div v-if="system.cloudProviderList.includes('individual')">
                        <hr>

                        <h2>
                            Liste der Cloudanbieter <sup>(optional)</sup>
                        </h2>
                        <p>
                            Bitte nennen Sie uns nachfolgend den eingesetzten Cloudanbieter
                        </p>

                        <text-field id="system-cloud-provider-individual" label="" v-model="system.cloudProviderIndividualList[0]"/>

                        <div v-if="numberAdditionalCloudProviders === 0" class="add-button">
                            <button class="ghost-primary" @click="addCloudProvider()">Weiteren Cloudanbieter hinzufügen</button>
                        </div>

                        <div v-for="currentIndex in numberAdditionalCloudProviders" :key="currentIndex">
                            <div class="additional-field">
                                <text-field :id="'system-cloud-provider-individual' + currentIndex" label="" v-model="system.cloudProviderIndividualList[currentIndex]" />
                                <div class="icon-remove">
                                    <button class="icon-button" @click="removeCloudProviderFromList(currentIndex)">
                                        <i class="material-icon mdi mdi-close" />
                                    </button>
                                </div>
                            </div>

                            <div v-if="numberAdditionalCloudProviders === currentIndex" class="add-button">
                                <button class="ghost-primary" @click="addCloudProvider()">Weiteren Cloudanbieter hinzufügen</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="currentStep === 3">
            <div class="asset-question">
                <h2>
                    Schutzbedarf <sup>(optional)</sup>
                </h2>
                <p>
                    Haben Sie den Schutzbedarf für die IT-Systeme ermittelt?
                </p>

                <tristate-switch-field id="system-security-assessment" v-model="system.securityAssessment" @input="checkClearValueSecurityAssessmentLevel" label="" label-pre="Ja" label-post="Nein"/>

                <div class="buttons-info">
                    <button class="button-info" @click="openSecurityAssessmentModal">Weitere Infos</button>
                </div>

                <div v-if="system.securityAssessment">
                    <hr>

                    <h2>
                        Höhe des Schutzbedarfs <sup>(optional)</sup>
                    </h2>
                    <p>
                        Wie hoch ist der Schutzbedarf/die Risikoeinstufung?
                    </p>

                    <radio-field label="" id="system-security-assessment-level" v-model="system.securityAssessmentLevel" :options="securityAssessmentLevelOptions" continuous/>

                    <div class="buttons-info">
                        <button class="button-info" @click="openSecurityAssessmentLevelModal">Weitere Infos</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="buttons">
            <div class="buttons-left">
                <button class="ghost-primary space-right" @click="back">Zurück</button>
            </div>
            <div class="buttons-center">
                <button v-if="route.path.includes('/edit')" class="ghost configurator-button" @click="openConfirmationEditModal()">Asset-Bearbeitung abbrechen</button>
                <button v-else class="ghost configurator-button" @click="openConfirmationCreateModal()">Asset-Erstellung abbrechen</button>
            </div>
            <div class="buttons-right">
                <button v-if="currentStep === 3 && !route.path.includes('/edit')" class="primary" @click="completeAsset">Asset-Konfiguration abschließen</button>
                <button v-else-if="currentStep === 3 && route.path.includes('/edit')" class="primary" @click="completeAsset">Asset-Bearbeitung abschließen</button>
                <button v-else class="primary" @click="advance">Weiter</button>
            </div>
        </div>

        <modal class="info-modal" v-model="confirmationCreateModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Möchten Sie die Asset-Erstellung wirklich abbrechen?</h2>

            <p>
                Dabei geht Ihre bisherige Konfiguration verloren.
            </p>

            <div class="buttons-modal">
                <div class="buttons-left">
                    <button class="ghost-primary button-modal-back-dialog" @click="gotoAssetList()">Ja, zurück zur Assetübersicht.</button>
                </div>
                <div class="buttons-right">
                    <button class="primary button-modal-back-dialog" @click="closeConfirmationCreateModal()">Nein, Asset-Konfiguration behalten.</button>
                </div>
            </div>
        </modal>

        <modal class="info-modal" v-model="confirmationEditModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Möchten Sie die Asset-Bearbeitung wirklich abbrechen?</h2>

            <p>
                Dabei gehen Ihre bisherigen Änderungen verloren.
            </p>

            <div class="buttons-modal">
                <div class="buttons-left">
                    <button class="ghost-primary button-modal-back-dialog" @click="gotoAssetList()">Ja, zurück zur Assetübersicht.</button>
                </div>
                <div class="buttons-right">
                    <button class="primary button-modal-back-dialog" @click="closeConfirmationEditModal()">Nein, Asset-Konfiguration behalten.</button>
                </div>
            </div>
        </modal>

        <modal class="info-modal" v-model="assetNameModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Assetname</h2>

            <p>
                Ihre Antwort hilft uns, die Anfrage intern besser zu verarbeiten. Bitte geben Sie einen für Sie eindeutigen
                Asset-Namen ein.
            </p>

            <form-actions slot="footer">
                <button @click="closeAssetNameModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="assetNameExistsModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Asset mit identischem Namen</h2>

            <p>
                Es existiert bereits ein Asset mit dem gleichen Namen. Möchten Sie den Namen des Assets ändern?
            </p>

            <div class="buttons-modal">
                <div class="buttons-left">
                    <button class="ghost-primary button-modal-back-dialog" @click="closeAssetNameExistsModal()">Ja, Namen ändern.</button>
                </div>
                <div class="buttons-right">
                    <button class="primary button-modal-back-dialog" @click="acceptAssetNameExists()">Nein, so fortfahren.</button>
                </div>
            </div>
        </modal>

        <modal class="info-modal" v-model="descriptionModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Kurzbeschreibung</h2>

            <p>
                Bitte beschreiben Sie in wenigen Sätzen, wofür das oder die von Ihnen angelegte(n) IT-System(e)
                eingesetzt wird/werden. Zentrale Fragen sind:
            </p>

            <ul>
                <li>
                    Handelt es sich um einen Web-, Datenbank-, Applikations- oder Mailserver oder ggf. um einen Router,
                    eine Firewall, einen zentralen Switch oder alternativ um eine Workstation eines Mitarbeiters?
                </li>
                <li>
                    Ist es ein einzelnes System oder ein Systemverbund?
                </li>
                <li>
                    Sollte es ein Systemverbund sein, ist er heterogen?
                </li>
                <li>
                    Werden Windows oder Linux IT-Systeme eingesetzt?
                </li>
                <li>
                    Wie interagieren die IT-Systeme?
                </li>
                <li>
                    Welche Daten werden von den IT-Systemen verarbeitet und/oder gespeichert?
                </li>
            </ul>

            <p>
                Ihre Beschreibung hilft uns, Ihren Prüfumfang besser zu verstehen. Je detaillierter Sie auf das Asset
                eingehen, umso besser können wir Ihren Prüfumfang im Vorfeld analysieren und kritisch hinterfragen.
            </p>

            <form-actions slot="footer">
                <button @click="closeDescriptionModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="ipAmountModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Anzahl zu prüfender IP-Adressen</h2>

            <p>
                Ein IT-System hat häufig eine IP-Adresse. In bestimmten Umgebungen können IT-Systeme (bspw. Firewalls)
                jedoch auch mehrere IP-Adressen besitzen. In diesen Fällen empfehlen wir die Prüfung sämtlicher
                IP-Adressen eines IT-Systems.
            </p>

            <form-actions slot="footer">
                <button @click="closeIpAmountModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="samplingModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Sampling</h2>

            <p>
                Oft existieren identisch konfigurierte IT-Systeme, beispielsweise um Redundanzen zu erzeugen und so 
                eine Ausfallsicherheit zu gewährleisten. In diesen Fällen ist es nicht sinnvoll jedes IT-System separat 
                zu testen, sondern stattdessen jede Systemkonfiguration einmal zu betrachten. Im Durchschnitt gehen wir 
                auf Basis unserer Erfahrungen davon aus, dass jedes 5. IT-System identisch konfiguriert ist. Prüfaufwände 
                können somit stark reduziert und die Ersparnis in Form reduzierter Preise an Sie weitergegeben werden. 
                Gleiche IT-Systeme identifizieren wir dabei über einen initialen Portscan aller IT-Systeme und dem Vergleich 
                der Ergebnisse. Alternativ kann das Sampling natürlich von Ihnen vorgegeben und zu Beginn des Pentests von 
                uns verifiziert werden.
            </p>

            <form-actions slot="footer">
                <button @click="closeSamplingModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="subnetModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Subnetze</h2>

            <p>
                Ein Subnetz ist ein physikalisches Segment (Teilnetz) eines Netzwerks, in dem IP-Adressen mit der
                gleichen Netzwerkadresse benutzt werden. Teilnetze können über Router und Switches miteinander
                verbunden werden und bilden dann ein großes zusammenhängendes Netzwerk.
            </p>

            <form-actions slot="footer">
                <button @click="closeSubnetModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="reachabilityModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Erreichbarkeit im Internet</h2>

            <p>
                IT-Systeme, die Unternehmens-intern, also im internen Firmen-Netzwerk, betrieben werden, sind nicht direkt
                über das Internet erreichbar.
            </p>
            <p>
                IT-Systeme, die öffentlich im Internet oder alternativ in einer an das Internet angebundenen
                demilitarisierten Zone mit oder ohne Schutz durch eine Firewall betrieben werden, sind direkt über das
                Internet erreichbar.
            </p>
            <p>
                Häufig kommt es dabei vor, dass der Internet-Zugriff mittels Black- bzw. White Listing auf bestimmte Nutzer
                beschränkt wird. Für die Beantwortung der Frage ist dies unerheblich; die IT-Systeme sind direkt aus dem
                Internet erreichbar.
            </p>

            <form-actions slot="footer">
                <button @click="closeReachabilityModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="cloudProviderModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Cloudanbieter</h2>

            <p>
                Im Rahmen des Pentests müssen ggf. besondere Vorgaben des Cloudanbieters eingehalten werden. Dies kann
                beispielsweise eine Vorankündigung der Sicherheitsanalyse bedeuten.
            </p>

            <form-actions slot="footer">
                <button @click="closeCloudProviderModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="securityAssessmentModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Schutzbedarf</h2>

            <p>
                Der Schutzbedarf misst sich an den potenziellen Schäden, die mit der Beeinträchtigung der
                betroffenen IT-Systeme, einhergehen. 
                Dabei werden üblicherweise die drei Schutzziele Integrität, Vertraulichkeit und Verfügbarkeit betrachtet. 
                Bei der Bewertung der Schäden sollten neben finanziellen Aspekten weitere Schadensszenarien, wie zum Beispiel: 
            </p>
            <ul>
                <li>Verstöße gegen Gesetze, Vorschriften oder Verträge</li>
                <li>Beeinträchtigungen des informationellen Selbstbestimmungsrechts</li>
                <li>Beeinträchtigungen der persönlichen Unversehrtheit</li>
                <li>Beeinträchtigungen der Aufgabenerfüllung</li>
            </ul>
            <p>
                berücksichtigt werden.
            </p>

            <form-actions slot="footer">
                <button @click="closeSecurityAssessmentModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="securityAssessmentLevelModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Höhe des Schutzbedarfs</h2>

            <p>
                Die konkrete Ausprägung der vorgenannten Schutzbedarfskategorien wird von jedem Unternehmen individuell
                vorgenommen und kann sich unterscheiden. Das Bundesamt für Informationssicherheit (BSI) liefert im
                BSI-Standard 100-4 folgendes Beispiel:
            </p>
            <ul>
                <li>Niedrig: kaum spürbare Auswirkung</li>
                <li>Normal: spürbare Auswirkungen</li>
                <li>Hoch: erhebliche Auswirkungen</li>
                <li>Sehr hoch: existentielle Auswirkungen</li>
            </ul>

            <form-actions slot="footer">
                <button @click="closeSecurityAssessmentLevelModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, onMounted, computed, nextTick, watch } from 'vue'
import { useModal } from '@/composables/use-modal'
import { useRouter } from '@/composables/use-router'
import { useProjectStore } from '@/store/projectStore'
import { useAssetStore } from '@/store/assetStore'
import Slider from '@/components/Slider.vue'
import BoxSelectRadio from '@/components/BoxSelectRadio.vue'
import BoxSelectCheckbox from '@/components/BoxSelectCheckbox.vue'
import { RequiredValidator, RequiredValidatorBoolean } from '@/validator/validator'

export default defineComponent({
    name: 'SystemAsset',

    props: {
        passedAsset: {
            type: Object,
            default: null
        }
    },

    components: {
        Slider,
        BoxSelectRadio,
        BoxSelectCheckbox
    },

    setup(props, { emit }) {
        const { router, route } = useRouter()

        const assetStore = useAssetStore()
        const currentAsset = computed(() => {
            if (route.value.path.includes('/edit')) {
                const assetList = assetStore.getAll.value
                return assetList.filter(asset => asset.id === route.value.params.assetId)[0]
            } else {
                return assetStore.getCurrentAsset.value
            }
        })

        const projectStore = useProjectStore()
        const projectId = projectStore.uuid

        const currentStep = ref<number>(0)

        const system = reactive<any>({
            ipAmount: typeof props.passedAsset.config.ipAmount === 'undefined' ? 10 : props.passedAsset.config.ipAmount,
            subnetting: typeof props.passedAsset.config.subnetting === 'undefined' ? null : props.passedAsset.config.subnetting,
            subnetAmount: typeof props.passedAsset.config.subnetAmount === 'undefined' ? '' : props.passedAsset.config.subnetAmount,
            sampling: typeof props.passedAsset.config.sampling === 'undefined' ? null : props.passedAsset.config.sampling,
            reachability: typeof props.passedAsset.config.reachability === 'undefined' ? null : props.passedAsset.config.reachability,
            operation: typeof props.passedAsset.config.operation === 'undefined' ? '' : props.passedAsset.config.operation,
            operatorList: typeof props.passedAsset.config.operatorList === 'undefined' ? [] : props.passedAsset.config.operatorList,
            cloud: typeof props.passedAsset.config.cloud === 'undefined' ? null : props.passedAsset.config.cloud,
            cloudProviderList: typeof props.passedAsset.config.cloudProviderList === 'undefined' ? [] : props.passedAsset.config.cloudProviderList,
            cloudProviderIndividualList: typeof props.passedAsset.config.cloudProviderIndividualList === 'undefined' ? [] : props.passedAsset.config.cloudProviderIndividualList,
            securityAssessment: typeof props.passedAsset.config.securityAssessment === 'undefined' ? null : props.passedAsset.config.securityAssessment,
            securityAssessmentLevel: typeof props.passedAsset.config.securityAssessmentLevel === 'undefined' ? '' : props.passedAsset.config.securityAssessmentLevel
        })

        const { modal: assetNameModal, openModal: openAssetNameModal, closeModal: closeAssetNameModal } = useModal()
        const { modal: assetNameExistsModal, openModal: openAssetNameExistsModal, closeModal: closeAssetNameExistsModal } = useModal()
        const { modal: descriptionModal, openModal: openDescriptionModal, closeModal: closeDescriptionModal } = useModal()
        const { modal: ipAmountModal, openModal: openIpAmountModal, closeModal: closeIpAmountModal } = useModal()
        const { modal: samplingModal, openModal: openSamplingModal, closeModal: closeSamplingModal } = useModal()
        const { modal: subnetModal, openModal: openSubnetModal, closeModal: closeSubnetModal } = useModal()
        const { modal: reachabilityModal, openModal: openReachabilityModal, closeModal: closeReachabilityModal } = useModal()
        const { modal: cloudProviderModal, openModal: openCloudProviderModal, closeModal: closeCloudProviderModal } = useModal()
        const { modal: securityAssessmentModal, openModal: openSecurityAssessmentModal, closeModal: closeSecurityAssessmentModal } = useModal()
        const { modal: securityAssessmentLevelModal, openModal: openSecurityAssessmentLevelModal, closeModal: closeSecurityAssessmentLevelModal } = useModal()
        const { modal: confirmationCreateModal, openModal: openConfirmationCreateModal, closeModal: closeConfirmationCreateModal } = useModal()
        const { modal: confirmationEditModal, openModal: openConfirmationEditModal, closeModal: closeConfirmationEditModal } = useModal()

        const subnetAmountOptions = [
            {
                id: '2-5',
                label: '2 bis 5 Subnetze'
            },
            {
                id: '6-10',
                label: '6 bis 10 Subnetze'
            },
            {
                id: 'more than 10',
                label: 'Mehr als 10 Subnetze'
            }
        ]

        const operationOptions = [
            {
                id: 'customer',
                label: 'Durch uns selbst'
            },
            {
                id: 'external',
                label: 'Durch einen Dienstleister'
            },
            {
                id: 'mixed',
                label: 'Gemeinsam mit einem Dienstleister'
            }
        ]

        const cloudProviderOptions = [
            {
                id: 'aws',
                label: 'Amazon Web Services (AWS)'
            },
            {
                id: 'gcp',
                label: 'Google Cloud Platform (GCP)'
            },
            {
                id: 'azure',
                label: 'Microsoft Azure'
            },
            {
                id: 'individual',
                label: 'Andere'
            }
        ]

        const securityAssessmentLevelOptions = [
            {
                id: 'low',
                label: 'Niedrig'
            },
            {
                id: 'regular',
                label: 'Normal'
            },
            {
                id: 'high',
                label: 'Hoch'
            },
            {
                id: 'very high',
                label: 'Sehr hoch'
            }
        ]

        const numberAdditionalOperators = ref<number>(0)
        const numberAdditionalCloudProviders = ref<number>(0)

        const checkOperatorListLength = () => {
            if (system.operatorList.length !== 0) {
                numberAdditionalOperators.value = system.operatorList.length - 1
            }
        }

        const checkCloudProviderIndividualListLength = () => {
            if (system.cloudProviderIndividualList.length !== 0) {
                numberAdditionalCloudProviders.value = system.cloudProviderIndividualList.length - 1
            }
        }

        const removeOperatorFromList = (index: number) => {
            system.operatorList.splice(index, 1)
            numberAdditionalOperators.value--
        }

        const removeCloudProviderFromList = (index: number) => {
            system.cloudProviderIndividualList.splice(index, 1)
            numberAdditionalCloudProviders.value--
        }

        const addOperator = () => {
            numberAdditionalOperators.value++
        }

        const addCloudProvider = () => {
            numberAdditionalCloudProviders.value++
        }

        onMounted(() => {
            checkOperatorListLength()
            checkCloudProviderIndividualListLength()
        })

        const markAssetDone = () => {
            props.passedAsset.config = system
            emit('systemAssetDone', props.passedAsset)
        }

        const completeAsset = async() => {
            if (await checkAdvance()) {
                markAssetDone()
            }
        }

        const checkClearValueSampling = () => {
            if (parseInt(system.ipAmount) <= 60) {
                system.sampling = null
            }
        }

        const checkClearValueSubnetAmount = () => {
            if (system.subnetting !== true) {
                system.subnetAmount = ''
            }
        }

        const checkClearValueOperatorList = () => {
            if (system.operation === 'customer') {
                system.operatorList = []
            }
        }

        const checkClearValueCloudProviderCloudProviderIndividualList = () => {
            if (system.cloud !== true) {
                system.cloudProvider = ''
                system.cloudProviderIndividualList = []
            }
        }

        const checkClearValueCloudProviderIndividualList = () => {
            if (!system.cloudProviderList.includes('individual')) {
                system.cloudProviderIndividualList = []
            }
        }

        const checkClearValueSecurityAssessmentLevel = () => {
            if (system.securityAssessment !== true) {
                system.securityAssessmentLevel = ''
            }
        }

        const nameUnique = ref<boolean>(true)
        const checkAssetNameUnique = () => {
            nameUnique.value = true
            const assetList = assetStore.getAll.value
            assetList.forEach((asset: any) => {
                if (currentAsset.value.name === asset.name && !route.value.path.includes('/edit')) {
                    nameUnique.value = false
                    openAssetNameExistsModal()
                }
            })
        }

        const acceptAssetNameExists = async() => {
            nameUnique.value = true
            closeAssetNameExistsModal()
            if (await validateFirstStepWithoutAssetNameUnique()) {
                window.scrollTo(0, 0)
                currentStep.value++
            }
        }

        const checkAdvance = async() => {
            if (currentStep.value === 0) {
                if (await validateFirstStep()) {
                    return true
                }
            } else if (currentStep.value === 1) {
                if (await validateSecondStep()) {
                    return true
                }
            } else if (currentStep.value === 2) {
                if (await validateThirdStep()) {
                    return true
                }
            } else if (currentStep.value === 3) {
                return true
            }
            return false
        }

        const advance = async() => {
            if (await checkAdvance()) {
                if (currentStep.value < 3) {
                    window.scrollTo(0, 0)
                    currentStep.value++
                }
            }
        }

        const back = () => {
            if (currentStep.value > 0) {
                currentStep.value--
            } else {
                assetStore.clearAsset()
                router.go(-1)
            }
        }

        const reset = () => {
            currentStep.value = 0
        }

        const nameError = ref<string>('')
        const descriptionError = ref<string>('')
        const samplingError = ref<string>('')
        const reachabilityError = ref<string>('')

        watch(() => currentAsset.value.name, (value) => {
            nameError.value = RequiredValidator(value)
        })

        watch(() => currentAsset.value.description, (value) => {
            descriptionError.value = RequiredValidator(value)
        })

        watch(() => system.sampling, (value) => {
            samplingError.value = RequiredValidatorBoolean(value)
        })

        watch(() => system.reachability, (value) => {
            reachabilityError.value = RequiredValidatorBoolean(value)
        })

        const validateFirstStep = async() => {
            nameError.value = RequiredValidator(currentAsset.value.name)
            descriptionError.value = RequiredValidator(currentAsset.value.description)
            if (nameError.value === '' && descriptionError.value === '') {
                checkAssetNameUnique()
                if (nameUnique.value) {
                    return true
                }
            } else {
                await nextTick()
                const element = document.querySelector('.invalid-feedback')?.parentNode?.parentNode as HTMLElement
                element.scrollIntoView({behavior: 'smooth'})
            }
        }

        const validateFirstStepWithoutAssetNameUnique = async() => {
            nameError.value = RequiredValidator(currentAsset.value.name)
            descriptionError.value = RequiredValidator(currentAsset.value.description)
            if (nameError.value === '' && descriptionError.value === '') {
                return true
            } else {
                await nextTick()
                const element = document.querySelector('.invalid-feedback')?.parentNode?.parentNode as HTMLElement
                element.scrollIntoView({behavior: 'smooth'})
            }
        }

        const validateSecondStep = async() => {
            if (system.ipAmount > 60) {
                samplingError.value = RequiredValidatorBoolean(system.sampling)
            }
            if (samplingError.value === '' || system.ipAmount <= 60) {
                return true
            } else {
                await nextTick()
                const element = document.querySelector('.invalid-feedback')?.parentNode?.parentNode as HTMLElement
                element.scrollIntoView({behavior: 'smooth'})
            }
        }

        const validateThirdStep = async() => {
            reachabilityError.value = RequiredValidatorBoolean(system.reachability)
            if (reachabilityError.value === '') {
                return true
            } else {
                await nextTick()
                const element = document.querySelector('.invalid-feedback')?.parentNode?.parentNode as HTMLElement
                element.scrollIntoView({behavior: 'smooth'})
            }
        }

        const gotoAssetList = () => {
            if (!route.value.path.includes('/edit')) {
                const assetStore = useAssetStore()
                assetStore.clearAsset()
            }
            window.scrollTo(0, 0)
            router.replace({ name: 'asset-based project', params: { id: projectId.value } })
        }

        return {
            route,
            currentAsset,
            currentStep,
            system,
            numberAdditionalOperators,
            acceptAssetNameExists,
            removeOperatorFromList,
            addOperator,
            numberAdditionalCloudProviders,
            removeCloudProviderFromList,
            addCloudProvider,
            assetNameModal,
            openAssetNameModal,
            closeAssetNameModal,
            assetNameExistsModal,
            openAssetNameExistsModal,
            closeAssetNameExistsModal,
            descriptionModal,
            openDescriptionModal,
            closeDescriptionModal,
            ipAmountModal,
            openIpAmountModal,
            closeIpAmountModal,
            samplingModal,
            openSamplingModal,
            closeSamplingModal,
            subnetModal,
            openSubnetModal,
            closeSubnetModal,
            reachabilityModal,
            openReachabilityModal,
            closeReachabilityModal,
            cloudProviderModal,
            openCloudProviderModal,
            closeCloudProviderModal,
            securityAssessmentModal,
            openSecurityAssessmentModal,
            closeSecurityAssessmentModal,
            securityAssessmentLevelModal,
            openSecurityAssessmentLevelModal,
            closeSecurityAssessmentLevelModal,
            confirmationCreateModal,
            openConfirmationCreateModal,
            closeConfirmationCreateModal,
            confirmationEditModal,
            openConfirmationEditModal,
            closeConfirmationEditModal,
            subnetAmountOptions,
            operationOptions,
            cloudProviderOptions,
            securityAssessmentLevelOptions,
            checkClearValueSampling,
            checkClearValueSubnetAmount,
            checkClearValueOperatorList,
            checkClearValueCloudProviderCloudProviderIndividualList,
            checkClearValueCloudProviderIndividualList,
            checkClearValueSecurityAssessmentLevel,
            nameError,
            descriptionError,
            samplingError,
            reachabilityError,
            completeAsset,
            advance,
            back,
            reset,
            gotoAssetList
        }
    }
})
</script>

<style lang="scss" scoped>
@import "../../styles/breakpoints";

.system-asset {
    h2 {
        color: var(--color-brand-primary);
    }

    .asset-question {
        @include default-padding();
    }

    .asset-question-white {
        background-color: var(--color-white);
    }

    .additional-field {
        display: flex;
    }

    .add-button {
        margin: 2em 0 0 -0.5em;
    }
}
</style>
