import { ref } from 'vue'

export const useModal = (isOpen?: Boolean) => {
    if (isOpen !== true) {
        isOpen = false
    }

    const modal = ref(isOpen)
    const openModal = () => {
        modal.value = true
    }
    const closeModal = () => {
        modal.value = false
    }
    return {
        modal,
        openModal,
        closeModal
    }
}
