<template>
    <div class="configurator-header">
        <img class="configurator-header-image" src="../../public/image-header.jpg">
        <div class="configurator-header-text">
            <h1 @click="openConfirmationModal()">Pentest-Konfigurator</h1>
        </div>

        <modal class="info-modal" v-model="confirmationModal">
            <img slot="title" src="./../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Konfiguration abbrechen und Konfigurator verlassen</h2>

            <p>
                Möchten Sie den Konfigurator wirklich verlassen? Ihre bisherigen Eingaben gehen dadurch unwiderruflich verloren.
            </p>

            <div class="buttons-modal">
                <div class="buttons-left">
                    <button class="ghost-primary button-modal-back-dialog" @click="gotoHome()">Ja, Konfigurator verlassen.</button>
                </div>
                <div class="buttons-right">
                    <button class="primary button-modal-back-dialog" @click="closeConfirmationModal()">Nein, Konfiguration fortsetzen.</button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useRouter } from '@/composables/use-router'
import { useModal } from '@/composables/use-modal'
import { useProjectStore } from '@/store/projectStore'

export default defineComponent({
    name: 'ConfiguratorHeader',

    setup() {
        const { router } = useRouter()

        const { modal: confirmationModal, openModal: openConfirmationModal, closeModal: closeConfirmationModal } = useModal()

        const projectStore = useProjectStore()

        const gotoHome = () => {
            projectStore.deleteProject()
            closeConfirmationModal()
            router.replace({ name: 'home' })
            window.scrollTo(0, 0)
            window.location.reload()
        }

        return {
            gotoHome,
            confirmationModal,
            openConfirmationModal,
            closeConfirmationModal,
        }
    }
})
</script>

<style lang="scss" scoped>
@import "../styles/breakpoints";

.configurator-header {
    position: relative;
    height: 250px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.configurator-header-image {
    position: absolute;
    min-width: 100%;
    min-height: 100%;

    object-fit: cover;
    bottom: -80%;
    right: 0;
}

.configurator-header-text {
    position: absolute;
    h1 {
        font-size: 50px;
        color: var(--color-white);
        cursor: pointer;
    }
}

@media screen and (max-width: $bp-medium) {
    .configurator-header-text h1 {
        font-size: 30px;
    }
}
</style>
