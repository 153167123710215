<template>
    <div class="certifications">
        <h1>
            Unsere Zertifizierungen
        </h1>

        <div class="certifications-container">
            <div class="certifications-column">
                <div class="certification">
                    <h2 class="certification-name-row-1">
                        OSCP
                    </h2>
                    <p>
                        Offensive Security Certified Professional
                    </p>
                </div>

                <div class="certification">
                    <h2 class="certification-name-row-1">
                        OSCE
                    </h2>
                    <p>
                        Offensive Security Certified Expert
                    </p>
                </div>

                <div class="certification">
                    <h2 class="certification-name-row-1">
                        OSEP
                    </h2>
                    <p>
                        Offensive Security Experienced Penetration Tester
                    </p>
                </div>

                <div class="certification">
                    <h2 class="certification-name-row-1">
                        OSWE
                    </h2>
                    <p>
                        Offensive Security Web Expert
                    </p>
                </div>

                <div class="certification">
                    <h2 class="certification-name-row-1">
                        OSED
                    </h2>
                    <p>
                        Offensive Security Exploit Developer
                    </p>
                </div>

                <div class="certification">
                    <h2 class="certification-name-row-1">
                        CEH
                    </h2>
                    <p>
                        Certified Ethical Hacker
                    </p>
                </div>
            </div>

            <div class="certifications-column">
                <div class="certification">
                    <h2 class="certification-name-row-2">
                        ASV
                    </h2>
                    <p>
                        Approved Scanning Vendor
                    </p>
                </div>

                <div class="certification">
                    <h2 class="certification-name-row-2">
                        ITIL
                    </h2>
                    <p>
                        IT Infrastructure Library
                    </p>
                </div>

                <div class="certification">
                    <h2 class="certification-name-row-2">
                        SANS SEC 504
                    </h2>
                    <p>
                        Hacker Tools, Techniques, Exploits, and Incident Handling
                    </p>
                </div>

                <div class="certification">
                    <h2 class="certification-name-row-2">
                        SANS SEC 575
                    </h2>
                    <p>
                        Mobile Device Security and Ethical Hacking
                    </p>
                </div>

                <div class="certification">
                    <h2 class="certification-name-row-2">
                        SANS FOR 408
                    </h2>
                    <p>
                        Windows Forensic Analysis
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Certifications'
})
</script>

<style lang="scss">
@import "../../styles/breakpoints";

.certifications {
    padding: 2.5% 10%;
    h1 {
        font-size: 50px;
    }
}

.certifications-container {
    display: flex;
    justify-content: flex-start;
    padding-top: 3%;
}

.certifications-column {
    margin-right: 5%;
}

.certification {
    display: flex;
    text-align: left;
    min-height: 55px;
    h2 {
        color: var(--color-brand-primary);
        font-weight: 400;
        margin-right: 10px;
    }
    .certification-name-row-1 {
        width: 80px;
    }
    .certification-name-row-2 {
        width: 180px;
    }
    p {
        color: var(--color-anthracite);
        font-weight: lighter;
    }
}

@media screen and (max-width: $bp-medium) {
    .certifications {
        padding: 2em 1em;
    }

    .certifications h1 {
        font-size: 30px;
    }

    .certifications-container {
        flex-direction: column;
    }

    .certification {
        .certification-name-row-1,
        .certification-name-row-2 {
            width: 180px;
        }

        p {
            width: calc(100% - 180px);
        }
    }
}

@media screen and (max-width: $bp-small) {
    .certification {
        .certification-name-row-1,
        .certification-name-row-2 {
            width: 80px;
        }

        p {
            width: calc(100% - 80px);
        }
    }
}
</style>
