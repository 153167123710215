<template>
    <div class="guidelines-summary">
        <p>
            <strong>
                Es gelten folgende Anforderungen für die eingesetzten Pentester sowie die Organisation.
            </strong>
        </p>

        <ul>
            <li class="list-item">
                <p class="list-item-name">
                    Personenzertifizierungen:
                </p>

                <p>
                    {{ pentesterCerts }}
                </p>
            </li>

            <li class="list-item">
                <p class="list-item-name">
                    Zusätzliche Sicherheitsüberprüfungen:
                </p>

                <div class="list-clearances">
                    <p v-if="guidelines.securityClearance.includes('criminal record')">
                        Polizeiliches Führungszeugnis
                    </p>
                    <p v-if="guidelines.securityClearance.includes('sueg')">
                        Sicherheitsüberprüfung gemäß Sicherheitsüberprüfungsgesetz (SÜG)
                    </p>
                </div>
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useScopeStore } from '@/store/scopeStore'

export default defineComponent({
    name: 'GuidelinesSummary',

    setup() {
        const scopeStore = useScopeStore()
        const guidelines = scopeStore.guidelines

        const pentesterCerts = computed(() => {
            let certs = ''
            guidelines.certPentester.forEach((cert, index) => {
                if (index < guidelines.certPentester.length - 1) {
                    certs += cert.toUpperCase() + ', '
                } else {
                    certs += cert.toUpperCase()
                }
            })
            return certs
        })

        return {
            guidelines,
            pentesterCerts
        }
    }
})
</script>

<style lang="scss" scoped>
@import "../../../styles/breakpoints";

.list-item-name {
    float: left;
    width: 20em;
    max-width: 100%;
}
.list-clearances {
    display: flex;
    flex-direction: column;
}
ul {
    margin-left: -1.5em;
}

@media screen and (max-width: $bp-medium) {
    .list-item-name {
        float: none;
    }
}
</style>
