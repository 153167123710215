<template>
    <div class="project-type">
        <h2>Projektansatz *</h2>

        <p>
            Bitte wählen Sie einen der folgenden Projektansätze aus.
        </p>

        <div class="project-type-container">
            <label>
                <input
                    class="project-type-card"
                    type="radio"
                    id="scenario"
                    name="project-type"
                    value="scenario"
                    v-model="projectType"
                />
                    <div class="project-type-card-input">
                        <img class="card-header-image" src="../../../public/images-project/projektansatz-szenario.jpg" alt="bild ansatz szenario">

                        <div class="card-text">
                            <h2>{{ fromJson.scenario.heading }}</h2>
                            <p>
                                {{ fromJson.scenario.body }}
                            </p>
                        </div>

                        <div class="card-checkmark" :class="{ unchecked: projectType !== 'scenario' }">
                            <i class="material-icon mdi mdi-check" />
                        </div>
                    </div>
            </label>

            <label>
                <input
                    class="project-type-card"
                    type="radio"
                    id="contingent"
                    name="project-type"
                    value="contingent"
                    v-model="projectType"
                />
                    <div class="project-type-card-input">
                        <img class="card-header-image" src="../../../public/images-project/projektansatz-kontingent.jpg" alt="bild ansatz kontingent">

                        <div class="card-text">
                            <h2>{{ fromJson.contingent.heading }}</h2>
                            <p>
                                {{ fromJson.contingent.body }}
                            </p>
                        </div>

                        <div class="card-checkmark" :class="{ unchecked: projectType !== 'contingent' }">
                            <i class="material-icon mdi mdi-check" />
                        </div>
                    </div>
            </label>

            <label>
                <input
                    class="project-type-card"
                    type="radio"
                    id="asset"
                    name="project-type"
                    value="asset"
                    v-model="projectType"
                />
                    <div class="project-type-card-input">
                        <img class="card-header-image" src="../../../public/images-project/projektansatz-asset.jpg" alt="bild ansatz asset">

                        <div class="card-text">
                            <h2>{{ fromJson.asset.heading }}</h2>
                            <p>
                                {{ fromJson.asset.body }}
                            </p>
                        </div>

                        <div class="card-checkmark" :class="{ unchecked: projectType !== 'asset' }">
                            <i class="material-icon mdi mdi-check" />
                        </div>
                    </div>
            </label>
        </div>

        <div v-if="error !== ''" class="invalid-feedback">
            {{ error }}
        </div>

        <div class="buttons-info">
            <button class="button-info" @click="openProjectTypeModal">Weitere Infos</button>
        </div>

        <modal class="info-modal" v-model="projectTypeModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Welcher Pentest-Ansatz ist für Sie geeignet?</h2>

            <p>
                <strong>
                    Szenario-basierter Ansatz:
                </strong>
            </p>
            <p>
                Sie haben in der Presse von einem neuen Hackerangriff gelesen, über Ihr Netzwerk von einem großen Cyber-Vorfall 
                in Ihrer Branche gehört oder sind auf mögliche technische Gefahren und Risiken innerhalb Ihres Unternehmens hingewiesen 
                worden. Sie fragen sich, ob Sie das nächste „Cyber-Opfer“ sind. Vielleicht wurden Sie auch stattdessen von Ihrer Versicherung, 
                einem Kunden oder aufgrund von Branchenvorgaben aufgefordert, Ihre technischen Cyber-Risiken bewerten zu lassen. In diesen 
                oder vergleichbaren Fällen bieten Ihnen unsere Szenario-basierten Pentests eine konkrete Antwort. Sie wählen die Angriffsszenarien 
                aus, die Sie interessieren. Technisches Fachwissen ist nicht notwendig. Nach der Beauftragung überprüfen wir Ihre Sicherheit 
                mittels standardisierter Pentests. Für den Abschluss des Pentest-Konfigurators im Szenario-basierten Ansatz sollten Sie einen 
                Zeitaufwand von ca. 10-15 Minuten einkalkulieren.
            </p>
            <p>
                <strong>
                    Kontingent-basierter Ansatz:
                </strong>
            </p>
            <p>
                Sie planen über einen beliebigen Zeitraum, z.B. einige Monate oder ein Jahr, einen größeren oder mehrere
                separate Pentests durchzuführen, können die Prüfinhalte (z.B. die zu prüfenden Ziele oder die Prüftiefe)
                allerdings zum aktuellen Zeitpunkt noch nicht  konkretisieren. In diesem Fall empfehlen wir die Vereinbarung
                eines Abrufkontingents, das wir in Absprache mit Ihnen flexibel nutzen, um auf Ihre Sicherheitsanforderungen
                dynamisch und hochindividuell zu reagieren. Bei der Ausgestaltung des Kontingents stehen Ihnen zahlreiche
                Optionen zur Verfügung. Technisches Fachwissen wird nicht benötigt. Sobald das Kontingent von Ihnen
                beauftragt ist, steigen wir gemeinsam in die Planungsphase ein und führen daraufhin abgestimmt Ihre
                individuellen Pentests durch. Für den Abschluss des Pentest-Konfigurators im Kontingent-basierten Ansatz
                sollten Sie einen Zeitaufwand von ca. 5-10 Minuten einkalkulieren.
            </p>
            <p>
                <strong>
                    Asset-basierter Ansatz:
                </strong>
            </p>
            <p>
                Hierbei handelt es sich um den Expertenmodus, der sich an technisch versierte Nutzer richtet. In einem
                geführten, einfachen Prozess ermitteln wir dabei zusammen mit Ihnen für jede zu prüfende IT-Komponente
                (z.B. Web- und Datenbankserver, Firewalls, Router, Webapplikationen und Webservices) die notwendigen
                technischen Detailinformationen und planen so Ihren Pentest mit individuellem Prüfumfang. Für den
                Abschluss des Pentest-Konfigurators im Asset-basierten Ansatz sollten Sie einen Zeitaufwand von mindestens
                20 Minuten, abhängig von Ihrem Prüfumfang, einkalkulieren.
            </p>

            <form-actions slot="footer">
                <button @click="closeProjectTypeModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useModal } from '@/composables/use-modal'
import { useProjectStore } from '@/store/projectStore'
import json from '@/json/ProjectTypeDE.json'

export default defineComponent({
    name: 'ProjectType',

    props: {
        error: {
            type: String,
            default: ''
        }
    },

    setup() {
        const { modal: projectTypeModal, openModal: openProjectTypeModal, closeModal: closeProjectTypeModal } = useModal()

        const fromJson = json

        const projectStore = useProjectStore()
        const projectType = projectStore.type

        return {
            fromJson,
            projectType,
            projectTypeModal,
            openProjectTypeModal,
            closeProjectTypeModal
        }
    }
})
</script>

<style lang="scss" scoped>
@import "../../styles/breakpoints";

.project-type {
    background-color: var(--color-white);
    @include default-padding();
    h2 {
        color: var(--color-brand-primary);
    }
}

.project-type-container {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    margin-top: 2em;

    label {
        width: 31%;
        max-width: 450px;
        display: flex;
        align-items: stretch;
        margin: 1%;
    }
}

.project-type-card {
    display: none;
}

.project-type-card-input {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin: 0;
    border: 2px solid var(--color-light-grey);
}

.project-type-card:checked + .project-type-card-input {
    border-color: var(--color-brand-secondary);
    background-color: var(--color-brand-secondary);
    color: var(--color-white);
    .card-text {
        h2 {
            color: var(--color-white);
        }
    }
}

.card-header-image {
    margin: -2px;
    width: calc(100% + 4px);
}

.card-text {
    flex: 1 auto;
    padding: 2em;
    h2 {
        color: var(--color-brand-secondary);
        text-align: center;
    }
}

@media screen and (max-width: 960px) {
    .project-type-container {
        display: flex;
        flex-direction: column;
    }

    .project-type-container label {
        width: 100%;
        max-width: initial;
        margin-bottom: 2em;
    }
}

</style>
