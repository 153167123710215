<template>
    <i :class="classes"></i>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'

export default defineComponent({
    props: {
        icon: {
            type: String,
            required: true
        }
    },

    setup(props) {
        const classes = computed(() => {
            const output = ['material-icon', 'mdi']
            output.push('mdi-' + props.icon)

            return output
        })

        return {
            classes
        }
    }
})
</script>

<style lang="scss" scoped>
    @import '~@mdi/font/css/materialdesignicons.css';

    .mdi::before, .mdi-set {
        font-size: 24px;
    }
</style>
