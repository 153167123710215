var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('footer',{staticClass:"page-footer"},[_c('div',{staticClass:"section-footer"},[_c('nav',{staticClass:"menu-footer"},[_c('h2',{staticClass:"menu-footer-head"},[_vm._v("usd AG")]),_c('ul',{staticClass:"menu-footer-links"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('li',[_vm._v(" © "+_vm._s(_vm.currentYear)+" usd AG ")])])]),_vm._m(4),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_c('a',{staticClass:"footer-link",attrs:{"href":"https://www.usd.de/contact","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("Kontakt")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_c('a',{staticClass:"footer-link",attrs:{"href":"https://www.usd.de/impressum/","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("Impressum")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_c('a',{staticClass:"footer-link",attrs:{"href":"https://www.usd.de/datenschutz/","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("Datenschutz")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_c('a',{staticClass:"footer-link",attrs:{"href":"https://www.usd.de/rechtliche-hinweise/","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("Allgemeine Nutzungsbedingungen")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('nav',{staticClass:"media-footer"},[_c('h2',{staticClass:"media-footer-head"},[_vm._v("Folgen Sie uns")]),_c('div',{staticClass:"social-media-icons"},[_c('div',{staticClass:"social-media-icons-row"},[_c('a',{staticClass:"icon-social-media icon-linkedin",attrs:{"href":"https://de.linkedin.com/company/usdag","target":"_blank","rel":"noopener noreferrer"}}),_c('a',{staticClass:"icon-social-media icon-youtube",attrs:{"href":"https://www.youtube.com/c/usdAG","target":"_blank","rel":"noopener noreferrer"}}),_c('a',{staticClass:"icon-social-media icon-twitter",attrs:{"href":"https://twitter.com/usdAG","target":"_blank","rel":"noopener noreferrer"}}),_c('a',{staticClass:"icon-social-media icon-meetup",attrs:{"href":"https://www.meetup.com/de-DE/Cyber-Security-Transformation-Academy","target":"_blank","rel":"noopener noreferrer"}})]),_c('div',{staticClass:"social-media-icons-row"},[_c('a',{staticClass:"icon-social-media icon-github",attrs:{"href":"https://github.com/usdAG","target":"_blank","rel":"noopener noreferrer"}}),_c('a',{staticClass:"icon-social-media icon-reddit",attrs:{"href":"https://www.reddit.com/user/usdAG","target":"_blank","rel":"noopener noreferrer"}}),_c('a',{staticClass:"icon-social-media icon-mastodon",attrs:{"href":"https://infosec.exchange/@usdAG","target":"_blank","rel":"noopener noreferrer"}})])]),_c('a',{staticClass:"link-report-bug",attrs:{"href":"https://www.usd.de/meldung-bug-oder-schwachstelle/","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("Meldung einer Schwachstelle oder eines Bugs")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"seal-footer"},[_c('a',{attrs:{"href":"https://pci.usd.de/compliance/1101-9C84-09F8-B6E7-F6C1-1817/details_de.html","target":"_blank","rel":"noopener noreferrer","onclick":"window.open(this.href, '', 'width=600, height=615, left=100, top=200'); return false;"}},[_c('img',{attrs:{"src":"https://pci.usd.de/compliance/1101-9C84-09F8-B6E7-F6C1-1817/seal.png","alt":"pci-dss approved seal"}})])])
}]

export { render, staticRenderFns }