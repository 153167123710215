<template>
    <div class='scope-guidelines'>
        <div class="scope-header">
            <h2>Anforderungen an eingesetztes Personal</h2>

            <p>
                Bitte konkretisieren Sie Ihre Anforderungen an das eingesetzte Pentest-Personal.
            </p>
        </div>

        <div>
            <div class="guidelines-options">
                <h3>Personenzertifizierungen *</h3>

                <p>
                    Werden neben dem usd Certified Pentester (UCP) zusätzliche Personenzertifizierung von den eingesetzten Pentestern der usd gefordert?
                </p>

                <BoxSelectCheckbox id="scope-guidelines-cert-pentester" v-model="certPentester" @input="addCertsToScope" :options="pentesterCertOptions" />

                <div class="buttons-info">
                    <button class="button-info" @click="openCertPentesterModal">Weitere Infos</button>
                </div>
            </div>

            <div class="guidelines-options">
                <h3>Sicherheitsüberprüfungen *</h3>

                <p>
                    Haben Sie besondere Anforderungen an die Sicherheitsüberprüfung eingesetzter Pentester?
                </p>

                <BoxSelectCheckbox id="scope-guidelines-check" v-model="securityClearance" :options="securityClearanceOptions" @input="addClearanceToScope" />

                <div class="buttons-info">
                    <button class="button-info" @click="openSecurityClearanceModal">Weitere Infos</button>
                </div>
            </div>
        </div>

        <modal class="info-modal" v-model="certPentesterModal">
            <img slot="title" src="../../../../public/icon-info.svg" alt="icon-info">
            <h2 slot="title">Personenzertifizierungen</h2>

            <p>
                Unser Team aus hochqualifizierten Security Analysten verfügt über zahlreiche Sicherheitszertifizierungen
                und umfangreiche Erfahrungen aus internationalen Pentest-Projekten.
            </p>

            <p>
                Mit dem Start bei usd, durchläuft jeder Security Analyst, unabhängig von seinen persönlichen Vorkenntnissen,
                zunächst eine umfangreiche mehrmonatige Ausbildung im usd HeroLab.
            </p>

            <p>
                Neben dem Abschluss einer anerkannten, internationalen Pentest-Zertifizierung, wie dem OSCP (Details finden
                sich weiter unten), umfasst diese insbesondere die Einarbeitung in die individuellen Pentest-Prüfkataloge
                der usd, genutzte Analysewerkzeuge, die Form der Berichtlegung und Ergebnispräsentation bei usd sowie die
                speziell von usd entwickelte Pentest-Toolchain, die den usd Pentest-Prozess voll digital abbildet und unterstützt.
            </p>

            <p>
                Den Abschluss der Ausbildung bildet eine Abschlussprüfung. Hierbei muss der Security Analyst in einer
                Testumgebung ein Pentest-Projekt unter Realbedingungen durchführen. Überprüft werden damit sowohl die
                technischen wie die organisatorischen Fähigkeiten des Security Analysten.
            </p>

            <p>
                Verläuft die Abschlussprüfung erfolgreich, erhält der Security Analyst die Zertifizierung als UCP (usd Certified Pentester).
                Erst mit dieser Zertifizierung wird der Security Analyst für Kundenprojekte freigegeben.
            </p>

            <p>
                Die Abschlussprüfung garantiert folglich, dass unabhängig vom eingesetzten Security Analysten die Ergebnisse
                der usd Pentests eine durchweg sehr hohe Qualität aufweisen.
            </p>

            <p>
                Nach Abschluss des UCP bildet sich jedes Teammitglied kontinuierlich weiter und spezialisiert sich im Laufe
                der Zeit auf unterschiedliche Fachgebiete (z.B. Mobile App oder Fat Client Pentests); so kombinieren wir in
                unseren Projekten das Expertenwissen aus verschiedenen Teilbereichen der IT-Sicherheit.
            </p>

            <p>
                Nachfolgende internationale Personenzertifizierungen im Pentest-Kontext werden von usd favorisiert und allen
                Pentestern im Rahmen der Aus- und Weiterbildung ermöglicht.
            </p>

            <ul>
                <li>
                    <p>
                        CEH: Certified Ethical Hacker (<a href="https://www.eccouncil.org/programs/certified-ethical-hacker-ceh/">https://www.eccouncil.org/programs/certified-ethical-hacker-ceh/</a>)
                    </p>
                </li>
                <li>
                    <p>
                        OSCP: Offensive Security Certified Professional (<a href="https://www.offensive-security.com/pwk-oscp/">https://www.offensive-security.com/pwk-oscp/</a>)
                    </p>
                </li>
                <li>
                    <p>
                        OSEP: Offensive Security Experienced Penetration Tester (<a href="https://www.offensive-security.com/pen300-osep/">https://www.offensive-security.com/pen300-osep/</a>)
                    </p>
                </li>
                <li>
                    <p>
                        OSWE: Offensive Security Web Expert (<a href="https://www.offensive-security.com/awae-oswe/">https://www.offensive-security.com/awae-oswe/</a>)
                    </p>
                </li>
                <li>
                    <p>
                        OSEE: Offensive Security Exploitation Expert (<a href="https://www.offensive-security.com/awe-osee/">https://www.offensive-security.com/awe-osee/</a>)
                    </p>
                </li>
            </ul>

            <form-actions slot="footer">
                <button @click="closeCertPentesterModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="securityClearanceModal">
            <img slot="title" src="../../../../public/icon-info.svg" alt="icon-info">
            <h2 slot="title">Sicherheitsüberprüfungen</h2>

            <p>
                Im Rahmen von Pentests greifen wir auf IT-Systeme und Anwendungen zu, die streng vertrauliche und damit schützenswerte Daten
                verarbeiten. Darüber hinaus erlangen wir im Rahmen von Pentests sensible Erkenntnisse zu teilweise hochkritischen Schwachstellen
                in der IT-Infrastruktur unserer Kunden.
            </p>

            <p>
                Es versteht sich deshalb für uns von selbst, dass wir alle Pentester der usd einmal im Jahr im Rahmen der rechtlichen Möglichkeiten
                in Deutschland überprüfen und dabei die Vorlage eines aktuellen, polizeilichen Führungszeugnisses verlangen.
            </p>

            <p>
                Darüber hinaus besteht die Möglichkeit, für besonders sensible Projekteinsätze, für relevante Pentester der usd eine
                Zuverlässigkeitsüberprüfung nach § 7 Luftisicherheitsgesetz (LuftSiG) oder eine Sicherheitsüberprüfung gemäß Sicherheitsüberprüfungsgesetz
                (SÜG) vorzunehmen. Wir weisen darauf hin, dass die vorgenannten Sicherheitsüberprüfungen vom Auftraggeber initiiert werden müssen.
                Selbstverständlich stellen wir im Rahmen des Antrags die notwendigen Informationen der usd Mitarbeiter zur Verfügung.
            </p>

            <form-actions slot="footer">
                <button @click="closeSecurityClearanceModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useModal } from '@/composables/use-modal'
import { useScopeStore } from '@/store/scopeStore'
import BoxSelectCheckbox from '@/components/BoxSelectCheckbox.vue'
import BoxSelectRadio from '@/components/BoxSelectRadio.vue'

export default defineComponent({
    name: 'ScopeGuidelines',

    components: {
        BoxSelectCheckbox,
        BoxSelectRadio
    },

    setup() {
        const { modal: certPentesterModal, openModal: openCertPentesterModal, closeModal: closeCertPentesterModal } = useModal()
        const { modal: securityClearanceModal, openModal: openSecurityClearanceModal, closeModal: closeSecurityClearanceModal } = useModal()

        const pentesterCertOptions = [
            {
                id: 'ucp',
                label: 'usd Certified Pentester'
            },
            {
                id: 'ceh',
                label: 'CEH'
            },
            {
                id: 'oscp',
                label: 'OSCP'
            },
            {
                id: 'osep',
                label: 'OSEP'
            },
            {
                id: 'oswe',
                label: 'OSWE'
            }
        ]

        const securityClearanceOptions = [
            {
                id: 'criminal record',
                label: 'Polizeiliches Führungszeugnis'
            },
            {
                id: 'sueg',
                label: 'Sicherheitsüberprüfung gemäß Sicherheitsüberprüfungsgesetz (SÜG)'
            }
        ]

        const scopeStore = useScopeStore()
        const guidelines = scopeStore.guidelines
        const certPentester = scopeStore.guidelines.certPentester
        const securityClearance = scopeStore.guidelines.securityClearance

        const addCertsToScope = (certs: any) => {
            scopeStore.setPentesterCerts(certs)
        }

        const addClearanceToScope = (clearance: any) => {
            scopeStore.setPentesterClearance(clearance)
        }

        return {
            pentesterCertOptions,
            securityClearanceOptions,
            guidelines,
            certPentester,
            securityClearance,
            certPentesterModal,
            openCertPentesterModal,
            closeCertPentesterModal,
            securityClearanceModal,
            openSecurityClearanceModal,
            closeSecurityClearanceModal,
            addCertsToScope,
            addClearanceToScope
        }
    },

    watch: {
        certPentester: (certPentester) => {
            if (!certPentester.includes('ucp')) {
                certPentester.push('ucp')
            }
        },
        securityClearance: (securityClearance) => {
            if (!securityClearance.includes('criminal record')) {
                securityClearance.push('criminal record')
            }
        }
    }

})
</script>

<style lang="scss" scoped>
@import "../../../styles/breakpoints";

.scope-guidelines {
    @include default-padding();
}

.scope-header {
    padding-bottom: 1.5%;
    text-align: left;
    h2 {
        color: var(--color-brand-primary);
    }
}

h3 {
    color: var(--color-brand-secondary);
}

.guidelines-options {
    margin-top: 2em;
}

.guidelines-options:not(:last-child) {
    margin-bottom: 1em;
}



.guidelines-radio-options {
    display: flex;
    margin-top: 1.5%;
    border: 1px solid var(--color-black);
    background-color: var(--color-white);
}

</style>
