<template>
    <div class="type-summary">
        <expansion-panel>
            <expansion-panel-content>
                <template v-slot:header>
                    <h3>
                        Ansatz
                    </h3>
                </template>
                <template>
                    <div class="summary-project-type-container">
                        <p>
                            <strong>
                                Für das Pentest-Projekt wurde folgender Ansatz ausgewählt:
                            </strong>
                        </p>

                        <div v-if="projectType === 'asset'" class="summary-project-type">
                            <img src="../../../public/images-project/projektansatz-asset.jpg">

                            <div>
                                <p>
                                    <strong>
                                        Asset-basierter Ansatz
                                    </strong>
                                </p>

                                <p>
                                    Die usd überprüft konkrete Assets des Auftraggebers gezielt auf Schwachstellen und
                                    Verwundbarkeiten.
                                </p>
                            </div>
                        </div>

                        <div v-else-if="projectType === 'scenario'" class="summary-project-type">
                            <img src="../../../public/images-project/projektansatz-szenario.jpg">

                            <div>
                                <p>
                                    <strong>
                                        Szenario-basierter Ansatz
                                    </strong>
                                </p>

                                <p>
                                    Auf Basis von Realbedrohungen sowie unseren Erfahrungen haben wir übergreifende
                                    Prüfziele abgeleitet und in Angriffsszenarien zusammengefasst, die wir im Rahmen des
                                    Pentests überprüfen. Es wird ein Risikobasierter Ansatz angewendet, um unter Kosten-Nutzen
                                    Aspekten das beste Ergebnis zu erzielen.
                                </p>
                            </div>
                        </div>

                        <div v-else-if="projectType === 'contingent'" class="summary-project-type">
                            <img src="../../../public/images-project/projektansatz-kontingent.jpg">

                            <div>
                                <p>
                                    <strong>
                                        Kontingent-basierter Ansatz
                                    </strong>
                                </p>

                                <p>
                                    Wenn Sie sich verpflichten einen Teil des Kontingents oder das gesamte Kontingent in
                                    der Vertragslaufzeit zu nutzen, verbessert das unsere Planbarkeit und wird von uns
                                    gerne über Sonderkonditionen honoriert. Bitte geben Sie nachfolgend an, ob Sie eine
                                    bestimmte Anzahl an Beratertagen verpflichtend abnehmen möchten.
                                </p>
                            </div>
                        </div>
                    </div>
                </template>
            </expansion-panel-content>
        </expansion-panel>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useProjectStore } from '@/store/projectStore'
import ExpansionPanel from '@/components/ExpansionPanel.vue'
import ExpansionPanelContent from '@/components/ExpansionPanelContent.vue'

export default defineComponent({
    name: 'TypeSummary',

    components: {
        ExpansionPanel,
        ExpansionPanelContent
    },

    setup() {
        const projectStore = useProjectStore()
        const projectType = projectStore.type

        return {
            projectType
        }
    }
})
</script>

<style lang="scss" scoped>
@import "../../styles/breakpoints";

.type-summary {
    h3 {
        color: var(--color-brand-secondary);
        font-size: 22px;
    }
    .summary-project-type {
        display: flex;
        align-items: flex-start;
        margin: 1em 0;

        img {
            align-self: center;
            object-fit: contain;
            max-width: 200px;
            margin-right: 15px;
        }
    }
}

@media screen and (max-width: $bp-medium) {
    .type-summary .summary-project-type {
        flex-direction: column;
        align-items: center;

        img {
            max-width: 100%;
            margin-bottom: 1em;
        }
    }
}
</style>
