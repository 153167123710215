<template>
    <div class="feedback-form">
        <h3 class="feedback-heading">
            Ihr Feedback ist uns wichtig
        </h3>
        <div class="feedback-stars">
            <span class="stars-heading">
                Bewertung:
            </span>
            <input type="radio" id="star5" name="feedback-star" value="5" v-model="rating">
            <label for="star5" title="5 Sterne">5 Sterne</label>
            <input type="radio" id="star4" name="feedback-star" value="4" v-model="rating">
            <label for="star4" title="4 Sterne">4 Sterne</label>
            <input type="radio" id="star3" name="feedback-star" value="3" v-model="rating">
            <label for="star3" title="3 Sterne">3 Sterne</label>
            <input type="radio" id="star2" name="feedback-star" value="2" v-model="rating">
            <label for="star2" title="2 Sterne">2 Sterne</label>
            <input type="radio" id="star1" name="feedback-star" value="1" v-model="rating">
            <label for="star1" title="1 Stern">1 Stern</label>
        </div>

        <div class="feedback-text">
            <text-area id="feedback-text" label="Anmerkungen:" v-model="comment"/>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useFeedbackStore } from '@/store/feedbackStore'

export default defineComponent({
    name: "FeedbackForm",

    setup() {
        const feedbackStore = useFeedbackStore()
        const rating = feedbackStore.rating
        const comment = feedbackStore.comment

        return {
            rating,
            comment
        }
    }
})
</script>

<style lang="scss" scoped>
.feedback-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .feedback-heading {
        padding: 1em 0 .5em;
        color: var(--color-brand-secondary);
    }

    .feedback-stars {
        float: Left;
    }

    .feedback-text {
        width: 100%;
    }

    .stars-heading {
        display: block;
        font-size: 0.8em;
        color: var(--color-ash);
        padding-bottom: .5em;
    }

    .feedback-stars:not(:checked) > input {
        display: None;
    }

    .feedback-stars:not(:checked) > label {
        float: Right;
        width: 1em;
        padding: 0 .1em;
        overflow: Hidden;
        white-space: Nowrap;
        cursor: Pointer;
        font-size: 200%;
        line-height: 1.2;
        color: #D0D0D0;
    }

    .feedback-stars:not(:checked) > label::before {
        content: '★ ';
    }

    .feedback-stars > input:checked ~ label {
        color: #FFD700;
    }

    .feedback-stars:not(:checked) > label:hover,
    .feedback-stars:not(:checked) > label:hover ~ label {
        color: #FECF41;
    }

    .feedback-stars > input:checked + label:hover,
    .feedback-stars > input:checked + label:hover ~ label,
    .feedback-stars > input:checked ~ label:hover,
    .feedback-stars > input:checked ~ label:hover ~ label,
    .feedback-stars > label:hover ~ input:checked ~ label {
        color: #FBB600;
    }
}
</style>