<template>
    <div class="scope-summary">
        <expansion-panel closed>
            <expansion-panel-content>
                <template v-slot:header>
                    <h3>
                        Organisation
                    </h3>
                </template>
                <template>
                    <div class="summary-project-scope-container">
                        <p>
                            <strong>
                                Nachfolgende organisatorische Rahmenbedingungen gelten für den Pentest:
                            </strong>
                        </p>

                        <ul>
                            <li>
                                <p>
                                    Der Leistungszeitraum wird nach Beauftragung flexibel mit dem Kunden abgestimmt.
                                </p>
                            </li>

                            <li>
                                <TimingSummary />
                            </li>

                            <li>
                                <LocationSummary />
                            </li>

                            <li v-if="projectType === 'asset'">
                                <HardwareSummary />
                            </li>

                            <li v-if="projectType === 'asset'">
                                <SeparateEnvironmentSummary />
                            </li>

                            <li>
                                <p>
                                    Identifizierte Schwachstellen werden tagesaktuell in einer tabellarischen Übersicht bereitgestellt.
                                </p>
                            </li>

                            <li>
                                <p>
                                    Abschließend werden im Rahmen eines Online-Meetings offene Fragen beantwortet.
                                </p>
                            </li>
                        </ul>

                        <ReportSummary />

                        <DateSummary v-if="dateList.length > 0"/>

                        <GuidelinesSummary />

                        <MotivationSummary v-if="motivation.length > 0"/>
                    </div>
                </template>
            </expansion-panel-content>
        </expansion-panel>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useProjectStore } from '@/store/projectStore'
import { useScopeStore } from '@/store/scopeStore'
import TimingSummary from '@/components/summary/components/TimingSummary.vue'
import LocationSummary from '@/components/summary/components/LocationSummary.vue'
import SeparateEnvironmentSummary from '@/components/summary/components/SeparateEnvironmentSummary.vue'
import HardwareSummary from '@/components/summary/components/HardwareSummary.vue'
import ReportSummary from '@/components/summary/components/ReportSummary.vue'
import GuidelinesSummary from '@/components/summary/components/GuidelinesSummary.vue'
import DateSummary from '@/components/summary/components/DateSummary.vue'
import MotivationSummary from '@/components/summary/components/MotivationSummary.vue'
import ExpansionPanel from '@/components/ExpansionPanel.vue'
import ExpansionPanelContent from '@/components/ExpansionPanelContent.vue'

export default defineComponent({
    name: 'ScopeSummary',

    components: {
        ExpansionPanel,
        ExpansionPanelContent,
        TimingSummary,
        LocationSummary,
        HardwareSummary,
        ReportSummary,
        GuidelinesSummary,
        DateSummary,
        MotivationSummary,
        SeparateEnvironmentSummary
    },

    setup() {
        const projectStore = useProjectStore()
        const projectType = projectStore.type

        const scopeStore = useScopeStore()
        const dateList = scopeStore.dateList
        const guidelines = scopeStore.guidelines
        const motivation = scopeStore.motivation

        return {
            projectType,
            dateList,
            guidelines,
            motivation
        }
    }
})
</script>

<style lang="scss" scoped>
.scope-summary {
    margin: 1em 0;

    h3 {
        color: var(--color-brand-secondary);
        font-size: 22px;
    }
    .scope-item {
        display: flex;
        margin-bottom: 2em;
    }
    .scope-item-name {
        width: 30%;
    }
    .scope-guideline-item, .scope-timing-item, .scope-location-item {
        display: flex;
        padding-left: 5%;
        margin-bottom: 2em;
    }
    .scope-guideline-header, .scope-timing-header, .scope-location-header {
        margin-bottom: 2em;
    }
    .scope-guideline-name, .scope-timing-name, .scope-location-name {
        width: 40%;
    }
    ul {
        margin-left: -1.5em;
    }
}
</style>
