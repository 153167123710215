import { ref, reactive } from 'vue'
import Repository from '@/repository/Repository'

// Interfaces
interface Report {
    layout: string,
    riskAssessment: Array<string>,
    customerRiskAssessment: boolean,
    language: Array<string>
}

interface Guidelines {
    certPentester: Array<string>,
    securityClearance: Array<string>
}

// Store
// Variables are used as two-way bindings
const standardSelection = ref<string>('default')

const location = ref<string>('remote')

const timing = ref<boolean>(false)

const separateEnvironment = ref<boolean>(false)

const hardware = ref<boolean>(false)

const report = reactive<Report>({
    layout: 'default',
    riskAssessment: ['usd'],
    customerRiskAssessment: false,
    language: ['de']
})

const guidelines = reactive<Guidelines>({
    certPentester: ['ucp'],
    securityClearance: ['criminal record']
})

const dateList = ref<Array<Object>>([])

const motivation = ref<Array<string>>([])

const fullPrice = ref<number>(0)

// Actions
const actions = {
    setPentesterCerts: async(certs: Array<string>) => {
        guidelines.certPentester = certs
    },
    setPentesterClearance: async(clearance: Array<string>) => {
        guidelines.securityClearance = clearance
    },
    setRiskAssessment: async(assessments: Array<string>) => {
        report.riskAssessment = assessments
    },
    // addDate: async(date: any, dateIndex: number) => {
    //     dateList.value[dateIndex] = date
    // },
    removeDate: (dateIndex: number) => {
        dateList.value.splice(dateIndex, 1)
    },
    initializeScope: async(projectId: string) => {
        const params = { projectUuid: projectId }
        try {
            await Repository.get('/scope', { params })
        } catch (error) {
            await Repository.post('/scope/init', params)
            // console.log('Save scope error: ' + error)
        }
    },
    updateScope: async(projectId: string) => {
        try {
            const data = {
                projectUuid: projectId,
                location: location.value,
                timing: timing.value,
                separateEnvironment: separateEnvironment.value,
                hardware: hardware.value,
                reportLayout: report.layout,
                riskAssessment: report.riskAssessment,
                customerRiskAssessment: report.customerRiskAssessment,
                reportLanguage: report.language,
                certPentester: guidelines.certPentester,
                clearancePentester: guidelines.securityClearance,
                chosenDates: dateList.value,
                motivation: motivation.value
            }
            await Repository.post('/scope', data)
        } catch (error) {
            // console.log('Save scope error: ' + error)
        }
    },
    loadScope: async(projectId: string) => {
        try {
            const params = { projectUuid: projectId }
            const response = await Repository.get('/scope', { params })
            location.value = response.data.location as string
            timing.value = response.data.timing as boolean
            separateEnvironment.value = response.data.separateEnvironment as boolean
            hardware.value = response.data.hardware as boolean
            report.layout = response.data.reportLayout as string
            report.riskAssessment = response.data.riskAssessment as string[]
            report.customerRiskAssessment = response.data.customerRiskAssessment as boolean
            report.language = response.data.reportLanguage as string[]
            guidelines.certPentester = response.data.certPentester as string[]
            guidelines.securityClearance = response.data.clearancePentester as string[]
            dateList.value = response.data.chosenDates as string[]
            motivation.value = response.data.motivation as string[]
        } catch (error) {
            // console.log(error)
        }
    },
    deleteScope: async(projectId: string) => {
        try {
            const params = { projectUuid: projectId }
            await Repository.delete('/scope/delete', { params })
        } catch (error) {
            // console.log('Delete error: ' + error)
        }
    }
}

// Export
export const useScopeStore = () => {
    return {
        standardSelection,
        location,
        timing,
        separateEnvironment,
        hardware,
        report,
        guidelines,
        dateList,
        motivation,
        fullPrice,
        ...actions
    } as const
}
