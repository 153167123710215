<template>
    <div class='scope-motivation'>
        <div class="scope-header">
            <h2>Motivation <sup>(optional)</sup></h2>

            <p>
                Was ist Ihre Motivation für den Pentest?
            </p>
        </div>

        <BoxSelectCheckbox id="motivation" v-model="motivation" :options="motivationOptions" @input="updateScope()"/>

    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useScopeStore } from '@/store/scopeStore'
import BoxSelectCheckbox from '@/components/BoxSelectCheckbox.vue'
import { useProjectStore } from '@/store/projectStore'

export default defineComponent({
    name: 'ScopeMotivation',

    components: {
        BoxSelectCheckbox
    },

    setup() {
        const projectStore = useProjectStore()
        const projectId = projectStore.uuid

        const scopeStore = useScopeStore()
        const motivation = scopeStore.motivation

        const motivationOptions = [
            {
                id: 'interest',
                label: 'Eigeninteresse'
            },
            {
                id: 'requirement',
                label: 'Regulatorik'
            },
            {
                id: 'customer-requirement',
                label: 'Kundenanforderung'
            },
            {
                id: 'hacked',
                label: 'Hackerangriff'
            }
        ]

        const updateScope = async() => {
            await scopeStore.updateScope(projectId.value)
        }

        return {
            motivation,
            motivationOptions,
            updateScope
        }
    }
})
</script>

<style lang="scss" scoped>
@import "../../../styles/breakpoints";

.scope-motivation {
    @include default-padding();
}

.scope-header {
    padding-bottom: 1.5%;
    text-align: left;
    h2 {
        color: var(--color-brand-primary);
    }
}

.motivation-checkbox-options {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.5%;
}

.motivation-checkbox-options label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32%;
    height: 6em;

    p {
        width: 100%;
        margin: 0.7em 0.5em;
        padding: 2em 4em;
        text-align: center;
        vertical-align: middle;
        border: 1px solid var(--color-light-grey);
        color: var(--color-light-grey);
        background-color: var(--color-white);
    }
}

.option-checkbox {
    display: none;
    margin: 2px 10px 0 0;
}

.option-checkbox:checked + p {
    border-color: var(--color-brand-secondary);
    background-color: var(--color-brand-secondary);
    color: var(--color-white);
}
</style>
