import { render, staticRenderFns } from "./RemediationSupport.vue?vue&type=template&id=ac9f4888&scoped=true"
import script from "./RemediationSupport.vue?vue&type=script&lang=ts"
export * from "./RemediationSupport.vue?vue&type=script&lang=ts"
import style0 from "./RemediationSupport.vue?vue&type=style&index=0&id=ac9f4888&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ac9f4888",
  null
  
)

export default component.exports