<template>
    <div class="scenario-scope-summary">
        <expansion-panel>
            <expansion-panel-content>
                <template v-slot:header>
                    <h3>
                        Scope
                    </h3>
                </template>
                <template>
                    <div class="summary-project-scope-container">
                        <div>
                            <div>
                                <p>
                                    <strong>
                                        Nachfolgend werden die zu überprüfenden Angriffsszenarien aufgelistet:
                                    </strong>
                                </p>

                                <div v-for="scenario in scenarioList" :key="scenario.name">
                                    <div class="summary-project-scope">
                                        <img v-if="scenario.name === 'compromisedWebsite'" src="../../../public/images-scenarios/szenario-website.jpg" alt="Szenario Kompromittiere Unternehmenswebseite">
                                        <img v-else-if="scenario.name === 'compromisedShop'" src="../../../public/images-scenarios/szenario-shop.jpg" alt="Szenario Kompromittierer Onlineshop">
                                        <img v-else-if="scenario.name === 'localPrivilegeEscalation'" src="../../../public/images-scenarios/szenario-client.jpg" alt="Szenario Rechteausweitung">
                                        <img v-else-if="scenario.name === 'compromisedNetwork'" src="../../../public/images-scenarios/szenario-netzwerk.jpg" alt="Szenario Kompromittiertes Netzwerk">
                                        <img v-else-if="scenario.name === 'externalAttack'" src="../../../public/images-scenarios/szenario-ext-angriff.jpg" alt="Szenario Externer Angriff">
                                        <img v-else-if="scenario.name === 'externalEnumeration'" src="../../../public/images-scenarios/szenario-externe-enumeration.jpg" alt="Szenario Externe Enumeration">
                                        <img v-else-if="scenario.name === 'insiderAttack'" src="../../../public/images-scenarios/szenario-insiderangriff.jpg" alt="Szenario Insiderangriff">
                                        <img v-else-if="scenario.name === 'stolenLaptop'" src="../../../public/images-scenarios/szenario-laptop.jpg" alt="Szenario Gestohlener Laptop">
                                        <img v-else-if="scenario.name === 'ransomware'" src="../../../public/images-scenarios/szenario-ransomware.jpg" alt="Szenario Ransomware Angriff">
                                        <img v-else-if="scenario.name === 'phishing'" src="../../../public/images-scenarios/szenario-phishing.jpg" alt="Szenario Phishing Angriff">
                                        <img v-else-if="scenario.name === 'cloudSecurity'" src="../../../public/images-scenarios/szenario-cloud.jpg" alt="Szenario Cloud Security">

                                        <div>
                                            <strong v-if="scenario.name === 'compromisedWebsite'">
                                                Website Defacement
                                            </strong>
                                            <strong v-else-if="scenario.name === 'compromisedShop'">
                                                Cyber-Angriff auf Online-Shop
                                            </strong>
                                            <strong v-else-if="scenario.name === 'localPrivilegeEscalation'">
                                                Lokale Rechteausweitung
                                            </strong>
                                            <strong v-else-if="scenario.name === 'compromisedNetwork'">
                                                Netzwerk Angriff
                                            </strong>
                                            <strong v-else-if="scenario.name === 'externalAttack'">
                                                Externer Angriff
                                            </strong>
                                            <strong v-else-if="scenario.name === 'externalEnumeration'">
                                                Externe Enumeration
                                            </strong>
                                            <strong v-else-if="scenario.name === 'insiderAttack'">
                                                Insiderangriff
                                            </strong>
                                            <strong v-else-if="scenario.name === 'stolenLaptop'">
                                                Gestohlener Laptop
                                            </strong>
                                            <strong v-else-if="scenario.name === 'ransomware'">
                                                Ransomware Angriff
                                            </strong>
                                            <strong v-else-if="scenario.name === 'phishing'">
                                                Phishing Angriff
                                            </strong>
                                            <strong v-else-if="scenario.name === 'cloudSecurity'">
                                                Cloud Angriff
                                            </strong>

                                            <p v-if="scenario.name === 'compromisedWebsite'">
                                                In diesem Szenario simulieren wir einen Hackerangriff auf Ihre Unternehmenswebseite im Internet.
                                            </p>
                                            <p v-else-if="scenario.name === 'compromisedShop'">
                                                Wir simulieren den Angriff eines Hackers auf Ihren Online-Shop.
                                            </p>
                                            <p v-else-if="scenario.name === 'localPrivilegeEscalation'">
                                                In diesem Szenario simulieren wir einen Mitarbeiter, der seine lokalen Rechte auf dem Client ausweiten und sich lokale Administratorrechte beschaffen möchte.
                                            </p>
                                            <p v-else-if="scenario.name === 'compromisedNetwork'">
                                                In diesem Szenario simulieren wir einen Hacker, der Sie über einen Firmen-internen Netzwerkanschluss oder ein WLAN-Netzwerk angreift.
                                            </p>
                                            <p v-else-if="scenario.name === 'externalAttack'">
                                                Wir simulieren den Angriff eines externen Hackers mit dem Ziel Ihr Unternehmen über das Internet zu kompromittieren.
                                            </p>
                                            <p v-else-if="scenario.name === 'externalEnumeration'">
                                                Wir simulieren in diesem Szenario einen Hacker, der nach verfügbaren Informationen zu Ihrem Unternehmen im Internet recherchiert, um so einen Angriff auf Sie vorzubereiten.
                                            </p>
                                            <p v-else-if="scenario.name === 'insiderAttack'">
                                                Wir simulieren in diesem Szenario einen Mitarbeiter, der böswillig über seinen Benutzeraccount das unternehmenseigene Netzwerk angreift.
                                            </p>
                                            <p v-else-if="scenario.name === 'stolenLaptop'">
                                                In diesem Szenario simulieren wir einen Angreifer, der in den Besitz Ihres Firmen-Laptops gelangt ist und dort gespeicherte Informationen entwenden möchte.                                            </p>
                                            <p v-else-if="scenario.name === 'ransomware'">
                                                In diesem Szenario simulieren wir einen gezielten Ransomware-Angriff auf
                                                die Workstation eines Mitarbeiters.
                                            </p>
                                            <p v-else-if="scenario.name === 'phishing'">
                                                Wir simulieren in diesem Szenario einen gezielten Phishing-Angriff auf Ihre
                                                Mitarbeiter.
                                            </p>
                                            <p v-else-if="scenario.name === 'cloudSecurity'">
                                                Wir simulieren in diesem Szenario einen Hacker, der die von Ihnen eingesetzte Cloud Infrastruktur angreift.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </expansion-panel-content>
        </expansion-panel>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useProjectStore } from '@/store/projectStore'
import { useScenarioStore } from '@/store/scenarioStore'
import ExpansionPanel from '@/components/ExpansionPanel.vue'
import ExpansionPanelContent from '@/components/ExpansionPanelContent.vue'

export default defineComponent({
    name: 'ScenarioScopeSummary',

    components: {
        ExpansionPanel,
        ExpansionPanelContent
    },

    setup() {
        const projectStore = useProjectStore()
        const projectId = projectStore.uuid

        const scenarioStore = useScenarioStore()
        scenarioStore.updateSelectedScenarioList(projectId.value)

        return {
            scenarioList: scenarioStore.getSelectedScenarios
        }
    }
})
</script>

<style lang="scss" scoped>
@import "../../styles/breakpoints";

.scenario-scope-summary {
    h3 {
        color: var(--color-brand-secondary);
        font-size: 22px;
    }
    .summary-project-scope {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 1em 0;

        img {
            max-width: 200px;
            margin-right: 1em;
        }
    }
}

@media screen and (max-width: $bp-medium) {
    .scenario-scope-summary .summary-project-scope {
        flex-direction: column;
        align-items: center;

        img {
            max-width: 100%;
            margin-right: 0;
            margin-bottom: 1em;
        }
    }
}
</style>
