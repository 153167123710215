<template>
    <div class="faq">
        <div class="faq-header">
            <h1>FAQ</h1>

<!--            <button class="primary faq-button-component" >Alle FAQ-Beiträge</button>-->
        </div>

        <expansion-panel closed>
            <expansion-panel-content>
                <template v-slot:header>
                    Warum sollte mein Unternehmen technische Sicherheitsanalysen durchführen lassen?
                </template>
                <template>
                    <p>
                        In den Medien wird täglich über Hackerangriffe auf Unternehmen berichtet. Die Folgen eines Cyber
                        Angriffs können verheerend sein. Es drohen neben hohen Umsatzeinbußen häufig auch erhebliche Imageschäden
                        sowie Bußgelder. Eine übergreifende IT-Sicherheitsstrategie hilft, die Risiken zu reduzieren.
                        Pentests bilden dabei einen integralen Bestandteil und schützen effektiv gegen Hackerangriffe,
                        indem versteckte Schwachstellen aufgedeckt und korrigiert werden können, bevor sie ein realer
                        Angreifer ausnutzen kann.
                    </p>
                </template>
            </expansion-panel-content>

            <expansion-panel-content>
                <template v-slot:header>
                    Was gibt es für technische Sicherheitsanalysen? Was ist ein Pentest? Wie unterscheidet sich ein Pentest
                    von einem Schwachstellenscan?
                </template>
                <template>
                    <p>
                        Schwachstellen in IT-Systemen und Applikationen lassen sich über verschiedene Prüfmethoden identifizieren.
                        Wir unterscheiden Security Scans und Pentests. Beide Lösungen haben ihre Vor- und Nachteile, die
                        wir Ihnen gerne nachfolgend erläutern.
                    </p>
                    <p>
                        Im Rahmen von Security Scans werden Schwachstellen automatisiert mittels spezieller Programme erkannt.
                        Die Programme werden einmal von uns konfiguriert und identifizieren daraufhin eigenständig vorhandene
                        Schwachstellen. Durch den sehr hohen Automatisierungsgrad in der Leistungserbringung können wir
                        Security Scans kostengünstig anbieten sowie schnell und zumeist kurzfristig durchführen. Die zwei
                        wesentlichen Nachteile des Security Scans sind das stark eingeschränkte Einsatzgebiet sowie die
                        Prüftiefe. Mittels Security Scans lassen sich ausschließlich IT-Systeme (Server, Clients und
                        Netzwerkkomponenten), Webapplikationen und Webservices (APIs) sowie Mobile Apps überprüfen.
                        Identifiziert werden ausschließlich bereits bekannte Schwachstellen.
                        Bislang unbekannte Schwachstellen (zero-day Schwachstellen) werden nicht identifiziert. Darüber hinaus lassen
                        sich ausschließlich technische Schwachstellen, nicht aber logische Fehler erkennen. Da die Ergebnisse
                        automatisiert erzeugt und nicht manuell validiert werden, ist die Fehlerquote hoch. Der Ergebnisbericht
                        eines Security Scans ist deshalb zumeist lang und kann False-Positives, also Schwachstellen die als
                        solche erkannt werden, tatsächlich aber nicht existieren, enthalten. Security Scans eignen sich damit hervorragend,
                        um kostengünstig einen ersten Eindruck von der Sicherheit der eingesetzten IT zu gewinnen. Dabei
                        können auch große IT-Infrastrukturen zu günstigen Preisen auf Schwachstellen überprüft werden.
                    </p>
                    <p>
                        Pentests adressieren alle vorgenannten Nachteile des Security Scans und bieten zahlreiche Vorteile.
                        Schwachstellen werden im Rahmen von Pentests manuell durch Sicherheitsexperten der usd identifiziert und validiert.
                        Dabei kann jedes beliebige IT-System sowie jede Applikation überprüft werden. Mainframes und
                        Telefonanlagen sind genauso überprüfbar wie SAP-Anwendungen. Standardsoftware und Eigenentwicklungen
                        werden gleichermaßen unterstützt. Der abgestimmte Prüfumfang wird auf technische und logische
                        Schwachstellen überprüft. So werden nicht nur Fehlkonfigurationen und bekannte Softwaredefekte,
                        sondern auch Logikfehler und bisher unbekannte Schwachstellen (zero-day Schwachstellen) aufgedeckt.
                        Jede Schwachstelle wird im Rahmen eines Pentests manuell verifiziert. Die Ergebnisberichte gehen
                        deshalb gezielt auf die identifizierten Schwachstellen ein, False-Positives existieren nicht. Der
                        wesentliche Nachteil eines Pentests ist der notwendige, teils hohe Aufwand für die Umsetzung der
                        manuellen Prüfungen. Dieser ist mit höheren Kosten verbunden. Pentests eignen sich damit, um ausgewählte
                        IT-Assets im Detail und vollumfänglich auf Schwachstellen überprüfen zu lassen.
                    </p>
                </template>
            </expansion-panel-content>

            <expansion-panel-content>
                <template v-slot:header>
                    Woran erkenne ich einen hochwertigen Pentest-Anbieter?
                </template>
                <template>
                    <p>
                        Naturgemäß ist die technische Qualität eines Pentests oft schwer zu bewerten. Schließlich handelt
                        es sich hierbei um eine hochkomplexe, manuelle technische Analyseleistung, deren einzelne Prüfschritte
                        nicht durch einen Standard definiert sind. Daher ist es umso wichtiger, einen hochwertigen,
                        vertrauensvollen Anbieter auszuwählen. Zu Ihrer Orientierung haben wir in folgendem Artikel die
                        wichtigsten Qualitätsmerkmale eines Pentest-Anbieters für Sie zusammengefasst:
                    </p>

                    <p>
                        <a href="https://www.usd.de/news-qualitaetsmerkmale-eines-pentest-partners/">
                            https://www.usd.de/news-qualitaetsmerkmale-eines-pentest-partners/
                        </a>
                    </p>
                </template>
            </expansion-panel-content>

            <expansion-panel-content>
                <template v-slot:header>
                    Was kann im Rahmen eines Pentests geprüft werden?
                </template>
                <template>
                    <p>
                        Folgende IT-Komponenten überprüfen wir gerne für Sie im Rahmen von Pentests auf Schwachstellen und
                        Verwundbarkeiten. Eine detaillierte Beschreibung der Prüfinhalte können Sie folgender Seite entnehmen:
                        <a href="https://www.usd.de/security-analysis-pentests/pentest/">https://www.usd.de/security-analysis-pentests/pentest/</a>.
                    </p>
                    <ul>
                        <li>
                            <p>
                                IT-Systeme (Server und Netzwerkkomponenten): Die Sicherheit von IT-Systemen ist einer der
                                wichtigsten Bestandteile der Unternehmenssicherheit. Angreifer nutzen Schwachstellen auf
                                Netzwerk- und Systemebene, um sich nach einem erfolgreichen Angriff im Unternehmensnetz
                                weiter auszubreiten.
                            </p>
                        </li>
                        <li>
                            <p>
                                Webapplikationen (Webanwendungen und Webservices/APIs): Webanwendungen sind aus unserer
                                täglichen Arbeit nicht mehr wegzudenken. Der vielfältige Einsatz birgt jedoch Gefahren, da
                                Webanwendungen oft sensible Daten verarbeiten und austauschen müssen. Dies macht Webanwendungen
                                zu einem beliebten Ziel für Angreifer.
                            </p>
                        </li>
                        <li>
                            <p>
                                Mobile Apps (iOS und Android): Die Bedeutung und Verbreitung von mobilen Anwendungen, kurz
                                Apps, nimmt stetig zu. So werden hier sensible Informationen nicht nur übertragen, sondern
                                auch direkt auf dem Endgerät abgespeichert. Durch Schwachstellen können Angreifer auf Nutzerdaten
                                oder sogar auf das unternehmensinterne Netzwerk zugreifen.
                            </p>
                        </li>
                        <li>
                            <p>
                                Fat Clients (native Anwendungen auf Windows und Linux): Unternehmen setzen vermehrt sogenannte
                                Fat-Clients ein, welche nativ auf dem Betriebssystem laufen und nicht über den Browser
                                aufgerufen werden. Diese Applikationen sind häufig selbst entwickelt und können ein hohes
                                Risiko für die Unternehmenssicherheit darstellen.
                            </p>
                        </li>
                        <li>
                            <p>
                                Mainframes: Mainframes gelten gemeinhin als besonders robust und sicher. Doch auch hier
                                können Fehler in Entwicklung, Konfiguration und Betrieb zu Schwachstellen mit existenzbedrohenden
                                Folgen führen. Deshalb sollten Sicherheitsüberprüfungen unbedingt regelmäßig erfolgen.
                            </p>
                        </li>
                        <li>
                            <p>
                                Workstations: Sicherheitslücken in Anwendungen und fehlerhaft konfigurierte Systemdienste
                                bieten Malware ideale Einfallstore, um einzelne Computer oder ein ganzes Netzwerk zu befallen.
                                Workstations oder Clients, wie beispielsweise Windows-Notebooks, stellen dabei häufig den
                                Eintrittspunkt dar.
                            </p>
                        </li>
                    </ul>

                    <p>
                        Individuelle Prüfbereiche wie WLAN-Netze, VoIP-Telefonanlagen oder Business Software wie SAP prüfen
                        wir gerne ebenfalls auf Schwachstellen. Im Konfigurator werden diese Bereiche jedoch noch nicht
                        aufgegriffen. Gerne können Sie uns persönlich für einen entsprechenden Pentest kontaktieren.
                    </p>
                </template>
            </expansion-panel-content>

            <expansion-panel-content>
                <template v-slot:header>
                    Wird mir bei der Korrektur identifizierter Schwachstellen nach dem Pentest geholfen?
                </template>
                <template>
                    <p>
                        Nicht selten treten Fragen zur Korrektur von Schwachstellen bei unseren Kunden nachgelagert zur
                        Abschlussbesprechung des Pentest-Projekts auf. Diese können sowohl technischer Natur sein oder die
                        zugrundeliegende IT-Architektur betreffen. Natürlich unterstützen wir Sie in diesem Kontext gerne
                        mit unserer Expertise. Sprechen Sie uns hierzu gerne jederzeit an oder buchen entsprechende
                        Zusatzleistungen direkt im Konfigurator.
                    </p>
                </template>
            </expansion-panel-content>

            <expansion-panel-content>
                <template v-slot:header>
                    Erhalte ich nach erfolgreichem Abschluss des Pentests ein Sicherheitszertifikat?
                </template>
                <template>
                    <p>
                        Gerne stellen wir Ihnen nach Abschluss des Pentests das usd Sicherheitszertifikat aus, damit Sie
                        auch Dritten bzw. Geschäftspartnern Ihren Anspruch an das Thema IT-Sicherheit demonstrieren können.
                        Bei der Bewertung der Ergebnisse orientieren wir uns an dem internationalen Standard der
                        Kreditkartenindustrie (PCI DSS). Bitte beachten Sie, dass wir unser Zertifikat nur dann ausstellen
                        können, insofern ein vollständiger, umfassender Pentest durchgeführt wurde und keine kritischen
                        Schwachstellen identifiziert wurden.
                    </p>
                </template>
            </expansion-panel-content>

            <expansion-panel-content>
                <template v-slot:header>
                    Wie sieht der Pentest-Prozess der usd aus?
                </template>
                <template>
                    <p>
                        Die IT-Sicherheitsexperten der usd haben auf Basis renommierter internationaler Standards und Best
                        Practices eine eigene Methodik für die Durchführung von Pentests abgeleitet und entwickeln diese
                        stetig weiter. Hier können Sie mehr über unser Vorgehensmodell erfahren:
                    </p>

                    <p>
                        <a href="https://www.usd.de/security-analysis-pentests/pentest/#vorgehen">
                            https://www.usd.de/security-analysis-pentests/pentest/#vorgehen
                        </a>
                    </p>
                </template>
            </expansion-panel-content>

            <expansion-panel-content>
                <template v-slot:header>
                    Wie wirkt sich ein Pentest auf die getesteten IT-Systeme und Anwendungen aus? Kann es zu Datenverlusten und Systemstörungen kommen?
                </template>
                <template>
                    <p>
                        Das Ziel eines Pentests ist es, Schwachstellen und Verwundbarkeiten in IT-Systemen und Anwendungen
                        zu identifizieren. Hierzu werden die IT-Systeme und Anwendungen von den IT-Sicherheitsexperten der
                        usd mit fehlerhaften Eingaben und Daten konfrontiert und ein Fehlverhalten bewusst provoziert.
                        Dabei können irreparable Schäden in IT-Systemen und Anwendungen auftreten. Beeinträchtigungen und
                        Veränderungen von Inhalten und Daten, wie zum Beispiel auf einer Webseite in Form von Layout-Veränderungen,
                        oder Beeinträchtigungen der IT-Systeme, wie zum Beispiel Systemausfälle oder eine Verschlechterung
                        des Antwortverhaltens, können nicht ausgeschlossen werden. Sofern möglich, empfehlen wir Pentests
                        in Testumgebungen durchzuführen. Sollte dies nicht möglich sein, ist die Durchführung eines aktuellen
                        Backup vor Beginn des Pentests dringend empfohlen. Natürlich versuchen wir entsprechende Beeinträchtigungen
                        zu minimieren. 100%ig ausschließen lassen sie sich indes leider nicht.
                    </p>
                </template>
            </expansion-panel-content>

            <expansion-panel-content>
                <template v-slot:header>
                    Können IT-Systeme und Anwendungen von/bei einem Drittanbieter geprüft werden?
                </template>
                <template>
                    <p>
                        Im Rahmen von Pentests überprüfen wir gerne auch IT-Systeme und Anwendungen oder SaaS-Lösungen,
                        die ein Drittanbieter für Sie (in der Cloud) betreibt. Bitte klären Sie vor Beauftragung des Pentests,
                        ob Ihr Drittanbieter einer Durchführung grundsätzlich zustimmt. Ist dies der Fall, unterstützen
                        wir Sie gerne in der Vorbereitungsphase des Pentests bei der Anmeldung sowie der Bestätigung ggf.
                        notwendiger Antragsformulare sowie der Beantwortung der Fragen Ihres Drittanbieters.
                    </p>
                </template>
            </expansion-panel-content>

            <expansion-panel-content>
                <template v-slot:header>
                    Benötige ich technisches Fachwissen für die Konfiguration eines Pentests?
                </template>
                <template>
                    <p>
                        Technisches Fachwissen ist nur dann notwendig, wenn Sie den Prüfumfang des Pentests individuell
                        und detailliert selbst gestalten möchten. In einem umfangreichen Prozess ermitteln wir dabei zusammen
                        mit Ihnen für jede zu prüfende IT-Komponente (z.B. Web- und Datenbankserver, Firewalls, Router,
                        Webapplikationen und Webservices) einzeln die notwendigen, technischen Detailinformationen und
                        planen so Ihren Pentest mit individuellem Prüfumfang. Beabsichtigen Sie andererseits ein Pentest-Abrufkontingent
                        oder einen Pentest auf Basis unserer vordefinierten Angriffsszenarien zu beauftragen, ist keinerlei
                        technisches Fachwissen notwendig.
                    </p>
                </template>
            </expansion-panel-content>

            <expansion-panel-content>
                <template v-slot:header>
                    Was ist der Pentest-Scope?
                </template>
                <template>
                    <p>
                        Der Pentest-Scope bezeichnet den Prüfumfang eines Pentests. Er definiert, welche Assets (wie z.B.
                        IT-Systeme oder Anwendungen) geprüft werden. Ebenso kann festgelegt werden was von einem Pentest
                        ausgeschlossen werden soll. Tiefergehende Informationen zum Thema "Scoping" haben wir für Sie in
                        folgendem Artikel zusammengefasst:
                    </p>

                    <p>
                        <a href="https://www.usd.de/news-pentest-pruefumfang/">
                            https://www.usd.de/news-pentest-pruefumfang/
                        </a>
                    </p>
                </template>
            </expansion-panel-content>

            <expansion-panel-content>
                <template v-slot:header>
                    Wie gehe ich vor, wenn die Durchführung von Pentests geplant ist, der exakte Prüfumfang aber noch nicht feststeht?
                </template>
                <template>
                    <p>
                        Wir empfehlen in diesem Fall ein Pentest-Abrufkontingent mit uns zu vereinbaren. Die Höhe des Kontingents
                        können Sie frei bestimmen. Eine Abrufverpflichtung müssen Sie nicht eingehen. Über das Kontingent
                        können wir daraufhin flexibel und gezielt auf Ihre Pentest-Anforderungen eingehen. Im Rahmen einer
                        Grobplanung stimmen wir zunächst eingangs Ihre Pläne grob ab. Zu gegebenem Anlass erfolgt dann die
                        Detailplanung sowie Durchführung der jeweiligen Pentests mit unseren Sicherheitsexperten.
                    </p>
                </template>
            </expansion-panel-content>

            <expansion-panel-content>
                <template v-slot:header>
                    Ist es möglich Pentests ganz ohne Expertenwissen zu beauftragen, z.B. indem Angriffsszenarien ausgewählt werden?
                </template>
                <template>
                    <p>
                        Wir haben zahlreiche Pentest-Szenarien in unserem Konfigurator abgebildet, die auf Basis von
                        Realbedrohungen sowie unserer langjährigen Erfahrungen übergreifende Prüfziele zusammenfassen
                        und so die Beauftragung eines Pentests für Sie so einfach wie möglich gestalten. Sie wählen die
                        relevanten Angriffsszenarien aus. Wir leiten für Sie die entsprechenden Prüfziele des Pentests
                        ab. Der komplexe Scoping-Prozess wird dadurch signifikant vereinfacht und die Beauftragung eines
                        Pentests ohne Expertenwissen jederzeit möglich.
                    </p>
                </template>
            </expansion-panel-content>
        </expansion-panel>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ExpansionPanel from '@/components/ExpansionPanel.vue'
import ExpansionPanelContent from '@/components/ExpansionPanelContent.vue'

export default defineComponent({
    name: 'FAQ',

    components: {
        ExpansionPanel,
        ExpansionPanelContent
    }
})
</script>

<style lang="scss">
@import "../../styles/breakpoints";

.faq {
    background-color: var(--color-lightest-grey);
    padding: 2.5% 10% 2.5% 10%;
    h1 {
        font-size: 50px;
        padding-bottom: 3%;
    }
}

.expansion-panel_content_header {
    color: var(--color-medium-grey);
}

.faq-header {
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: $bp-small) {
    .faq {
        padding: 2em 1em;
    }

    .faq h1 {
        font-size: 30px;
    }

    .faq-header {
        flex-direction: column;
        align-items: center;
    }

    .expansion-panel_content_content {
        padding-left: 25px;
        padding-right: 25px;
    }
}
</style>
