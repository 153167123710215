<template>
    <div class='asset-list'>
        <div v-if="assetList.length === 0">
            <table class="asset-table">
                <tr class="table-header-row">
                    <th class="table-header-name">Asset-Name</th>
                    <th class="table-header-type">Asset-Typ</th>
                    <th class="table-header-edit"></th>
                    <th class="table-header-delete"></th>
                </tr>
            </table>

            <p class="asset-list-empty-text">
                Sie haben noch keine Assets angelegt. Bitte legen Sie ein oder mehrere Assets an.
            </p>
        </div>

        <div v-else>
            <table class="asset-table">
                <tr class="table-header-row">
                    <th class="table-header-name">Asset-Name</th>
                    <th class="table-header-type">Asset-Typ</th>
                    <th class="table-header-edit"></th>
                    <th class="table-header-delete"></th>
                </tr>

                <tr v-for="asset in assetList" :key="asset.id" class="table-row">
                    <td>{{ asset.name }}</td>
                    <td v-if="asset.type === 'system'">IT-System(e)</td>
                    <td v-else-if="asset.type === 'webapp'">Webapplikation</td>
                    <td v-else-if="asset.type === 'webservice'">Webservice / API</td>
                    <td v-else-if="asset.type === 'mobile'">Mobile App</td>
                    <td v-else-if="asset.type === 'fatclient'">Fat Client</td>
                    <td>
                        <a class="icon-link" @click="editAsset(asset)">
                            <img src="../../../public/icon-edit.svg">
                        </a>
                    </td>
                    <td>
                        <a class="icon-link" @click="checkDeleteAsset(asset)">
                            <img src="../../../public/icon-delete.svg">
                        </a>
                    </td>
                </tr>
            </table>
        </div>

        <modal v-model="deleteModal">
            <h2 slot="title">Asset löschen</h2>
            <p>Möchten Sie das Asset wirklich löschen?</p>

            <div class="buttons">
                <div class="buttons-left">
                    <button class="primary" @click="closeDeleteModal()">Nein, Asset behalten</button>
                </div>
                <div class="buttons-right">
                    <button class="primary" @click="deleteAsset()">Ja, Asset löschen</button>
                </div>
            </div>
        </modal>

    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useModal } from '@/composables/use-modal'
import { useRouter } from '@/composables/use-router'
import { useAssetStore } from '@/store/assetStore'
import { useProjectStore } from '@/store/projectStore'
import { usePriceStore } from '@/store/priceStore'

export default defineComponent({
    name: 'AssetList',

    setup() {
        const { router } = useRouter()

        const { modal: deleteModal, openModal: openDeleteModal, closeModal: closeDeleteModal } = useModal()

        const projectStore = useProjectStore()
        const projectId = projectStore.uuid.value

        const assetStore = useAssetStore()
        const assetList = assetStore.getAll

        const editAsset = (asset: Asset) => {
            gotoEditPage(asset)
        }

        const gotoEditPage = (asset: Asset) => {
            router.push({ name: 'edit asset', params: { assetId: asset.id } })
        }

        const selectedAsset = ref<Asset>()

        const checkDeleteAsset = (asset: Asset) => {
            selectedAsset.value = asset
            openDeleteModal()
        }

        const priceStore = usePriceStore()

        const deleteAsset = async() => {
            if (selectedAsset.value !== undefined) {
                await assetStore.deleteAsset(selectedAsset.value.id, projectId)
            }
            await assetStore.updateAssetList(projectId)
            assetStore.clearAsset()
            await priceStore.calculateAssetPrice(projectId)
            await priceStore.fetchProjectPrice(projectId)
            closeDeleteModal()
        }

        return {
            assetList,
            selectedAsset,
            editAsset,
            checkDeleteAsset,
            deleteAsset,
            deleteModal,
            openDeleteModal,
            closeDeleteModal
        }
    }
})
</script>

<style lang="scss" scoped>
.asset-list {
    width: 100%;
}

.asset-table {
    margin: 2% 0;
    width: 100%;
    input[type="checkbox"] {
        display: inline-block;
    }
}

th {
    color: var(--color-brand-secondary);
}

td:not(:first-child) {
  padding-top:20px;
  padding-bottom:20px;
  padding-right:20px;
}

.table-header-row {
    border-bottom: 2px solid var(--color-black);
}

.table-row {
    border-bottom: 1px solid var(--color-black);
}

.table-header-name {
    min-width: 30%;
}

.table-header-edit, .table-header-delete {
    width: 30px;
}

.icon-link {
    cursor: pointer;
    img {
        height: 24px;
    }
}

.asset-list-empty-text {
    margin: 0 0 4% 2%;
}

</style>
