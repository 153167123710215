import axios, { AxiosResponse } from 'axios'
import qs from 'qs'

const csrfTokenMeta = document.querySelector('meta[name="csrf-token"]')
const csrfToken = csrfTokenMeta ? csrfTokenMeta.getAttribute('content') : ''

// All api calls need to have the /api prefix
const baseURL = window.location.protocol + '//' + window.location.hostname + '/api'

export const Repository = axios.create({
    baseURL,
    headers: {
        // A fix for IE11 - we need to define Pragma header
        Pragma: 'no-cache',
        'Content-Type': 'application/vnd.api+json',
        'X-CSRF-TOKEN': csrfToken || ''
    },

    paramsSerializer: function(params) {
        return qs.stringify(params)
    }
})

/**
 * This interceptor handles all 403 requests.
 *
 * The 403s usually mean that the user's session has timed out. Since we cannot be 100% certain of that,
 * we force a page reload and let the server handle the resulting conditions.
 */
Repository.interceptors.response.use((response: AxiosResponse) => {
    return response
},
(error: any) => {
    if (error.response.status === 403) {
        // If the user's session has timed out on an API call, we will get a message "Username could not be found.".
        // In this case, redirect to front, as we want to login the user again.
        if (error.response.data.message === 'Username could not be found.' ||
            error.response.data.message === 'Invalid credentials.'
        ) {
            window.location.href = '/'
        }
    }
    return Promise.reject(error)
})

export default Repository
