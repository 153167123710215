<template>
    <div class="separate-environment-summary">
        <p v-if="separateEnvironment">
            Eine Testumgebung muss von usd aufsetzt werden.
        </p>

        <p v-else-if="!separateEnvironment">
            Eine Testumgebung muss nicht von usd aufsetzt werden.
        </p>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useScopeStore } from '@/store/scopeStore'

export default defineComponent({
    name: 'SeparateEnvironmentSummary',

    setup() {
        const scopeStore = useScopeStore()
        const separateEnvironment = scopeStore.separateEnvironment

        return {
            separateEnvironment
        }
    }
})
</script>

<style lang="scss" scoped>
.scope-item {
    display: flex;
    margin-bottom: 2em;
}
.scope-item-name {
    width: 30%;
}
</style>
