<template>
    <div class='remediation-support-optional'>
        <div class="optional-services-header">
            <h2>{{ fromJson.remediation.heading }}</h2>

            <p>
                {{ fromJson.remediation.body }}
            </p>
        </div>

        <tristate-switch-field id="remediation-switch" v-model="remediationSupport" @input="updateOptionals()" disable-null label="" label-pre="Ja" label-post="Nein"/>

        <div class="buttons-info">
            <button class="button-info" @click="openWantedModal">{{ fromJson.remediation.button }}</button>
        </div>

        <modal class="info-modal" v-model="wantedModal">
            <img slot="title" src="../../../public/icon-info.svg" alt="icon info">
            <h2 slot="title">{{ fromJson.remediation.modal.heading }}</h2>

            <p v-for="paragraph in fromJson.remediation.modal.body" :key="paragraph">
                {{ paragraph }}
            </p>

            <p>
                {{ fromJson.remediation.modal.optional }}
            </p>

            <form-actions slot="footer">
                <button @click="closeWantedModal" class="primary button-modal">{{ fromJson.remediation.modal.button }}</button>
            </form-actions>
        </modal>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useModal } from '@/composables/use-modal'
import { useProjectStore } from '@/store/projectStore'
import { useOptionalStore } from '@/store/optionalStore'
import { usePriceStore } from '@/store/priceStore'
import Slider from '@/components/Slider.vue'
import json from '@/json/OptionalDE.json'

export default defineComponent({
    name: 'RemediationSupportOptional',

    components: {
        Slider
    },

    setup() {
        const { modal: wantedModal, openModal: openWantedModal, closeModal: closeWantedModal } = useModal()

        const projectStore = useProjectStore()
        const projectId = projectStore.uuid

        const fromJson = json

        const optionalStore = useOptionalStore()
        const remediationSupport = optionalStore.remediationSupport

        const priceStore = usePriceStore()

        const processing = ref<Boolean>(false)

        const updateOptionals = async() => {
            if (processing.value === false) {
                processing.value = true
                await optionalStore.updateOptionals(projectId.value)
                await priceStore.fetchOptionalsPrices(projectId.value)
                await priceStore.fetchProjectPrice(projectId.value)
                processing.value = false
            }
        }

        return {
            fromJson,
            remediationSupport,
            updateOptionals,
            wantedModal,
            openWantedModal,
            closeWantedModal
        }
    }

})
</script>

<style lang="scss" scoped>
@import "../../styles/breakpoints";

.remediation-support-optional {
    @include default-padding();

}

.optional-services-header {
    padding-bottom: 1.5%;
    text-align: left;
    h2 {
        color: var(--color-brand-primary);
    }
}

.optional-services-options {
    padding: 2.5% 4%;
}

.optional-services-radio-options {
    display: flex;
    margin-top: 1.5%;
    border: 1px solid var(--color-black);
    background-color: var(--color-white);
}

ul {
    margin-left: -1.5em;
}
</style>
