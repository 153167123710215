<template>
    <div class="hardware-summary">
        <p v-if="hardware">
            Für die Durchführung des Pentests stellt der Auftraggeber zusätzliche Spezialhardware zur Verfügung.
        </p>

        <p v-else-if="!hardware">
            Es ist nicht vorgesehen, dass Spezialhardware verwendet wird.
        </p>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useScopeStore } from '@/store/scopeStore'

export default defineComponent({
    name: 'HardwareSummary',

    setup() {
        const scopeStore = useScopeStore()
        const hardware = scopeStore.hardware

        return {
            hardware
        }
    }
})
</script>

<style lang="scss" scoped>
.scope-item {
    display: flex;
    margin-bottom: 2em;
}
.scope-item-name {
    width: 30%;
}
</style>
