import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/components/Home.vue'
import ProjectCreate from '@/components/project/ProjectCreate.vue'
import AssetConfig from '@/components/AssetConfig.vue'
import ContingentConfig from '@/components/ContingentConfig.vue'
import ScenarioConfig from '@/components/ScenarioConfig.vue'
import ProjectScope from '@/components/project/ProjectScope.vue'
import ProjectOptional from '@/components/project/ProjectOptional.vue'
import ProjectSummary from '@/components/project/ProjectSummary.vue'
import AssetCreate from '@/components/asset/AssetCreate.vue'
import AssetConfigurator from '@/components/asset/AssetConfigurator.vue'
import ErrorPage from '@/components/ErrorPage.vue'
import AccessLimitPage from '@/components/AccessLimitPage.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            title: 'Pentestkonfigurator | usd AG',
            metaTags: [
                {
                    name: 'description',
                    content: 'Mit dem Pentest-Konfigurator der usd AG erstellen Sie Ihre individuelle Pentest-Projektanfrage in 5 einfachen Schritten online. Jederzeit. Zügig. Sicher.'
                }
            ]
        },
    },
    {
        path: '/project',
        name: 'new project',
        component: ProjectCreate,
        meta: {
            title: 'Projekt anlegen | more security. usd AG',
            metaTags: [
                {
                    name: 'description',
                    content: 'Sichern Sie sich attraktive Sonderkonditionen auf Ihr nächstes Pentest-Projekt und nutzen Sie den online Pentest-Konfigurator der usd AG für Ihre nächste Pentest-Anfrage.'
                }
            ]
        },
        children: [
            {
                path: ':id/configure',
                name: 'asset-based project',
                component: AssetConfig,
                meta: {
                    title: 'Asset | more security. usd AG',
                    metaTags: [
                        {
                            name: 'robots',
                            content: 'noindex'
                        }
                    ]
                },
                children: [
                    {
                        path: 'asset',
                        name: 'add asset',
                        component: AssetCreate,
                        meta: {
                            title: 'Asset anlegen | more security. usd AG',
                            metaTags: [
                                {
                                    name: 'robots',
                                    content: 'noindex'
                                }
                            ]
                        },
                        children: [
                            {
                                path: 'configure',
                                name: 'configure asset',
                                component: AssetConfigurator,
                                meta: {
                                    title: 'Asset anlegen | more security. usd AG',
                                    metaTags: [
                                        {
                                            name: 'robots',
                                            content: 'noindex'
                                        }
                                    ]
                                }
                            },
                            {
                                path: ':assetId/edit',
                                name: 'edit asset',
                                component: AssetConfigurator,
                                meta: {
                                    title: 'Asset bearbeiten | more security. usd AG',
                                    metaTags: [
                                        {
                                            name: 'robots',
                                            content: 'noindex'
                                        }
                                    ]
                                }
                            }
                        ]
                    }
                ]
            },
            {
                path: ':id/configure/contingent',
                name: 'contingent-based project',
                component: ContingentConfig,
                meta: {
                    title: 'Kontingent | more security. usd AG',
                    metaTags: [
                        {
                            name: 'robots',
                            content: 'noindex'
                        }
                    ]
                }
            },
            {
                path: ':id/configure/scenario',
                name: 'scenario-based project',
                component: ScenarioConfig,
                meta: {
                    title: 'Szenario | more security. usd AG',
                    metaTags: [
                        {
                            name: 'robots',
                            content: 'noindex'
                        }
                    ]
                }
            },
            {
                path: ':id/configure/scope',
                name: 'project scope',
                component: ProjectScope,
                meta: {
                    title: 'Organisatorisches | more security. usd AG',
                    metaTags: [
                        {
                            name: 'robots',
                            content: 'noindex'
                        }
                    ]
                }
            },
            {
                path: ':id/configure/optional',
                name: 'project optional',
                component: ProjectOptional,
                meta: {
                    title: 'Zusatzleistungen | more security. usd AG',
                    metaTags: [
                        {
                            name: 'robots',
                            content: 'noindex'
                        }
                    ]
                }
            },
            {
                path: ':id/summary',
                name: 'project summary',
                component: ProjectSummary,
                meta: {
                    title: 'Zusammenfassung | more security. usd AG',
                    metaTags: [
                        {
                            name: 'robots',
                            content: 'noindex'
                        }
                    ]
                }
            }
        ]
    },
    {
        path: "/:catchAll(.*)",
        name: "not found",
        component: ErrorPage,
        meta: {
            title: 'Error | more security. usd AG',
            metaTags: [
                {
                    name: 'robots',
                    content: 'noindex'
                }
            ]
        }
    },
    {
        path: "/error",
        name: "too many requests",
        component: AccessLimitPage,
        meta: {
            title: 'Error | more security. usd AG',
            metaTags: [
                {
                    name: 'robots',
                    content: 'noindex'
                }
            ]
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if(nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    } else if(previousNearestWithMeta) {
        document.title = previousNearestWithMeta.meta.title;
    }

    // Remove any stale meta tags from the document using the key attribute we set below.
    // @ts-ignore
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if(!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map((tagDef: any) => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    })
        // Add the meta tags to the document head.
        .forEach((tag: any) => document.head.appendChild(tag));

    next();
});

export default router
