<template>
    <footer class="page-footer">
        <div class="section-footer">
            <nav class="menu-footer">
                <h2 class="menu-footer-head">usd AG</h2>
                <ul class="menu-footer-links">
                    <li>
                        <a class="footer-link" href="https://www.usd.de/contact" target="_blank" rel="noopener noreferrer">Kontakt</a>
                    </li>
                    <li>
                        <a class="footer-link" href="https://www.usd.de/impressum/" target="_blank" rel="noopener noreferrer">Impressum</a>
                    </li>
                    <li>
                        <a class="footer-link" href="https://www.usd.de/datenschutz/" target="_blank" rel="noopener noreferrer">Datenschutz</a>
                    </li>
                    <li>
                        <a class="footer-link" href="https://www.usd.de/rechtliche-hinweise/" target="_blank" rel="noopener noreferrer">Allgemeine Nutzungsbedingungen</a>
                    </li>
                    <li>
                      © {{ currentYear }} usd AG
                    </li>
                </ul>
            </nav>

            <nav class="media-footer">
                <h2 class="media-footer-head">Folgen Sie uns</h2>

                <div class="social-media-icons">
                    <div class="social-media-icons-row">
                        <a class="icon-social-media icon-linkedin" href="https://de.linkedin.com/company/usdag" target="_blank" rel="noopener noreferrer"></a>

                        <a class="icon-social-media icon-youtube" href="https://www.youtube.com/c/usdAG" target="_blank" rel="noopener noreferrer"></a>

                        <a class="icon-social-media icon-twitter" href="https://twitter.com/usdAG" target="_blank" rel="noopener noreferrer"></a>

                        <a class="icon-social-media icon-meetup" href="https://www.meetup.com/de-DE/Cyber-Security-Transformation-Academy" target="_blank" rel="noopener noreferrer"></a>
                    </div>

                    <div class="social-media-icons-row">
                        <a class="icon-social-media icon-github" href="https://github.com/usdAG" target="_blank" rel="noopener noreferrer"></a>

                        <a class="icon-social-media icon-reddit" href="https://www.reddit.com/user/usdAG" target="_blank" rel="noopener noreferrer"></a>

                        <a class="icon-social-media icon-mastodon" href="https://infosec.exchange/@usdAG" target="_blank" rel="noopener noreferrer"></a>
                    </div>
                </div>

                <a class="link-report-bug" href="https://www.usd.de/meldung-bug-oder-schwachstelle/" target="_blank" rel="noopener noreferrer">Meldung einer Schwachstelle oder eines Bugs</a>
            </nav>

          <div class="seal-footer">
            <a href="https://pci.usd.de/compliance/1101-9C84-09F8-B6E7-F6C1-1817/details_de.html" target="_blank" rel="noopener noreferrer" onclick="window.open(this.href, '', 'width=600, height=615, left=100, top=200'); return false;">
              <img src="https://pci.usd.de/compliance/1101-9C84-09F8-B6E7-F6C1-1817/seal.png" alt="pci-dss approved seal">
            </a>
          </div>

        </div>
    </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Footer',

    setup() {
        const currentYear = new Date().getFullYear()

        return {
            currentYear
        }
    }
})
</script>

<style lang="scss">
@import "../styles/breakpoints";

.page-footer {
    position: relative;
    display: block;
    font-size: 14px;

    h2 {
        font-size: 18px;
        font-weight: 300;
    }
    a {
        color: var(--color-white);
        text-decoration: none;
    }
    .section-footer {
        display: flex;
        padding: 2% 10%;
        background-color: var(--color-anthracite);
        color: var(--color-white);
    }
    .menu-footer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 33%;

        .menu-footer-head {
            color: #8796a8;
        }
        .menu-footer-links {
            display: flex;
            flex-direction: column;
            list-style-type: none;
            padding: 0;
            margin: 0;
        }
    }
    .media-footer {
        display: flex;
        flex-direction: column;
        width: 33%;

        .media-footer-head {
            color: #8796a8;
        }

        .link-report-bug {
            color: var(--color-brand-primary);
        }

        .social-media-icons-row {
            display: flex;
            margin-bottom: 1em;

            .icon-social-media {
                margin-right: 10px;
                display: block;
                width: 36px;
                height: 36px;
            }
            .icon-linkedin {
                background-image: url('../../public/icons-social-media/usd-social-media-linkedin.svg');
            }
            .icon-twitter {
                background-image: url('../../public/icons-social-media/usd-social-media-x.svg');
            }
            .icon-meetup {
                background-image: url('../../public/icons-social-media/usd-social-media-meetup.svg');
            }
            .icon-youtube {
                background-image: url('../../public/icons-social-media/usd-social-media-youtube.svg');
            }
            .icon-github {
                background-image: url('../../public/icons-social-media/usd-social-media-github.svg');
            }
            .icon-reddit {
                background-image: url('../../public/icons-social-media/usd-social-media-reddit.svg');
            }
            .icon-mastodon {
                background-image: url('../../public/icons-social-media/usd-social-media-mastodon.svg');
            }
        }
    }
    .seal-footer {
        width: 33%;
        text-align: center;
    }
}

@media screen and (max-width: $bp-medium) {
    .page-footer .section-footer {
        flex-direction: column;
        align-items: center;
        padding: 2em 1em;
    }

    .page-footer .menu-footer {
        width: 100%;
        align-items: center;
        text-align: center;
    }

    .page-footer .media-footer {
        width: 100%;
        margin-top: 2em;
    }

    .seal-footer {
        width: 100%;
        margin-top: 2em;
    }
}
</style>
