<template>
    <div class="navbar" id="navbar">
        <div class="logo" @click="checkOpenConfirmationModal()">
            <img src="@/assets/usd_logo.png" alt="usd logo">
        </div>

<!--        <div class="navbar-items">-->
<!--            <PricePreview class="navbar-toggle"/>-->

<!--            <div class="navbar-item">-->
<!--                <a href="">Hilfe</a>-->
<!--            </div>-->

<!--            <div class="navbar-item">-->
<!--                <a href="">English</a>-->
<!--            </div>-->
<!--        </div>-->

        <modal class="info-modal" v-model="confirmationModal">
            <img slot="title" src="./../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Konfiguration abbrechen und Konfigurator verlassen</h2>

            <p>
                Möchten Sie den Konfigurator wirklich verlassen? Ihre bisherigen Eingaben gehen dadurch unwiderruflich verloren.
            </p>

            <div class="buttons-modal">
                <div class="buttons-left">
                    <button class="ghost-primary button-modal-back-dialog" @click="gotoHome()">Ja, Konfigurator verlassen.</button>
                </div>
                <div class="buttons-right">
                    <button class="primary button-modal-back-dialog" @click="closeConfirmationModal()">Nein, Konfiguration fortsetzen.</button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted } from 'vue'
// import PricePreview from '@/components/price/PricePreview.vue'
import { useRouter } from '@/composables/use-router'
import { useModal } from '@/composables/use-modal'
import { useProjectStore } from '@/store/projectStore'

export default defineComponent({
    name: 'Navbar',

    // components: {
    //     PricePreview
    // },

    setup() {
        const { router, route } = useRouter()

        const { modal: confirmationModal, openModal: openConfirmationModal, closeModal: closeConfirmationModal } = useModal()

        const handleScroll = () => {
            const navbar = document.getElementById('navbar')
            if (navbar) {
                const sticky = navbar.offsetTop
                if (window.pageYOffset >= sticky) {
                    navbar.classList.add('sticky')
                } else {
                    navbar.classList.remove('sticky')
                }
            }
        }

        onMounted(() => {
            document.addEventListener('scroll', handleScroll)
        })

        onUnmounted(() => {
            document.removeEventListener('scroll', handleScroll)
        })

        const checkOpenConfirmationModal = () => {
            if (route.value.name !== 'home' && route.value.name !== 'not found') {
                openConfirmationModal()
            } else if (route.value.name === 'not found') {
                projectStore.deleteProject()
                router.replace({ name: 'home' })
                window.scrollTo(0, 0)
                window.location.reload()
            }
        }

        const projectStore = useProjectStore()

        const gotoHome = () => {
            projectStore.deleteProject()
            closeConfirmationModal()
            router.replace({ name: 'home' })
            window.scrollTo(0, 0)
            window.location.reload()
        }

        return {
            checkOpenConfirmationModal,
            gotoHome,
            confirmationModal,
            openConfirmationModal,
            closeConfirmationModal
        }
    }
})
</script>

<style lang="scss" scoped>
@import "../styles/breakpoints";

@media screen and (max-width: $bp-small) {
    .logo {
        width: 50%;
    }
}
</style>
