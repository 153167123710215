<template>
    <div class='asset-type'>
        <div class="asset-type-title">
            <h2>
                Um was für ein Asset handelt es sich? *
            </h2>
            <p>
                Bitte wählen Sie den passenden Asset-Typ aus.
            </p>
        </div>

        <div class="asset-type-card-container">
            <label>
                <input
                    class="asset-type-card"
                    type="radio"
                    id="system"
                    name="asset-type"
                    value="system"
                    v-model="type"
                    v-on:change="changeAssetType('system')"
                />
                    <div class="asset-type-card-input">
                        <img class="card-header-image" src="../../../public/images-assets/asset-networkcomponent.jpg">
                        <div class="card-text">
                            <h2>{{ fromJson.system.heading }}</h2>
                            <p>
                                {{ fromJson.system.body }}
                            </p>
                        </div>

                        <div class="asset-type-card-footer">
                            <button class="button-link" @click="openUrl('https://www.usd.de/security-analysis-pentests/pentest-systeme/')">Mehr erfahren</button>

                            <div class="card-checkmark" :class="{ unchecked: type !== 'system' }">
                                <i class="material-icon mdi mdi-check" />
                            </div>
                        </div>
                    </div>
            </label>

            <label>
                <input
                    class="asset-type-card"
                    type="radio"
                    id="webapp"
                    name="asset-type"
                    value="webapp"
                    v-model="type"
                    v-on:change="changeAssetType('webapp')"
                />
                    <div class="asset-type-card-input">
                        <img class="card-header-image" src="../../../public/images-assets/asset-webapplication.jpg">
                        <div class="card-text">
                            <h2>{{ fromJson.webapp.heading }}</h2>
                            <p>
                                {{ fromJson.webapp.body }}
                            </p>
                        </div>

                        <div class="asset-type-card-footer">
                            <button class="button-link" @click="openUrl('https://www.usd.de/security-analysis-pentests/pentest-webapplikationen/')">Mehr erfahren</button>

                            <div class="card-checkmark" :class="{ unchecked: type !== 'webapp' }">
                                <i class="material-icon mdi mdi-check" />
                            </div>
                        </div>
                    </div>
            </label>

            <label>
                <input
                    class="asset-type-card"
                    type="radio"
                    id="webservice"
                    name="asset-type"
                    value="webservice"
                    v-model="type"
                    v-on:change="changeAssetType('webservice')"
                />
                    <div class="asset-type-card-input">
                        <img class="card-header-image" src="../../../public/images-assets/asset-webservice.jpg">
                        <div class="card-text">
                            <h2>{{ fromJson.webservice.heading }}</h2>
                            <p>
                                {{ fromJson.webservice.body }}
                            </p>
                        </div>

                        <div class="asset-type-card-footer">
                            <button class="button-link" @click="openUrl('https://www.usd.de/security-analysis-pentests/pentest-webapplikationen/')">Mehr erfahren</button>

                            <div class="card-checkmark" :class="{ unchecked: type !== 'webservice' }">
                                <i class="material-icon mdi mdi-check" />
                            </div>
                        </div>
                    </div>
            </label>

            <label>
                <input
                    class="asset-type-card"
                    type="radio"
                    id="mobile"
                    name="asset-type"
                    value="mobile"
                    v-model="type"
                    v-on:change="changeAssetType('mobile')"
                />
                    <div class="asset-type-card-input">
                        <img class="card-header-image" src="../../../public/images-assets/asset-mobileapplication.jpg">
                        <div class="card-text">
                            <h2>{{ fromJson.mobile.heading }}</h2>
                            <p>
                                {{ fromJson.mobile.body }}
                            </p>
                        </div>

                        <div class="asset-type-card-footer">
                            <button class="button-link" @click="openUrl('https://www.usd.de/security-analysis-pentests/pentest-mobile-anwendungen/')">Mehr erfahren</button>

                            <div class="card-checkmark" :class="{ unchecked: type !== 'mobile' }">
                                <i class="material-icon mdi mdi-check" />
                            </div>
                        </div>
                    </div>
            </label>

<!--            <label>-->
<!--                <input-->
<!--                    class="asset-type-card"-->
<!--                    type="radio"-->
<!--                    id="cloud"-->
<!--                    name="asset-type"-->
<!--                    value="cloud"-->
<!--                    v-model="type"-->
<!--                    v-on:change="changeAssetType('cloud')"-->
<!--                />-->
<!--                    <div class="asset-type-card-input">-->
<!--                        <img class="card-header-image" src="../../../public/images-assets/asset-cloudcomponent.jpg">-->
<!--                        <div class="card-text">-->
<!--                            <h2>{{ fromJson.cloud.heading }}</h2>-->
<!--                            <p>-->
<!--                                {{ fromJson.cloud.body }}-->
<!--                            </p>-->
<!--                        </div>-->
<!---->
<!--                        <div class="card-checkmark" :class="{ unchecked: type !== 'cloud' }">-->
<!--                            <i class="material-icon mdi mdi-check" />-->
<!--                        </div>-->
<!--            -->
<!--                        <button class="button-link" @click="openUrl('https://www.usd.de/security-analysis-pentests/pentest-cloud/')">Mehr erfahren</button>-->
<!--                    </div>-->
<!--            </label>-->

            <label>
                <input
                    class="asset-type-card"
                    type="radio"
                    id="fatclient"
                    name="asset-type"
                    value="fatclient"
                    v-model="type"
                    v-on:change="changeAssetType('fatclient')"
                />
                    <div class="asset-type-card-input">
                        <img class="card-header-image" src="../../../public/images-assets/asset-fatclient.jpg">
                        <div class="card-text">
                            <h2>{{ fromJson.fatclient.heading }}</h2>
                            <p>
                                {{ fromJson.fatclient.body }}
                            </p>
                        </div>

                        <div class="asset-type-card-footer">
                            <button class="button-link" @click="openUrl('https://www.usd.de/security-analysis-pentests/pentest-fat-clients/')">Mehr erfahren</button>

                            <div class="card-checkmark" :class="{ unchecked: type !== 'fatclient' }">
                                <i class="material-icon mdi mdi-check" />
                            </div>
                        </div>
                    </div>
            </label>
        </div>

        <div v-if="error !== ''" class="invalid-feedback">
            {{ error }}
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import json from '@/json/AssetTypeDE.json'

export default defineComponent({
    name: 'AssetType',

    props: {
        selectedAssetType: {
            type: String,
            default: ''
        },
        error: {
            type: String,
            default: ''
        }
    },

    setup(props, { emit }) {
        const fromJson = json

        const type = ref<string>(props.selectedAssetType)

        const changeAssetType = (type: string) => {
            emit('change-asset-type', type)
        }

        const openUrl = (url: string) => {
            // open given URL in a new tab with a null-opener to make it more secure
            window.open(url, '_blank', 'noopener')
        }

        return {
            fromJson,
            type,
            changeAssetType,
            openUrl
        }
    }
})
</script>

<style lang="scss" scoped>
@import "../../styles/breakpoints";

.asset-type {
    @include default-padding();
    margin-top: 2em;
}

.asset-type-title {
    padding-top: 1.5%;
    h2 {
        color: var(--color-brand-primary);
    }
}

.asset-type-card-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    label {
        width: 31%;
        max-width: 440px;
        display: flex;
        align-items: stretch;
        margin: 1%;
    }
}

.asset-type-card {
    display: none;
}

.asset-type-card-input {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin: 0;
    border: 2px solid var(--color-light-grey);
}

.asset-type-card-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.asset-type-card:checked + .asset-type-card-input {
    border-color: var(--color-brand-secondary);
    background-color: var(--color-brand-secondary);
    color: var(--color-white);
    .card-text {
        h2 {
            color: var(--color-white);
        }
    }
    .button-link {
        background: url(../../../public/icon-link-weiss.svg) no-repeat right center;
        background-size: 20px;
        color: var(--color-white);
    }
}

.asset-type-card-container label > div {
    width: 100%;
}

.card-header-image {
    margin: -2px;
    width: calc(100% + 4px);
}

.card-text {
    flex: 1 auto;
    padding: 2em;
    h2 {
        color: var(--color-brand-secondary);
        text-align: center;
    }
}

@media screen and (max-width: 1250px) {
    .asset-type-card-container {
        justify-content: space-between;
    }

    .asset-type-card-container label {
        width: 48%;
        max-width: initial;
        margin: 0 0 1em 0;
    }
}

@media screen and (max-width: $bp-medium) {
    .asset-type-card-container {
        flex-direction: column;
    }

    .asset-type-card-container label {
        width: 100%;
        max-width: initial;
        margin-bottom: 1em;
    }
}
</style>
