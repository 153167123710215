<template>
    <div class="date-summary">
        <div v-if="dateList.length > 0">
            <p>
                <strong>
                    Folgende Wunschtermine wurden für den Pentest ausgewählt.
                </strong>
            </p>

            <div v-for="index in dateList.length" :key="index">
                <p>
                    <u>
                        Wunschzeitraum {{ index }}
                    </u>
                </p>

                <ul>
                    <li class="list-item">
                        <p class="list-item-name">
                            Projektstart:
                        </p>

                        <p>
                            {{ startDateList[index - 1] }}
                        </p>
                    </li>

                    <li class="list-item">
                        <p class="list-item-name">
                            Projektende:
                        </p>

                        <p>
                            {{ endDateList[index - 1] }}
                        </p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useScopeStore } from '@/store/scopeStore'

export default defineComponent({
    name: 'DateSummary',

    setup() {
        const scopeStore = useScopeStore()
        const dateList = scopeStore.dateList.value

        const startDateList = computed(() => {
            const dateArray: any = []
            dateList.forEach((dateEntry: any) => {
                const dateParts = dateEntry.start.toLocaleDateString().split('/')
                const dateString = dateParts[1] + '.' + dateParts[0] + '.' + dateParts[2]
                dateArray.push(dateString)
            })
            return dateArray
        })

        const endDateList = computed(() => {
            const dateArray: any = []
            dateList.forEach((dateEntry: any) => {
                const dateParts = dateEntry.end.toLocaleDateString().split('/')
                const dateString = dateParts[1] + '.' + dateParts[0] + '.' + dateParts[2]
                dateArray.push(dateString)
            })
            return dateArray
        })

        return {
            dateList,
            startDateList,
            endDateList
        }
    }
})
</script>

<style lang="scss" scoped>
.list-item-name {
    float: left;
    width: 7em;
}
ul {
    margin-left: -1.5em;
}
</style>
