export const RequiredValidator = (value: string): string => {
    return value === '' ? 'Dies ist ein Pflichtfeld.' : ''
}

export const RequiredValidatorBoolean = (value: boolean): string => {
    return value === null ? 'Dies ist ein Pflichtfeld.' : ''
}

export const RequiredValidatorArray = (value: string[]): string => {
    return value.length > 0 ? '' : 'Dies ist ein Pflichtfeld.'
}

export const EmailValidator = (value: string): string => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    return re.test(value) ? '' : 'Bitte geben Sie eine valide E-Mail-Adresse ein.'
}

// export const TelephoneValidator = (value: string) => {
//     // Use libphonenumber library for validation: https://github.com/google/libphonenumber/tree/master/javascript
// }