import { ref, reactive, computed } from 'vue'
import Repository from '@/repository/Repository'

// Store
const rating = ref<number>(0)

const comment = ref<string>('')

const actions = {
    sendFeedback: async() => {
        if (rating.value !== 0 || comment.value !== '') {
            try {
                const data = {
                    rating: rating.value,
                    comment: comment.value
                }
                await Repository.post('/asana/feedback', data)
            } catch (error: any) {
                throw new Error(error.statusCode)
                // console.log('Create feedback task error: ' + error)
            }
        }
    }
}

export const useFeedbackStore = () => {
    return {
        rating,
        comment,
        ...actions
    } as const
}
