<template>
    <div class="expansion-panel">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'ExpansionPanel',

    props: {
        closed: {
            type: Boolean,
            default: false
        }
    },

    data: () => ({
        items: [],
        open: null
    }),

    provide() {
        return {
            register: this.register,
            unregister: this.unregister,
            setOpen: this.setOpen
        }
    },

    methods: {
        register(content) {
            this.items.push(content)
            // If we have the first panel, set it to open.
            if (this.open === null && this.closed === false) {
                content.toggle(true)
            }
        },

        unregister(content) {
            if (this.open === content) {
                this.open = null
            }
            const index = this.items.findIndex(i => i._uid === content._uid)
            this.items.splice(index, 1)
        },

        setOpen(content, isOpen) {
            for (let i = 0; i < this.items.length; i++) {
                const item = this.items[i]
                if (isOpen === true && item._uid !== content._uid) {
                    item.toggle(false)
                }
            }

            if (isOpen === false) {
                this.open = null
            } else {
                this.open = content
            }
        }
    }
}
</script>
