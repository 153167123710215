<template>
    <div class="home">

        <div class="image-header">
            <img src="../../public/image-header.jpg">
            <div class="image-header-text">
                <img class="usd-configurator-logo" src="../../public/logo_de.svg">
                <h2>
                    für Ihren Pentest
                </h2>
                <p>
                    Starten Sie jetzt schnell und unkompliziert in Ihr Pentest-Projekt!
                </p>

                <div class="image-header-buttons">
                    <button class="primary image-header-button" @click="gotoProject()">Projekt anfragen</button>
                </div>
            </div>
        </div>

        <NumberedSteps />
        <Advantages />
        <HowWeProtectYou />
        <Standards />
        <Certifications />
        <FAQ />

    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import NumberedSteps from '@/components/home/NumberedSteps.vue'
import Advantages from '@/components/home/Advantages.vue'
import HowWeProtectYou from '@/components/home/HowWeProtectYou.vue'
import Certifications from '@/components/home/Certifications.vue'
import Standards from '@/components/home/Standards.vue'
import FAQ from '@/components/home/FAQ.vue'

export default defineComponent({
    name: 'Home',

    components: {
        NumberedSteps,
        Advantages,
        HowWeProtectYou,
        Certifications,
        Standards,
        FAQ
    },

    methods: {
        gotoProject() {
            window.scrollTo(0, 0)
            this.$router.push('project/')
        }
    }
})
</script>

<style lang="scss">
@import "../styles/breakpoints";

.image-header {
    position: relative;
    text-align: left;
    color: var(--color-white);
    img {
        width: 100%;
        max-height: 800px;
        object-fit: cover;
    }
    .usd-configurator-logo {
        width: 100%;
        height: 100%;
    }
}

.image-header-text {
    position: absolute;
    top: 15%;
    left: 10%;
    h1 {
        font-size: 70px;
    }
    h2 {
        font-size: 35px;
        padding-left: 5px;
        padding-bottom: 30px;
    }
    p {
        font-size: 22px;
        padding-left: 5px;
        padding-bottom: 130px;
    }
}

.image-header-button {
    padding: 15px 55px;
    font-size: 28px;
    font-weight: 300;
    margin-right: 40px;
}

@media screen and (max-width: 1220px) {
    .image-header {
        overflow: hidden;
        & > img {
            position: absolute;
            min-width: 100%;
            min-height: 100%;
            object-fit: cover;
        }

        .usd-configurator-logo {
            max-width: 717px;
        }
    }

    .image-header-text {
        position: relative;
        padding: 2em 1em;
        left: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .image-header-text h2 {
        font-size: 25px;
        margin-top: 5px;
    }

    .image-header-text p {
        padding-bottom: 2em;
    }

    .image-header-button {
        margin-right: 0;
        font-size: 20px;
        padding: 15px 30px;
    }
}
</style>
