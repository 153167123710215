import { ref, computed } from 'vue'
import Repository from '@/repository/Repository'

// Store
const scenarioList = ref<Array<any>>([])

const scenarioPrice = ref<number>(0)

const contingentPrice = ref<number>(0)

const assetList = ref<Array<any>>([])

const assetPrice = ref<number>(0)

const scopePrice = ref<number>(0)

const finalPresentationPrice = ref<number>(0)

const auditReportPrice = ref<number>(0)

const liveDemoPrice = ref<number>(0)

const retestPrice = ref<number>(0)

const fullPrice = ref<number>(0)

// Getters
const getters = {
    getScenarioPrice: computed(() => scenarioPrice.value),
    getContingentPrice: computed(() => contingentPrice.value),
    getAssetPrice: computed(() => assetPrice.value),
    getScopePrice: computed(() => scopePrice.value),
    getFinalPresentationPrice: computed(() => finalPresentationPrice.value),
    getAuditReportPrice: computed(() => auditReportPrice.value),
    getLiveDemoPrice: computed(() => liveDemoPrice.value),
    getRetestPrice: computed(() => retestPrice.value),
    getFullPrice: computed(() => fullPrice.value)
}

// Actions
const actions = {
    fetchOptionalsPrices: async(projectId: string) => {
        try {
            const params = { projectUuid: projectId }
            const response = await Repository.get('/optionals', { params })
            const optionals = response.data
            finalPresentationPrice.value = optionals.finalPresentationPrice
            auditReportPrice.value = optionals.auditReportPrice
            liveDemoPrice.value = optionals.liveDemoPrice
            retestPrice.value = optionals.retestPrice
        } catch (error) {
            // console.log('Calculate optionals price error: ' + error)
        }
    },
    fetchScopePrice: async(projectId: string) => {
        try {
            const params = { projectUuid: projectId }
            const response = await Repository.get('/scope', { params })
            scopePrice.value = response.data.price
        } catch (error) {
            // console.log('Calculate scenario price error: ' + error)
        }
    },
    calculateScenarioPrice: async(projectId: string) => {
        // Reset price
        scenarioPrice.value = 0
        // Calculate price for all scenarios combined
        try {
            const params = { projectUuid: projectId }
            const response = await Repository.get('/scenario', { params })
            scenarioList.value = response.data
            scenarioList.value.forEach((scenario: any) => {
                scenarioPrice.value += scenario.price
            })
        } catch (error) {
            // console.log('Calculate scenario price error: ' + error)
        }
    },
    fetchContingentPrice: async(projectId: string) => {
        try {
            const params = { projectUuid: projectId }
            const response = await Repository.get('/contingent', { params })
            contingentPrice.value = response.data.price
        } catch (error) {
            contingentPrice.value = 0
            // console.log('Fetch contingent price error: ' + error)
        }
    },
    calculateAssetPrice: async(projectId: string) => {
        // Reset price
        assetPrice.value = 0
        // Calculate price for all assets combined
        try {
            const params = { projectUuid: projectId }
            const response = await Repository.get('/asset/list', { params })
            assetList.value = response.data
            assetList.value.forEach((asset: any) => {
                assetPrice.value += asset.price
            })
        } catch (error) {
            // console.log('Calculate asset price error: ' + error)
        }
    },
    fetchProjectPrice: async(projectId: string) => {
        try {
            const params = { projectId: projectId }
            const response = await Repository.get('/project/price', { params })
            fullPrice.value = response.data
        } catch (error) {
            // console.log('Update project price error: ' + error)
        }
    }
}

export const usePriceStore = () => {
    return {
        ...getters,
        ...actions
    } as const
}
