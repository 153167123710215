<template>
    <div class='scope-location'>
        <div class="scope-header">
            <h2>Leistungsort *</h2>

            <p>
                Kann der Pentest remote von den Büros der usd durchgeführt werden?
            </p>
        </div>

        <BoxSelectRadio id="location-radio" v-model="location" :options="locationOptions" />

        <div v-if="location === 'custom' && projectType !== 'contingent'" class="location-list-container">
            <div class="location-list">
                <p>
                    Bitte konkretisieren Sie, welche Prüfungen vor Ort bzw. remote durchgeführt werden sollen.
                </p>
                <!-- Ask question for each element in AssetList/all scenarios which are displayed in a table-->
                <div v-if="projectType === 'asset'">
                    <table class="asset-table">
                        <tr class="table-header-row">
                            <th class="table-header-name">Asset-Name</th>
                            <th class="table-header-type">Asset-Typ</th>
                            <th class="table-header-option">
                                <span class="location-header-option">vor Ort</span>
                                <span class="location-header-option">Remote</span>
                            </th>
                        </tr>

                        <tr v-for="asset in assetList" :key="asset.id" class="table-row">
                            <td>{{ asset.name }}</td>
                            <td v-if="asset.type === 'system'">
                                IT-System(e)
                            </td>
                            <td v-else-if="asset.type === 'webapp'">
                                Webapplikation
                            </td>
                            <td v-else-if="asset.type === 'webservice'">
                                Webservices und APIs
                            </td>
                            <td v-else-if="asset.type === 'mobile'">
                                Mobile Apps
                            </td>
                            <td v-else-if="asset.type === 'fatclient'">
                                Fat Client
                            </td>
                            <td>
                                <label>
                                    <input
                                        type="radio"
                                        class="location-select"
                                        :id="asset.id  + '-location'"
                                        :name="asset.name + '-location'"
                                        value="onsite"
                                        v-model="asset.location"
                                        @input="updateAssetLocation(asset.id, 'onsite')"
                                    >
                                    <i class="material-icon mdi" :class="[ asset.location === 'onsite' ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' ]" />
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        class="location-select"
                                        :id="asset.id  + '-location'"
                                        :name="asset.name + '-location'"
                                        value="remote"
                                        v-model="asset.location"
                                        @input="updateAssetLocation(asset.id, 'onsite')"
                                    >
                                    <i class="material-icon mdi" :class="[ asset.location === 'remote' ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' ]" />
                                </label>
                            </td>
                        </tr>
                    </table>
                </div>

                <div v-else-if="projectType === 'scenario'">
                    <table class="scenario-table">
                        <tr class="table-header-row">
                            <th class="table-header-type">Szenario-Bezeichnung</th>
                            <th class="table-header-option">
                                <span class="location-header-option">vor Ort</span>
                                <span class="location-header-option">Remote</span>
                            </th>
                        </tr>

                        <tbody v-for="scenario in scenarioList" :key="scenario.name">
                            <tr class="table-row">
                                <td v-if="scenario.name === 'compromisedWebsite'">
                                    Website Defacement
                                </td>
                                <td v-else-if="scenario.name === 'compromisedShop'">
                                    Cyber-Angriff auf Online-Shop
                                </td>
                                <td v-else-if="scenario.name === 'localPrivilegeEscalation'">
                                    Lokale Rechteausweitung
                                </td>
                                <td v-else-if="scenario.name === 'compromisedNetwork'">
                                    Netzwerk Angriff
                                </td>
                                <td v-else-if="scenario.name === 'externalAttack'">
                                    Externer Angriff
                                </td>
                                <td v-else-if="scenario.name === 'externalEnumeration'">
                                    Externe Enumeration
                                </td>
                                <td v-else-if="scenario.name === 'insiderAttack'">
                                    Insiderangriff
                                </td>
                                <td v-else-if="scenario.name === 'stolenLaptop'">
                                    Gestohlener Laptop
                                </td>
                                <td v-else-if="scenario.name === 'ransomware'">
                                    Ransomware Angriff
                                </td>
                                <td v-else-if="scenario.name === 'phishing'">
                                    Phishing Angriff
                                </td>
                                <td v-else-if="scenario.name === 'cloudSecurity'">
                                    Cloud Angriff
                                </td>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            class="location-select"
                                            :id="scenario.name  + '-location'"
                                            :name="scenario.name + '-location'"
                                            value="onsite"
                                            v-model="scenario.location"
                                            @input="updateScenarioLocation(scenario.name, 'onsite')"
                                        >
                                        <i class="material-icon mdi" :class="[ scenario.location === 'onsite' ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' ]" />
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="location-select"
                                            :id="scenario.name  + '-location'"
                                            :name="scenario.name + '-location'"
                                            value="remote"
                                            v-model="scenario.location"
                                            @input="updateScenarioLocation(scenario.name, 'remote')"
                                        >
                                        <i class="material-icon mdi" :class="[ scenario.location === 'remote' ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' ]" />
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="buttons-info">
            <button class="button-info" @click="openLocationModal">Weitere Infos</button>
        </div>

        <modal class="info-modal" v-model="locationModal">
            <img slot="title" src="../../../../public/icon-info.svg" alt="icon info">
            <h2 slot="title">Leistungsort</h2>

            <p>
                Üblicherweise führen wir Pentests remote aus einem separaten Pentest-Netzwerk von den Büros der usd über das Internet durch.
                Dabei verwenden wir bekannte, feste IP-Adressen, sodass Firewall-Freischaltungen einfach möglich und sämtliche Testaktivitäten
                jederzeit von Ihrem Betriebspersonal eindeutig zugeordnet werden können.
            </p>

            <p>
                Sollten einzelne zu prüfende Komponenten nicht direkt über das Internet erreichbar sein, empfehlen wir den Aufbau einer
                gesicherten VPN Verbindung und die Durchführung des Pentests remote von den Büros der usd über diese gesicherte Verbindung.
                Hierzu bieten wir Ihnen verschiedene Möglichkeiten, wie Site-to-Site oder Client-to-Site VPNs, an.
            </p>

            <p>
                Sollten diese Verbindungsarten nicht realisierbar sein, stellen wir wir Ihnen 
                gerne die usd OrangeBox, eine VPN-Appliance der usd, zur Verfügung. Im Rahmen der 
                Inbetriebnahme verbinden Sie die OrangeBox mit dem Strom und schließen sie an ihr lokales 
                Netzwerk (mit Internetzugang) an. Im Anschluss stellt die OrangeBox vollautomatisch eine sichere Verbindung zum 
                usd Pentest-Netzwerk her, über die wir dann den Pentest durchführen.
            </p>

            <p>
                Sollte auch diese Option nicht möglich sein oder Sie wünschen aus einem anderen Grund die Durchführung des Pentests vor Ort, 
                zum Beispiel in Ihrem Rechenzentrum oder an einer Niederlassung, so machen wir dies gerne für Sie möglich. 
                Bitte beachten Sie, dass dabei Reisekosten und Spesen entstehen.
            </p>

            <p>
                Selbstverständlich kann es bei verteilten IT-Systemen heute auch zu gemischten Lösungen kommen. Heißt, ein Teil der zu prüfenden
                Komponenten ist direkt über das Internet, ein Teil über VPN-Verbindungen und ein verbleibender Rest nur vor Ort prüfbar. Auch diese
                gemischten Formen unterstützen wir gerne.
            </p>

            <form-actions slot="footer">
                <button @click="closeLocationModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useModal } from '@/composables/use-modal'
import { useScopeStore } from '@/store/scopeStore'
import { useProjectStore } from '@/store/projectStore'
import { useAssetStore } from '@/store/assetStore'
import { useScenarioStore } from '@/store/scenarioStore'
import BoxSelectRadio from '@/components/BoxSelectRadio.vue'

export default defineComponent({
    name: 'ScopeLocation',

    components: {
        BoxSelectRadio
    },

    setup() {
        const { modal: locationModal, openModal: openLocationModal, closeModal: closeLocationModal } = useModal()

        const locationOptions = [
            {
                id: 'remote',
                label: 'Alle Prüfungen erfolgen remote.'
            },
            {
                id: 'onsite',
                label: 'Alle Prüfungen erfolgen vor Ort.'
            },
            {
                id: 'custom',
                label: 'Einige Prüfungen erfolgen remote, andere vor Ort.'
            }
        ]

        const projectStore = useProjectStore()
        const projectId = projectStore.uuid.value
        const projectType = projectStore.type

        const scopeStore = useScopeStore()
        const location = scopeStore.location

        const assetStore = useAssetStore()
        const assetList = assetStore.getAll.value

        const scenarioStore = useScenarioStore()
        scenarioStore.updateSelectedScenarioList(projectId)
        const scenarioList = scenarioStore.getSelectedScenarios

        const updateAssetLocation = async(assetId: string, location: string) => {
            await assetStore.updateAssetLocation(projectId, assetId, location)
            await assetStore.updateAssetList(projectId)
        }

        const updateScenarioLocation = (name: string, location: string) => {
            scenarioStore.updateScenarioLocation(projectId, name, location)
        }

        return {
            locationOptions,
            projectType,
            location,
            assetList,
            scenarioList,
            updateAssetLocation,
            updateScenarioLocation,
            locationModal,
            openLocationModal,
            closeLocationModal
        }
    }
})
</script>

<style lang="scss" scoped>
@import "../../../styles/breakpoints";

.scope-location {
    @include default-padding();
}

.scope-header {
    padding-bottom: 1.5%;
    text-align: left;
    h2 {
        color: var(--color-brand-primary);
    }
}

.location-list-container {
    margin-top: 1.5%;
    border: 1px solid var(--color-brand-secondary);
    background-color: var(--color-white);
}

.location-list {
    padding: 1.5% 3.5%;
}

.location-radio-options {
    display: flex;
    margin-top: 1.5%;
    border: 1px solid var(--color-black);
    background-color: var(--color-white);
}

.asset-table, .scenario-table {
    margin: 2% 0;
    width: 100%;
}

th {
    color: var(--color-brand-secondary);
}

.table-header-name {
    width: 20%;
}

.table-header-type {
    min-width: 30%;
}

.table-header-option {
    width: 50%;
    .location-header-option {
        display: inline-block;
        text-align: center;
        width: 50%;
    }
}
</style>
