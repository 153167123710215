<template>
    <div class='scenario-config'>
        <ConfiguratorHeader />

        <Progressbar :active="2" />

        <div class="scenario-choices">
            <div class="scenario-choices-title">
                <h2>
                    Auswahl der gewünschten Angriffsszenarien *
                </h2>
                <p>
                    Bitte wählen Sie ein oder mehrere Szenarien aus, die wir im Rahmen Ihres Pentest-Projekts für Sie überprüfen sollen.
                </p>
            </div>

            <div class="scenario-card-container">
                <label>
                    <input
                        class="scenario-card"
                        type="checkbox"
                        id="compromisedWebsite"
                        name="scenario-choice"
                        value="true"
                        v-model="scenarioList.compromisedWebsite"
                        @input="changeScenario('compromisedWebsite')"
                        :disabled="processing"
                    />
                    <div class="scenario-card-input">
                        <img class="card-header-image" src="../../public/images-scenarios/szenario-website.jpg" alt="bild szenario website">

                        <div class="card-text">
                            <h2>Website Defacement</h2>

                            <p>
                                In diesem Szenario simulieren wir einen Hackerangriff auf Ihre Unternehmenswebseite im Internet.
                            </p>
                        </div>

                        <div class="scenario-card-footer">
                            <button class="button-info" @click="openCompromisedWebsiteModal">Mehr erfahren</button>

                            <div class="card-checkmark" :class="{ unchecked: scenarioList.compromisedWebsite !== true }">
                                <i class="material-icon mdi mdi-check" />
                            </div>
                        </div>
                    </div>
                </label>

                <label>
                    <input
                        class="scenario-card"
                        type="checkbox"
                        id="compromisedShop"
                        name="scenario-choice"
                        value="true"
                        v-model="scenarioList.compromisedShop"
                        @input="changeScenario('compromisedShop')"
                        :disabled="processing"
                    />
                    <div class="scenario-card-input">
                        <img class="card-header-image" src="../../public/images-scenarios/szenario-shop.jpg" alt="bild szenario shop">

                        <div class="card-text">
                            <h2>Cyber-Angriff auf Online-Shop</h2>

                            <p>
                                Wir simulieren den Angriff eines Hackers auf Ihren Online-Shop.
                            </p>
                        </div>

                        <div class="scenario-card-footer">
                            <button class="button-info" @click="openCompromisedShopModal">Mehr erfahren</button>

                            <div class="card-checkmark" :class="{ unchecked: scenarioList.compromisedShop !== true }">
                                <i class="material-icon mdi mdi-check" />
                            </div>
                        </div>
                    </div>
                </label>

                <label>
                    <input
                        class="scenario-card"
                        type="checkbox"
                        id="externalAttack"
                        name="scenario-choice"
                        value="true"
                        v-model="scenarioList.externalAttack"
                        @input="changeScenario('externalAttack')"
                        :disabled="processing"
                    />
                    <div class="scenario-card-input">
                        <img class="card-header-image" src="../../public/images-scenarios/szenario-ext-angriff.jpg" alt="bild szenario externer angriff">

                        <div class="card-text">
                            <h2>Externer Angriff</h2>

                            <p>
                                Wir simulieren den Angriff eines externen Hackers mit dem Ziel Ihr Unternehmen über das Internet zu kompromittieren.
                            </p>
                        </div>

                        <div class="scenario-card-footer">
                            <button class="button-info" @click="openExternalAttackModal">Mehr erfahren</button>

                            <div class="card-checkmark" :class="{ unchecked: scenarioList.externalAttack !== true }">
                                <i class="material-icon mdi mdi-check" />
                            </div>
                        </div>
                    </div>
                </label>

                <label>
                    <input
                        class="scenario-card"
                        type="checkbox"
                        id="ransomware"
                        name="scenario-choice"
                        value="true"
                        v-model="scenarioList.ransomware"
                        @input="changeScenario('ransomware')"
                        :disabled="processing"
                    />
                    <div class="scenario-card-input">
                        <img class="card-header-image" src="../../public/images-scenarios/szenario-ransomware.jpg" alt="bild szenario ransomware">

                        <div class="card-text">
                            <h2>Ransomware Angriff</h2>

                            <p>
                                In diesem Szenario simulieren wir einen gezielten Ransomware-Angriff auf die Workstation
                                eines Mitarbeiters.
                            </p>
                        </div>

                        <div class="scenario-card-footer">
                            <button class="button-info" @click="openRansomwareModal">Mehr erfahren</button>

                            <div class="card-checkmark" :class="{ unchecked: scenarioList.ransomware !== true }">
                                <i class="material-icon mdi mdi-check" />
                            </div>
                        </div>
                    </div>
                </label>

                <label>
                    <input
                        class="scenario-card"
                        type="checkbox"
                        id="phishing"
                        name="scenario-choice"
                        value="true"
                        v-model="scenarioList.phishing"
                        @input="changeScenario('phishing')"
                        :disabled="processing"
                    />
                    <div class="scenario-card-input">
                        <img class="card-header-image" src="../../public/images-scenarios/szenario-phishing.jpg" alt="bild szenario phishing">

                        <div class="card-text">
                            <h2>Phishing Angriff</h2>

                            <p>
                                Wir simulieren in diesem Szenario einen gezielten Phishing-Angriff auf Ihre Mitarbeiter.
                            </p>
                        </div>

                        <div class="scenario-card-footer">
                            <button class="button-info" @click="openPhishingModal">Mehr erfahren</button>

                            <div class="card-checkmark" :class="{ unchecked: scenarioList.phishing !== true }">
                                <i class="material-icon mdi mdi-check" />
                            </div>
                        </div>
                    </div>
                </label>

                <label>
                    <input
                        class="scenario-card"
                        type="checkbox"
                        id="insiderAttack"
                        name="scenario-choice"
                        value="true"
                        v-model="scenarioList.insiderAttack"
                        @input="changeScenario('insiderAttack')"
                        :disabled="processing"
                    />
                    <div class="scenario-card-input">
                        <img class="card-header-image" src="../../public/images-scenarios/szenario-insiderangriff.jpg" alt="bild szenario insiderangriff">

                        <div class="card-text">
                            <h2>Insiderangriff</h2>

                            <p>
                                Wir simulieren in diesem Szenario einen Mitarbeiter, der böswillig über seinen Benutzeraccount das unternehmenseigene Netzwerk angreift.
                            </p>
                        </div>

                        <div class="scenario-card-footer">
                            <button class="button-info" @click="openInsiderAttackModal">Mehr erfahren</button>

                            <div class="card-checkmark" :class="{ unchecked: scenarioList.insiderAttack !== true }">
                                <i class="material-icon mdi mdi-check" />
                            </div>
                        </div>
                    </div>
                </label>

                <label>
                    <input
                        class="scenario-card"
                        type="checkbox"
                        id="cloudSecurity"
                        name="scenario-choice"
                        value="true"
                        v-model="scenarioList.cloudSecurity"
                        @input="changeScenario('cloudSecurity')"
                        :disabled="processing"
                    />
                    <div class="scenario-card-input">
                        <img class="card-header-image" src="../../public/images-scenarios/szenario-cloud.jpg" alt="bild szenario cloud angriff">

                        <div class="card-text">
                            <h2>Cloud Angriff</h2>

                            <p>
                                Wir simulieren in diesem Szenario einen Hacker, der die von Ihnen eingesetzte Cloud Infrastruktur angreift.                            </p>
                        </div>

                        <div class="scenario-card-footer">
                            <button class="button-info" @click="openCloudSecurityModal">Mehr erfahren</button>

                            <div class="card-checkmark" :class="{ unchecked: scenarioList.cloudSecurity !== true }">
                                <i class="material-icon mdi mdi-check" />
                            </div>
                        </div>
                    </div>
                </label>

                <label>
                    <input
                        class="scenario-card"
                        type="checkbox"
                        id="compromisedNetwork"
                        name="scenario-choice"
                        value="true"
                        v-model="scenarioList.compromisedNetwork"
                        @input="changeScenario('compromisedNetwork')"
                        :disabled="processing"
                    />
                    <div class="scenario-card-input">
                        <img class="card-header-image" src="../../public/images-scenarios/szenario-netzwerk.jpg" alt="bild szenario netzwerk">

                        <div class="card-text">
                            <h2>Netzwerk Angriff</h2>

                            <p>
                                In diesem Szenario simulieren wir einen Hacker, der Sie über einen Firmen-internen Netzwerkanschluss oder ein WLAN-Netzwerk angreift.                            </p>
                        </div>

                        <div class="scenario-card-footer">
                            <button class="button-info" @click="openCompromisedNetworkModal">Mehr erfahren</button>

                            <div class="card-checkmark" :class="{ unchecked: scenarioList.compromisedNetwork !== true }">
                                <i class="material-icon mdi mdi-check" />
                            </div>
                        </div>
                    </div>
                </label>

                <label>
                    <input
                        class="scenario-card"
                        type="checkbox"
                        id="localPrivilegeEscalation"
                        name="scenario-choice"
                        value="true"
                        v-model="scenarioList.localPrivilegeEscalation"
                        @input="changeScenario('localPrivilegeEscalation')"
                        :disabled="processing"
                    />
                        <div class="scenario-card-input">
                            <img class="card-header-image" src="../../public/images-scenarios/szenario-client.jpg" alt="bild szenario client">

                            <div class="card-text">
                                <h2>Lokale Rechteausweitung</h2>

                                <p>
                                    In diesem Szenario simulieren wir einen Mitarbeiter, der seine lokalen Rechte auf dem Client ausweiten und sich lokale Administratorrechte beschaffen möchte.                                </p>
                            </div>

                            <div class="scenario-card-footer">
                                <button class="button-info" @click="openLocalPrivilegeEscalationModal">Mehr erfahren</button>

                                <div class="card-checkmark" :class="{ unchecked: scenarioList.localPrivilegeEscalation !== true }">
                                    <i class="material-icon mdi mdi-check" />
                                </div>
                            </div>
                        </div>
                </label>

<!--                <label>-->
<!--                    <input-->
<!--                        class="scenario-card"-->
<!--                        type="checkbox"-->
<!--                        id="homeofficeAttack"-->
<!--                        name="scenario-choice"-->
<!--                        value="true"-->
<!--                        v-model="scenarioList.homeofficeAttack"-->
<!--                        @input="changeScenario('homeofficeAttack')"-->
<!--                        :disabled="processing"-->
<!--                    />-->
<!--                        <div class="scenario-card-input">-->
<!--                            <img class="card-header-image" src="../../public/images-scenarios/szenario-remote-service.jpg" alt="bild szenario homeoffice">-->

<!--                            <div class="card-text">-->
<!--                                <h2>Homeoffice-Angriff</h2>-->

<!--                                <p>-->
<!--                                    In diesem Szenario simulieren wir den Angriff eines Hackers, der über den Heimarbeitsplatz-->
<!--                                    (Remote Office/Home Office) eines Mitarbeiters erfolgt. Der Hacker verfügt über Zugriff-->
<!--                                    zu Remote Services (z.B. einem Microsoft Terminalserver oder einer Citrix-Umgebung)-->
<!--                                    Ihres Unternehmens und versucht darüber Ihre Unternehmens-IT zu kompromittieren und-->
<!--                                    sensible Unternehmensdaten zu entwenden. Wir überprüfen im Kontext dieses Szenarios,-->
<!--                                    ob es möglich ist, aus dem beschränkten Nutzerkontext auszubrechen und dadurch Zugriff-->
<!--                                    auf weitere IT-Systeme und Applikationen sowie Daten Ihres Unternehmens zu erlangen.-->
<!--                                </p>-->
<!--                            </div>-->

<!--                            <div class="scenario-card-footer">-->
<!--                                <button class="button-info">Mehr erfahren</button>-->

<!--                                <div class="card-checkmark" :class="{ unchecked: scenarioList.homeofficeAttack !== true }">-->
<!--                                    <i class="material-icon mdi mdi-check" />-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                </label>-->

                <label>
                    <input
                        class="scenario-card"
                        type="checkbox"
                        id="stolenLaptop"
                        name="scenario-choice"
                        value="true"
                        v-model="scenarioList.stolenLaptop"
                        @input="changeScenario('stolenLaptop')"
                        :disabled="processing"
                    />
                        <div class="scenario-card-input">
                            <img class="card-header-image" src="../../public/images-scenarios/szenario-laptop.jpg" alt="bild szenario laptop">

                            <div class="card-text">
                                <h2>Gestohlener Laptop</h2>

                                <p>
                                    In diesem Szenario simulieren wir einen Angreifer, der in den Besitz Ihres Firmen-Laptops gelangt ist und dort gespeicherte Informationen entwenden möchte.                                </p>
                            </div>

                            <div class="scenario-card-footer">
                                <button class="button-info" @click="openStolenLaptopModal">Mehr erfahren</button>

                                <div class="card-checkmark" :class="{ unchecked: scenarioList.stolenLaptop !== true }">
                                    <i class="material-icon mdi mdi-check" />
                                </div>
                            </div>
                        </div>
                </label>

<!--                <label>-->
<!--                    <input-->
<!--                        class="scenario-card"-->
<!--                        type="checkbox"-->
<!--                        id="activeDirectory"-->
<!--                        name="scenario-choice"-->
<!--                        value="true"-->
<!--                        v-model="scenarioList.activeDirectory"-->
<!--                        @input="changeScenario('activeDirectory')"-->
<!--                        :disabled="processing"-->
<!--                    />-->
<!--                        <div class="scenario-card-input">-->
<!--                            <img class="card-header-image" src="../../public/images-scenarios/szenario-active-directory.jpg" alt="bild szenario active directory">-->

<!--                            <div class="card-text">-->
<!--                                <h2>Active Directory</h2>-->

<!--                                <p>-->
<!--                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt-->
<!--                                    ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo-->
<!--                                    dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor-->
<!--                                    sit amet.-->
<!--                                </p>-->
<!--                            </div>-->

<!--                            <div class="card-checkmark" :class="{ unchecked: scenarioList.activeDirectory !== true }">-->
<!--                                <i class="material-icon mdi mdi-check" />-->
<!--                            </div>-->
<!--                        </div>-->
<!--                </label>-->

<!--                <label>-->
<!--                    <input-->
<!--                        class="scenario-card"-->
<!--                        type="checkbox"-->
<!--                        id="informationLeak"-->
<!--                        name="scenario-choice"-->
<!--                        value="true"-->
<!--                        v-model="scenarioList.informationLeak"-->
<!--                        @input="changeScenario('informationLeak')"-->
<!--                        :disabled="processing"-->
<!--                    />-->
<!--                        <div class="scenario-card-input">-->
<!--                            <img class="card-header-image" src="../../public/images-scenarios/szenario-leaked-information.jpg" alt="bild szenario leaked information">-->

<!--                            <div class="card-text">-->
<!--                                <h2>Leaked Information</h2>-->

<!--                                <p>-->
<!--                                    Wir simulieren in diesem Szenario einen Hacker, der im Internet nach frei verfügbaren-->
<!--                                    Informationen Ihres Unternehmens recherchiert, um so einen Angriff auf Ihre IT-Infrastruktur-->
<!--                                    oder einen Phishing-Angriff auf Ihre Mitarbeiter vorzubereiten. Mögliche Anknüpfungspunkte-->
<!--                                    des Angreifers sind z.B. falsch konfigurierte Webserver, die Zugriff auf sensible Daten-->
<!--                                    Ihres Unternehmens ermöglichen; von Ihnen bewusst veröffentlichte Office- oder PDF-Dokumente-->
<!--                                    im Internet, die sensible IT- und Unternehmensdaten unbemerkt in den Meta-Daten enthalten;-->
<!--                                    bewusst veröffentlichte Informationen zu verantwortlichen Mitarbeitern wie Namen,-->
<!--                                    E-Mail-Adressen und Ihre Position sowie (un)bewusst veröffentlichte Informationen zu-->
<!--                                    internen Geschäftsprozessen und Abläufen, die vom Hacker ausgenutzt werden können; sowie-->
<!--                                    Hilfegesuche Ihrer Administratoren oder Entwickler in öffentlichen Foren, die sensible-->
<!--                                    Informationen zu Quellcode oder IT-Konfigurationen enthalten. Im Kontext dieses Szenarios-->
<!--                                    identifizieren wir mittels gezielter Suchmaschinen-Abfragen sowie spezialisierten Tools-->
<!--                                    entsprechende Schwachstellen Ihres Unternehmens.-->
<!--                                </p>-->
<!--                            </div>-->

<!--                            <div class="scenario-card-footer">-->
<!--                                <button class="button-info" @click="openInformationLeakModal">Mehr erfahren</button>-->

<!--                                <div class="card-checkmark" :class="{ unchecked: scenarioList.informationLeak !== true }">-->
<!--                                    <i class="material-icon mdi mdi-check" />-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                </label>-->

<!--                <label>-->
<!--                    <input-->
<!--                        class="scenario-card"-->
<!--                        type="checkbox"-->
<!--                        id="kioskAttack"-->
<!--                        name="scenario-choice"-->
<!--                        value="true"-->
<!--                        v-model="scenarioList.kioskAttack"-->
<!--                        @input="changeScenario('kioskAttack')"-->
<!--                        :disabled="processing"-->
<!--                    />-->
<!--                        <div class="scenario-card-input">-->
<!--                            <img class="card-header-image" src="../../public/images-scenarios/szenario-kiosk.jpg" alt="bild szenario kiosk">-->

<!--                            <div class="card-text">-->
<!--                                <h2>Kiosk Angriff</h2>-->

<!--                                <p>-->
<!--                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt-->
<!--                                    ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo-->
<!--                                    dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor-->
<!--                                    sit amet.-->
<!--                                </p>-->
<!--                            </div>-->

<!--                            <div class="card-checkmark" :class="{ unchecked: scenarioList.kioskAttack !== true }">-->
<!--                                <i class="material-icon mdi mdi-check" />-->
<!--                            </div>-->
<!--                        </div>-->
<!--                </label>-->

                <label>
                    <input
                        class="scenario-card"
                        type="checkbox"
                        id="externalEnumeration"
                        name="scenario-choice"
                        value="true"
                        v-model="scenarioList.externalEnumeration"
                        @input="changeScenario('externalEnumeration')"
                        :disabled="processing"
                    />
                    <div class="scenario-card-input">
                        <img class="card-header-image" src="../../public/images-scenarios/szenario-externe-enumeration.jpg" alt="bild szenario externe enumeration">

                        <div class="card-text">
                            <h2>Externe Enumeration</h2>

                            <p>
                                Wir simulieren in diesem Szenario einen Hacker, der nach verfügbaren Informationen zu Ihrem Unternehmen im Internet recherchiert, um so einen Angriff auf Sie vorzubereiten.
                            </p>
                        </div>

                        <div class="scenario-card-footer">
                            <button class="button-info" @click="openExternalEnumerationModal">Mehr erfahren</button>

                            <div class="card-checkmark" :class="{ unchecked: scenarioList.externalEnumeration !== true }">
                                <i class="material-icon mdi mdi-check" />
                            </div>
                        </div>
                    </div>
                </label>

<!--                <label>-->
<!--                    <input-->
<!--                        class="scenario-card"-->
<!--                        type="checkbox"-->
<!--                        id="maliciousCleaner"-->
<!--                        name="scenario-choice"-->
<!--                        value="true"-->
<!--                        v-model="scenarioList.maliciousCleaner"-->
<!--                        @input="changeScenario('maliciousCleaner')"-->
<!--                        :disabled="processing"-->
<!--                    />-->
<!--                    <div class="scenario-card-input">-->
<!--                        <img class="card-header-image" src="../../public/images-scenarios/szenario-tower.jpg" alt="bild szenario tower">-->

<!--                        <div class="card-text">-->
<!--                            <h2>Böswillige Reinigungsfachkraft</h2>-->

<!--                            <p>-->
<!--                                Wir simulieren in diesem Szenario den Angriff eines Hackers, der physischen Zugang zu dem-->
<!--                                Arbeitsplatz eines Mitarbeiters besitzt. Der Hacker versucht logischen Zugriff auf die-->
<!--                                Workstation des Mitarbeiters (auf das Betriebssystem) zu erlangen, um vertrauliche Daten-->
<!--                                zu entwenden und das IT-System für weitere illegale Zwecke zu missbrauchen. Im Kontext-->
<!--                                dieses Szenarios überprüfen wir die physischen Schnittstellen der Workstation (z.B. LAN-Anschlüsse,-->
<!--                                USB-Ports) in Verbindung mit der Konfiguration der Workstation und versuchen, über identifizierte-->
<!--                                Schwachstellen einen Zugriff auf das Betriebssystem sowie Daten zu erlangen. Darüber hinaus-->
<!--                                überprüfen wir die BIOS-Konfiguration sowie die Festplattenverschlüsselung auf mögliche-->
<!--                                Schwachstellen.-->
<!--                            </p>-->
<!--                        </div>-->

<!--                        <div class="scenario-card-footer">-->
<!--                            <button class="button-info" @click="openMaliciousCleanerModal">Mehr erfahren</button>-->

<!--                            <div class="card-checkmark" :class="{ unchecked: scenarioList.maliciousCleaner !== true }">-->
<!--                                <i class="material-icon mdi mdi-check" />-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </label>-->
            </div>

            <div v-if="scenarioError !== ''" class="invalid-feedback">
                {{ scenarioError }}
            </div>
        </div>

        <div class="buttons">
            <button class="ghost-primary configurator-button" @click="confirmBack">Zurück</button>
            <button class="primary configurator-button" @click="gotoProjectScope">Weiter</button>
        </div>

        <modal class="info-modal" v-model="confirmationModal">
            <img slot="title" src="./../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Möchten Sie Ihren Projektansatz wirklich ändern?</h2>

            <div class="buttons-modal">
                <div class="buttons-left">
                    <button class="ghost-primary button-modal-back-dialog" @click="gotoProjectType()">Ja, zurück zur Projektansatzauswahl.</button>
                </div>
                <div class="buttons-right">
                    <button class="primary button-modal-back-dialog" @click="closeConfirmationModal()">Nein, der Projektansatz wird weiterverfolgt.</button>
                </div>
            </div>
        </modal>

        <modal class="info-modal" v-model="compromisedWebsiteModal">
            <img slot="title" src="./../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Website Defacement</h2>

            <p>
                Die usd überprüft in diesem Szenario mittels eines Pentests, ob ein Hacker die Unternehmenswebseite des
                Auftraggebers erfolgreich kompromittieren, Webseiteninhalte verändern und Besucher auf eine andere,
                präparierte Webseite mit schädlichem Inhalt (z.B. eine Drive-by Infection) umleiten kann. Die nachfolgenden
                Leistungen werden durch usd übernommen:
            </p>

            <ul>
                <li>
                    <p>
                        Telefonischer Kick-off und Vorbereitung des Pentests
                    </p>
                </li>
                <li>
                    <p>
                        Manueller Pentest von IT-Systemen
                    </p>

                    <ul>
                        <li>
                            <p>
                                Geprüft werden bis zu 5 verschiedene IP-Adressen (eins oder mehrere IT-Systeme).
                            </p>
                        </li>
                        <li>
                            <p>
                                Folgende IT-Systeme können z.B. überprüft werden: Firewalls, Router, Load Balancer, Webserver,
                                Applikationsserver, Datenbankserver und Mailserver.
                            </p>
                        </li>
                        <li>
                            <p>
                                Die IP-Adressen sind über das Internet erreichbar.
                            </p>
                        </li>
                        <li>
                            <p>
                                Für den Zeitpunkt des Pentests wird eine Firewall-Freischaltung für usd umgesetzt.
                            </p>
                        </li>
                        <li>
                            <p>
                                Ziel: usd strebt an, kritische Schwachstellen in den IT-Systemen zu identifizieren, anhand
                                derer die IT-Systeme kompromittiert und im 2. Schritt die Unternehmenswebseite manipuliert
                                werden kann.
                            </p>
                        </li>
                    </ul>
                </li>
                <li>
                    <p>
                        Manueller Pentest von Applikationen
                    </p>

                    <ul>
                        <li>
                            <p>
                                Geprüft wird eine Unternehmenswebseite, erreichbar über eine Domain und einen Port.
                            </p>
                        </li>
                        <li>
                            <p>
                                Bereitgestellt werden überwiegend statische Inhalte (Texte, Bilder).
                            </p>
                        </li>
                        <li>
                            <p>
                                Es existieren wenige Interaktionsmöglichkeiten (z.B. eine Suchfunktion oder ein Kontaktformular).
                            </p>
                        </li>
                        <li>
                            <p>
                                Die Webseite basiert auf einer Standard-Blog- oder CMS-Lösung (z.B. Typo3, Wordpress, Drupal)
                                oder ist eine Eigenentwicklung.
                            </p>
                        </li>
                        <li>
                            <p>
                                Die Prüfung erfolgt nicht authentisiert. Falls ein geschützter Bereich existiert, wird die
                                Authentisierung überprüft, nicht aber Funktionen im geschützten Bereich.
                            </p>
                        </li>
                        <li>
                            <p>
                                Die Unternehmenswebseite ist über das Internet erreichbar.
                            </p>
                        </li>
                        <li>
                            <p>
                                Für den Zeitpunkt des Pentests wird eine Firewall-Freischaltung für usd umgesetzt.
                            </p>
                        </li>
                        <li>
                            <p>
                                Ziel: usd strebt an kritische Schwachstellen in der Webapplikation zu identifizieren,
                                anhand derer die Webapplikation kompromittiert und die Inhalte der Unternehmenswebseite
                                manipuliert werden können.
                            </p>
                        </li>
                        <li>
                            <p>
                                Weitere eigenständige Webapplikationen (z.B. ein unabhängiges Bewerberportal), die in die
                                Unternehmenswebseite eingebunden sind, sind nicht Bestandteil der Prüfung.
                            </p>
                        </li>
                    </ul>
                </li>
                <li>
                    <p>
                        usd berichtet über die Ergebnisse des Pentests schriftlich in Form eines Abschlussberichts. Dieser
                        beinhaltet eine Management Summary sowie eine detaillierte Beschreibung identifizierter und ggf.
                        ausgenutzter Schwachstellen und Sicherheitslücken. Für sämtliche identifizierten Schwachstellen
                        enthält der Bericht eine individuelle Risikobewertung sowie konkrete, detaillierte Maßnahmenempfehlungen.
                    </p>
                </li>
                <li>
                    <p>
                        Abschließend diskutiert usd die Ergebnisse mit den verantwortlichen Ansprechpartnern des Aufraggebers
                        im Rahmen einer Webkonferenz.
                    </p>
                </li>
            </ul>

            <form-actions slot="footer">
                <button @click="closeCompromisedWebsiteModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="compromisedShopModal">
            <img slot="title" src="./../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Cyber-Angriff auf Online-Shop</h2>

            <p>
                Die usd überprüft in diesem Szenario mittels eines Pentests, ob ein Hacker den Online-Shop des Auftraggebers
                erfolgreich kompromittieren und damit das System übernehmen und für illegale Zwecke missbrauchen, sensible
                Kunden- und Unternehmensdaten entwenden sowie kostenfreie oder vergünstigte Einkäufe vornehmen kann.
            </p>

            <p>
                Die nachfolgenden Leistungen werden durch usd übernommen:
            </p>

            <ul>
                <li>
                    <p>
                        Telefonischer Kick-off und Vorbereitung des Pentests
                    </p>
                </li>
                <li>
                    <p>
                        Manueller Pentest von externen IT-Systemen
                    </p>
                    <ul>
                        <li>
                            <p>
                                Geprüft werden bis zu 5 verschiedene IP-Adressen (eins oder mehrere IT-Systeme).
                            </p>
                        </li>
                        <li>
                            <p>
                                Folgende IT-Systeme können z.B. überprüft werden: Firewalls, Router, Load Balancer, Webserver,
                                Applikationsserver, Datenbankserver und Mailserver.
                            </p>
                        </li>
                        <li>
                            <p>
                                Die IP-Adressen sind über das Internet erreichbar.
                            </p>
                        </li>
                        <li>
                            <p>
                                Für den Zeitpunkt des Pentests wird eine Firewall-Freischaltung für usd umgesetzt.
                            </p>
                        </li>
                        <li>
                            <p>
                                Ziel: usd strebt an, kritische Schwachstellen in den IT-Systemen zu identifizieren, anhand
                                derer die IT-Systeme kompromittiert und im 2. Schritt der Online-Shop manipuliert werden kann.
                            </p>
                        </li>
                    </ul>
                </li>
                <li>
                    <p>
                        Manueller Pentest von Applikationen
                    </p>

                    <ul>
                        <li>
                            <p>
                                Geprüft wird ein Online-Shop, erreichbar über eine Domain und einen Port.
                            </p>
                        </li>
                        <li>
                            <p>
                                Der Online-Shop basiert auf einer Standard-E-Commerce-Lösung (z.B. Magento, Shopware) oder
                                ist eine Eigenentwicklung.
                            </p>
                        </li>
                        <li>
                            <p>
                                Die Prüfung erfolgt authentisiert mit bis zu 2 unterschiedlichen Benutzerrollen sowie 4 Accounts.
                            </p>
                        </li>
                        <li>
                            <p>
                                Überprüft werden sowohl öffentliche, wie auch nicht-öffentliche Funktionen des Online-Shops im geschützten Benutzerbereich.
                            </p>
                        </li>
                        <li>
                            <p>
                                Es werden sowohl technische, als auch logische Schwachstellen überprüft.
                            </p>
                        </li>
                        <li>
                            <p>
                                Der Online-Shop ist über das Internet erreichbar.
                            </p>
                        </li>
                        <li>
                            <p>
                                Für den Zeitpunkt des Pentests wird eine Firewall-Freischaltung für usd umgesetzt.
                            </p>
                        </li>
                        <li>
                            <p>
                                Ziel: usd strebt an kritische Schwachstellen in der Webapplikation zu identifizieren, anhand
                                derer die Webapplikation kompromittiert und die Ziele des Hackers (Diebstahl von Daten,
                                kostenfreie Einkäufe, Missbrauch der IT-Systeme) erfolgreich erreicht werden.
                            </p>
                        </li>
                        <li>
                            <p>
                                Weitere eigenständige Webapplikationen (z.B. ein Blog für Unternehmensneuigkeiten), die
                                in den Online-Shop eingebunden sind, sind nicht Bestandteil der Prüfung.
                            </p>
                        </li>
                    </ul>
                </li>
                <li>
                    <p>
                        usd berichtet über die Ergebnisse des Pentests schriftlich in Form eines Abschlussberichts. Dieser
                        beinhaltet eine Management Summary sowie eine detaillierte Beschreibung identifizierter und ggf.
                        ausgenutzter Schwachstellen und Sicherheitslücken. Für sämtliche identifizierten Schwachstellen
                        enthält der Bericht eine individuelle Risikobewertung sowie konkrete, detaillierte Maßnahmenempfehlungen.
                    </p>
                </li>
                <li>
                    <p>
                        Abschließend diskutiert usd die Ergebnisse mit den verantwortlichen Ansprechpartnern des Aufraggebers
                        im Rahmen einer Webkonferenz.
                    </p>
                </li>
            </ul>

            <form-actions slot="footer">
                <button @click="closeCompromisedShopModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="localPrivilegeEscalationModal">
            <img slot="title" src="./../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Lokale Rechteausweitung</h2>

            <p>
                Die usd überprüft in diesem Szenario mittels eines Pentests, ob ein Mitarbeiter böswillig seine lokalen
                Benutzerrechte auf dem Client-IT-System des Auftraggebers zu lokalen Administratorrechten erweitern und
                darüber einen weitergehenden Angriff auf den Auftraggeber ausführen kann. Die nachfolgenden Leistungen
                werden durch usd übernommen:
            </p>

            <ul>
                <li>
                    <p>
                        Telefonischer Kick-off und Vorbereitung des Pentests
                    </p>
                </li>
                <li>
                    <p>
                        Überprüfung eines Clients auf die Möglichkeit einer lokalen Rechteausweitung (Local Privilege
                        Escalation) inklusive manuellem Pentest
                    </p>
                    <ul>
                        <li>
                            <p>
                                Geprüft wird die Konfiguration eines Client-IT-Systems des Auftraggebers auf Basis von
                                Windows oder Linux.
                            </p>
                        </li>
                        <li>
                            <p>
                                Es erfolgt ein manueller Pentests auf Systemebene.
                            </p>
                        </li>
                        <li>
                            <p>
                                Die Transportverschlüsselung wird von usd überprüft.
                            </p>
                        </li>
                        <li>
                            <p>
                                Die usd überprüft, ob es auf dem Client des Auftraggebers möglich ist, als normaler Nutzer
                                unerlaubt Administratorrechte zu erlangen.
                            </p>
                        </li>
                        <li>
                            <p>
                                Bei der Überprüfung von Clients auf die Möglichkeit einer lokalen Rechteausweitung werden
                                folgende Prüfungen von usd durchgeführt:
                            </p>

                            <ul>
                                <li>
                                    <p>
                                        Analyse von scheduled Tasks und Cronjobs
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Prüfungen auf unsicher gesetzte Dateiberechtigungen
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Suche nach lokal gespeicherten Zugangsdaten (z.B. Windows Credential Store, Skripte,
                                        Registry oder Logdateien)
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Schreibrechte auf Dienste, die mit höheren Rechten ausgeführt werden.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Untersuchung von hoch privilegierten Prozessen, die Benutzer-kontrollierte Ressourcen
                                        verwenden (hardlink oder symlink Angriffe).
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Analyse von Netzwerkdiensten, die nur von dem lokalen System erreichbar sind.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Überprüfung des aktuellen Patch-Standes des Systems.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Untersuchung der generellen Systemkonfiguration nach Best Practices.
                                    </p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p>
                                Darüber hinaus setzt usd zahlreiche, spezifische Verfahren ein, um die Möglichkeit einer
                                Rechteausweitung auf Windows und Linux-Systemen zu überprüfen.
                            </p>
                        </li>
                    </ul>
                </li>
                <li>
                    <p>
                        usd berichtet über die Ergebnisse des Pentests schriftlich in Form eines Abschlussberichts. Dieser
                        beinhaltet eine Management Summary sowie eine detaillierte Beschreibung identifizierter und ggf.
                        ausgenutzter Schwachstellen und Sicherheitslücken. Für sämtliche identifizierten Schwachstellen
                        enthält der Bericht eine individuelle Risikobewertung sowie konkrete, detaillierte Maßnahmenempfehlungen.
                    </p>
                </li>
                <li>
                    <p>
                        Abschließend diskutiert usd die Ergebnisse mit den verantwortlichen Ansprechpartnern des Aufraggebers
                        im Rahmen einer Webkonferenz.
                    </p>
                </li>
            </ul>

            <form-actions slot="footer">
                <button @click="closeLocalPrivilegeEscalationModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

<!--        <modal class="info-modal" v-model="homeofficeAttackModal">-->
<!--            <img slot="title" src="./../../public/icon-info.svg" alt="info icon">-->
<!--            <h2 slot="title">Homeoffice-Angriff</h2>-->

<!--            <p>-->
<!--            </p>-->

<!--            <form-actions slot="footer">-->
<!--                <button @click="closeHomeofficeAttackModal" class="primary button-modal">Schließen</button>-->
<!--            </form-actions>-->
<!--        </modal>-->

        <modal class="info-modal" v-model="compromisedNetworkModal">
            <img slot="title" src="./../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Netzwerk Angriff</h2>

            <p>
                Die usd überprüft in diesem Szenario mittels eines Pentests, ob der Auftraggeber von einem Hacker über
                einen ungeschützten Firmen-internen Netzwerkanschluss (eine Netzwerkdose bzw. ein Netzwerkkabel) oder ein
                ungeschütztes Firmen-internes WLAN-Netzwerk erfolgreich angegriffen und die IT-Infrastruktur des
                Auftraggebers kompromittiert werden kann. Die nachfolgenden Leistungen werden durch usd übernommen:
            </p>

            <ul>
                <li>
                    <p>
                        Telefonischer Kick-off und Vorbereitung des Pentests
                    </p>
                </li>
                <li>
                    <p>
                        Die usd umgeht zunächst ggf. vorhandene, aktive Netzwerkschutzmaßnahmen, wie eine aktivierte Port
                        Security oder Network Access Control (NAC) und Radius Server.
                    </p>
                </li>
                <li>
                    <p>
                        Daraufhin erfolgt eine manuelle Reconnaissance (Informationsbeschaffung und Enumeration).
                    </p>

                    <ul>
                        <li>
                            <p>
                                Zunächst führt usd einen Discovery Scan durch, um im Netzwerk erreichbare IT-Systeme,
                                Services und Applikationen zu identifizieren.
                            </p>
                        </li>
                        <li>
                            <p>
                                Anschließend erfolgt ein Schwachstellenscan für max. 250 IP-Adressen sowie 3 Webapplikationen
                                (jeweils betrieben unter einer Domain sowie einem Port).
                            </p>
                        </li>
                        <li>
                            <p>
                                Ziel: usd strebt an möglichst effizient die gegebene Netzwerkarchitektur sowie betriebene
                                IT-Systeme, Services und Applikationen des Auftraggebers zu identifizieren sowie erste
                                Informationen zu vorhandenen Schwachstellen und möglichen Angriffspunkten zu erhalten.
                            </p>
                        </li>
                    </ul>
                </li>
                <li>
                    <p>
                        Diskussion der Ergebnisse der manuellen Reconnaissance mit dem Auftraggeber und gemeinsame Auswahl
                        besonders exponierter und damit lohnenswerter Angriffsziele für die folgende, manuelle Angriffsphase.
                    </p>
                </li>
                <li>
                    <p>
                        Manueller Pentest von internen IT-Systemen und Applikationen
                    </p>

                    <ul>
                        <li>
                            <p>
                                Prüfumfang: bis zu 10 IP-Adressen und 1 Webapplikation, betrieben unter einer Domain und
                                einem Port
                            </p>
                        </li>
                        <li>
                            <p>
                                Folgende Assets können z.B. überprüft werden: Firewalls, Router, Load Balancer, Webserver,
                                Applikationsserver, Datenbankserver, Mailserver, Unternehmenswebseiten, News-Blogs,
                                Bewerber- oder Kundenportale
                            </p>
                        </li>
                        <li>
                            <p>
                                Die Prüfaufwand ist auf maximal 5 Beratertage beschränkt.
                            </p>
                        </li>
                        <li>
                            <p>
                                Die Prüfung von Webapplikationen erfolgt authentisiert mit maximal 2 Rollen und 4 Benutzern.
                            </p>
                        </li>
                        <li>
                            <p>
                                Ziel: usd strebt an kritische Schwachstellen in den überprüften IT-Systemen und der Webapplikation
                                zu identifizieren, anhand derer es einem Hacker möglich wäre, die IT-Infrastruktur des
                                Auftraggebers zu kompromittieren.
                            </p>
                        </li>
                    </ul>
                </li>
                <li>
                    <p>
                        usd berichtet über die Ergebnisse des Pentests schriftlich in Form eines Abschlussberichts. Dieser
                        beinhaltet eine Management Summary sowie eine detaillierte Beschreibung identifizierter und ggf.
                        ausgenutzter Schwachstellen und Sicherheitslücken. Für sämtliche identifizierten Schwachstellen
                        enthält der Bericht eine individuelle Risikobewertung sowie konkrete, detaillierte Maßnahmenempfehlungen.
                    </p>
                </li>
                <li>
                    <p>
                        Abschließend diskutiert usd die Ergebnisse mit den verantwortlichen Ansprechpartnern des Aufraggebers
                        im Rahmen einer Webkonferenz.
                    </p>
                </li>
            </ul>

            <form-actions slot="footer">
                <button @click="closeCompromisedNetworkModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

<!--        <modal class="info-modal" v-model="maliciousCleanerModal">-->
<!--            <img slot="title" src="./../../public/icon-info.svg" alt="info icon">-->
<!--            <h2 slot="title">Böswillige Reinigungsfachkraft</h2>-->

<!--            <p>-->
<!--                In diesem Szenario überprüft usd mittels eines Pentests, ob sich ein Hacker mit physischem Zugang zu dem-->
<!--                Arbeitsplatz eines Mitarbeiters erfolgreich logischen Zugriff auf die Workstation verschaffen kann, um-->
<!--                vertrauliche Daten zu entwenden. Die nachfolgenden Leistungen werden durch usd übernommen:-->
<!--            </p>-->

<!--            <ul>-->
<!--                <li>-->
<!--                    <p>-->
<!--                        Telefonischer Kick-off und Vorbereitung des Pentests-->
<!--                    </p>-->
<!--                </li>-->
<!--                <li>-->
<!--                    <p>-->
<!--                        Manueller Pentest der physischen Schnittstellen der Workstation-->
<!--                    </p>-->

<!--                    <ul>-->
<!--                        <li>-->
<!--                            <p>-->
<!--                                Geprüft wird eine Workstation auf Basis von Windows oder Linux.-->
<!--                            </p>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            <p>-->
<!--                                Die Hardware wird usd vom Auftraggeber bereitgestellt.-->
<!--                            </p>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            <p>-->
<!--                                Folgende Prüfungsinhalte werden von usd abgedeckt:-->
<!--                            </p>-->

<!--                            <ul>-->
<!--                                <li>-->
<!--                                    <p>-->
<!--                                        Angriffe über USB- und Firewire-Schnittstellen-->
<!--                                    </p>-->
<!--                                </li>-->
<!--                                <li>-->
<!--                                    <p>-->
<!--                                        Angriffe über Netzwerk-Schnittstellen-->
<!--                                    </p>-->
<!--                                </li>-->
<!--                                <li>-->
<!--                                    <p>-->
<!--                                        Direktes Auslesen der Festplatte sowie, falls vorhanden, Überprüfung der Festplattenverschlüsselung-->
<!--                                    </p>-->
<!--                                </li>-->
<!--                                <li>-->
<!--                                    <p>-->
<!--                                        Überprüfen der Sicherheit des BIOS-->
<!--                                    </p>-->
<!--                                </li>-->
<!--                            </ul>-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                </li>-->
<!--                <li>-->
<!--                    <p>-->
<!--                        usd berichtet über die Ergebnisse des Pentests schriftlich in Form eines Abschlussberichts. Dieser-->
<!--                        beinhaltet eine Management Summary sowie eine detaillierte Beschreibung identifizierter und ggf.-->
<!--                        ausgenutzter Schwachstellen und Sicherheitslücken. Für sämtliche identifizierten Schwachstellen-->
<!--                        enthält der Bericht eine individuelle Risikobewertung sowie konkrete, detaillierte Maßnahmenempfehlungen.-->
<!--                    </p>-->
<!--                </li>-->
<!--                <li>-->
<!--                    <p>-->
<!--                        Abschließend diskutiert usd die Ergebnisse mit den verantwortlichen Ansprechpartnern des Aufraggebers-->
<!--                        im Rahmen einer Webkonferenz.-->
<!--                    </p>-->
<!--                </li>-->
<!--            </ul>-->

<!--            <form-actions slot="footer">-->
<!--                <button @click="closeMaliciousCleanerModal" class="primary button-modal">Schließen</button>-->
<!--            </form-actions>-->
<!--        </modal>-->

        <modal class="info-modal" v-model="stolenLaptopModal">
            <img slot="title" src="./../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Gestohlener Laptop</h2>

            <p>
                Die usd überprüft in diesem Szenario mittels eines Pentests, ob ein Dritter böswillig und unerlaubt vertrauliche
                Unternehmensdaten von einem Laptop des Auftraggebers entwenden kann. Die nachfolgenden Leistungen werden
                durch usd übernommen:
            </p>

            <ul>
                <li>
                    <p>
                        Telefonischer Kick-off und Vorbereitung des Pentests
                    </p>
                </li>
                <li>
                    <p>
                        Manueller Pentest der physischen Schnittstellen des Laptops
                    </p>

                    <ul>
                        <li>
                            <p>
                                Geprüft wird ein Laptop auf Basis von Windows oder Linux.
                            </p>
                        </li>
                        <li>
                            <p>
                                Die Hardware wird usd vom Auftraggeber bereitgestellt.
                            </p>
                        </li>
                        <li>
                            <p>
                                Folgende Prüfungsinhalte werden von usd abgedeckt:
                            </p>

                            <ul>
                                <li>
                                    <p>
                                        Angriffe über USB- und Firewire-Schnittstellen
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Angriffe über Netzwerk-Schnittstellen
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Direktes Auslesen der Festplatte sowie, falls vorhanden, Überprüfung der Festplattenverschlüsselung
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Überprüfen der Sicherheit des BIOS
                                    </p>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>
                    <p>
                        usd berichtet über die Ergebnisse des Pentests schriftlich in Form eines Abschlussberichts. Dieser
                        beinhaltet eine Management Summary sowie eine detaillierte Beschreibung identifizierter und ggf.
                        ausgenutzter Schwachstellen und Sicherheitslücken. Für sämtliche identifizierten Schwachstellen
                        enthält der Bericht eine individuelle Risikobewertung sowie konkrete, detaillierte Maßnahmenempfehlungen.
                    </p>
                </li>
                <li>
                    <p>
                        Abschließend diskutiert usd die Ergebnisse mit den verantwortlichen Ansprechpartnern des Aufraggebers
                        im Rahmen einer Webkonferenz.
                    </p>
                </li>
            </ul>

            <p>
                <strong>
                    Hinweis: Gerne übertragen wir die vorgenannten Prüfinhalte auf eine normale Workstation (einen fest
                    installierten PC-Arbeitsplatz) und überprüfen die Sicherheit dieses Clients.
                </strong>
            </p>

            <form-actions slot="footer">
                <button @click="closeStolenLaptopModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="externalEnumerationModal">
            <img slot="title" src="./../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Externe Enumeration</h2>

            <p>
                Die usd überprüft in diesem Szenario mittels manueller Reconnaissance-Techniken, welche vertraulichen
                Informationen zur IT-Infrastruktur, Organisation und den Mitarbeitern des Auftraggebers im Internet frei
                verfügbar sind und zur Vorbereitung eines Hackerangriffs genutzt werden können. Die nachfolgenden
                Leistungen werden durch usd übernommen:
            </p>

            <ul>
                <li>
                    <p>
                        Telefonischer Kick-off und Vorbereitung
                    </p>
                </li>
                <li>
                    <p>
                        Manuelle Reconnaissance und Informationsbeschaffung zu der technischen Angriffsfläche
                    </p>

                    <ul>
                        <li>
                            <p>
                                Die usd stellt eine Liste an Domains und Subdomains des Auftraggebers zusammen und identifiziert
                                anhand dieser alle aktiven, über das Internet erreichbaren IT-Systeme, Services und
                                Applikationen des Auftraggebers.
                            </p>
                        </li>
                        <li>
                            <p>
                                Um Sub-/Domains zu identifizieren, die sich dem Auftraggeber zuordnen lassen, werden verschiedene
                                Techniken von usd angewendet. Idealerweise unterstützt der Auftraggeber durch die Bereitstellung
                                einer Liste bekannter externer Domains und Subdomains.
                            </p>
                        </li>
                        <li>
                            <p>
                                Zu den eingesetzten Techniken und Verfahren zählen unter anderem:
                            </p>

                            <ul>
                                <li>
                                    <p>
                                        Enumerierung von validen DNS-Namen (bspw. via DNS Lookups)
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Identifizierung der Netzwerkinfrastruktur (bspw. DNS- oder Mail-Server) für Domains
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Durchführung von Reverse DNS Lookups (bspw. mithilfe der PTR Records)
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Durchführung von DNS Zonen-Transfers (sofern möglich)
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Suche nach Zertifikaten und zugehörigen Identitäten in Zertifikat-Cache Datenbanken und Suchmaschinen
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Einsatz von Google Hacking sowie spezialisierten Suchmaschinen wie Shodan.io und ZoomEye
                                    </p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p>
                                Die Start-Domain wird vom Auftraggeber festgelegt.
                            </p>
                        </li>
                        <li>
                            <p>
                                Anschließend erfolgt eine Aufbereitung der Informationen. Dabei werden unter anderem folgende
                                Tätigkeiten durchgeführt:
                            </p>
                            <ul>
                                <li>
                                    <p>
                                        Überprüfung der Zugehörigkeit identifizierter Assets zum Verantwortungsbereich des Auftraggebers
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Identifizierung von ggf. existierenden Hostern oder Drittanbietern
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Entfernung von Duplikaten
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Ableitung von Informationen zur Netzwerk-Infrastruktur, betriebenen IT-Systemen,
                                        Services sowie Applikationen des Auftraggebers
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Im Rahmen der Informationsaufbereitung, insbesondere bei der Überprüfung der Zugehörigkeit
                                        identifizierter Assets zum Verantwortungsbereich des Auftraggebers, empfehlen wir
                                        eine enge Abstimmung mit den verantwortlichen Ansprechpartnern des Auftraggebers.
                                    </p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p>
                                Identifizierte Web- und FTP-Server des Auftraggebers werden auf ungeschützte Verzeichnisse
                                und unbewusst veröffentlichte, vertrauliche Informationen überprüft
                            </p>
                        </li>
                        <li>
                            <p>
                                Identifizierte Datenbanken des Auftraggebers werden auf unbewusst veröffentlichte,
                                vertrauliche Informationen überprüft (z.B. Datenbank-Dumps bzw. Backup-Dateien, die
                                unbeabsichtigt über das Internet erreichbar sind)
                            </p>
                        </li>
                        <li>
                            <p>
                                Identifizierte Code-Repositories des Auftraggebers werden auf unbewusst veröffentlichte,
                                vertrauliche Informationen, wie z.B. Access Tokens, überprüft
                            </p>
                        </li>
                        <li>
                            <p>
                                Die Konfiguration identifizierter DNS- und Mailserver wird auf unbewusst veröffentlichte,
                                vertrauliche Informationen überprüft
                            </p>
                        </li>
                    </ul>
                </li>
                <li>
                    <p>
                        Manuelle Reconnaissance und Informationsbeschaffung zur organisatorischen Angriffsfläche
                    </p>

                    <ul>
                        <li>
                            <p>
                                Veröffentlichte Dokumente auf der Unternehmenswebseite (z.B. Microsoft Office Dokumente,
                                PDF-Dokumente, Grafiken und Fotos) des Auftraggebers werden auf vertrauliche Meta-Informationen
                                überprüft.
                            </p>
                        </li>
                        <li>
                            <p>
                                Für alle auf der Unternehmenswebseite des Auftraggebers gelisteten E-Mail-Adressen wird
                                überprüft, ob diese im Rahmen vorangegangener, internationaler Hackerangriff kompromittiert
                                wurden.
                            </p>
                        </li>
                        <li>
                            <p>
                                Manuelle Untersuchung der Unternehmenswebseite des Auftraggebers zur Identifikation
                                veröffentlichter Informationen zur Organisation sowie Mitarbeitern, die im Rahmen eines
                                Hackerangriffs ausgenutzt werden könnten.
                            </p>
                        </li>
                        <li>
                            <p>
                                Manuelle Überprüfung bekannter online Foren sowie Communities (z.B. GitHub, GitLab) auf
                                Posts angestellter Mitarbeiter des Auftraggebers und darüber ggf. veröffentlichte, vertrauliche
                                Informationen.
                            </p>
                        </li>
                    </ul>
                </li>
                <li>
                    <p>
                        usd berichtet über die Ergebnisse der manuellen Reconnaissance schriftlich in Form eines Abschlussberichts.
                        Dieser beinhaltet eine Management Summary, eine detaillierte Beschreibung der identifizierten
                        IT-Infrastruktur-Informationen sowie weiterer öffentlich einsehbarer, vertraulicher Informationen
                        des Auftraggebers, eine Einschätzung der Kritikalität dieser im Hinblick auf das Ziel eines Hackers
                        der Vorbereitung eines Cyber-Angriffs sowie konkrete Maßnahmenempfehlungen zur Verbesserung des
                        Sicherheitsniveaus des Auftraggebers.
                    </p>
                </li>
                <li>
                    <p>
                        Abschließend diskutiert usd die Ergebnisse mit den verantwortlichen Ansprechpartnern des Aufraggebers
                        im Rahmen einer Webkonferenz.
                    </p>
                </li>
            </ul>

            <form-actions slot="footer">
                <button @click="closeExternalEnumerationModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="externalAttackModal">
            <img slot="title" src="./../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Externer Angriff</h2>

            <p>
                Die usd überprüft in diesem Szenario mittels eines 3-stufigen Pentests, ob ein Hacker die öffentliche
                IT-Infrastruktur des Auftraggebers über das Internet kompromittieren kann. Die nachfolgenden Leistungen
                werden durch usd übernommen:
            </p>

            <ul>
                <li>
                    <p>
                        Telefonischer Kick-off und Vorbereitung des Pentests
                    </p>
                </li>
                <li>
                    <p>
                        Manuelle Reconnaissance (Informationsbeschaffung und Enumeration)
                    </p>

                    <ul>
                        <li>
                            <p>
                                In der ersten Phase stellt usd eine Liste an Domains und Subdomains des Auftraggebers zusammen
                                und identifiziert anhand dieser alle aktiven, über das Internet erreichbaren IT-Systeme,
                                Services und Applikationen des Auftraggebers.
                            </p>
                        </li>
                        <li>
                            <p>
                                Um Sub-/Domains zu identifizieren, die sich dem Auftraggeber zuordnen lassen, werden verschiedene
                                Techniken von usd angewendet. Idealerweise unterstützt der Auftraggeber durch die Bereitstellung
                                einer Liste bekannter externer Domains und Subdomains.
                            </p>
                        </li>
                        <li>
                            <p>
                                Zu den eingesetzten Techniken und Verfahren zählen unter anderem:
                            </p>

                            <ul>
                                <li>
                                    <p>
                                        Enumerierung von validen DNS-Namen (bspw. via DNS Lookups)
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Identifizierung der Netzwerkinfrastruktur (bspw. DNS- oder Mail-Server) für Domains
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Durchführung von Reverse DNS Lookups (bspw. mithilfe der PTR Records)
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Durchführung von DNS Zone-Transfers (insofern möglich)
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Suche nach Zertifikaten und zugehörigen Identitäten in Zertifikat-Cache von Datenbanken
                                        und Suchmaschinen
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Google Hacking
                                    </p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p>
                                Die Start-Domain für diese Phase wird vom Auftraggeber festgelegt.
                            </p>
                        </li>
                        <li>
                            <p>
                                Anschließend erfolgt eine Aufbereitung der Informationen. Dabei werden unter anderem folgende
                                Tätigkeiten durchgeführt:
                            </p>

                            <ul>
                                <li>
                                    <p>
                                        Überprüfung der Zugehörigkeit identifizierter Assets zum Verantwortungsbereich des
                                        Auftraggebers
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Identifizierung von ggf. existierenden Hostern oder Drittanbietern
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Entfernung von Duplikaten
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Ableitung von Informationen zur Netzwerk-Infrastruktur, betriebenen IT-Systemen,
                                        Services sowie Applikationen des Auftraggebers
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Der Auftraggeber und usd werden anschließend die identifizierten Assets gemeinsam
                                        sichten und usd wird eine Empfehlung für den Scope des anschließenden Pentests
                                        aussprechen. Daraufhin wird der finale Scope für die Durchführung des Pentests
                                        einvernehmlich festgelegt.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Im Rahmen der Informationsaufbereitung, insbesondere bei der Überprüfung der Zugehörigkeit
                                        identifizierter Assets zum Verantwortungsbereich des Auftraggebers, empfehlen wir
                                        eine enge Abstimmung mit den verantwortlichen Ansprechpartnern des Auftraggebers.
                                    </p>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>
                    <p>
                        Automatisierter Schwachstellenscan identifizierter externer IT-Systeme und Webapplikationen des Auftraggebers
                    </p>

                    <ul>
                        <li>
                            <p>
                                Geprüft werden bis zu 250 IP-Adressen sowie 3 Webapplikationen, jeweils betrieben unter
                                einer Domain und einem Port.
                            </p>
                        </li>
                        <li>
                            <p>
                                Folgende Assets können z.B. überprüft werden: Firewalls, Router, Load Balancer, Webserver,
                                Applikationsserver, Datenbankserver, Mailserver, Unternehmenswebseiten, News-Blogs,
                                Bewerber- oder Kundenportale.
                            </p>
                        </li>
                        <li>
                            <p>
                                Die Prüfung von Webapplikationen erfolgt authentisiert mit maximal 2 Rollen und 4 Benutzern.
                            </p>
                        </li>
                        <li>
                            <p>
                                Die IT-Systeme und Webapplikationen sind über das Internet erreichbar.
                            </p>
                        </li>
                        <li>
                            <p>
                                Für den Zeitpunkt des Schwachstellenscans wird eine Firewall-Freischaltung für usd umgesetzt.
                            </p>
                        </li>
                        <li>
                            <p>
                                Ziel: Der automatisierte Schwachstellenscan ermöglicht es usd einen guten Überblick über
                                die vom Auftraggeber betriebenen IT-Systeme und Webapplikationen zu erhalten und eine erste
                                Einschätzung zu der Sicherheit dieser zu treffen.
                            </p>
                        </li>
                    </ul>
                </li>
                <li>
                    <p>
                        Diskussion der Ergebnisse des automatisierten Schwachstellenscans mit dem Auftraggeber und gemeinsame
                        Auswahl besonders exponierter und damit lohnenswerter Angriffsziele für die folgende, manuelle Angriffsphase.
                    </p>
                </li>
                <li>
                    <p>
                        Manueller Pentest von externen IT-Systemen und Applikationen
                    </p>

                    <ul>
                        <li>
                            <p>
                                Geprüft werden bis zu 10 IP-Adressen und 1 Webapplikation, betrieben unter einer Domain
                                und einem Port.
                            </p>
                        </li>
                        <li>
                            <p>
                                Folgende Assets können z.B. überprüft werden: Firewalls, Router, Load Balancer, Webserver,
                                Applikationsserver, Datenbankserver, Mailserver, Unternehmenswebseiten, News-Blogs,
                                Bewerber- oder Kundenportale
                            </p>
                        </li>
                        <li>
                            <p>
                                Die Prüfaufwand ist auf maximal 5 Beratertage beschränkt.
                            </p>
                        </li>
                        <li>
                            <p>
                                Die Prüfung von Webapplikationen erfolgt authentisiert mit maximal 2 Rollen und 4 Benutzern.
                            </p>
                        </li>
                        <li>
                            <p>
                                Die IT-Systeme und Webapplikationen sind über das Internet erreichbar.
                            </p>
                        </li>
                        <li>
                            <p>
                                Für den Zeitpunkt des manuellen Pentests wird eine Firewall-Freischaltung für usd umgesetzt.
                            </p>
                        </li>
                        <li>
                            <p>
                                Ziel: usd strebt an kritische Schwachstellen in den überprüften IT-Systemen und der Webapplikation
                                zu identifizieren, anhand derer es einem Hacker möglich wäre, die öffentliche IT-Infrastruktur
                                des Auftraggebers zu kompromittieren.
                            </p>
                        </li>
                    </ul>
                </li>
                <li>
                    <p>
                        usd berichtet über die Ergebnisse des 3-stufigen Pentests schriftlich in Form eines Abschlussberichts.
                        Dieser beinhaltet eine Management Summary sowie eine detaillierte Beschreibung identifizierter und
                        ggf. ausgenutzter Schwachstellen und Sicherheitslücken. Für sämtliche identifizierten Schwachstellen
                        enthält der Bericht eine individuelle Risikobewertung sowie konkrete, detaillierte Maßnahmenempfehlungen.
                    </p>
                </li>
                <li>
                    <p>
                        Abschließend diskutiert usd die Ergebnisse mit den verantwortlichen Ansprechpartnern des Aufraggebers
                        im Rahmen einer Webkonferenz.
                    </p>
                </li>
            </ul>

            <form-actions slot="footer">
                <button @click="closeExternalAttackModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

<!--        <modal class="info-modal" v-model="informationLeakModal">-->
<!--            <img slot="title" src="./../../public/icon-info.svg" alt="info icon">-->
<!--            <h2 slot="title">Leaked Information</h2>-->

<!--            <p>-->
<!--                Die usd überprüft in diesem Szenario mittels manueller Reconnaissance-Techniken, welche vertraulichen-->
<!--                Informationen zur Organisation und den Mitarbeitern des Auftraggebers im Internet frei verfügbar sind-->
<!--                und zur Vorbereitung eines Hackerangriffs genutzt werden können. Die nachfolgenden Leistungen werden-->
<!--                durch usd übernommen:-->
<!--            </p>-->

<!--            <ul>-->
<!--                <li>-->
<!--                    <p>-->
<!--                        Telefonischer Kick-off und Vorbereitung-->
<!--                    </p>-->
<!--                </li>-->
<!--                <li>-->
<!--                    <p>-->
<!--                        Manuelle Reconnaissance (Informationsbeschaffung)-->
<!--                    </p>-->

<!--                    <ul>-->
<!--                        <li>-->
<!--                            <p>-->
<!--                                Überprüfung von Web- und FTP-Servern des Auftraggebers auf ungeschützte Verzeichnisse und-->
<!--                                unbewusst veröffentlichte, vertrauliche Informationen-->
<!--                            </p>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            <p>-->
<!--                                Überprüfung eingesetzter Datenbanken des Auftraggebers auf unbewusst veröffentlichte,-->
<!--                                vertrauliche Informationen-->
<!--                            </p>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            <p>-->
<!--                                Überprüfung eingesetzter Repositories des Auftraggebers auf unbewusst veröffentlichte,-->
<!--                                vertrauliche Informationen-->
<!--                            </p>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            <p>-->
<!--                                Überprüfung der DNS- und Mailserver-Konfiguration auf unbewusst veröffentlichte,-->
<!--                                vertrauliche Informationen-->
<!--                            </p>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            <p>-->
<!--                                Überprüfung veröffentlichter Dokumente auf der Unternehmenswebseite (z.B. Microsoft Office-->
<!--                                Dokumente, PDF-Dokumente, Grafiken und Fotos) des Auftraggebers auf vertrauliche Meta-Informationen-->
<!--                            </p>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            <p>-->
<!--                                Für alle auf der Unternehmenswebseite des Auftraggebers gelisteten E-Mail-Adressen wird-->
<!--                                überprüft, ob diese im Rahmen vorangegangener, internationaler Hackerangriff kompromittiert-->
<!--                                wurden.-->
<!--                            </p>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            <p>-->
<!--                                Einsatz von Google Hacking Techniken sowie spezialisierten Suchmaschinen wie Shodan.io und-->
<!--                                ZoomEye zur Identifikation weiterer vertraulicher Informationen des Auftraggebers-->
<!--                            </p>-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                </li>-->
<!--                <li>-->
<!--                    <p>-->
<!--                        usd berichtet über die Ergebnisse der manuellen Reconnaissance schriftlich in Form eines Abschlussberichts.-->
<!--                        Dieser beinhaltet eine Management Summary, eine detaillierte Beschreibung der identifizierten-->
<!--                        Informationen zu dem Unternehmen des Auftraggebers, eine Einschätzung der Kritikalität dieser im-->
<!--                        Hinblick auf das Ziel eines Hackers der Vorbereitung eines Cyber-Angriffs sowie konkrete Maßnahmenempfehlungen-->
<!--                        zur Verbesserung des Sicherheitsniveaus des Auftraggebers.-->
<!--                    </p>-->
<!--                </li>-->
<!--                <li>-->
<!--                    <p>-->
<!--                        Abschließend diskutiert usd die Ergebnisse mit den verantwortlichen Ansprechpartnern des Aufraggebers-->
<!--                        im Rahmen einer Webkonferenz.-->
<!--                    </p>-->
<!--                </li>-->
<!--            </ul>-->

<!--            <form-actions slot="footer">-->
<!--                <button @click="closeInformationLeakModal" class="primary button-modal">Schließen</button>-->
<!--            </form-actions>-->
<!--        </modal>-->

        <modal class="info-modal" v-model="insiderAttackModal">
            <img slot="title" src="./../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Insiderangriff</h2>

            <p>
                Die usd überprüft in diesem Szenario mittels eines Pentests, ob ein Mitarbeiter über seinen Benutzeraccount
                das unternehmenseigene Netzwerk erfolgreich kompromittieren kann. Aufgrund seiner Stellung als Mitarbeiter
                verfügt der Angreifer in diesem Szenario über privilegierten Zugriff auf Unternehmens-interne IT-Ressourcen,
                wie z.B. die Workstation, VPN-Verbindungen, Terminalserver-Verbindungen, Zugriff auf interne Firmen-Applikationen
                oder auch File-Shares. Die nachfolgenden Leistungen werden durch usd übernommen:
            </p>

            <p>
                <u>
                    Kick-off und Vorbereitung
                </u>
            </p>

            <p>
                Zur Vorbereitung führt usd mit den verantwortlichen Ansprechpartnern des Auftraggebers ein Kick-off durch.  Dabei werden die Rahmenparameter auf Basis der Empfehlungen von usd in Abstimmung mit dem Auftraggeber festgelegt. Folgende Fragestellungen werden in Bezug auf das angenommene Insider-Szenario diskutiert:
            </p>

            <ul>
                <li>
                    <p>
                        Abstimmung von High-Value-Targets
                    </p>
                </li>
                <li>
                    <p>
                        Definition von Scope-Ausschlüssen
                    </p>
                </li>
                <li>
                    <p>
                        Definition von einem konkreten Prüfungsziel (einem sogenannten "Flag", z.B. eine in Besitz zu bringende
                        sensible Information oder die Erreichung von administrativen Rechten im Active Directory des Auftraggebers)
                    </p>
                </li>
                <li>
                    <p>
                        Definition eines Einstiegspunkts für den Insider Angriff
                    </p>
                </li>
            </ul>

            <p>
                Die Ergebnisse des Kick-offs werden von usd in einem Protokoll dokumentiert und dem Auftraggeber im Nachgang
                zur Verfügung gestellt.
            </p>

            <p>
                <u>
                    Durchführung des simulierten Insider Angriffs
                </u>
            </p>

            <p>
                Ausgehend von dem vereinbarten Einstiegspunkt enumeriert usd das Unternehmensnetzwerk und identifiziert
                erreichbare IT-Assets. Im Rahmen der weiteren Informationssammlung werden diese von usd hinsichtlich der
                Erfolgsaussichten einer Kompromittierung und dem Erreichen des abgestimmten Prüfungsziels kategorisiert.
            </p>

            <p>
                Auf dieser Basis entscheidet usd in Abstimmung mit dem Auftraggeber, welche IT-Assets mit welcher Prüftiefe
                weitergehend untersucht werden. Folgende Assets können in diesem Kontext von usd untersucht werden:
            </p>

            <ul>
                <li>
                    <p>
                        Server inklusive deren bereitgestellter Netzwerkdienste
                    </p>
                </li>
                <li>
                    <p>
                        Netzwerkinfrastrukturkomponenten wie Switches oder Router
                    </p>
                </li>
                <li>
                    <p>
                        Workstations anderer Nutzer
                    </p>
                </li>
                <li>
                    <p>
                        Webapplikationen und Webservices (APIs)
                    </p>
                </li>
                <li>
                    <p>
                        Mobile Applikationen
                    </p>
                </li>
                <li>
                    <p>
                        Fat Clients oder Desktop/Server-Anwendungen
                    </p>
                </li>
            </ul>

            <p>
                Ziel der usd ist es, kritische Schwachstellen und Verwundbarkeiten in den IT-Assets des Auftraggebers zu
                identifizieren, die genutzt werden können, um das angestrebte Prüfungsziel zu erreichen. Der Insider Angriff
                erfolgt bis das vereinbarte Prüfungsziel von usd erreicht wurde, aber maximal 5 Beratertage.
            </p>

            <p>
                <u>
                    Berichterstellung und Debriefing
                </u>
            </p>

            <p>
                usd berichtet über die Ergebnisse des simulierten Insider Angriffs schriftlich in Form eines Abschlussberichts.
                Dieser beinhaltet eine Management Summary sowie eine detaillierte Beschreibung identifizierter und ggf.
                ausgenutzter Schwachstellen und Sicherheitslücken. Für sämtliche identifizierten Schwachstellen enthält
                der Bericht eine individuelle Risikobewertung sowie konkrete, detaillierte Maßnahmenempfehlungen.
            </p>

            <p>
                Abschließend diskutiert usd die Ergebnisse mit den verantwortlichen Ansprechpartnern des Aufraggebers im
            Rahmen einer Webkonferenz.
            </p>

            <form-actions slot="footer">
                <button @click="closeInsiderAttackModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="ransomwareModal">
            <img slot="title" src="./../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Ransomware Angriff</h2>

            <p>
                Die usd überprüft in diesem Szenario mittels einer manuellen, technischen Sicherheitsanalyse, ob ein Hacker
                die Client-IT-Systeme des Auftraggebers erfolgreich mittels einer Ransomware kompromittieren kann. Die
                nachfolgenden Leistungen werden durch usd übernommen:
            </p>

            <ul>
                <li>
                    <p>
                        Abstimmung des konkreten Angriffsszenarios mit den verantwortlichen Ansprechpartnern des
                        Auftraggebers im Rahmen eines Kick-offs
                    </p>
                </li>
                <li>
                    <p>
                        Aufsetzen einer Windows 10 kompatiblen Ransomware mit folgenden Inhalten:
                    </p>

                    <ul>
                        <li>
                            <p>
                                Verschlüsselung von vordefinierten Dateipfaden
                            </p>
                        </li>
                        <li>
                            <p>
                                Verbreitung der Ransomware über das E-Mail-Konto des angemeldeten Benutzers
                            </p>
                        </li>
                        <li>
                            <p>
                                Exfiltration von kleinen und größeren Dateien über das Netzwerk
                            </p>
                        </li>
                        <li>
                            <p>
                                Herstellung eines persistenten Zugangs zu dem infizierten Client
                            </p>
                        </li>
                        <li>
                            <p>
                                Anwendung von Obfuskation, um lokale Schutzmechanismen zu umgehen
                            </p>
                        </li>
                        <li>
                            <p>
                                Verschiedene Auslieferungswege sind möglich (Excel-Dateien, E-Mail-Anhänge, HTADokumente)
                            </p>
                        </li>
                    </ul>
                </li>
                <li>
                    <p>
                        Durchführung des simulierten Ransomware-Angriffs
                    </p>

                    <ul>
                        <li>
                            <p>
                                Ausführen der Ransomware im User-Kontext des Client-IT-Systems des Auftraggebers
                            </p>
                        </li>
                        <li>
                            <p>
                                Auswertung der lokalen Ergebnisse des Ransomware-Befalls auf dem Client
                            </p>
                        </li>
                    </ul>
                </li>
                <li>
                    <p>
                        Die Ergebnisse der Sicherheitsanalyse dokumentiert usd in Form eines Abschlussberichts und unterbreitet
                        Maßnahmenempfehlungen zur Verbesserung des Sicherheitsniveaus des Auftraggebers.
                    </p>
                </li>
                <li>
                    <p>
                        Abschließend diskutiert usd die Ergebnisse mit den verantwortlichen Ansprechpartnern des Aufraggebers
                        im Rahmen einer Webkonferenz.
                    </p>
                </li>
            </ul>

            <form-actions slot="footer">
                <button @click="closeRansomwareModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="phishingModal">
            <img slot="title" src="./../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Phishing Angriff</h2>

            <p>
                Die usd überprüft in diesem Szenario mittels einer Phishing Awareness Kampagne, ob ein Hacker erfolgreich
                mittels präparierter Phishing E-Mails die Mitarbeiter des Auftraggebers angreifen und so Zugriff auf
                IT-Systeme und Daten des Auftraggebers erhalten kann. Die nachfolgenden Leistungen werden durch usd
                übernommen:
            </p>

            <p>
                Bei der Phishing Kampagne handelt es sich um zwei geplante, simulierte Angriffe per E-Mail, die usd im
                Namen des Auftraggebers durchführt. Im Rahmen der Angriffe wird keine Schadsoftware heruntergeladen, noch
                werden vertrauliche Daten ausgelesen oder missbraucht. Dem Auftraggeber entsteht kein Schaden.
            </p>

            <p>
                Stattdessen wird gemessen, wie viele der in der Zielgruppe befindlichen Mitarbeiter des Auftraggebers den
                Angriff nicht als solchen erkennen und den in der von usd präparierten Phishing E-Mail angebotenen Link
                anklicken. Der Klick des Links gilt im Rahmen der abschließenden Auswertung als erfolgreicher Angriff.

            </p>

            <p>
                Im Rahmen der Phishing Angriffe erhalten die Mitarbeiter des Auftraggebers eine von usd präparierte
                Phishing-E-Mail. Die Phishing-E-Mail führt die Mitarbeiter über einen Link auf eine Webseite. Die Zugriffe
                auf die Webseite werden zentral von usd überwacht und anonymisiert protokolliert. Es erfolgt keine
                personenbezogene Auswertung. Die Auswertung orientiert sich ausschließlich an den Sendegruppen. Es
                entstehen keine Nachteile für den einzelnen Mitarbeiter des Auftraggebers. Alle Daten werden nach
                erfolgreicher Abnahme des Berichts unwiderruflich gelöscht.
            </p>

            <p>
                Die Zuteilung zu den einzelnen Sendegruppen erfolgt in Absprache mit dem Auftraggeber, wobei eine Auswertung
                für jede Sendegruppe durchgeführt wird und keine personalisierte Mitarbeiterauswertung erfolgt.
            </p>

            <p>
                Die Umsetzung der Phishing Angriffe gliedert sich in die folgenden Phasen und Aufgaben:
            </p>
            <ul>
                <li>
                    <p>
                        Informationssammlung und Vorbereitung:
                    </p>

                    <ul>
                        <li>
                            <p>
                                Abstimmung der Inhalte der Phishing-E-Mails
                            </p>
                        </li>
                        <li>
                            <p>
                                Abstimmung der Zielgruppen und E-Mail Empfänger
                            </p>
                        </li>
                        <li>
                            <p>
                                Abstimmung der technischen Rahmenbedingungen und Kommunikationswege
                            </p>
                        </li>
                    </ul>
                </li>
                <li>
                    <p>
                        Umsetzung der Awareness-Kampagne:
                    </p>

                    <ul>
                        <li>
                            <p>
                                Implementierung der technischen Rahmenbedingungen
                            </p>
                        </li>
                        <li>
                            <p>
                                Entwicklung der Phishing-E-Mails (Inhalte, Design, Absender usw.)
                            </p>
                        </li>
                        <li>
                            <p>
                                Gestaltung der Landing-Page nach Abstimmung mit dem Auftraggeber
                            </p>
                        </li>
                        <li>
                            <p>
                                Gestaltung eines informativen One-Pagers zum Thema Phishing
                            </p>
                        </li>
                        <li>
                            <p>
                                Versand der Phishing-E-Mails an die vorab definierten Empfänger
                            </p>
                        </li>
                        <li>
                            <p>
                                Monitoring und anonymisierte Protokollierung der Webseitenzugriffe
                            </p>
                        </li>
                    </ul>
                </li>
                <li>
                    <p>
                        Sendegruppen-spezifische Auswertung und Dokumentation:
                    </p>

                    <ul>
                        <li>
                            <p>
                                Auswertung der Phishing Kampagne
                            </p>
                        </li>
                        <li>
                            <p>
                                Dokumentation der Ergebnisse in einem Bericht
                            </p>
                        </li>
                    </ul>
                </li>
            </ul>

            <p>
                Die Umsetzung der Phishing Awareness-Kampagne erfolgt durch einen Sicherheitsexperten der usd in enger
                Zusammenarbeit mit dem Auftraggeber. Die Leistungen der usd erfolgen mittels Telefonkonferenzen oder
                innerhalb der Büroräume der usd.
            </p>

            <form-actions slot="footer">
                <button @click="closePhishingModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>

        <modal class="info-modal" v-model="cloudSecurityModal">
            <img slot="title" src="./../../public/icon-info.svg" alt="info icon">
            <h2 slot="title">Cloud Angriff</h2>

            <p>
                Die usd überprüft in diesem Szenario mittels eines Cloud Security Audits, ob ein Hacker über die vom
                Auftraggeber genutzte Cloud Infrastruktur (Amazon AWS, Google Cloud Plattform oder Microsoft Azure) erfolgreich
                die dort betriebenen IT-Systeme und Applikationen sowie die dort verarbeiteten und gespeicherten Unternehmensdaten
                des Auftraggebers kompromittieren kann. Die nachfolgenden Leistungen werden durch usd übernommen:
            </p>

            <p>
                <u>
                    Kick-off und Vorbereitung
                </u>
            </p>

            <p>
                Die usd wird die Konfiguration der genutzten Cloud Services des Auftraggebers auf ihre Sicherheit überprüfen.
                Zu Beginn stellt usd einen Prüfkatalog auf Basis renommierter Best Practices im Kontext der Cloud Security
                bereit. Die Basis hierfür bilden in ihrer jeweils aktuellen Version:
            </p>

            <ul>
                <li>
                    <p>
                        Center for Internet Security (CIS) Foundation Benchmarks
                    </p>
                </li>
                <li>
                    <p>
                        Anforderungen des Payment Card Industry Data Security Standards (PCI DSS)
                    </p>
                </li>
                <li>
                    <p>
                        Best Practices des jeweiligen Cloud Anbieters
                    </p>
                </li>
                <li>
                    <p>
                        usd Best Practices Cloud Security
                    </p>
                </li>
            </ul>

            <p>
                Folgende Kategorien können abhängig von der individuellen Cloud-Umgebung des Auftraggebers im Rahmen des
                Cloud Security Audits von usd auf Schwachstellen und Verwundbarkeiten überprüft werden.
            </p>

            <ul>
                <li>
                    <p>
                        Sicherheitsarchitektur
                    </p>
                </li>
                <li>
                    <p>
                        Identitäts- und Access-Management (IAM)
                    </p>
                </li>
                <li>
                    <p>
                        Netzwerk
                    </p>
                </li>
                <li>
                    <p>
                        Speicher
                    </p>
                </li>
                <li>
                    <p>
                        Virtuelle Server und Serverless Computing
                    </p>
                </li>
                <li>
                    <p>
                        Datenbanken
                    </p>
                </li>
                <li>
                    <p>
                        Logging, Monitoring und Alerting
                    </p>
                </li>
            </ul>

            <p>
                usd stimmt den Prüfkatalog mit den verantwortlichen Ansprechpartnern des Auftraggebers ab und dokumentiert
                die Ergebnisse des Kick-offs in einem Protokoll.
            </p>

            <p>
                <u>
                    Durchführung des Cloud Security Audits
                </u>
            </p>

            <p>
                Daraufhin führt usd die Überprüfung der einzelnen Sicherheitsanforderungen gemäß abgestimmtem Prüfkatalog
                in Form eines Konfigurations-Reviews durch. Der Auftraggeber stellt usd hierzu entsprechende Benutzeraccounts
                (z.B. IAM Benutzer) zur Verfügung. Überprüft wird von usd maximal eine Cloud Subscription (AWS, Azure oder
                GCP) mit bis zu 5.000 Assets/Komponenten (z.B. Server, Netzwerke, Storage, Datenbanken, User).
            </p>

            <p>
                <u>
                    Berichterstellung und Debriefing
                </u>
            </p>

            <p>
                usd berichtet über die Ergebnisse des Cloud Security Audits schriftlich in Form eines Abschlussberichts.
                Dieser beinhaltet eine Management Summary sowie eine detaillierte Beschreibung identifizierter und ggf.
                ausgenutzter Schwachstellen und Sicherheitslücken. Für sämtliche identifizierten Schwachstellen enthält
                der Bericht eine individuelle Risikobewertung sowie konkrete, detaillierte Maßnahmenempfehlungen.
            </p>

            <p>
                Abschließend diskutiert usd die Ergebnisse mit den verantwortlichen Ansprechpartnern des Aufraggebers im
                Rahmen einer Webkonferenz.
            </p>

            <form-actions slot="footer">
                <button @click="closeCloudSecurityModal" class="primary button-modal">Schließen</button>
            </form-actions>
        </modal>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, nextTick, onMounted } from 'vue'
import { useRouter } from '@/composables/use-router'
import { useModal } from '@/composables/use-modal'
import { useProjectStore } from '@/store/projectStore'
import { useScenarioStore } from '@/store/scenarioStore'
import { usePriceStore } from '@/store/priceStore'
import { useScopeStore } from '@/store/scopeStore'
import { useOptionalStore } from '@/store/optionalStore'
import ConfiguratorHeader from '@/components/ConfiguratorHeader.vue'
import Progressbar from '@/components/Progressbar.vue'

export default defineComponent({
    name: 'ScenarioConfig',

    components: {
        ConfiguratorHeader,
        Progressbar
    },

    setup() {
        const { router, route } = useRouter()

        const { modal: confirmationModal, openModal: openConfirmationModal, closeModal: closeConfirmationModal } = useModal()
        const { modal: compromisedWebsiteModal, openModal: openCompromisedWebsiteModal, closeModal: closeCompromisedWebsiteModal } = useModal()
        const { modal: compromisedShopModal, openModal: openCompromisedShopModal, closeModal: closeCompromisedShopModal } = useModal()
        const { modal: localPrivilegeEscalationModal, openModal: openLocalPrivilegeEscalationModal, closeModal: closeLocalPrivilegeEscalationModal } = useModal()
        const { modal: compromisedNetworkModal, openModal: openCompromisedNetworkModal, closeModal: closeCompromisedNetworkModal } = useModal()
        const { modal: stolenLaptopModal, openModal: openStolenLaptopModal, closeModal: closeStolenLaptopModal } = useModal()
        const { modal: externalEnumerationModal, openModal: openExternalEnumerationModal, closeModal: closeExternalEnumerationModal } = useModal()
        const { modal: externalAttackModal, openModal: openExternalAttackModal, closeModal: closeExternalAttackModal } = useModal()
        const { modal: insiderAttackModal, openModal: openInsiderAttackModal, closeModal: closeInsiderAttackModal } = useModal()
        const { modal: ransomwareModal, openModal: openRansomwareModal, closeModal: closeRansomwareModal } = useModal()
        const { modal: phishingModal, openModal: openPhishingModal, closeModal: closePhishingModal } = useModal()
        const { modal: cloudSecurityModal, openModal: openCloudSecurityModal, closeModal: closeCloudSecurityModal } = useModal()

        const projectStore = useProjectStore()
        const projectId = projectStore.uuid

        const scenarioStore = useScenarioStore()
        const scenarioList = scenarioStore.getScenarios
        const selectedScenarios = scenarioStore.getSelectedScenarios

        onMounted(async() => {
            if (projectId.value === '') {
                const scopeStore = useScopeStore()
                const optionalStore = useOptionalStore()
                await projectStore.loadProject(route.value.params.id)
                await scenarioStore.updateSelectedScenarioList(route.value.params.id)
                selectedScenarios.value.forEach((selectedScenario: Scenario) => {
                    // Write true into scenarioList for all scenarios in selectedScenarios
                    scenarioList.value[selectedScenario.name] = true
                })
                await calculateScenarioPrice()
                await scopeStore.loadScope(route.value.params.id)
                await priceStore.fetchScopePrice(route.value.params.id)
                await optionalStore.loadOptionals(route.value.params.id)
                await priceStore.fetchOptionalsPrices(route.value.params.id)
            }
        })

        const processing = ref<boolean>(false)

        const priceStore = usePriceStore()
        const calculateScenarioPrice = async() => {
            await priceStore.calculateScenarioPrice(projectId.value)
            await priceStore.fetchProjectPrice(projectId.value)
        }

        const changeScenario = async(name: string) => {
            if (processing.value === false) {
                processing.value = true
                const exists = await scenarioStore.checkScenarioExists(projectId.value, name)
                if (!exists) {
                    await scenarioStore.saveScenario(projectId.value, name)
                } else {
                    await scenarioStore.deleteScenario(projectId.value, name)
                }
                await scenarioStore.updateSelectedScenarioList(projectId.value)
                await calculateScenarioPrice()
                scenarioError.value = Object.values(scenarioList.value).every(value => !value) ? 'Dies ist ein Pflichtfeld.' : ''
                processing.value = false
            }
        }

        const confirmBack = () => {
            openConfirmationModal()
        }

        const scenarioError = ref<string>('')

        const validate = async() => {
            scenarioError.value = Object.values(scenarioList.value).every(value => !value) ? 'Dies ist ein Pflichtfeld.' : ''

            if (scenarioError.value === '') {
                return true
            } else {
                await nextTick()
                const element = document.querySelector('.invalid-feedback')?.parentNode?.parentNode as HTMLElement
                element.scrollIntoView({behavior: 'smooth'})
            }
        }

        const gotoProjectType = async() => {
            window.scrollTo(0, 0)
            closeConfirmationModal()
            await router.push({ name: 'new project', params: { id: projectId.value } })
        }

        const gotoProjectScope = async() => {
            if (await validate()) {
                window.scrollTo(0, 0)
                const scopeStore = useScopeStore()
                await scopeStore.initializeScope(projectId.value)
                await router.push({ name: 'project scope', params: { id: projectId.value } })
            }
        }

        return {
            processing,
            scenarioList,
            scenarioError,
            changeScenario,
            confirmBack,
            gotoProjectType,
            gotoProjectScope,
            confirmationModal,
            openConfirmationModal,
            closeConfirmationModal,
            compromisedWebsiteModal,
            openCompromisedWebsiteModal,
            closeCompromisedWebsiteModal,
            compromisedShopModal,
            openCompromisedShopModal,
            closeCompromisedShopModal,
            localPrivilegeEscalationModal,
            openLocalPrivilegeEscalationModal,
            closeLocalPrivilegeEscalationModal,
            compromisedNetworkModal,
            openCompromisedNetworkModal,
            closeCompromisedNetworkModal,
            stolenLaptopModal,
            openStolenLaptopModal,
            closeStolenLaptopModal,
            externalEnumerationModal,
            openExternalEnumerationModal,
            closeExternalEnumerationModal,
            externalAttackModal,
            openExternalAttackModal,
            closeExternalAttackModal,
            insiderAttackModal,
            openInsiderAttackModal,
            closeInsiderAttackModal,
            ransomwareModal,
            openRansomwareModal,
            closeRansomwareModal,
            phishingModal,
            openPhishingModal,
            closePhishingModal,
            cloudSecurityModal,
            openCloudSecurityModal,
            closeCloudSecurityModal
        }
    }
})
</script>

<style lang="scss" scoped>
@import "../styles/breakpoints";

.scenario-header {
    @include default-padding();
    background-color: var(--color-white);
    h2 {
        color: var(--color-brand-primary);
    }
}

.scenario-choices {
    background-color: var(--color-lightest-grey);
    @include default-padding();
    h2 {
        color: var(--color-brand-primary);
    }
}

.scenario-choices-title {
    h2 {
        color: var(--color-brand-primary);
    }
}

.scenario-card-container {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
    margin-top: 2em;
    label {
        width: 31%;
        max-width: 450px;
        display: flex;
        align-items: stretch;
        margin: 1%;
    }
}

.scenario-card {
    display: none;
}

.scenario-card-input {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin: 0;
    border: 2px solid var(--color-light-grey);
    width: 100%;
}

.scenario-card-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.scenario-card:checked + .scenario-card-input {
    border-color: var(--color-brand-secondary);
    background-color: var(--color-brand-secondary);
    color: var(--color-white);

    .card-text {
        h2 {
            color: var(--color-white);
        }
    }
    .button-info {
        background: url(../../public/icon-info-weiss.svg) no-repeat right center;
        background-size: 30px;
        color: var(--color-white);
        &:hover {
            background: url(../../public/icon-info-weiss.svg) no-repeat right center;
            background-size: 30px;
        }
    }
}

.card-header-image {
    margin: -2px;
    width: calc(100% + 4px);
}

.card-text {
    flex: 1 auto;
    padding: 2em;
    h2 {
        color: var(--color-brand-secondary);
        text-align: center;
    }
}

@media screen and (max-width: 1100px) {
    .scenario-card-container {
        justify-content: space-between;

        label {
            width: 48%;
            max-width: initial;
            margin: 2em 0;
        }
    }
}

@media screen and (max-width: $bp-small) {
    .scenario-card-container {
        label {
            width: 100%;
        }
    }
}
</style>
