<template>
    <div class="support-footer">
        <h2>
            Sie haben Fragen oder benötigen Unterstützung? Wir helfen Ihnen gerne weiter!
        </h2>
        <div class="support-contact">
            <img src="../../public/contact-heyne.png">
            <div class="support-contact-text">
                <strong>
                    Daniel Heyne
                </strong>
                <p>
                    Security Consultant Pentest
                </p>
                <p>
                    OSCP, OSCE
                </p>
                <p>
                    vertrieb@usd.de | +49 6102 8631-190
                </p>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'SupportFooter'
})
</script>

<style lang="scss">
@import "../styles/breakpoints";

.support-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url("../../public/image-footer.jpg") no-repeat;
    background-size: cover;
    min-height: 250px;
    padding: 1em;

    h2 {
        font-weight: 400;
        color: var(--color-brand-primary);
        padding-bottom: 10px;
        text-align: center;
    }
    .support-contact {
        display: flex;
        justify-content: center;
        img {
            width: 120px;
            height: 120px;
        }
        p {
            margin: 0;
        }
        .support-contact-text {
            text-align: left;
            padding-left: 10px;
            font-size: 20px;
            color: var(--color-anthracite);
        }
    }
}

@media screen and (max-width: $bp-medium) {
    .support-footer .support-contact .support-contact-text {
        font-size: 16px;
    }
}
</style>
