<template>
    <div class="summary-asset-scope">
        <expansion-panel>
            <expansion-panel-content>
                <template v-slot:header>
                    <h3 class="summary-asset-scope-header">
                        Scope
                    </h3>
                </template>
                <template>
                    <div class="summary-asset-scope-container">
                        <p>
                            <strong>
                                Nachfolgend werden die zu überprüfenden Assets aufgelistet.
                            </strong>
                        </p>

                        <div v-if="systemList.length > 0">
                            <div class="summary-asset-scope">
                                <img src="../../../public/images-assets/asset-networkcomponent.jpg" alt="bild asset system">

                                <div class="summary-asset-scope-text">
                                    <p>
                                        <strong>
                                            IT-System(e):
                                        </strong>
                                    </p>

                                    <p>
                                        Überprüft werden Server, Workstations sowie Netzwerkkomponenten (bspw. Firewalls oder
                                        Switches) verschiedenster Betriebssysteme (z.B. Windows und Linux).
                                    </p>
                                </div>
                            </div>

                            <hr>

                            <div class="asset-container" v-for="asset in systemList" :key="asset.id">
                                <p class="asset-name">
                                    <u>
                                        {{ asset.name }}
                                    </u>
                                </p>

                                <div class="asset-element-container">
                                    <p class="asset-element-key">
                                        <strong>
                                            Beschreibung:
                                        </strong>
                                    </p>
                                    <p>
                                        {{ asset.description }}
                                    </p>
                                </div>

                                <div class="asset-element-container">
                                    <p class="asset-element-key">
                                        <strong>
                                            Anzahl IP-Adressen:
                                        </strong>
                                    </p>
                                    <p v-if="asset.config.ipAmount > 1">
                                        {{ asset.config.ipAmount }} IP-Adressen
                                    </p>
                                    <p v-else>
                                        {{ asset.config.ipAmount }} IP-Adresse
                                    </p>
                                </div>

                                <hr>
                            </div>
                        </div>

                        <div v-if="webappList.length > 0">
                            <div class="summary-asset-scope">
                                <img src="../../../public/images-assets/asset-webapplication.jpg" alt="bild asset webapp">

                                <div class="summary-asset-scope-text">
                                    <p>
                                        <strong>
                                            Webapplikation:
                                        </strong>
                                    </p>

                                    <p>
                                        Überprüft werden Webapplikationen in verschiedenen Programmiersprachen (z.B. Java,
                                        php oder ASP), und Frameworks (z.B. Angular, Spring, Ruby oder Django).
                                    </p>
                                </div>
                            </div>

                            <hr>

                            <div class="asset-container" v-for="asset in webappList" :key="asset.id">
                                <p class="asset-name">
                                    <u>
                                        {{ asset.name }}
                                    </u>
                                </p>

                                <div class="asset-element-container">
                                    <p class="asset-element-key">
                                        <strong>
                                            Beschreibung:
                                        </strong>
                                    </p>
                                    <p>
                                        {{ asset.description }}
                                    </p>
                                </div>

                                <div class="asset-element-container">
                                    <p class="asset-element-key">
                                        <strong>
                                            Art der Applikation:
                                        </strong>
                                    </p>
                                    <p v-if="asset.config.type === 'website'">
                                        Statische Webanwendung / Webseite
                                    </p>
                                    <p v-else-if="asset.config.type === 'webapp'">
                                        Dynamische Webanwendung
                                    </p>
                                </div>

                                <div v-if="asset.config.type === 'webapp'" class="asset-element-container">
                                    <p class="asset-element-key">
                                        <strong>
                                            Größe der Applikation:
                                        </strong>
                                    </p>
                                    <p v-if="asset.config.size === 'small'">
                                        Klein (bis zu 20 Funktionen)
                                    </p>
                                    <p v-else-if="asset.config.size === 'medium'">
                                        Mittel (mehr als 20, aber weniger als 40 Funktionen)
                                    </p>
                                    <p v-else-if="asset.config.size === 'large'">
                                        Groß (mehr als 40, aber weniger als 60 Funktionen)
                                    </p>
                                    <p v-else-if="asset.config.size === 'very large'">
                                        Sehr groß (mehr als 60 Funktionen)
                                    </p>
                                </div>

                                <hr>
                            </div>
                        </div>

                        <div v-if="webserviceList.length > 0">
                            <div class="summary-asset-scope">
                                <img src="../../../public/images-assets/asset-webservice.jpg" alt="bild asset webservice">

                                <div class="summary-asset-scope-text">
                                    <p>
                                        <strong>
                                            Webservice / API:
                                        </strong>
                                    </p>

                                    <p>
                                        Überprüft werden Webschnittstellen gängiger state-of-the-art Technologien (z.B. SOAP,
                                        REST oder JSON) inklusive der implementierten Requests und Parameter.
                                    </p>
                                </div>
                            </div>

                            <hr>

                            <div class="asset-container" v-for="asset in webserviceList" :key="asset.id">
                                <p class="asset-name">
                                    <u>
                                        {{ asset.name }}
                                    </u>
                                </p>

                                <div class="asset-element-container">
                                    <p class="asset-element-key">
                                        <strong>
                                            Beschreibung:
                                        </strong>
                                    </p>
                                    <p>
                                        {{ asset.description }}
                                    </p>
                                </div>

                                <div class="asset-element-container">
                                    <p class="asset-element-key">
                                        <strong>
                                            Größe des Webservice:
                                        </strong>
                                    </p>
                                    <p v-if="asset.config.size === 'very small'">
                                        Sehr klein (bis zu 5 Endpunkte)
                                    </p>
                                    <p v-else-if="asset.config.size === 'small'">
                                        Klein (mehr als 5, aber weniger als 10 Endpunkte)
                                    </p>
                                    <p v-else-if="asset.config.size === 'medium'">
                                        Mittel (mehr als 10, aber weniger als 30 Endpunkte)
                                    </p>
                                    <p v-else-if="asset.config.size === 'large'">
                                        Groß (mehr als 30, aber weniger als 50 Endpunkte)
                                    </p>
                                    <p v-else-if="asset.config.size === 'very large'">
                                        Sehr groß (mehr als 50 Endpunkte)
                                    </p>
                                </div>

                                <hr>
                            </div>
                        </div>

                        <div v-if="mobileList.length > 0">
                            <div class="summary-asset-scope">
                                <img src="../../../public/images-assets/asset-mobileapplication.jpg" alt="bild asset mobile">

                                <div class="summary-asset-scope-text">
                                    <p>
                                        <strong>
                                            Mobile App:
                                        </strong>
                                    </p>

                                    <p>
                                        Überprüft werden mobile Applikationen für die Betriebssysteme iOS und Android, wie
                                        sie beispielsweise auf Smartphones oder Tablets zum Einsatz kommen.
                                    </p>
                                </div>
                            </div>

                            <hr>

                            <div class="asset-container" v-for="asset in mobileList" :key="asset.id">
                                <p class="asset-name">
                                    <u>
                                        {{ asset.name }}
                                    </u>
                                </p>

                                <div class="asset-element-container">
                                    <p class="asset-element-key">
                                        <strong>
                                            Beschreibung:
                                        </strong>
                                    </p>
                                    <p>
                                        {{ asset.description }}
                                    </p>
                                </div>

                                <div class="asset-element-container">
                                    <p class="asset-element-key">
                                        <strong>
                                            App-Typ:
                                        </strong>
                                    </p>
                                    <p v-if="asset.config.type === 'native'">
                                        Native Mobile App
                                    </p>
                                    <p v-else-if="asset.config.type === 'hybrid'">
                                        (Progressive/hybride) Web App
                                    </p>
                                </div>

                                <div v-if="asset.config.type === 'native'">
                                    <div class="asset-element-container">
                                        <p class="asset-element-key">
                                            <strong v-if="asset.config.nativeOperatingSystem.length > 1">
                                                Betriebssysteme:
                                            </strong>
                                            <strong v-else>
                                                Betriebssystem:
                                            </strong>
                                        </p>
                                        <p v-if="asset.config.nativeOperatingSystem.includes('android') && asset.config.nativeOperatingSystem.length === 1">
                                            Android
                                        </p>
                                        <p v-else-if="asset.config.nativeOperatingSystem.includes('ios') && asset.config.nativeOperatingSystem.length === 1">
                                            iOS
                                        </p>
                                        <p v-else-if="asset.config.nativeOperatingSystem.length === 2">
                                            Android, iOS
                                        </p>
                                    </div>

                                    <div class="asset-element-container">
                                        <p class="asset-element-key">
                                            <strong>
                                                Architektur:
                                            </strong>
                                        </p>
                                        <p v-if="asset.config.nativeArchitecture === 'client'">
                                            Lokale App
                                        </p>
                                        <p v-else-if="asset.config.nativeArchitecture === 'client-server'">
                                            Client-Server
                                        </p>
                                    </div>
                                </div>

                                <div v-else-if="asset.config.type === 'hybrid'">
                                    <div class="asset-element-container">
                                        <p class="asset-element-key">
                                            <strong>
                                                App-Größe:
                                            </strong>
                                        </p>
                                        <p v-if="asset.config.hybridSize === 'small'">
                                            Klein (bis zu 20 Funktionen)
                                        </p>
                                        <p v-else-if="asset.config.hybridSize === 'medium'">
                                            Mittel (mehr als 20, aber weniger als 40 Funktionen)
                                        </p>
                                        <p v-else-if="asset.config.hybridSize === 'large'">
                                            Groß (mehr als 40, aber weniger als 60 Funktionen)
                                        </p>
                                        <p v-else-if="asset.config.hybridSize === 'very large'">
                                            Sehr groß (mehr als 60 Funktionen)
                                        </p>
                                    </div>
                                </div>

                                <hr>
                            </div>
                        </div>

                        <div v-if="fatclientList.length > 0">
                            <div class="summary-asset-scope">
                                <img src="../../../public/images-assets/asset-fatclient.jpg" alt="bild asset fatclient">

                                <div class="summary-asset-scope-text">
                                    <p>
                                        <strong>
                                            Fat Client:
                                        </strong>
                                    </p>

                                    <p>
                                        Überprüft werden (native) Sever/Desktop Anwendungen (Fat/Thick Clients) in den
                                        Programmiersprachen C/C++, .NET oder Java.
                                    </p>
                                </div>
                            </div>

                            <div class="asset-container" v-for="asset in fatclientList" :key="asset.id">
                                <hr>

                                <p class="asset-name">
                                    <u>
                                        {{ asset.name }}
                                    </u>
                                </p>

                                <div class="asset-element-container">
                                    <p class="asset-element-key">
                                        <strong>
                                            Beschreibung:
                                        </strong>
                                    </p>
                                    <p>
                                        {{ asset.description }}
                                    </p>
                                </div>

                                <div class="asset-element-container">
                                    <p class="asset-element-key">
                                        <strong>
                                            Größe des Fat Clients:
                                        </strong>
                                    </p>
                                    <p v-if="asset.config.size === 'small'">
                                        Klein (bis zu 20 Funktionen)
                                    </p>
                                    <p v-else-if="asset.config.size === 'medium'">
                                        Mittel (mehr als 20, aber weniger als 40 Funktionen)
                                    </p>
                                    <p v-else-if="asset.config.size === 'large'">
                                        Groß (mehr als 40, aber weniger als 60 Funktionen)
                                    </p>
                                    <p v-else-if="asset.config.size === 'very large'">
                                        Sehr groß (mehr als 60 Funktionen)
                                    </p>
                                </div>

                                <div class="asset-element-container">
                                    <p class="asset-element-key">
                                        <strong v-if="asset.config.operatingSystem.length > 1">
                                            Betriebssysteme:
                                        </strong>
                                        <strong v-else>
                                            Betriebssystem:
                                        </strong>
                                    </p>
                                    <p v-if="asset.config.operatingSystem.includes('windows') && asset.config.operatingSystem.length === 1">
                                        Windows
                                    </p>
                                    <p v-else-if="asset.config.operatingSystem.includes('linux') && asset.config.operatingSystem.length === 1">
                                        Linux
                                    </p>
                                    <p v-else-if="asset.config.operatingSystem.includes('macos') && asset.config.operatingSystem.length === 1">
                                        MacOS
                                    </p>
                                    <p v-else-if="asset.config.operatingSystem.includes('windows') && asset.config.operatingSystem.includes('linux') && asset.config.operatingSystem.length === 2">
                                        Windows, Linux
                                    </p>
                                    <p v-else-if="asset.config.operatingSystem.includes('windows') && asset.config.operatingSystem.includes('macos') && asset.config.operatingSystem.length === 2">
                                        Windows, MacOS
                                    </p>
                                    <p v-else-if="asset.config.operatingSystem.includes('linux') && asset.config.operatingSystem.includes('macos') && asset.config.operatingSystem.length === 2">
                                        Linux, MacOS
                                    </p>
                                    <p v-else-if="asset.config.operatingSystem.length === 3">
                                        Windows, Linux, MacOS
                                    </p>
                                </div>

                                <div class="asset-element-container">
                                    <p class="asset-element-key">
                                        <strong>
                                            Architektur:
                                        </strong>
                                    </p>
                                    <p v-if="asset.config.architecture === 'client'">
                                        Client Anwendung
                                    </p>
                                    <p v-else-if="asset.config.architecture === 'client-server'">
                                        Client-Server Anwendung
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </template>
            </expansion-panel-content>
        </expansion-panel>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useAssetStore } from '@/store/assetStore'
import ExpansionPanel from '@/components/ExpansionPanel.vue'
import ExpansionPanelContent from '@/components/ExpansionPanelContent.vue'

export default defineComponent({
    name: 'AssetScopeSummary',

    components: {
        ExpansionPanel,
        ExpansionPanelContent
    },

    setup() {
        const assetStore = useAssetStore()
        const assetList = assetStore.getAll

        const systemList = computed(() => {
            return assetList.value.filter(asset => asset.type === 'system')
        })
        const webappList = computed(() => {
            return assetList.value.filter(asset => asset.type === 'webapp')
        })
        const webserviceList = computed(() => {
            return assetList.value.filter(asset => asset.type === 'webservice')
        })
        const mobileList = computed(() => {
            return assetList.value.filter(asset => asset.type === 'mobile')
        })
        const fatclientList = computed(() => {
            return assetList.value.filter(asset => asset.type === 'fatclient')
        })

        return {
            systemList,
            webappList,
            webserviceList,
            mobileList,
            fatclientList
        }
    }
})
</script>

<style lang="scss" scoped>
.summary-asset-scope {
    h3 {
        color: var(--color-brand-secondary);
        font-size: 22px;
    }
    .summary-asset-scope-container {
        display: flex;
        flex-direction: column;
        margin: 1em 0;
        align-items: flex-start;

        .asset-container {
            width: 100%;

            .asset-name {
                font-size: 20px;
            }
            .asset-element-container {
                display: flex;

                .asset-element-key {
                    width: 30%;
                }
            }
        }
        .summary-asset-scope {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            margin: 1em 0;

            img {
                max-width: 200px;
                margin-right: 1em;
            }
        }
    }
}
</style>
